import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import { Link } from 'react-router-dom';

import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import ApTree from 'common/ApTree/ApTree.js';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApSteps from 'common/ApSteps/ApSteps.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import ApTimeline from 'common/ApTimeline/ApTimeline.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import ApUserImage  from 'common/ApUserImage/ApUserImage.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { ApInputSmall } from 'common/ApInputSmall/ApInputSmall.js';
import { Collapse } from 'react-bootstrap';
import PurchaseOrderTooltip  from 'modules/Storage/common/PurchaseOrderTooltip/PurchaseOrderTooltip.js';
import NewPurchaseOrder from 'modules/Storage/PurchaseOrders/NewPurchaseOrder/NewPurchaseOrder.js';

import ComponentTooltip from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';


import ProjectStorage from 'modules/Storage/Locations/ProjectStorage.js';
import Payments from 'modules/Projects/Payments/Payments.js';
import Production from 'modules/Projects/Production/Production.js';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';

import { ApReactTable,
    colPreset }     from 'common/ApReactTable/ApReactTable.js';


import { findItemById
       , mapTree
       , iterateTree
       , mapChildrenOfId
       , editItemById
       , sqlToDateInput
       , formatMoney
       , getLastCodePart
       , objectIsEmpty
       , keyExists
       , errorPopper 
       , groupify
       , tr
       , currentLang, 
       handleBlobResponse} from 'services/Helpers/Helpers.js';

import { getTypeIcon } from 'modules/Storage/common/StorageHelpers.js';

import Head from '../Head/Head.js';

import AddMultipleComponents from './AddMultipleComponents/AddMultipleComponents.js';
import TallyModal from 'modules/Projects/TallyModal/TallyModal.js';
import ComponentEdit from 'modules/Storage/Components/ComponentEdit/ComponentEdit.js';

import ComponentTable from './ComponentTable/ComponentTable.js';
import Personnel from './Personnel/Personnel.js';

import CreateStorage from 'modules/Storage/Locations/EditStorage/CreateStorage.js';


import './Management.css';
import ComponentNeeds from './ComponentNeeds/ComponentNeeds.js';
import ComponentNeedsTabs from './ComponentNeeds/ComponentNeedsTabs.js';
import ContractBillingPage from 'modules/ContractBilling/ContractBillingPage.js';


const now = moment();

const tabIds = {
    0: "projects",
    1: "schedule",
    2: "personnel",
    3: 'buy',
    4: 'product',
    5: 'sell',
};

const detailOptions = [

    { group: tr('rows'), value: "hours", label: tr('works'), decimals: 0, unit: tr('pcs') },
    { group: tr('rows'), value: "items", label: tr('components_alt'), decimals: 0, unit: tr('pcs') },
    { group: tr('rows'), value: "expenses", label: tr('costs'), decimals: 0, unit: tr('pcs') },

    { group: tr('work'), value: "hours_alloc", label: tr('allocated'), decimals: 1, unit: 'h' },
    { group: tr('work'), value: "hours_entry", label: tr('recorded'), decimals: 1, unit: 'h' },
    { group: tr('work'), value: "hours_remaining", label: tr('remaining'), decimals: 1, unit: 'h' },
    { group: tr('work'), value: "hours_alloc_used_percent", label: `${tr('used')} %`, decimals: 1, unit: '%' },

    { group: tr('work_estimate'), value: "hours_estimate_percent", label: `${tr('estimate')} %`, decimals: 1, unit: '%' },
    { group: tr('work_estimate'), value: "hours_estimate_count", label: tr('hours_estimate'), decimals: 1, unit: 'h' },
    //{ group: 'Työ arvio', value: "hours_estimate_count_remaining", label: "Jäljellä", decimals: 1, unit: 'h' },

    // { group: 'Työ arvio', value: "hours_future_difference_count", label: "Ero budjettiin", decimals: 1, unit: 'h' },
    { group: tr('work_estimate'), value: "hours_future_difference_percent", label: `${tr('over_or_under')} %`, decimals: 1, unit: '%' },

    { group: tr('component'), value: "items_installed", label: tr('installed'), decimals: 1, unit: '%' },

    { value: "status", label: tr('status') },
    { value: "schedule", label: tr('scheduling') },
    { value: "manager", label: tr('manager') },
    // { value: "workhour_approver", label: tr('approver_of_hours') }, // Pois koska voi olla useampi kuin yksi hyväksyjä
    { value: "comment_count", label: tr('comment_count'), decimals: 0, unit: tr('pcs') },

    // { value: "hours_estimate_count", label: "TMP1", decimals: 1, unit: '' },
    // { value: "hours_future_count", label: "TMP2", decimals: 2, unit: '' },
    //{ value: "hours_future_percent", label: "TMP3", decimals: 2, unit: '' },

    { group: tr('customer_billing_shares'), value: "billing_margin_travel", label: tr('travel_expenses') },
    { group: tr('customer_billing_shares'), value: "billing_margin_expense", label: tr('expense_compensation') },
    { group: tr('customer_billing_shares'), value: "billing_margin_allowance", label: tr('daily_allowances') },
];

// In order to confuse our enemies the different categories have slightly different names in every place
const sel = [
    { type: 'work', pName:  'works',      sName: 'selectedWorks', title: tr('work') },
    { type: 'item', pName:  'components', sName: 'selectedItems', title: '' },
    { type: 'other', pName: 'expenses',   sName: 'selectedExpenses' },
    { type: 'storage', pName: 'storage',   sName: 'selectedStorage', title: '' },
];

// Invalid value to zero
const iz = ( value ) => ( parseFloat( value ) ? parseFloat( value ) : 0 );

// Percent from two object values
const ps = ( o, v1, v2 ) => {
    let tmp = ( ( o[ v1 ] / o[ v2 ] ) * 100)
    if( isNaN( tmp ) || tmp === 0 ) return 0;
    return tmp;
};

class ProjectManagement extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {

            mainParentProject: null,
            newPoShow: false,
            newPoData: null,
            showStorageTransfer: false,
            storageTransferComponents: null,
            storageTransferWarning: null,

            selectedItems: [],
            selectedWorks: [],
            selectedExpenses: [],
            selectedStorage: [],

            storageBalance: [],
            projectStorageExists: false,
            newInstallationComponentAdded: null,

            locked: false,
            has_offer: false,

            treeUnsaved: false,
            tree: [],
            selectedProject: null,

            selectedDetailCumulative: true,
            selectedDetail: 'hours',

            colFilters: {
                offer: false,
                offerSell: false,
            },

            colShow: {
                componentAllocAs: 'count',
                componentInstalledAs: 'count',
                componentOfferAs: 'all',
                componentOfferSellAs: 'all',
                workAllocAs: 'count',
                workEntryAs: 'count',
                workOfferAs: 'all',
                workOfferSellAs: 'all',
                expenseAllocAs: 'all',
                expenseOfferAs: 'all',
                expenseOfferSellAs: 'all',
            },

            currencySign: auth.getCurrencySign(),

            removeComponentConfirm: {},
            removeProjectConfirm: {},
            removeProjectStorageConfirm: {},

            markProjectDone: {},
            downloadQR: {},
            subProjectEdit: {},
            subProjectCopy: {},
            divideComponent: {},

            addMultiple: {},

            tallyModal: {},

            componentEdit: {},

            storageType : 'B',
            selectedSupplierId : '',
            suppliers: null,
            supplierSelected: false,

            transports: '',
            bTranport: false,

            entries_only_if_in_personnel: false,
            project_ext_work_enabled: false,
            project_auto_approve_enabled: false,
            work_progress_in_timetracking_enabled: false,
            project_storage_none: false,
            customer_mandatory: false,

            main_project_logging_disabled: false,
            project_storage_options: '',
            auto_use_storage_id: null,

            personnel:[],

            //Henkilöstön muuttujat
            personnelGroups: [],
            personnelItems: [],
            openPersonnelGroups: ['holiday', 'available', 'absence'],
            openPersonnelParentGroups: [],
            editedPersonnelItems: [],
            deletedPersonnelItems: [],
            personnelWeeklyMaxHours: [],
            projectsStart: moment(),
            projectsEnd: moment(),
            dateMarkers: [],
            groups:[],

            distanceTestMetres: null,
            
        }

        this.newCounter = 0;
        this.nullReceiver();

        autoBind(this);
    }

    onTabChange( tabId )
    {
        this.props.history.replace( `/projects/${ this.props.projectId }/management/${ tabIds[ tabId ] }`);
    }

    getTabId( param )
    {

        if( param in tabIds )
            return parseInt( param, 10 );
        return parseInt( Object.keys( tabIds ).find( key => tabIds[ key ] === param ), 10 );
    }
    
    handleChange( key, value )
    {
        let settings = { ...this.state.subProjectCopy };
        settings[ key ] = value;
        settings['muokkaus']=true;
        this.setState({ "subProjectCopy" : settings });
    }

    componentDidMount()
    {
        this.getProject();
        this.getExistingStorage();

        //this.getTransports();
        setTimeout(() => {
            if (this.state.newInstallationComponentAdded) {
                this.getProject(); //fetch again to update component installations
            }
        }, 1000)
    }

    componentDidUpdate( prevProps )
    {
        if( prevProps.projectId !== this.props.projectId )
        {
            this.getProject();

            setTimeout(() => {
                if (this.state.newInstallationComponentAdded) {
                    this.getProject(); //fetch again to update component installations
                }
            }, 1000)
        }
    }

    getExistingStorage() {
        const pId = this.props.projectId;
        api({
            method: 'get',
            url: `project/management/${ pId }/getExistingStorage`,
        }).then((response) => {
            if(response==1) {
                this.setState({
                    projectStorageExists : true,
                })
            }  
        });
    }

    doProjectStorage()
    {
       const pId = this.props.projectId;
       const data = { project_id: pId }
       
       api({
           method: 'post',
           url: `project/management/doProjectStorage`,
           data: data
       }).then((response) => {
            if(response==1) {
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <div>{ tr('project_storage_created') }</div>,
                });
                //this.updateStorageBalance(pId); //Päivitä projektivarastoon kaikki tarvittavat komponentit

            } else if (response == 2) { //Jos projektivarasto jo olemassa
                window.emitter.emit('popper', {
                    type: 'warning',
                    content: <div>{ tr('project_storage_already_exists_id') }</div>,
                });
            } else {
                console.warn(`Projektivaraston luonnin koodi: [${response}]`);
                window.emitter.emit('popper', {
                    type: 'danger',
                    content: <div>{ tr('failed_to_create_project_storage') }</div>,
                });
            }
       }).catch((error) => {
            console.warn(`Projektivaraston luonti epäonnistui: [${error}]`);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <div>{ tr('failed_to_create_project_storage') }</div>,
            });
        });
    }

    updateStorageBalance(pId) {
        api({
            method: 'get',
            url: `project/management/${pId}/updateProjectStorage`,
        }).then((response) => {
            if (response != 0) {
                setTimeout(this.getStorageBalance(), 1000);
                this.renderComponentNeeds();
            }
        }).catch((error) => {
            console.error(`Projektivaraston saldojen päivitys ei onnistunut! ${error}`);
            this.setState({ loading: false });
        });
    }
    

    renderPaymentsAndPosts() {
        let data = [];
        data.projectId = this.props.projectId;
        data.currencySign = this.state.currencySign;

        const proj = this.state.tree.length ? this.state.tree[0] : null;
        data.project = proj;
        data.project_code = proj.project_code;
        data.project_name = proj.name;
        data.total_sum = proj.totalbudget;
        
        return <Payments
            data={data}
        />	
    }
    
    
    renderProduction() {
        let data = [];
        const project = this.state.selectedProject;

        return <Production
        	project={ project }
        	productionRemoveClick={ this.productionRemoveClick }
        	productionChangeProductionCount = { this.productionChangeProductionCount }
        	productionAddStorageProduction = { this.productionAddStorageProduction }
        	productionChangeFinalLocation = { this.productionChangeFinalLocation }
        	productionAdd={ this.productionAdd }
	        openComponentEdit={ this.openComponentEdit }
	        loading={ this.state.loading }
	        locked={ this.state.locked }
        />
    }

    getTransports()
    {
        const pId = this.props.projectId;
        //console.log('prod id: ', pId);
        api({
            method: 'get',
            url: `storage/delivery/${ pId }/getTransportsByProjectId`,
        }).then((response) => {
            this.setState({
                transports : response,
            })
            //console.log('transports: ', response);
        });
    }

    getProject(unsaved=false)
    {
        this.setState( {loading: true } );
        api({
            method: 'get',
            url: `project/management/${ this.props.projectId }/tree`,
        }).then((response) => {
            let tree = response.tree;
            let oldTree = this.state.tree

            // console.log("RESPONSE", response);
            //console.log(tree[0])

            response.personnel.forEach(user => {
                if (user.holiday) {
                    user.holiday.forEach(holiday => {
                        user.allocations.push(...this.splitHoliday(holiday));
                    });
                }
            });

            this.setState({mainParentProject: tree[0]})

            const oldProjectId = ( oldTree.length > 0 ) ? oldTree[ 0 ].id : null;
            const newProjectId = ( tree.length > 0 ) ? tree[ 0 ].id : null;
            const sameProject = ( oldProjectId === newProjectId );

            let selectedString = null;
            let selectedProject = null;
            let oldTreeOpenNodes = [];

            // When we are getting data for same project that is currently open, we want to keep the nodes currenly
            // open open, and keep the same subproject seleted This makes string of open project indexes and puts
            // them in array, later we check if array contains those indexes we mark that node as open
            // also save the selected node string and set it later on
            if( sameProject )
            {
                iterateTree( oldTree, ( item, parent, indexArray ) => {
                    const tmp = indexArray.join('-');
                    if( item.__selected )  selectedString = tmp;
                    if( item.__open ) oldTreeOpenNodes.push( tmp );
                });
            }

            tree = mapTree( tree, ( item, parent, indexArray ) => {
                const tmp = indexArray.join('-');
                item.__schedule_child_open = false;
                // Seur. kommentoitu, Front 128 28.1.2020 JAr
                //if( oldTreeOpenNodes.includes( tmp ) )
                    item.__open = true;
                
                if( selectedString === tmp )
                    selectedProject = item;
                return item;
            });

            selectedProject = selectedProject || tree[0];
            selectedProject.__selected = true;

            const projectsStart = moment(tree[0].start).startOf("isoWeek");
            const projectsEnd = tree[0].end
                ? tree[0].end
                : moment().add(1, "year");

            this.setState({ selectedProject: selectedProject, groups: response.groups, }, () => {
                this.calculateTree( tree );
                this.setState({
                    subproject_code_default_length: response.subproject_code_default_length,
                    project_ext_work_enabled: response.project_ext_work_enabled,
                    project_auto_approve_enabled: response.project_auto_approve_enabled,
                    work_progress_in_timetracking_enabled: response.work_progress_in_timetracking_enabled,
                    entries_only_if_in_personnel: response.entries_only_if_in_personnel,
                    project_storage_none: response.project_storage_none,
                    personnel: response.personnel,
                    personnel_assignments: response.personnel_assignments,
                    personnel_assignments_sub_projects: response.personnel_assignments_sub_projects,
                    project_storage_options: response.project_storage_options,
                    auto_use_storage_id: response.auto_use_storage_id,
                    main_project_logging_disabled: response.main_project_logging_disabled,
                    show_remaining_hours: response.show_remaining_hours,
                    remaining_hours_show_selected_projects: response.remaining_hours_show_selected_projects,
                    use_tax_groups: response.use_tax_groups,

                    treeUnsaved: unsaved,
                    loading: false,
                    newInstallationComponentAdded: response.new_installation_components, //handles project reload if new component installations added. This is a workaround as purchase orders is build too differently to handle adding component installations to projects. For now
                    projectsStart: projectsStart,
                    projectsEnd: projectsEnd,
                    chatUserCount: response.chatUserCount,
                    customer_mandatory: response.customer_mandatory,
                }, () => {
                    let allProjects = [];
                    const findProjects = (project) => {
                        if (typeof(project) == 'object') {
                            allProjects.push(project);
                            project.children.forEach(subProject => {
                                findProjects(subProject);
                            });
                        }
                    }
                    findProjects(tree[0]);

                    this.parseMarkers(allProjects);
                    this.parseGroups( tree );
                });
            });

        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        });
    }

    performStorageTransfer() {

        if (!this.state.storageTransferComponents) { return; }

        //Filteraa ulos komponentit joita ei siirretä.
        const comps = this.state.storageTransferComponents.filter(c => c.toMove == true && c.moveCount > 0);
        if (comps.length > 0 && this.state.receiverId) {
            const senderId = this.state.tree[0].storage_location.id;
            const data = {
                sender_id: senderId,
                receiver_id: this.state.receiverId,
                comment: "",

                components: comps.map(c => {
                    return {
                        component_id: c.component_id,
                        delivery_count: c.moveCount,
                        delivery_from_id: senderId,
                        delivery_to_id: this.state.receiverId,
                    }
                })
            };
    
            api({
                method: 'post',
                url: 'storage/delivery/saveStorageTransport',
                data: data,
            }).then((response) => {
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <div>Tavaran lähetys kirjattu!</div>,
                });
                
                sessionStorage.setItem('transportId', response);
                this.setState({storageTransferDone: true});
            }).catch((error) => {
                errorPopper(error, 'Tallennuksessa tapahtui virhe');
            });
            
        } 
    }
    getComponentsMassList(item) {
        api({
            method: 'get',
            url: 'project/management/getComponentsMassList/' + item.id,
            responseType: 'blob',
        }).then((response) => {
            //console.log(response);
            
            let day = moment().format("DD.MM.YYYY");
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', item.name + "_" + item.project_code + "_" + tr("mass_list") + "_" + day + ".xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
            });
        }).catch((error) => {
            errorPopper(error, 'Massalistan lataamisessa tapahtui virhe');
        });
    }

    handleExcelDataComponent(project, component) {
        const allocated = component.alloc_count || 0;
        const installed = component.installed_count || 0;

        const readiness = installed === 0 || allocated === 0 ? 0 : (Number(installed) / Number(allocated) * 100).toFixed(2);
        return {
            project: project.name,
            project_code: project.project_code,
            storage_component: component.name,
            allocated_quantity: allocated,
            installed: installed,
            degree_of_readiness_estimate: readiness + "%"
        }
    }

    handleExcelDataSum(project) {
        const sum = project.components.reduce((total, component) => {
            const allocated = component.alloc_count || 0;
            const installed = component.installed_count || 0;

            return {
                project: project.name,
                project_code: project.project_code,
                allocated_quantity: Number(allocated) + Number(total.allocated_quantity),
                installed: Number(installed) + Number(total.installed),
            }
        },
        {
            project: project.name,
            project_code: project.project_code,
            allocated_quantity: 0,
            installed: 0,
        });

        sum.degree_of_readiness_estimate = sum.allocated_quantity === 0 || sum.installed === 0 
            ? 0 + "%"
            : (Number(sum.installed) / Number(sum.allocated_quantity) * 100).toFixed(2) + "%";

        return sum;
    }

    handleExcelDataTotalRow(data, project) {
        const total = data.reduce((total, row) => {
            if (row.project_code !== project.project_code) return total;
            return {
                project: project.name + " " + tr('in_total'),
                project_code: project.project_code,
                storage_component: "-",
                allocated_quantity: Number(total.allocated_quantity) + Number(row.allocated_quantity),
                installed: Number(total.installed) + Number(row.installed),
            }
        }, {
            project: project.name,
            project_code: project.project_code,
            storage_component: "-",
            allocated_quantity: 0,
            installed: 0,
        });

        total.degree_of_readiness_estimate = total.allocated_quantity === 0 || total.installed === 0 
            ? 0 + "%"
            : (Number(total.installed) / Number(total.allocated_quantity) * 100).toFixed(2) + "%";
        
        return total;
    }

    getProgressReport(item, includeSubprojects = false, sum = false) {
        let excelData = [];
        let total;
        let allTotal = {
            project: tr('in_total'),
            project_code: '-',
            storage_component: '-',
            allocated_quantity: 0,
            installed: 0,
            degree_of_readiness_estimate: "0%",
        }
        if (sum) {
            excelData.push(this.handleExcelDataSum(item));
            if (allTotal.storage_component) delete allTotal.storage_component;
        } else {
            excelData = item.components.map(c => {
                return this.handleExcelDataComponent(item, c);
            });
            total = this.handleExcelDataTotalRow(excelData, item);
            excelData.push(total);
            allTotal.allocated_quantity = Number(allTotal.allocated_quantity) + Number(total.allocated_quantity);
            allTotal.installed = Number(allTotal.installed) + Number(total.installed);
        }

        if (includeSubprojects) {
            mapTree([item], (project) => {
                if (project.id !== item.id) {
                    if (sum) {
                        excelData.push(this.handleExcelDataSum(project));
                    } else {
                        excelData.push(...project.components.map(c => {
                            return this.handleExcelDataComponent(project, c);
                        }));
                        total = this.handleExcelDataTotalRow(excelData, project);
                        excelData.push(total);
                        allTotal.allocated_quantity = Number(allTotal.allocated_quantity) + Number(total.allocated_quantity);
                        allTotal.installed = Number(allTotal.installed) + Number(total.installed);
                    }
                }
                return project;
            });
            allTotal.degree_of_readiness_estimate = allTotal.allocated_quantity === 0 || allTotal.installed === 0 
                ? 0 + "%"
                : (Number(allTotal.installed) / Number(allTotal.allocated_quantity) * 100).toFixed(2) + "%";
        }
        
        if (sum) {
            allTotal.allocated_quantity = excelData.reduce((total, row) => Number(row.allocated_quantity || 0) + Number(total), 0);
            allTotal.installed = excelData.reduce((total, row) => Number(row.installed || 0) + Number(total), 0);
            
            allTotal.degree_of_readiness_estimate = allTotal.allocated_quantity === 0 || allTotal.installed === 0 
                ? 0 + "%"
                : (Number(allTotal.installed) / Number(allTotal.allocated_quantity) * 100).toFixed(2) + "%";
        }

        if (sum || includeSubprojects) {
            excelData.push(allTotal);
        }
            
        excelData = excelData.map(row => {
            row.allocated_quantity = Number(row.allocated_quantity);
            row.installed = Number(row.installed);
            return row;
        });

        api({
            url: 'excel/generate',
            method: 'POST',
            data: {
                rows: excelData,
                title: tr('progress_report'),
                sheetTitle: tr('progress_report'),
            },
            responseType: 'blob',
            fileTitle: `${item.name} ${tr('progress_report')} ${moment().format('L')}.xlsx`,
        }).then(response => {
            this.setState({
                loading: false,
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                loading: false,
            });
        })
    }

    saveTree()
    {
        this.updateWorkHours(this.state.personnelGroups)
        this.updateAllocatedHours(this.state.personnelGroups)
        this.performStorageTransfer();

        let tree = JSON.parse( JSON.stringify( this.state.tree ));

        tree = mapTree( tree, ( item, parent ) => {
            const components = item.components.map( ( c ) => {
                let tmp = {
                    id: isNaN( parseInt( c.id, 10 ) ) ? null : c.id,
                    component_id: c.component ? c.component.id : null,
                    name: c.name,
                    done: c.done || null,
                    

                    alloc_count: parseFloat( c.alloc_count ),
                    alloc_price_single: parseFloat( c.alloc_price_single ),

                    in_timetracking: c.in_timetracking,
                    installed_count: parseFloat(c.installed_count),
                    comment: c.comment,
                    group_id: c.group_id,
                    moved: c.moved,
                    save_as_new: false,
                }

                if( c.billing )
                {
                    tmp.billing = true;
                    tmp.billing_price = null;
                    if( c.billing_price_manual )
                        tmp.billing_price = c.billing_price;
                }
                else {
                    tmp.billing = false;
                }

                if (c.save_as_new) {
                	tmp.save_as_new=true;
                }
                return tmp;
            });

            const works = item.works.map(( c ) => {
                let tmp = {
                    id: isNaN( parseInt( c.id, 10 ) ) ? null : c.id,
                    component_id: c.component ? c.component.id : null,
                    name: c.name,
                    done: c.done || null,

                    done_estimate: parseFloat( c.done_estimate ),
                    alloc_count: parseFloat( c.alloc_count ),
                    alloc_price_single: parseFloat( c.alloc_price_single ),
                    alloc_personnel: parseFloat( c.alloc_personnel ),

                    billing: false,
                    billing_price: null,
                    comment: c.comment,
                    group_id: c.group_id,
                }

                if( c.billing )
                {
                    tmp.billing = true;
                    tmp.billing_price = null;
                    if( c.billing_price_manual )
                        tmp.billing_price = c.billing_price;

                    tmp.hour_type_values = [];
                    c.hour_types.forEach( h => {
                        let hTmp = {};
                        if( h.project_no_billing )
                            hTmp.no_billing = true;

                        if( h.project_price_manual )
                            hTmp.value = h.value;

                        if( !objectIsEmpty( hTmp ) )
                        {
                            hTmp.id = h.id;
                            tmp.hour_type_values.push( hTmp );
                        }
                    });
                }
                return tmp;
            });

            const expenses = item.expenses.map(( c ) => {
                let tmp = {
                    id: isNaN( parseInt( c.id, 10 ) ) ? null : c.id,
                    component_id: c.component ? c.component.id : null,
                    name: c.name,

                    alloc_count: parseFloat( c.alloc_count ),
                    alloc_price_single: parseFloat( c.alloc_price_single ),
                    in_timetracking: c.in_timetracking,

                    billing: false,
                    billing_price: null,
                    comment: c.comment,
                    group_id: c.group_id,
                }

                if( c.billing )
                {
                    tmp.billing = true;
                    tmp.billing_price = null;
                    if( c.billing_price_manual )
                        tmp.billing_price = c.billing_price;
                }

                return tmp;
            });
            let production = [];
            if (this.state.selectedProject.component_production!==undefined) {
                production = this.state.selectedProject.component_production.map( ( c ) => { //KESKEN
                    let tmp = {
                    	production_id: isNaN( parseInt( c.production_id, 10 ) ) ? null : c.production_id,
                        component_id: c.id,
                        production_count: c.production_count,
                        production_count_done: c.production_count_done || null,
                        ready: c.ready || null,
                        storage_id: c.storage_id,
                        locations: c.locations,
                        location_is_new: c.location_is_new?c.location_is_new:false,
                    }
                    return tmp;
                });
            }


            //console.log("tree", parent)

            return {
                id: isNaN( parseInt( item.id, 10 ) ) ? null : item.id,
                project_code: item.project_code,
                name: item.name,

                ended: item.ended || null,
                done: item.done || false,

                manager_id: item.manager ? item.manager.id : null,
                workhour_approver_id: item.workhour_approver ? item.workhour_approver.id : null,
				customer_order_number: item.customer_order_number ? item.customer_order_number : null,
                description: item.description,
                begin_date: item.start,
                end_date: item.end,
                end_work_date: item.end_work,
                is_continuous: item.is_continuous,

                cost_center_id: item.cost_center ? item.cost_center.id : null,
                show_project_to_extranet: parent == null ? (item.show_project_to_extranet ? item.show_project_to_extranet:false):false,
                billing_auto_approval: item.billing_auto_approval ? item.billing_auto_approval:false,
                billing_auto_approval_date: item.billing_auto_approval_date ? item.billing_auto_approval_date:'Mon',
                billing_hourly: item.billing_hourly,
                approval_exception: this.state.mainParentProject.approval_exception,

                user_add_progress: item.user_add_progress,
                customer_id: item.customer ? item.customer.id : null,
                customer_contact_id: item.customer_contact ? item.customer_contact.id : null,

                children: item.children,

                components: components,
                works: works,
                expenses: expenses,
                production: production,

                billing_margin_travel: item.billing_margin_travel || null,
                billing_margin_expense: item.billing_margin_expense || null,
                billing_margin_allowance: item.billing_margin_allowance || null,
                billing_travel_distance: item.billing_travel_distance || null,
                billing_travel_hours: item.billing_travel_hours || null,

                billers: item.billers || [],
                extranet_approvers: item.extranet_approvers || [],

                allocated_hours: item.allocated_hours,
                show_remaining_hours: item.show_remaining_hours,
                storage_location: item.storage_location,
                hour_approvers: item.hour_approvers,
                project_visible_to_users: item.project_visible_to_users,
                default_expence_tax_group_id: item.default_expence_tax_group_id,
                default_item_tax_group_id: item.default_item_tax_group_id,
                default_work_tax_group_id: item.default_work_tax_group_id,
                send_pdf_in_billable:   item.send_pdf_in_billable,
                customer_work_number_required: item.customer_work_number_required,

                site_address: item.site_address,
                site_postal_code: item.site_postal_code,
                site_city: item.site_city,
                site_country: item.site_country,
                site_max_distance: item.site_max_distance,
            }
        });
        //console.log( 'Saving', tree );
        this.savePersonnel()

        this.setState({ loading: true });
        api({
            method: 'post',
            url: `project/management/${ this.props.projectId }/tree`,
            data: {
                tree: tree,
                groups: this.state.groups,
            }
        }).then(async response => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });

            this.setState({
                loading: false,
            });

            this.props.onSave( this.props.projectId );
            this.getProject();
            //POISTETTU 27.5.2021 Lisätty aliprojekteihin varastot. Ei voida päivittää pääprojektin tilannetta.
            //Päivitä projektivaraston tiedot jos olemassa 
            //this.updateStorageBalance(this.props.projectId);
        }).catch((error) => {
            console.error(`Projektin tallennuksen virhe: ${error}`);
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });

    }

    getStorageBalance() {
        const pId = this.props.projectId;

        api({
            method: 'get',
            url: `project/management/${ pId }/storageBalance`,
        }).then((response) => {
            this.setState({
                storageBalance: response,
                loading: false,
            })
        }).catch((error) => {
            console.log(error, tr('get_error'));
            this.setState({ loading: false });
        });
    }


    saveRunning()
    {
        //console.log('Set project running' );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: `project/management/${ this.props.projectId }/start`,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('started') }</strong>,
            });

            this.setState({ loading: false });
            this.getProject();

        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });
    }

    savePersonnel()
    {
        const edited = this.state.personnelItems.filter(
            (item) =>
                item.group !== "available" &&
                item.group !== "holiday" &&
                !item.isEntry &&
                !item.isProjectSchedule
        );
        let deleted = [...this.state.deletedPersonnelItems];
        this.state.personnelItems.forEach(item => {
            if (item.db_id && item.group === 'available')
            {
                deleted.push(item.db_id);
            }
        })
        
        api({
            method: 'post',
            url: `project/management/savepersonnel`,
            data: {
                project_id: this.props.projectId,
                manual_assignments: this.state.personnel_assignments,
                edited: edited,
                deleted: deleted

            }
        })
        .catch((error) => {
            console.error(error);
            errorPopper(error, tr('save_error'));
            this.setState({loading: false});
        });
    }

    openComponentEdit( id )
    {
        this.setState({ componentEdit: { show: true, id: id } });
    }

    calculateTree( tree = null )
    {
        if( tree === null ) tree = this.state.tree.slice( 0 );

        let selectedProject = null;

        tree = mapTree( tree, null, ( item, parent ) => {

            let own = {
                status: 'active',

                error: false,
                notRemovable: false,
                total: {
                    subprojects: item.children.length,
                    items: 0,
                    hours: 0,
                    expenses: 0,

                    items_count: 0,
                    items_installed: 0,
                    items_installed_count: 0,

                    hours_alloc: 0,
                    hours_entry: 0,
                    hours_remaining: 0,
                    hours_alloc_used_percent: 0,
                    hours_estimate_percent: 0, // ( percent )
                    hours_estimate_count: 0, // ( kpl )

                    hours_future_count: 0,
                    hours_future_difference_count: 0,
                    hours_future_difference_percent: 0,
                    
                    production_count: 0,
                    comment_count:0 // kpl
                },
            };

            if( item.done ) own.status = 'done';
            else if( item.ended ) own.status = 'ended';

            if (item.component_production!==undefined) {
                item.component_production.forEach(( c ) => {
                	own.total.production_count += iz( c.production_count );
            	});
            }

            // Components
            // ----------
            
            item.components.forEach(( c ) => {
                if( !c.name ) own.error = `${tr('name_missing')} (${tr('component')})`;

                if( !own.notRemovable )
                    own.notRemovable = this.itemNotRemovable( c );

                own.total.items++;

                own.total.items_count += iz( c.alloc_count );
                own.total.items_installed_count += iz(c.installed_count);
                if (c.comment) {
                    own.total.comment_count++;
                }
            });
            own.total.items_installed = ps( own.total, 'items_installed_count', 'items_count' );

            // Works
            // -----

            item.works.forEach(( c ) => {
                if( !c.name ) own.error = `${tr('name_missing')} (${tr('work')})`;

                if( !own.notRemovable )
                    own.notRemovable = this.workNotRemovable( c );

                own.total.hours++;
                own.total.hours_alloc += iz( c.alloc_count );
                own.total.hours_entry += iz( c.entry_count );
                own.total.hours_estimate_count += iz((parseFloat(c.done_estimate) / 100) * c.alloc_count);
                if (c.comment) {
                    own.total.comment_count++;
                }
            });
            own.total.hours_remaining = iz( own.total.hours_alloc - own.total.hours_entry );
            own.total.hours_alloc_used_percent = ps( own.total, 'hours_entry', 'hours_alloc' );
            own.total.hours_estimate_percent = ps( own.total, 'hours_estimate_count', 'hours_alloc' );
            own.total.hours_estimate_count_remaining = iz( own.total.hours_alloc - own.total.hours_estimate_count );


            own.total.hours_future_count = iz( own.total.hours_entry / ( parseFloat( own.total.hours_estimate_percent ) / 100 ));

            own.total.hours_future_difference_count = iz( (own.total.hours_alloc - own.total.hours_future_count ) );
            own.total.hours_future_difference_percent = iz( (own.total.hours_alloc / own.total.hours_future_count -1 ) * 100 );



            // Expenses
            // --------

            item.expenses.forEach(( c ) => {
                if( !c.name ) own.error = `${tr('name_missing')} (${tr('cost')})`;

                if( !own.notRemovable )
                    own.notRemovable = this.expenseNotRemovable( c );

                own.total.expenses++;
                if (c.comment) {
                    own.total.comment_count++;
                }
            });

            // Check siblings
            if( parent && parent.children )
            {
                let counts = {};
                parent.children.forEach( s => {
                    counts[ s.project_code ] = counts[ s.project_code ] ? counts[ s.project_code ] + 1 : 1;
                });

                if( counts[ item.project_code ] > 1 )
                    own.error = 'Sama projektikoodi usealla aliprojektilla';
            }

            // -----------------------
            // Children included stuff
            // -----------------------

            // Clone own stuff to total stuff
            let  all = JSON.parse( JSON.stringify( own ) );

            item.children.forEach(( child ) => {

                if( !child.calculated ) return null;
                [     'subprojects'
                    , 'items'
                    , 'hours'
                    , 'expenses'

                    , 'items_count'
                    , 'items_installed_count'

                    , 'hours_alloc'
                    , 'hours_entry'
                    , 'hours_remaining'
                    , 'hours_estimate_count'
                    , 'production_count'
                    
                    , 'comment_count'
                ].forEach( field => {
                    all.total[ field ] += child.calculated.all.total[ field ];
                });

                if( child.calculated.all.notRemovable )
                    all.notRemovable = child.calculated.all.notRemovable;

                if( child.calculated.all.error )
                    all.error = child.calculated.all.error;

            });

            all.total.items_installed = ps( all.total, 'items_installed_count', 'items_count' );

            all.total.hours_alloc_used_percent = ps( all.total, 'hours_entry', 'hours_alloc' );
            all.total.hours_estimate_percent = ps( all.total, 'hours_estimate_count', 'hours_alloc' );
            all.total.hours_estimate_count_remaining = iz( all.total.hours_alloc - all.total.hours_estimate_count );

            all.total.hours_future_count = iz( all.total.hours_entry / ( parseFloat( all.total.hours_estimate_percent ) / 100 ));
            all.total.hours_future_difference_percent = iz( (all.total.hours_alloc / all.total.hours_future_count -1 ) * 100 );

            // own.total.hours_done_estimate_to_entry = '???';

            item.calculated = {
                own: own,
                all: all,
            }

            if( this.state.selectedProject && this.state.selectedProject.id === item.id )
                selectedProject = item;

            item.invalid = item.calculated.all.error;

            return item;
        });

         //console.log('calculated', tree);

        this.setState({
            tree: tree,
            selectedProject: selectedProject,
        });
    }
    productionRemoveClick( productId ) //TODO
    {
    	//console.log(productId,value)
        let project = this.state.selectedProject;
    	let component_production = project.component_production.slice(0);
    	let updated = [];
    	component_production.forEach(product => {
    		if (productId!=product.id) {
    			updated.push(product);
    		}
  
        });
    	project.component_production=updated;
        this.setState({
        	selectedProject: project,
        	treeUnsaved: true
        });
    }
    
    productionChangeProductionCount( productId, value ) //TODO
    {
    	//console.log(productId,value)
        let project = this.state.selectedProject;
    	let component_production = project.component_production.slice(0);
    	let updated = [];
    	component_production.forEach(product => {
    		if (productId==product.id) {
    			product.production_count=value;
    		}
    		updated.push(product);
        });
    	project.component_production=updated;
        this.setState({
        	selectedProject: project
        });
        this.setState({ treeUnsaved: true });
    }
    
    productionAddStorageProduction( productId, storage ) //TODO
    {
    	//console.log(productId,storage);
        let project = this.state.selectedProject;
    	let component_production = project.component_production.slice(0);
    	let updated = [];
    	component_production.forEach(product => {
    		if (productId==product.id) {
    			product.locations=[];
    			product.locations.push(storage);
    			product.storage_id=storage.id;
    			product.location_is_new=true;
    		}
    		updated.push(product);
        });
    	project.component_production=updated;
        this.setState({
        	selectedProject: project
        });
        this.setState({ treeUnsaved: true });
    }
    
    productionChangeFinalLocation( productId, locationId ) //TODO
    {
    	//console.log(productId,locationId);
        let project = this.state.selectedProject;
    	let component_production = project.component_production.slice(0);
    	let updated = [];
    	component_production.forEach(product => {
    		if (productId==product.id) {
    			product.storage_id=locationId;
    		}
    		updated.push(product);
        });
    	project.component_production=updated;
        this.setState({
        	selectedProject: project
        });
        this.setState({ treeUnsaved: true });
    }
    
    productionAdd( product )
    {
    	let project=this.state.selectedProject;
    	let components = [];
    	if (project.component_production!==undefined) {
    		components = project.component_production.slice( 0 );
    	}
    	components.push( product );
    	project.component_production=components;
    
        this.setState({
        	selectedProject: project,
        	treeUnsaved: true 
        });
    }

    selectNode( id )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            item.__selected = false;
            if( id === item.id )
            {
                item.__selected = true;

                if( item.children )
                    item.__open = true;
            }

            return item;
        });
        this.calculateTree( tree );
    }

    selectTreeProject( project )
    {
        this.selectNode( project.id );
        this.setState({ selectedProject: project });
    }
    copyProjectClick( parent )
    {   
        if( !parent )
            parent = this.state.tree[0];

        



        this.setState({
            subProjectEdit: {
                show: true,
                parent: parent,
                data: parent,
                copy: true,
            }
        });

    }
   
    copyProjectToAnotherClick( parent )
    {   

        if( !parent )
            parent = this.state.tree[0];
        
        let data = parent;
        let name = '';
        let code = '';
        let id = null;

        this.setState({
            subProjectCopy: {
                show: true,
                parent: parent,
                data: parent,
                name: name,
                code: code
            }
        });
    }
    
    setReceiverStorage( selected )
    {
        if( selected ){
            this.setState({
                receiverTitle:   selected.name,
                receiverId:      selected.id,
                receiverName:    selected.delivery_name,
                receiverAddress: selected.delivery_address,
                receiverZipcode: selected.delivery_zipcode,
                receiverCity:    selected.delivery_city,
                receiverCountry: selected.delivery_country,
                receiverContactName: selected.delivery_contact,
            });
            if (selected.delivery_address == null && selected.delivery_name == null) { 
                this.setDefaultStorage(selected.name, selected.id);
            }
        }
        else{ this.nullReceiver(); }
    }

    nullReceiver() {
        this.setState({
            receiverProjectId: null,
            receiverTitle:   null,
            receiverId:      null,
            receiverName:    null,
            receiverAddress: null,
            receiverZipcode: null,
            receiverCity:    null,
            receiverCountry: null,
            receiverContactName: null,
        });
    }

    
    addSubProjectFromExisting( project )
    {   
    	let copyProject = { ...this.state.subProjectCopy };
    	this.setState( {loading: true } );

        let id = null;
        copyProject["project"]=project;
        
        api({
            method: 'get',
            url: `project/management/${ project.id }/tree/copy`,
        }).then((response) => {
        	copyProject["original"]=response.tree[0];
        	copyProject["projectSelected"]=true;
            this.setState({ "subProjectCopy" : copyProject });
            this.setState( {loading: false } );

        });
        
    	let data=project;
    	if( data )
	    {
			id = data.id;
			copyProject.origname = data.name;
			copyProject.name = `${tr('copy_of')} ${data.name}`;
			
            if( copyProject.parent )
            {
                const siblings = copyProject.parent.children;
                let nextCode = 0;
                siblings.forEach(( s ) => {
                    let code = parseInt( getLastCodePart( s.project_code ), 10);
                    if( code > nextCode ) nextCode = code;
                });
                nextCode = String( nextCode + 1 );

                let defaultLength = this.state.subproject_code_default_length || 0;
                let prefixLength = ( defaultLength - nextCode.length );
                copyProject.code = ( prefixLength > 0 ) ? "0".repeat( prefixLength ) + nextCode : nextCode;
            }
	    }
    }
    
    addSubProjectFromExistingSelected() {
    	if (this.state.subProjectCopy["projectSelected"]) {
	        let tree = this.state.tree.slice(0);
	        let orig = this.state.subProjectCopy.original;
	        let data = this.state.subProjectCopy;
	       	tree = this.projectAddCopyToTree(tree, data, orig);     
	        this.calculateTree( tree );
	    	this.setState({ "subProjectCopy" : {} });
	    }
    }

    
    addSubProjectClick( parent )
    {
        if( !parent )
            parent = this.state.tree[0];

        this.setState({
            subProjectEdit: {
                show: true,
                parent: parent,
                data: null,
            }
        });
    }

    editSubProjectClick( item )
    {
        this.setState({
            subProjectEdit: {
                show: true,
                parent: {},
                data: item,
            }
        });
    }

    getColumns() {
        return [{
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            filterable: false,
            sortable: false,
            width: 100
        },
        {
            id: 'name',
            Header: 'Komponentti',
            accessor: 'name',
            filterable: false,
            sortable: false,
        },
        {
            id: 'amount',
            Header: 'Määrä',
            accessor: 'amount',
            filterable: false,
            sortable: false,
            width: 150,
        }, {
            Header: 'Siirto',
            id: 'moveCount',
            accessor: 'moveCount',
            width: 120,
            Cell: ({row}) => { 
                const components = this.state.storageTransferComponents;
                const component = components.find(c => c.id == row._original.id);

                return <ApInputSmall
                    type="text"
                    name='moveCount'
                    value={ component.moveCount }
                    onChange={ ( e ) => {
                        component.moveCount = e.target.value;
                        this.setState({ storageTransferComponents: components })
                        } 
                    }
                />
            },   
                         
        }, {
            Header: 'Siirretäänkö',
            id: 'toMove',
            accessor: 'toMove',
            width: 110,
            Cell: ({row}) => { 
                const components = this.state.storageTransferComponents;
                const component = components.find(c => c.id == row._original.id);
                
                return <ApSwitch
                    small
                    inline
                    name='OnOff'
                    id={ component.component_id }
                    on = { component.toMove }
                    onChange={ ( e ) => {
                            component.toMove = !component.toMove;
                            this.setState({ storageTransferComponents: components });
                        }  
                    }
                />
            },               
    }];
    }


    removeProjectStorageClick()
    {
        this.setState({
            removeProjectStorageConfirm: {
                show: true,
            }
        });
    }
    removeSubProjectClick( project )
    {
        this.setState({
            removeProjectConfirm: {
                show: true,
                data: project,
                body: <div>{ tr('delete_subproject_confirm', [<strong>{ project.name }</strong>]) }</div>,
            }
        });
    }

    removeSubProject()
    {
        let parent = null;
        const project = this.state.removeProjectConfirm.data;
        let tree = this.state.tree.slice(0);
        tree = mapTree( tree, ( item ) => {

            if( item.id === project.parent_id )
            {
                parent = item;

                // TODO remove selected items, works and expenses
                // let removedProject = item.children.find( p => p.id === project.id );
                // iterateTree( [ removedProject ], ( subRemoved ) => {
                    // removedComponents = [ ...removedComponents, ...subRemoved.components ];
                // });

                // Remove the project
                item.children = item.children.filter( child => child.id !== project.id );
            }
            return item;
        });

        this.calculateTree( tree );
        this.selectTreeProject( parent );
        this.setState({ treeUnsaved: true });
    }

    subProjectSubmitted( data )
    {
        if (data.name && data.name.length > 255) {
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('project_name_length_error') }</strong>,
            });
            return;
        }
        
    	if (data.copy) {
            let tree = this.state.tree.slice(0);
            let orig = findItemById(tree, data.id);
           	tree = this.projectAddCopyToTree(tree, data, orig);     
            this.calculateTree( tree );
    	}
    	else {
	        if( data.id )
	            this.editProject( data );
	        else
	        {
	            let tree = this.state.tree.slice(0);
	            tree = this.projectAddToTree( tree, data );
	            this.calculateTree( tree );
	        }
    	}
    }
    editProject( project )
    {
        if( !project.id ) return false;
        let tree = this.state.tree.slice(0);
        tree = mapTree( tree, ( item, parent ) => {
            if( item.id === project.id )
            {
                for( const prop in project )
                {
                    if( prop === "id" ) continue;
                    if( prop === 'code' )
                    {
                        if( parent )
                            item.project_code = `${ parent.project_code }.${ project[ 'code' ] }`;
                        else
                            item.project_code = project[ 'code' ];
                    }
                    else
                        item[ prop ] = project[ prop ];
                }
            }
            // Update child project codes
            if( parent )
            {
                const value = getLastCodePart( item.project_code );
                item.project_code = `${ parent.project_code }.${ value }`;
            }
            return item;
        });
        this.calculateTree( tree );

        this.setState({ treeUnsaved: true });
    }

    selectComponent( c )
    {
        const sName = sel.find( f => f.type === c.type_name ).sName;

        let selected = this.state[ sName ].slice( 0 );
        const found = selected.find( f => f.id === c.id );

        if( found ) selected = selected.filter( f => f.id !== c.id );
        else selected.push( c );

        this.setState({ [ sName ]: selected });
    }
    clearSelected(type) {
        const sName = sel.find(f => f.type === type).sName;
        this.setState({ [sName]: [] });
    }

    divideOpen( project, c )
    {
        //console.log('divideOpen', project, c );
        this.setState({
            divideComponent: {
                show: true,
                c: c,
                project: project,
                tree: [ JSON.parse(JSON.stringify( project )) ],
            }
        });

    }


    projectAddToTree( tree, project, newComponent = [] )
    {
        const parent = project.parent;

        let hourApproverParent = parent.workhour_approver_parent;
        let hourApproversParent = parent.hour_approvers_parent;

        if( parent.workhour_approver ) hourApproverParent = parent.workhour_approver;

        let managerParent = parent.manager_parent;
        if( parent.manager ) managerParent = parent.manager;

        this.newCounter++;
        project = {
            id: `new${this.newCounter}`,
            name: project.name,
            project_code: `${parent.project_code}.${project.code}`,
            parent_id: parent.id,

            workhour_approver: null,
            hour_approvers: [],
            workhour_approver_parent: hourApproverParent,
            hour_approvers_parent: hourApproversParent,

            manager: null,
            manager_parent: managerParent,

            description: null,
            end: parent.end,
            end_work: parent.end_work,
            start: parent.start,
            is_continuous: parent.is_continuous,
            user_add_progress: parent.user_add_progress,

            ended: parent.ended,
            done: parent.done,
            billing_hourly: parent.billing_hourly,

            billing_margin_travel: null,
            billing_margin_expense: null,
            billing_margin_allowance: null,

            billers: [],
            extranet_approvers: [],

            children: [],
            components: [],
            expenses: [],
            works: [],

            __schedule_child_open: false,
        };

        tree = mapTree( tree, ( item ) => {
            if( item.id === parent.id )
            {
                item.childsOpen = true;
                item.children.push( project );
            }
            return item;
        });

        if( newComponent.length > 0 )
        {
            newComponent.forEach(( c ) => {
                tree = this.componentAddToTree( tree, c, project.id );
            });
        }

        this.setState({ treeUnsaved: true });

        return tree;
    }
    
    //Add copy of project and its subprojects, with all components to tree, return tree
    projectAddCopyToTree( tree, project, orig )
    {
        const parent = project.parent;
        
        let newComponent=orig.components.slice(0).concat(orig.works.slice(0)).concat(orig.expenses.slice(0));

        let hourApproverParent = parent.workhour_approver_parent;
        let hourApproversParent = parent.hour_approvers_parent;
        if( parent.workhour_approver ) hourApproverParent = parent.workhour_approver;

        let managerParent = parent.manager_parent;
        if( parent.manager ) managerParent = parent.manager;

        this.newCounter++;
        project = {
            id: `new${this.newCounter}`,
            name: project.name,
            project_code: `${parent.project_code}.${project.code}`,
            parent_id: parent.id,

            workhour_approver: null,
            hour_approvers: [],
            workhour_approver_parent: hourApproverParent,
            hour_approvers_parent: hourApproversParent,

            manager: null,
            manager_parent: managerParent,

            description: null,
            end: parent.end,
            start: parent.start,
            is_continuous: parent.is_continuous,

            ended: parent.ended,
            done: parent.done,
            billing_hourly: parent.billing_hourly,

            billing_margin_travel: null,
            billing_margin_expense: null,
            billing_margin_allowance: null,

            billers: [],
            extranet_approvers: [],

            children: [],
            components: [],
            expenses: [],
            works: [],

            __schedule_child_open: false,
        };

        tree = mapTree( tree, ( item ) => {
            if( item.id === parent.id )
            {
                item.childsOpen = true;
                item.children.push( project );
            }
            return item;
        });

        if( newComponent.length > 0 )
        {
            newComponent.forEach(( c ) => {
                tree = this.componentAddToTree( tree, c, project.id );
            });
        }
        
        if( orig.children.length > 0 )
        {
        	orig.children.forEach(( c ) => {
        		c.parent=project;
        		c.code = getLastCodePart( c.project_code );
        		tree=this.projectAddCopyToTree( tree, c, c );
            });
        }
        this.setState({ treeUnsaved: true });
        return tree;
    }
    
    componentAdd( type, component = null )
    {
        this.setState({ loading: true });

        let url = `project/management/cformat/outside/${ type }`;
        if( component ) url = `project/management/cformat/component/${ component.id }`;

        api({
            method: 'get',
            url: url,
        }).then(( response ) => {
            this.setState({ loading: false });

            let tree = this.state.tree.slice(0);
            let options = {}
            if (component?.alloc_count) {
                options = { alloc_count: component.alloc_count }
            }
            tree = this.componentAddToTree( tree, response, this.state.selectedProject.id, null, options );
            this.calculateTree( tree );
            this.setState({ treeUnsaved: true });

        }).catch( ( error ) => {
            console.error( error );
            errorPopper(error, tr('get_error') );
            this.setState({ loading: false });
        });
    }

    componentAddToTree( tree, c, subProjectId, atIndex = null, options = {} )
    {
        tree = mapTree( tree, ( item ) => {
            if( item.id === subProjectId )
            {
                //console.log('Adding', c )

                this.newCounter++;

                c.id = `new${this.newCounter}`;
                c.project_id = subProjectId;
                if (options.alloc_count) {
                    c.alloc_count = options.alloc_count;
                }

                if( c.type_name === 'work' )
                {
                    if( atIndex ) item.works.splice( atIndex, 0, c );
                    else item.works.push( c );
                }
                else if( c.type_name === 'item' )
                {
                    if( atIndex ) item.components.splice( atIndex, 0, c );
                    else item.components.push( c );
                }
                else if( c.type_name === 'other' )
                {
                    if( atIndex ) item.expenses.splice( atIndex, 0, c );
                    else item.expenses.push( c );
                }
                else
                    console.error(`Unknown component type "${ c.type_name }"`);
            }
            return item;
        });
        this.setState({ treeUnsaved: true });
        return tree;
    }

    componentRemoveClick( c )
    {
        let body = null;
        let components = [ c ];

        if( c === 'selected' )
        {
            //console.log( 'TODO', components );
            // const sName = sel.find( f => f.type === c.type_name ).sName;

            /*
            const selectedComponents = this.state.selectedComponents;
            body = <div>Varmista että haluat poistaa kaikki <strong>{ selectedComponents.length }</strong> valittua varastonimikettä projektista</div>;
            ids = selectedComponents.map( sc => sc.id );
            */
        }
        else
        {
            body = <div>{ tr('storage_component_confirm_delete', [<strong>{ c.name }</strong>]) }</div>;
        }

        this.setState({
            removeComponentConfirm: {
                show: true,
                body: body,
                components: components,
            },
        });
    }

    downloadQRcodePopup( workID ) {
        this.setState({
            downloadQR: {
                show: true,
                work_id: workID,
            },
        });
    }
    

    removeComponents()
    {
        const components = this.state.removeComponentConfirm.components;

        let tree = this.state.tree.slice(0);
        tree = mapTree( tree, ( item ) => {
            components.forEach ( c => {
                const s = sel.find( f => f.type === c.type_name );
                item[ s.pName ] = item[ s.pName ].filter( f => f.id !== c.id );
            });
            return item;
        });

        // Remove components from selected
        components.forEach ( c => {
            const s = sel.find( f => f.type === c.type_name );
            let selected = this.state[ s.sName ];
            selected = selected.filter( f => f.id !== c.id );
            this.setState({ [ s.sName ]: selected });
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    // Common function used by all component input edits
    updateTreeItem(projectId, func)
    {
        let tree = this.state.tree.slice(0);
        tree = mapTree( tree, ( item ) => {
            if( projectId === item.id )
                return func( item );
            return item;
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    infoBarOnChange( type, value,markUnsaved=true )
    {
        if( type === 'customer')
            this.calculateTree( [ value ] );
        else if( type === 'manager')
            this.changeSettingSetManager( this.state.tree[ 0 ], value );
		else if( type === 'customer_order_number')
            this.changeSettingSetCustomerOrderNumber( this.state.tree[ 0 ], value );
        else if ( type === 'workhour_approver')
            this.changeSettingSetApprover( this.state.tree[ 0 ], value );
        else if ( type === 'hour_approvers')
            this.changeSettingSetApprover( this.state.tree[ 0 ], value );
        else if ( type === 'settings_project_storage_none')
            this.changeSettingSetApprover(this.state.tree[0], value);
        else if (type === 'chatUserCount') {
            this.setState({ chatUserCount: value });
        }
        if (markUnsaved) {
            this.setState({ treeUnsaved: true });
        }
        
    }

    markProjectDone( project )
    {
        const tree = mapChildrenOfId( this.state.tree, project.id, ( item ) => {
            if( !item.ended ) item.ended = now.format('YYYY-MM-DD');

            if( this.state.markProjectDone.type === 'done' )
            {

                if( !item.done ) item.done = true;

                item.components = item.components.map( c => {
                    if( !c.done ) c.done = now.format('YYYY-MM-DD');
                    if (this.state.markAllInstalled && c.alloc_count > c.installed_count) {
                        c.installed_count = c.alloc_count;
                    }
                    return c;
                });
                item.works = item.works.map( c => {
                    if( !c.done ) c.done = now.format('YYYY-MM-DD');
                    c.done_estimate = 100;
                    return c;
                });
                item.component_production = item.component_production.map( c => {
                	//console.log("tuote",c)
                    if( !c.ready ) c.ready = now.format('YYYY-MM-DD');
                    c.production_count_done = c.production_count;
                    return c;
                });
            }

            return item;
        });
        this.setState({ treeUnsaved: true });
        api({
            method: 'get',
            url: `project/management/${project.id}/getStorageTransferComponents`,
        }).then((response) => {
            this.setupStorageTransferComponents(response[0], response[1]);
        }).catch(() => {
            console.warn('Ongelma loppusiirron komponenttien haussa...');
        });

        this.setState({ 
            treeUnsaved: true,
            showStorageTransfer: !this.state.markAllInstalled
         });
         
        this.calculateTree( tree );
    }

    setupStorageTransferComponents(isRoot, data) {
        if (!this.state.markAllInstalled) {
            const components = data.map(c => {
                return {
                    id: c.id,
                    name: c.name,
                    component_id: c.component_id,
                    amount: c.amount,
                    moveCount: c.amount,
                    toMove: true
                }
            });
            
            this.setState({storageTransferComponents: components});
        }
    }

    // ----------------------------------------
    //  Setting input edits
    // ----------------------------------------

    changeSettingStatus( project, value )
    {
        const isRoot = this.state.tree[0].id == project.id;
        if (isRoot) {
            //Varmista ettei ole mitään komponentteja miinuksella
            api({
                method: 'get',
                url: `project/management/${ this.props.projectId }/balanceCheck`,
            }).then((response) => {
                if (Array.isArray(response) && response.length > 0) {
                    const warning = <div id="warningMessage">
                        <h5>{tr('component_balances_negative')}</h5>
                        <table>
                            <tbody>
                            {response.map((stock, i) => {
                                return <tr key={i}>
                                    <td>{stock.component?.name}</td>
                                    <td>{stock.balance} {stock.component?.unit}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>;
                    this.setState({storageTransferWarning: warning});
                } else {
                    this.setState({storageTransferWarning: null});
                }
                // this.setState({storageTransferWarning: response == 1 ? warning : null});
            });
        } else { this.setState({storageTransferWarning: null}); }
    
        if( value === 'done' )
        {
            const body = <div>
                <div className="markProjectDoneBody apInfoMsg">
                    <h3>{ tr('mark_project_as_completed', [<strong>{ project.name }</strong>]) }</h3>
                    <div>{ tr('mark_project_as_completed_info') }</div>
                    <table>
                        <tbody>
                            <tr><td>{ tr('subprojects_completed') }:</td><td>{ project.calculated.all.total.subprojects }</td></tr>
                            <tr><td>{ tr('components_completed') }:</td><td>{ project.calculated.all.total.items }</td></tr>
                            <tr><td>{ tr('works_completed') }:</td><td>{ project.calculated.all.total.hours }</td></tr>
                            <tr><td>{ tr('products_completed') }:</td><td>{ project.calculated.all.total.production_count }</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>

            this.setState({ markProjectDone: {
                show: true,
                project: project,
                body: body,
                type: value,
            }});
        }
        else if( value === 'ended')
        {
            const body = <div>
                <div className="markProjectDoneBody apInfoMsg">
                    <h3>{ tr('mark_project_as_ended', [<strong>{ project.name }</strong>]) }</h3>
                    <div>{ tr('mark_project_as_ended_info') }</div>
                    <table>
                        <tbody>
                            <tr><td>{ tr('subprojects_completed') }:</td><td>{ project.calculated.all.total.subprojects }</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>

            this.setState({ markProjectDone: {
                show: true,
                project: project,
                body: body,
                type: value,
            }});

        }
        else if( value === 'active' )
        {
            this.updateTreeItem( project.id, ( item ) => {
                item.ended = null;
                item.done = false;
                return item;
            });
            this.setState({ treeUnsaved: true });
        }
    }

    renderMarkAllInstalledSwitch() {
        if (this.state.markProjectDone && 
        this.state.markProjectDone.type === 'done' && 
        this.state.mainParentProject && 
        !this.state.mainParentProject.billing_hourly) {
            return <div className="apSwitchBlock">
                <label htmlFor='mark-all-installed-switch' className="info">
                    { tr('mark_all_installed_q') }
                </label>
                <ApSwitch
                    id='mark-all-installed-switch'
                    on={ Boolean(this.state.markAllInstalled) }
                    onChange={ () => { this.setState({markAllInstalled: !this.state.markAllInstalled}) } }
                    disabled={ this.state.loading }
                />
            </div>
        }
        return null;
    }

    changeSettingDescription( project, value )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.description = value;
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changeApprovalException(project)
    {
        if( project.parent_id )
        {
            console.error( 'Should not be able to toggle project with parents' );
            return null;
        }

        const mainProject = {... this.state.mainParentProject}
        mainProject.approval_exception = !mainProject.approval_exception
        this.setState({ mainParentProject: mainProject , treeUnsaved: true });
    }

    handleProjectValueChange(projectId, key, value) {
        this.updateTreeItem( projectId, ( item ) => {
            item[key] = value;
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changeSettingIsContinuous( project )
    {
        if( project.parent_id )
        {
            console.error( 'Should not be able to toggle project with parents' );
            return null;
        }

        const value = !project.is_continuous;
        const endDefault = moment().add( 2, 'weeks' ).format('YYYY-MM-DD');

        let tree = mapTree( this.state.tree, ( item ) => {
            item.is_continuous = value;
            if( !value ) item.end = endDefault;
            return item;
        } );

        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingBillingAutoApprovals( project )
    {
        if( project.parent_id )
        {
            console.error( 'Should not be able to toggle project with parents' );
            return null;
        }

        const value = !project.billing_auto_approval;

        let tree = mapTree( this.state.tree, ( item ) => {
            item.billing_auto_approval = value;
            return item;
        } );

        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingRowsInEmail( project )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            if( item.id === project.id )
                item.send_pdf_in_billable = !item.send_pdf_in_billable;
            return item;
        });

        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingBillingAutoApprovalDate( project, value )
    {
        if( project.parent_id )
        {
            console.error( 'Should not be able to toggle project with parents' );
            return null;
        }

        let tree = mapTree( this.state.tree, ( item ) => {
            item.billing_auto_approval_date = value;
            return item;
        } );

        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    
    
    changeShowProjectToExtranet(project) {
        if (project.parent_id) {
            console.error('Should not be able to toggle project with parents');
            return null;
        }

        const value = !project.show_project_to_extranet;
        

        let tree = mapTree(this.state.tree, (item) => {
            item.show_project_to_extranet = value;
            return item;
        });

        this.calculateTree(tree);
        this.setState({ treeUnsaved: true });
    }

    changeCostCenter(project, cost_center) {
        const value = cost_center;


        this.updateTreeItem(project.id, (item) => {
            item.cost_center = value;
            return item;
        });
        this.setState({ treeUnsaved: true });
    }
    changeUserAddProgress( project )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            if( item.id === project.id )
                item.user_add_progress = !item.user_add_progress;
            return item;
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeShowRemainingHours( project )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            if( item.id === project.id )
                item.show_remaining_hours = !item.show_remaining_hours;
            return item;
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingSetManager( project, user )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            if( item.id === project.id )
                item.manager = user;

            if( parent )
            {
                if( parent.manager ) item.manager_parent = parent.manager;
                else item.manager_parent = parent.manager_parent;
            }

            return item;
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingSetVisibility ( project, users ) {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            if( item.id === project.id )
                item.project_visible_to_users = users;


            return item;
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingSetApprover( project, users )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            if( item.id === project.id )
                item.hour_approvers = users;

            if( parent )
            {
                if( parent.hour_approvers.length ) item.hour_approvers_parent = parent.hour_approvers;
                else item.hour_approvers_parent = parent.hour_approvers_parent;
            }

            return item;
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }
	changeSettingSetCustomerOrderNumber( project, user )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            if( item.id === project.id )
                item.customer_order_number = user;

            if( parent )
            {
                if( parent.customer_order_number ) item.customer_order_number_parent = parent.customer_order_number;
                else item.customer_order_number_parent = parent.customer_order_number_parent;
            }

            return item;
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingSetBillers( users )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));

        // At the moment, only root project can have billers set
        tree[0].billers = users;

        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingSetExtranetUsers( users )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));

        // At the moment, only root project can have extranet approverers
        tree[0].extranet_approvers = users;

        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingExtranetUserSetting( key, setting, value )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));

        if( setting === "is_primary" )
            tree[0].extranet_approvers.map( approver => approver.is_primary = false );
        
        // At the moment, only root project can have extranet approverers
        tree[0].extranet_approvers[ key ][ setting ] = value;



        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    changeSettingStart( project, value )
    {
        //console.log( 'changeSettingStart', value, project );
        if( !value ) return null;
        if( project.end && moment( value ).isAfter( project.end )) return null;

        this.updateTreeItem( project.id, ( item ) => {
            item.start = value;
            return item;
        });
        this.setState({ treeUnsaved: true });

    }

    changeSettingEnd( project, value )
    {
        //console.log( 'changeSettingEnd', value, project );
        if( !value ) return null;
        if( moment( value ).isBefore( project.start )) return null;

        this.updateTreeItem( project.id, ( item ) => {
            if( moment( value ).isAfter( project.end_work ))  {
                item.end_work=value;
            }
            item.end = value;
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changeSettingAddress( project, e ) {
        const { value, name } = e.target;
        this.updateTreeItem(project.id, (item) => {
            item['site_'+name] = value;
            return item;
        });
        if (name !== 'max_distance') {
            this.setState({ distanceTestMetres: null });
        }
        this.setState({ treeUnsaved: true });
    }

    changeSettingEndWork( project, value )
    {
        //console.log( 'changeSettingEndWork', value, project );
        if( !value ) return null;
        if( moment( value ).isBefore( project.end )) return null;

        this.updateTreeItem( project.id, ( item ) => {
            item.end_work = value;
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changeSettingSetBillingMargin( project, column, value )
    {
        if (value === '') value = null

        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            if( item.id === project.id )
                item[ column ] = value;

            if( parent )
            {
                if( parent[ column ] ) item[ column + "_parent" ] = parent[ column ];
                else item[ column + "_parent" ] = parent[ column + "_parent" ];
            }

            return item;
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }


    // ----------------------------------------
    //  Check removability
    // ----------------------------------------

    notRemoveble( type, c )
    {
        switch ( type ) {
            case 'item':
                return this.itemNotRemovable( c );
            case 'work':
                return this.workNotRemovable( c );
            case 'expense':
                return this.expenseNotRemovable( c );
            default:
                return false;
        }
    }

    itemNotRemovable( c )
    {
        if( parseFloat( c.installed_entry_count ) > 0 ) return tr('recorded_progress');
        else if( parseFloat( c.offer_count ) > 0 ) return tr('specified_offer_price');
        else if( parseFloat( c.offer_sell_count ) > 0 ) return tr('specified_offer_selling_price');
        return false;
    }
    workNotRemovable( c )
    {
        if( parseFloat( c.entry_count ) > 0 ) return tr('recorded_hours');
        else if( parseFloat( c.offer_count ) > 0 ) return tr('specified_offer_price');
        else if( parseFloat( c.offer_sell_count ) > 0 ) return tr('specified_offer_selling_price');
        return false;
    }
    expenseNotRemovable( c )
    {
        if( parseFloat( c.expense_entry_count ) > 0 ) return tr('recorded_costs');
        else if( parseFloat( c.chargeable_entry_count ) > 0 ) return tr('recorded_billable');
        else if( parseFloat( c.offer_count ) > 0 ) return tr('specified_offer_price');
        else if( parseFloat( c.offer_sell_count ) > 0 ) return tr('specified_offer_selling_price');
        return false;
    }

    tallyShow( project, c, type )
    {
        this.setState({
            tallyModal: { show: true, id: c.id, type: type },
        })
    }

    // ----------------------------------------
    //  Personnel
    // ----------------------------------------

    updatePersonnelGroups(groups)
    {
        this.setState({ personnelGroups: groups })
    }

    updateOpenPersonnelGroups(openGroups)
    {
        this.setState({ openPersonnelGroups: openGroups });
    }

    updateOpenPersonnelParentGroups(openGroups)
    {
        this.setState({ openPersonnelParentGroups: openGroups });
    }

    calculateGroupValues(items)
    {
        const { personnelGroups } = this.state
        let groupValues = []
        let filtered = items.filter(item => 
            item.group !== 'available' 
            && item.group !== "holiday"
            && !item.isProjectSchedule
            && !item.isEntry
            && !moment().startOf("week").isAfter(moment(item.start_time)));

        filtered.forEach(item => {
            let foundGroup = groupValues.find(group => group['id'] === item.group)
            if (foundGroup)
            {
                let works;

                let filteredByWork = items.filter(it => it.work === item.work);
                let newWorkValue = 0
                filteredByWork.forEach(fItem => {
                    newWorkValue += fItem.value
                })

                if (foundGroup.works.some(work => work['workId'] === item.work))
                {
                    works = foundGroup.works.map(work => {
                        if (work.id === item.work)
                        {
                            return (
                                Object.assign({}, work, {
                                    value: newWorkValue,
                                })
                            )
                        }
                        else
                        {
                            return work
                        }
                    })
                }
                else if (item.work)
                {
                    works = foundGroup.works
                    works.push({
                        workId: item.work,
                        value: newWorkValue
                    })
                }

                let filteredByGroup = filtered.filter(it => it.group === item.group);
                let newGroupValue = 0
                filteredByGroup.forEach(fItem => {
                    newGroupValue += fItem.value
                })

                groupValues = groupValues.map(group => {
                    if (works && group.id === item.group)
                    {
                        return (
                            Object.assign({}, group, {
                                value: newGroupValue,
                                works: works
                            })
                        )
                    }
                    else if (group.id === item.group)
                    {
                        return (
                            Object.assign({}, group, {
                                value: newGroupValue,
                            })
                        )
                    }
                    else
                    {
                        return group
                    }
               
                })
            }
            else
            {
                let newWorks = []
                if (item.work)
                {
                    let filteredByWork = items.filter(it => it.work === item.work);
                    let newWorkValue = 0
                    filteredByWork.forEach(fItem => {
                        newWorkValue += fItem.value
                    })
                    newWorks.push({
                        workId: item.work,
                        value: newWorkValue
                    })
                }
                groupValues.push({
                    id: item.group,
                    value: item.value,
                    works: newWorks
                })
            }
        })

        let newWorks;
        let newGroups = personnelGroups.map(group => {
            let foundGroup = groupValues.find(val => val.id === group.id)
            if (foundGroup)
            {
                newWorks = group.works && group.works.map(work => {
                    let foundWork = foundGroup.works.find(fwork => fwork.workId === work.id)
                    if (foundWork)
                    {
                        return (
                            Object.assign({}, work, {
                                alloc_personnel: foundWork.value
                            })
                        )
                    }
                    return (
                        Object.assign({}, work, {
                            alloc_personnel: 0
                        })
                    )
                })
                return (
                    Object.assign({}, group, {
                        value: foundGroup.value,
                        works: newWorks
                    })
                )
            }
            else 
            {
                if (group.works)
                {
                    newWorks = group.works.map(work => {
                        return (
                            Object.assign({}, work, {
                                alloc_personnel: 0
                            })
                        )
                    })
                }
                return (
                    Object.assign({}, group, {
                        value: 0,
                        works: newWorks
                    })
                )
            }
        })

        this.setState({ personnelItems: items, personnelGroups: newGroups })
    }

    convertTime(value)
    {
        let decimalTime = parseFloat(value);
        decimalTime = decimalTime * 60 * 60;
        let hours = Math.floor((decimalTime / (60 * 60)));
        decimalTime = decimalTime - (hours * 60 * 60);
        let minutes = Math.round(decimalTime / 60);
        if (minutes === 60){
            hours += 1;
            minutes = 0;
        }
        if(hours < 10)
        {
            hours = "0" + hours;
        }
        if(minutes < 10)
        {
            minutes = "0" + minutes;
        }

        return `${hours}:${minutes}`
    }

    handleEditPersonnelItem(item)
    {
        let editedPersonnelItems = [...this.state.editedPersonnelItems]
        if (!editedPersonnelItems.includes(item.id))
        {
            editedPersonnelItems.push(item.id);
        }
        this.setState({ treeUnsaved: true, editedPersonnelItems: editedPersonnelItems });
    }

    handleAssignmetsEdit(assignments)
    {
        this.setState({ treeUnsaved: true, personnel_assignments: assignments });
    }

    handleDeletePersonnelItem(item)
    {
        let deletedPersonnelItems = [...this.state.deletedPersonnelItems]
        if (!deletedPersonnelItems.includes(item.db_id))
        {
            deletedPersonnelItems.push(item.db_id);
        }
        this.setState({ treeUnsaved: true, deletedPersonnelItems: deletedPersonnelItems });
    }

    
    handleDeleteAssignmetItem(id)
    {
        let AssignmentItems = [...this.state.personnel_assignments];
        //console.log(AssignmentItems);
        let assignments = [];
        AssignmentItems.forEach(user => {
            if (user.user_id!=id) {
                assignments.push( user );
            }
        });
        //console.log(assignments);
        this.setState({ treeUnsaved: true, personnel_assignments: assignments });
    }
    
    updateAllocatedHours(groups)
    {
        groups.forEach(group => {
            this.updateTreeItem( group.id, ( item ) => {
                item.allocated_hours = group.value;
                return item;
            });
        })
    }

    updateWorkHours(groups)
    {
        groups.forEach(group => {
            this.updateTreeItem( group.id, ( item ) => {
                item.works.forEach(work => {
                    if (group.works)
                    {
                        let found = group.works.find(groupWork => groupWork.id === work.id)
                        if (found)
                        {
                            work.alloc_personnel = found.alloc_personnel
                        }
                    }

                });
                return item;
            });
        })
    }

    parseGroups( tree ) 
    {
        const childs = 'children';
        if( !tree )
            return false;

        let groups = [];
        let openGroups = [...this.state.openPersonnelGroups];
        let openParentGroups = [...this.state.openPersonnelParentGroups];

        const loopItems = ( array, level, parentId ) => {
            if( Array.isArray( array ) )
            {
                if( typeof( level ) != "number" ) level = 0;
                for( let i = 0; i < array.length; i++ )
                {
                    const item = array[i];

                    const hoursTotalArray = this.state.personnel.map((person) => {
                        const hoursDaily = person.contract_detail.hours_daily ? parseFloat(person.contract_detail.hours_daily) : 8;
                        const multiplier = 24 / hoursDaily;

                        const filtered = person.allocations.filter(allocation => 
                            allocation.project_id === item.id
                            && !moment().startOf("week").isAfter(allocation.start));

                        const hours = filtered.reduce((prevVal, currVal) => {
                            return prevVal + (( moment(currVal.end, "YYYY-MM-DD hh:mm:ss").format("x") - moment(currVal.start, "YYYY-MM-DD hh:mm:ss").format("x") ) /60/60/1000/multiplier)
                        }, 0);
                        return hours;
                    });

                    const hoursTotal = hoursTotalArray.reduce((prevVal, currVal) => prevVal + currVal, 0);                    

                    let allocated = 0;
                    item.works.forEach( work => {
                        allocated += parseFloat(work.alloc_count);
                    });
                    allocated = Math.round(allocated * 10) / 10;

                    groups.push({
                        id: item.id,
                        title: item.name,
                        project_code: item.project_code,
                        open: openParentGroups.includes(item.id) ? true : false,
                        children: ( item[ childs ] && item[ childs ].length > 0 ? item[ childs ] : false ),
                        level: level,
                        first_child: (i == 0 ? true : false),
                        last_child: ( (i + 1) == array.length ? true : false ),
                        value: hoursTotal,
                        allocated: allocated,
                        works: item.works,
                        begin_date: item.start,
                        end_date: item.end,
                        end_work_date: item.end_work?item.end_work:item.end,
                        stackItems: true,
                        entryCount: item.entry_count,
                    }); 

                    if (level === 0 && !openGroups.includes(item.id))
                    {
                        openGroups.push(item.id)
                    }

                    if( item[ childs ] )
                        loopItems( item[ childs ], (level + 1), item.id );
                }
            }
        };
        
        loopItems( tree );

        groups.push(
            {
                id: 'absence',
                title: tr('absences'),
                disabled: true,
                begin_date: moment().startOf("week").format("YYYY-MM-DD"),
                end_date: moment(this.state.projectsEnd).format("YYYY-MM-DD"),
            },
            {
                id: 'holiday',
                title: tr('holidays'),
                disabled: true,
                begin_date: moment().subtract(1, "week").format("YYYY-MM-DD"),
                end_date: moment().subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD"),
            },
            {
                id: 'available', 
                title: tr('available'),
                disabled: true
            }
        )

        this.parseItems(groups)

        this.setState({
            personnelGroups: groups,
            openPersonnelGroups: openGroups
        });
    }

    parseItems(groups)
    {
        let start = moment(this.state.tree[0].start).startOf('isoWeek')
        let end = this.state.tree[0].end ? moment(this.state.tree[0].end).startOf('isoWeek') : moment().add(1, 'years').startOf('isoWeek')
        let diff = end.diff(start, 'week')
        let items = [], weeklyMaxHours = [];

        // project schedule item
        const loopProjects = (project) => {
            if (typeof(project) == 'object') {
                const week = moment(project.start).week();
                const year = moment(project.start).year();
                const weekNumber = `${week}-${year}`

                if (!project.is_continuous) {
                    items.push({
                        id: `${week}-${year}-${project.id}-projectSchedule`,
                        user: {},
                        group: project.id,
                        value: 0,
                        week: weekNumber,
                        title: `${project.name} ${tr('begins')}: ${moment(project.start).format('L')}, ${tr('ends')}: ${moment(project.end).format('L')}`,
                        start_time: parseInt(moment(project.start).format('x')),
                        end_time: parseInt(moment(project.end).add(1, 'day').format('x')),
                        canMove: false,
                        canResize: false,
                        canChangeGroup: false,
                        isProjectSchedule: true,
                    });
                }

                project.works.forEach(work => {
                    const entryIds = [];
                    work.time_entries.sort((a ,b) => {
                        return new Date(a.date) - new Date(b.date);
                    });
                    work.time_entries.forEach(entry => {
                        if (!entryIds.includes(entry.id)) entryIds.push(entry.id);
                        else return;
                        
                        if (entry.status != "open") {
                            const foundUser = this.state.personnel.find(person =>
                                person.id == entry.user_id
                            );
                            if(!foundUser) return;

                            const foundEntry = work.progress_entries.find(p_entry => p_entry.entry_id == entry.id);
                            const doneEstimate = foundEntry 
                                ? foundEntry.progress
                                : work.doneEstimate;

                            const readiness_degree_txt = doneEstimate
                                ? `${tr('degree_of_readiness_estimate')}: ${doneEstimate}%`
                                : "";

                            const week = moment(entry.date).week();
                            const year = moment(entry.date).year();
                            
                            const hoursDaily = foundUser.contract_detail.hours_daily ? parseFloat(foundUser.contract_detail.hours_daily) : 8;
                            const multiplier = parseFloat(24 / hoursDaily);
                            // check if the same work continues next day with no other project in between
                            // let lastItem = items[items.length - 1];
                            let lastItem = items.filter(entry =>
                                entry.user.id === foundUser.id
                                && entry.workId === work.id
                                && entry.group === project.id
                            ).at(-1);

                            if (lastItem 
                            && (lastItem.end_time == parseInt(moment(entry.date).startOf('day').format('x')) 
                                || lastItem.start_time == parseInt(moment(entry.date).startOf('day').format('x')))
                            && lastItem.isEntry 
                            && lastItem.group == project.id
                            && lastItem.workId == work.id) {
                                const newTime = lastItem.value + parseFloat(entry.total_hours);
                                lastItem.end_time = parseInt(moment(lastItem.end_time, 'x')
                                    .add(entry.total_hours * multiplier, 'hours')
                                    .format('x'));
                                lastItem.value = newTime;
                                lastItem.title = `${foundUser.user.name} ${this.convertTime(newTime)}, ${lastItem.workName} ${readiness_degree_txt}`
                                lastItem.doneEstimate = doneEstimate;
                            } else {
                                items.push({
                                    id: `${week}-${year}-${entry.id}-entry`,
                                    user: foundUser,
                                    group: project.id,
                                    value: parseFloat(entry.total_hours),
                                    week: `${week}-${year}`,
                                    title: `${foundUser.user.name} ${this.convertTime(entry.total_hours)}, ${work.name} ${readiness_degree_txt}`,
                                    start_time: parseInt(moment(entry.date).startOf('day').format('x')),
                                    end_time: parseInt(moment(entry.date).startOf('day').add(entry.total_hours * multiplier, 'hours').format('x')),
                                    canMove: false,
                                    canResize: false,
                                    canChangeGroup: false,
                                    isEntry: true,
                                    description: entry.description,
                                    doneEstimate: doneEstimate,
                                    workId: work.id,
                                    workName: work.name,
                                })
                            }
                        }
                    })
                })

                project.children.forEach(subProject => {
                    loopProjects(subProject);
                });
            }
        }
        loopProjects(this.state.mainParentProject);

        for (let i=0; i <= diff; i++)
        {
            let week = start.week();
            let year = start.year();
            if (week === 1 && moment(start).endOf('isoWeek').format('YYYY') > year)
            {
                year += 1;
            }

            const weekNow = moment().isoWeek();
            const yearNow = moment().year();
            let isBeforeCurrWeek = false;
            // Only commented out in case we want to do something with weeks before current week
            // if (week < weekNow && year <= yearNow) 
            //     isBeforeCurrWeek = true;
            // else
            //     isBeforeCurrWeek = false;

            let weekNumber = `${week}-${year}`;
            this.state.personnel.forEach((user, index) => {
                if (user.allocations && user.allocations.find(allocation => allocation.week === weekNumber && groups.find(group => group.id == allocation.project_id) || allocation.absence))
                {
                    const hoursDaily = user.contract_detail.hours_daily ? parseFloat(user.contract_detail.hours_daily) : 8
                    const hoursWeekly = user.contract_detail.hours_weekly ? parseFloat(user.contract_detail.hours_weekly) : 40
                    let multiplier = parseFloat(24 / hoursDaily);
                    let total = 0;
                    user.allocations.forEach(allocation => {
                        let isHoliday = allocation.project_id && allocation.project_id === "holiday"
                            ? true
                            : false;

                        let group = groups.find(group => group.id == allocation.project_id)
                        if (allocation.absence) {
                            group = groups.find(group => group.id == 'absence');
                        }

                        if (allocation.week === weekNumber && group)
                        {
                            let timeDifference = (moment(allocation.end).format('x')-moment(allocation.start).format('x'))/60/60/1000
                            let value = (timeDifference / multiplier)
                            total += value;
                            items.push({
                                id: `${week}-${year}-${user.id}-${items.length}${isHoliday ? "-holiday":""}`,
                                db_id: allocation.id,
                                user: user,
                                group: group.id,
                                value: value,
                                week: weekNumber,
                                work: allocation.work_id,
                                title: !isHoliday ? 
                                    `${user.user.name} ${this.convertTime(value)}`
                                    :`${allocation.name}`,
                                start_time: parseInt(moment(allocation.start).format('x')),
                                end_time: parseInt(moment(allocation.end).format('x')),
                                canMove: isBeforeCurrWeek || isHoliday ? false : true,
                                canResize: isBeforeCurrWeek || isHoliday ? false : true,
                                canChangeGroup: isBeforeCurrWeek || isHoliday ? false : true,
                                isHoliday: isHoliday
                            })
                        }
                    })
                    total = hoursWeekly - total;
                    let roundedTotal = total.toFixed(3);
                    if (roundedTotal > 0)
                    {
                        items.push({
                            id: `${week}-${year}-${user.id}-${items.length}`,
                            user: user,
                            group: 'available',
                            value: total,
                            week: weekNumber,
                            title: `${user.user.name} ${this.convertTime(total)}`,
                            start_time: parseInt(moment(start).startOf('isoWeek').format('x')),
                            end_time: parseInt(moment(start).startOf('isoWeek').add(total*multiplier, 'hours').format('x')),
                            canMove: isBeforeCurrWeek ? false : true,
                            canResize: isBeforeCurrWeek ? false : true,
                            canChangeGroup: isBeforeCurrWeek ? false : true,
                        })
                    }
                }
                else if (user.allocations && user.allocations.find(allocation => allocation.week === weekNumber))
                {
                    const hoursDaily = user.contract_detail.hours_daily ? parseFloat(user.contract_detail.hours_daily) : 8
                    const hoursWeekly = user.contract_detail.hours_weekly ? parseFloat(user.contract_detail.hours_weekly) : 40
                    let multiplier = parseFloat(24 / hoursDaily);
                    let total = 0;
                    user.allocations.forEach(allocation => {
                        if (allocation.week === weekNumber)
                        {
                            let timeDifference = (moment(allocation.end).format('x')-moment(allocation.start).format('x'))/60/60/1000
                            let value = (timeDifference / multiplier)
                            total += value;
                        }
                    })
                    let value = hoursWeekly - total;
                    let roundedTotal = value.toFixed(3);
                    if (roundedTotal > 0)
                    {
                        items.push({
                            id: `${week}-${year}-${user.id}-${items.length}`,
                            user: user,
                            group: 'available',
                            value: value,
                            week: weekNumber,
                            title: `${user.user.name} ${this.convertTime(value)}`,
                            start_time: parseInt(moment(start).startOf('isoWeek').format('x')),
                            end_time: parseInt(moment(start).startOf('isoWeek').add(value*multiplier, 'hours').format('x')),
                            canMove: isBeforeCurrWeek ? false : true,
                            canResize: isBeforeCurrWeek ? false : true,
                            canChangeGroup: isBeforeCurrWeek ? false : true,
                        })
                        weeklyMaxHours.push({
                            user: user,
                            week: weekNumber,
                            value: value
                        })
                    }
                }
                else
                {
                    const hoursWeekly = user.contract_detail.hours_weekly ? parseFloat(user.contract_detail.hours_weekly) : 40
                    const daysWeekly = user.contract_detail.days_weekly ? parseInt(user.contract_detail.days_weekly) : 5;

                    const contractEnd = user.contract_detail.contract_end;
                    const contractHasEnded = !!contractEnd
                        && moment(contractEnd).endOf("isoWeek").isBefore(start);

                    if (!contractHasEnded) {
                        items.push({
                            id: `${week}-${year}-${user.id}`,
                            user: user,
                            group: 'available',
                            value: hoursWeekly,
                            week: weekNumber,
                            title: `${user.user.name} ${this.convertTime(hoursWeekly)}`,
                            start_time: parseInt(moment(start).startOf('isoWeek').format('x')),
                            end_time: parseInt(moment(start).startOf('isoWeek').add(daysWeekly, 'days').format('x')),
                            canMove: isBeforeCurrWeek ? false : true,
                            canResize: isBeforeCurrWeek ? false : true,
                            canChangeGroup: isBeforeCurrWeek ? false : true,
                        })
                    }
                }
            })
            start = start.add(1, 'week')
        }

        items = this.handleAvailableTime(items);
        this.setState({ 
            personnelItems: items,
            personnelWeeklyMaxHours: weeklyMaxHours
         });
    }

    handleAvailableTime = (items) => {
        // return if project has already ended
        if (moment().isAfter(this.state.projectsEnd)) return items;
        const weekNow = moment().isoWeek();
        const yearNow = moment().year();

        const newItems = items.filter(item => {
            if (item.group != "available") return true;

            // is available for the whole week
            const daysWeekly = item.user.contract_detail.days_weekly ? parseInt(item.user.contract_detail.days_weekly) : 5;
            if (item.group == "available" && 
                moment(item.start_time).add(daysWeekly, "days").format("x") == item.end_time) {
                    const weekHasAllocation = !!(item.user.allocations && item.user.allocations.find(allocation => allocation.week === item.week))
                    return !weekHasAllocation;
            }

            if (item.group == "available" && item.db_id) return true;
        });

        let freeItems = [];

        // calculate new available times
        this.state.personnel.forEach(user => {
            const hoursDaily = user.contract_detail.hours_daily ? parseFloat(user.contract_detail.hours_daily) : 8;
            const hoursWeekly = user.contract_detail.hours_weekly ? parseFloat(user.contract_detail.hours_weekly) : 40;
            const daysWeekly = user.contract_detail.days_weekly ? parseInt(user.contract_detail.days_weekly) : 5;
            const multiplier = parseFloat(24 / hoursDaily);

            // const allocations = items.filter(item => item.user.id === user.id && (item.group != "available" || item.db_id));
            const allocations = user.allocations.filter(item => item.group != "available" || !item.isProjectSchedule);
            allocations.sort((a,b) => moment(a.start, "YYYY-MM-DD hh:mm:ss").format("x") - moment(b.start, "YYYY-MM-DD hh:mm:ss").format("x"));

            const startYear = (allocations[0]) 
                ? parseInt(allocations[0].week.split("-")[1])
                : parseInt(moment().startOf("year").format("YYYY"));
            const endYear = (allocations[0]) 
                ? parseInt(allocations[allocations.length - 1].week.split("-")[1]) 
                : parseInt(moment().endOf("year").format("YYYY"));
            
            for (let year = startYear; year <= endYear; year++) {
                const weeksAmount = moment().year(year).isLeapYear()
                    ? 53
                    : 52;
                for (let week = 1; week <= weeksAmount; week++) {
                    const contractEnd = user.contract_detail.contract_end;
                    const contractEndYear = moment(contractEnd).year();
                    const contractEndWeek = moment(contractEnd).week();

                    const contractHasEnded = !!contractEnd
                        && (year > contractEndYear
                        || (year <= contractEndYear && week > contractEndWeek))
                    
                        if (contractHasEnded) {
                            continue;
                        };
                        
                    let weekNumber = `${week}-${year}`;
                    let isBeforeCurrWeek = false;
                    // Only commented out in case we want to do something with weeks before current week
                    // if (week < weekNow && year <= yearNow) 
                    //     isBeforeCurrWeek = true;
                    // else
                    //     isBeforeCurrWeek = false;

                    const filteredAllocations = allocations.filter(allocation => allocation.week == weekNumber);
                    filteredAllocations.sort((a,b) => moment(a.start, "YYYY-MM-DD hh:mm:ss").format("x") - moment(b.start, "YYYY-MM-DD hh:mm:ss").format("x"));

                    let total = 0;
                    filteredAllocations.forEach(allocation => {
                        const duration = ((parseInt(moment(allocation.end, "YYYY-MM-DD hh:mm:ss").format('x'))-parseInt(moment(allocation.start, "YYYY-MM-DD hh:mm:ss").format('x')))/60/60/1000)/multiplier;
                        total += duration;
                    });
                    total = hoursWeekly - total.toFixed(3);
                    filteredAllocations.forEach((allocation, index) => {
                        const startOfWeek = parseInt(moment(allocation.start, "YYYY-MM-DD hh:mm:ss").startOf('isoWeek').format('x'));

                        if (hoursWeekly == total) {
                            freeItems.push({
                                id: `${week}-${year}-${user.id}-${items.length}-${index}`,
                                user: user,
                                group: 'available',
                                value: total,
                                week: weekNumber,
                                title: `${user.user.name} ${this.convertTime(total)}`,
                                start_time: startOfWeek,
                                end_time: parseInt(moment(startOfWeek).add(daysWeekly, "days").format("x")),
                                canMove: isBeforeCurrWeek ? false : true,
                                canResize: isBeforeCurrWeek ? false : true,
                                canChangeGroup: isBeforeCurrWeek ? false : true,
                            })
                            total = 0;
                        }
                        if (total <= 0) return;

                        const startAtBeginningOfWeek = moment(allocation.start, "YYYY-MM-DD hh:mm:ss").format("x") <= startOfWeek;

                        // set available time to beginning of week if allocated time does not start at beginning of week
                        if (index === 0 && !startAtBeginningOfWeek) {
                            let value = ((moment(allocation.start, "YYYY-MM-DD hh:mm:ss").format('x')-moment(startOfWeek).format('x'))/60/60/1000)/multiplier;
                            value = total < value ? total : value;

                            if (value <= 0) return; // 2 items next to each other

                            freeItems.push({
                                id: `${week}-${year}-${user.id}-${items.length}-${index}`,
                                user: user,
                                group: 'available',
                                value: value,
                                week: weekNumber,
                                title: `${user.user.name} ${this.convertTime(value)}`,
                                start_time: startOfWeek,
                                end_time: parseInt(moment(startOfWeek).add(value * multiplier, "hours").format("x")),
                                canMove: isBeforeCurrWeek ? false : true,
                                canResize: isBeforeCurrWeek ? false : true,
                                canChangeGroup: isBeforeCurrWeek ? false : true,
                            })
                            total -= value;
                        }

                        // next item starts before current item ends
                        if (filteredAllocations[index + 1] && 
                            moment(allocation.end, "YYYY-MM-DD hh:mm:ss").format('x') > moment(filteredAllocations[index + 1].start, "YYYY-MM-DD hh:mm:ss").format("x")) return;

                        let availableTimeStart = startAtBeginningOfWeek 
                            ? startOfWeek 
                            : parseInt(moment(allocation.end, "YYYY-MM-DD hh:mm:ss").format('x'));
                        let availableTimeEnd = filteredAllocations[index + 1]
                            ? parseInt(moment(filteredAllocations[index + 1].start, "YYYY-MM-DD hh:mm:ss").format("x"))
                            : parseFloat(moment(availableTimeStart).add(total*multiplier, 'hours').format('x'));

                        
                        // allocated time starts at beginning of week
                        if (total > 0 && startAtBeginningOfWeek) {
                            let value = ((parseInt(moment(availableTimeEnd).format('x'))-parseInt(moment(allocation.end, "YYYY-MM-DD hh:mm:ss").format('x')))/60/60/1000)/multiplier;
                            value = total < value ? total : value;
                            value = filteredAllocations[index + 1] ? value : total
                            if (value <= 0) return;

                            freeItems.push({
                                id: `${week}-${year}-${user.id}-${items.length}-${index+1}`,
                                user: user,
                                group: 'available',
                                value: value,
                                week: weekNumber,
                                title: `${user.user.name} ${this.convertTime(value)}`,
                                start_time: parseInt(moment(allocation.end, "YYYY-MM-DD hh:mm:ss").format("x")),
                                end_time: parseInt(moment(allocation.end, "YYYY-MM-DD hh:mm:ss").add(value*multiplier, 'hours').format('x')),
                                canMove: isBeforeCurrWeek ? false : true,
                                canResize: isBeforeCurrWeek ? false : true,
                                canChangeGroup: isBeforeCurrWeek ? false : true,
                            })
                            total -= value;
                        }

                        else if(total > 0) {
                            let value = ((moment(availableTimeEnd).format('x')-moment(availableTimeStart).format('x'))/60/60/1000)/multiplier;
                            value = total < value ? total : value;

                            if (value <= 0) return;

                            freeItems.push({
                                id: `${week}-${year}-${user.id}-${items.length}-${index+1}`,
                                user: user,
                                group: 'available',
                                value: value,
                                week: weekNumber,
                                title: `${user.user.name} ${this.convertTime(value)}`,
                                start_time: availableTimeStart,
                                end_time: parseInt(moment(availableTimeStart).add(value*multiplier, 'hours').format('x')),
                                canMove: isBeforeCurrWeek ? false : true,
                                canResize: isBeforeCurrWeek ? false : true,
                                canChangeGroup: isBeforeCurrWeek ? false : true,
                            })
                            total -= value;
                        }
                    })
                }
            }
        })
        return newItems.concat(freeItems);
    }

    splitHoliday(holiday) {
        let items = [];
        let start = moment(holiday.start_date).format("YYYY-MM-DD");
        let end = moment(holiday.end_date).add(1, "day").format("YYYY-MM-DD");

        while (parseInt(moment(start).format("W")) != parseInt(moment(end).format("W"))) {
            items.push({
                start: moment(start).startOf("isoWeek").format("YYYY-MM-DD"),
                end: moment(start).startOf("isoWeek").add(5, "days").format("YYYY-MM-DD"),
                project_id: "holiday",
                week: `${moment(start).isoWeek()}-${moment(start).year()}`,
                user_id: holiday.user_id,
                name: holiday.name
            });
            start = moment(start).add(1, "week").format("YYYY-MM-DD");
        }
        if (end != start) {
            items.push({
                start: moment(start).format("YYYY-MM-DD"),
                end: moment(end).format("YYYY-MM-DD"),
                project_id: "holiday",
                week: `${moment(start).isoWeek()}-${moment(start).year()}`,
                user_id: holiday.user_id,
                name: holiday.name
            });
        }

        return items;
    }

    parseMarkers(projects) {
        let dateMarkers = [];
        projects.forEach(project => {
            if (dateMarkers.some(mark => mark['date'] === project.start && mark.begins)) {
                dateMarkers = dateMarkers.map(marker => {
                    if (marker.date === project.start && marker.begins) {
                        let newBegins = marker.begins
                        newBegins.push(project.name)
                        return (
                            Object.assign({}, marker, {
                                begins: newBegins,
                            })
                        )
                    }
                    else {
                        return marker
                    }
                })
            }
            else {
                dateMarkers.push({
                    id: dateMarkers.length,
                    date: project.start,
                    begins: [project.name]
                })
            }
            if (dateMarkers.some(mark => mark['date'] === project.end && mark.ends)) {
                dateMarkers = dateMarkers.map(marker => {
                    if (marker.date === project.end && marker.ends) {
                        let newEnds = marker.ends
                        newEnds.push(project.name)
                        return (
                            Object.assign({}, marker, {
                                ends: newEnds,
                            })
                        )
                    }
                    else {
                        return marker
                    }
                })
            }
            else if (project.end) {
                dateMarkers.push({
                    id: dateMarkers.length,
                    date: project.end,
                    ends: [project.name]
                })
            }
            else {
                this.setState({ continious: true })
            }
        })
        this.setState({ dateMarkers: dateMarkers })
    }

    renderPersonnel()
    {
        return (
            <Personnel
                start={ this.state.tree[0].start }
                end={ this.state.tree[0].end }
                groups={ this.state.personnelGroups }
                items={ this.state.personnelItems }
                loading={ this.state.loading }
                personnel={ this.state.personnel }
                personnel_assignments={ this.state.personnel_assignments }
                personnel_assignments_sub_projects={ this.state.personnel_assignments_sub_projects }
                openGroups={ this.state.openPersonnelGroups }
                entries_only_if_in_personnel={ this.state.entries_only_if_in_personnel }
                openParentGroups={ this.state.openPersonnelParentGroups }
                weeklyMaxHours={ this.state.personnelWeeklyMaxHours }
                handleEditPersonnelItem={this.handleEditPersonnelItem}
                handleAssignmetsEdit={this.handleAssignmetsEdit}
                handleDeleteAssignmetItem={this.handleDeleteAssignmetItem}
                handleDeletePersonnelItem={this.handleDeletePersonnelItem}
                updatePersonnelGroups={this.updatePersonnelGroups}
                updateOpenPersonnelGroups={this.updateOpenPersonnelGroups}
                updateOpenPersonnelParentGroups={this.updateOpenPersonnelParentGroups}
                calculateGroupValues={this.calculateGroupValues}
                convertTime={this.convertTime}
                projectsStart={this.state.projectsStart}
                projectsEnd={this.state.projectsEnd}
                // dateMarkers={this.state.dateMarkers}
            />
        )
    }

    // ----------------------------------------
    //  Render tree settings
    // ----------------------------------------

    renderTreeSettingManager( project, parent )
    {

        let hasOwn = false;
        let selected = project.manager_parent;
        let info = null;

        if( selected )
        {
            info = <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                Päällikkö tulee ylemmältä projektilta
            </div>
        }

        if( project.manager )
        {
            selected = project.manager;
            hasOwn = true;
            info = null;
        }

        return <div>
            <ApSelect
                label={ tr('project_manager') }
                value={ selected || null }
                optionRenderer="user"
                onChange={ ( user ) => { this.changeSettingSetManager( project, user) } }
                objKeyId="id"
                objKeyValue="name"
                clearable={ hasOwn }
                apiUrl="project/find/manager"
                loading={ this.state.loading }
                disabled={ this.state.locked || this.state.loading || false }
                filterNonActives={true}
            />
            { info }
        </div>
    }

    renderTreeSettingProjectVisibility( project, parent) {
        if (parent || project.parent_id) return null;

        const selected = project.project_visible_to_users;
        const managerId = keyExists(project, 'manager.id', true, null);

        let info = <div className="apInfo small">
            <SvgIcon icon="info-circle" type="solid" />
            {tr('select_project_visibility')}
        </div>;

        return (
            <div>
                <ApSelect
                    label={ tr('project_visibility') }
                    value={ selected || [] }
                    optionRenderer="user"
                    onChange={ ( user ) => { this.changeSettingSetVisibility( project, user ) } }
                    objKeyId="id"
                    objKeyValue="name"
                    clearable={ true }
                    apiUrl="search/user"
                    apiData={{
                        modules: ['projects'],
                        is_active: true,
                        exludeIds: managerId ? [managerId] : null,
                        active_contracts_only: true
                    }}
                    loading={ this.state.loading }
                    disabled={ this.state.locked || this.state.loading || false }
                    filterNonActives={true}
                    multiselect
                    valueRenderer="user"
                    objKeySearchable="user"
                />
                { info }
            </div>
        )
    }

    renderTreeSettingHourApprover( project, parent )
    {
        
        let hasOwn = false;
        let selected = project.hour_approvers_parent;
        let info = null;

        if( selected && !project.hour_approvers.length )
        {
            info = <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('approver_comes_from_upper_project') }
            </div>
        }

        if( project.hour_approvers.length )
        {
            selected = project.hour_approvers;
            hasOwn = true;
            info = null;
        }

        return <div>
            <ApSelect
                label={ tr('approver_of_hours') }
                value={ selected || [] }
                optionRenderer="user"
                onChange={ ( user ) => { this.changeSettingSetApprover( project, user ) } }
                objKeyId="id"
                objKeyValue="name"
                clearable={ hasOwn }
                apiUrl="project/find/workhourapprover"
                loading={ this.state.loading }
                disabled={ this.state.locked || this.state.loading || false }
                filterNonActives={true}
                multiselect
                valueRenderer="user"
                objKeySearchable="user"
            />
            { info }
        </div>
    }
    changeTaxGroup(data, key) {
        let tree = this.state.tree
        let project = tree[0];
        if (key === "component") {
            project.default_item_tax_group = data;
            project.default_item_tax_group_id = data.id||null;
        }
        else if (key === "work") {
            project.default_work_tax_group = data;
            project.default_work_tax_group_id = data.id || null;
        }
        else if (key === "expence") {
            project.default_expence_tax_group = data;
            project.default_expence_tax_group_id = data.id || null;
        }
        tree[0] = project;
        this.setState({ tree, treeUnsaved:true});
    }

    renderTreeSettingBilling( project, parent )
    {

        // console.log(project.extranet_approvers);
        // console.log('FO', project, parent );

        if( !project.billing_hourly )
            return null;
            
        return (
            <div>
                <ApSelect
                    label={ tr('billers') }
                    value={ project.billers || [] }
                    valueRenderer="user"
                    optionRenderer="user"
                    onChange={ ( users ) => { this.changeSettingSetBillers( users ) } }
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="project/find/biller"
                    loading={ this.state.loading }
                    disabled={ project.parent_id || this.state.locked || this.state.loading || false }
                    multiselect
                    filterNonActives={true}
                    validationState={(!project.parent_id && (project.billers && !project.billers.length) ) ? "error":""}
                    debounce={500}
                />
                { project.parent_id && 
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('billers_info') }
                    </div>
                }

                <br />

                <ApSelect
                    label={ tr('approvers_of_customer_hours') }
                    value={ project.extranet_approvers || [] }
                    valueRenderer={ ( item ) => <span className={ "extranetUserValue" + ( item.extranet_active ? " active" : "" ) }>{ item.name || "Nimetön" }</span> }
                    optionRenderer={ ( item ) => <div className="extranetUserOption">
                        <span>{ item.name || tr('anonymous') }</span>
                        <span className={ "username" + ( item.extranet_active ? " active" : "" ) }>
                            <SvgIcon clasName="small-inline" icon="handshake" type="solid" />
                            { item.extranet_username }
                        </span>
                    </div> }
                    onChange={ ( users ) => { this.changeSettingSetExtranetUsers( users ) } }
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="project/find/extranetuser"
                    apiData={{
                        crm_company: keyExists( project, "customer.id", true ),
                        extranet_active: true,
                    }}
                    loading={ this.state.loading }
                    disabled={ project.parent_id || this.state.locked || this.state.loading || false }
                    multiselect
                />

                { !project.parent_id && project.customer && !project.customer.extranet_active && project.extranet_approvers.length > 0 &&
                    <div className="apWarningMsg">
                        <strong>{ tr('customer_extranet_turned_off') }</strong><br />
                        { tr('customer_extranet_turned_off_info') }
                    </div>
                }
                <div className="apFormGroup">
                <div className="apSwitchBlock ">
                    <ApTooltip text={tr('add_billable_rows_in_email_info') } block position="topright">
                    <label htmlFor="add-billable-rows-email-switch" className="info">
                        { tr('add_billable_rows_in_email') }
                        <small>{ tr('add_billable_rows_in_email_info') }</small>
                    </label>
                    <ApSwitch
                        // small
                        id="add-billable-rows-email-switch"
                        on={ project.send_pdf_in_billable }
                        onChange={ () => { this.changeSettingRowsInEmail( project ) } }
                        disabled={ this.state.loading || this.state.locked || Boolean( project.parent_id ) }
                    />
                </ApTooltip>
            </div>
            </div>

                { project.extranet_approvers.map( ( approver, key ) => 
                    <div className="extranetApproverSettings">
                        <ApInputStack key={ key } gap="0">
                            <ApAddon>{ approver.name }</ApAddon>
                            <ApAddon 
                                noLeftBorder
                                width="250" 
                                onClick={ () => this.changeSettingExtranetUserSetting( key, "is_auto_approved", !approver.is_auto_approved ) }
                                className="switchAddon"
                            >
                                <ApSwitch
                                    inline
                                    small
                                    id={ "auto-approval-switch" + key }
                                    on={ approver.is_auto_approved }
                                    disabled={ this.state.loading || this.state.locked }
                                />
                                <small>{ tr('automatic_approval') }</small>
                            </ApAddon>
                            <ApAddon 
                                noLeftBorder
                                width="50" 
                                onClick={ () => this.changeSettingExtranetUserSetting( key, "is_primary", true ) }
                                className={ approver.is_primary ? "primary" : "" }
                                tooltip={ tr('set_as_default_approver') }
                            >
                                <SvgIcon icon="star" type={ approver.is_primary ? "solid" : "regular" } />
                            </ApAddon>
                        </ApInputStack> 
                    </div> 
                )}

                { project.extranet_approvers && project.extranet_approvers.length > 0 &&
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('automatic_approval_info') }
                    </div>
                }   

                { project.parent_id && 
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('approvers_of_customer_hours_info') }
                    </div>
                }

                { this.state.mainParentProject && 
                    (   this.state.mainParentProject.customer &&
                        this.state.mainParentProject.customer.extranet_active ) && 
                    (   project.extranet_approvers &&
                        project.extranet_approvers.length === 0 ) &&
                    <div className="apFormGroup">
                        <div className="apSwitchBlock small">
                            <ApTooltip text={project.parent_id ? 'Hyväksynnän poikkeusta voi säätää ainoastaan pääprojektista' : null} block position="topright">
                                <label htmlFor="approval-disabled-exception-switch" className="info">
                                    Hyväksynnän poikkeus
                                    <small>Asiakkaalla on extranet päällä, mutta projekti ei tarvitse laskutukseen tuntien hyväksyjää</small>
                                </label>
                                <ApSwitch
                                    small
                                    id="approval-disabled-exception-switch"
                                    on={ this.state.mainParentProject.approval_exception }
                                    onChange={ () => { this.changeApprovalException(project) } }
                                    disabled={ this.state.loading || this.state.locked || Boolean(project.parent_id) }
                                />
                            </ApTooltip>
                        </div>
                    </div>
                }

                <div className="apFormGroup">
                    <div className="apSwitchBlock small">
                        <ApTooltip text={tr('affects_only_selected_project')} block position="topright">
                            <label htmlFor="customer-work-number-required-switch" className="info">
                                {tr('customer_work_number_required')}
                                <small>{tr('customer_work_number_required_info')}</small>
                            </label>
                            <ApSwitch
                                small
                                id="customer-work-number-required-switch"
                                on={ project.customer_work_number_required }
                                onChange={ () => { this.handleProjectValueChange(project.id, 'customer_work_number_required', !Boolean(project.customer_work_number_required)) } }
                                disabled={ this.state.loading || this.state.locked }
                            />
                        </ApTooltip>
                    </div>
                </div>

            </div>
        );
    }
    renderLabel() {
        return <span>{tr('cost_center')}</span>
    };

    renderLabelValue(code) {
        return code.full_name;
        if (!this.currentlyInputingTail()) return code.code + ": " + code.name;
        const separator = this.props.codeSeparator ? this.props.codeSeparator : '';
        const tail = this.props.tail ? this.props.tail : ''
        return `${code.code}${separator}${tail}`;
    };
    currentlyInputingTail() {
        // When code format is free, we only input the tail
        if (this.props.codeLimit && this.props.codeLimit.is_free)
            return true;
        if (!this.props.code) return false;
        return (this.props.code.ancestors.length + 1 === this.props.codeLevelCount);
    }

    renderTaxGroupValue(taxGroup) {
        if (!taxGroup) {
            return "";
        }
        let name = taxGroup.name || "";
        let tax = taxGroup.vat ? (taxGroup.vat.value+"%") : "";
        let account = taxGroup.account ? ("("+taxGroup.account+")"): "";
        let text = name + " " + tax + " " + account;
        return text
    }

    renderTreeSettingSchedule( project, parent )
    {
        let componentTable = <div style={{ marginTop: '1em', fontWeight: '900' }}><p>{tr('project_no_storage_transfer_components')}</p></div>;
        if (this.state.storageTransferComponents && this.state.storageTransferComponents.length > 0) {
            componentTable = <div>
            <p>{tr('components')}</p>
            <ApReactTable
                data={ this.state.storageTransferComponents }
                columns={ this.getColumns() }
            />
            <p>{tr('storage_transfer')}:</p>
            
            <ApSelect
                label={tr('recipient_storage')}
                value={ this.state.receiverTitle ? this.state.receiverTitle : '' }
                optionRenderer="storage_location_address"
                onChange={ this.setReceiverStorage }
                objKeyId="id"
                clearable
                apiUrl="storage/locations/find"
                apiData={{ 
                    canReceive: true,
                    projectStorage: false,
                }}
            />

            </div>
        }

        const transferDiv = <div>
            <ApButton onClick={ () => window.emitter.emit( 'goTo', { pathname: `../../../storage/transports/edit` } )}>
                <SvgIcon icon="search" type="solid" />
                {tr('go_to_transfer')}
            </ApButton>
        </div>

        let continuousTooltip = null;
        if( project.parent_id )
            continuousTooltip = tr('project_continuity_adjust_info')
        else if( !project.billing_hourly )
            continuousTooltip = tr('contract_job_billing_project_info')
        let showProjectToExtranetTooltip = null;
        if (project.parent_id)
            showProjectToExtranetTooltip = tr('show_project_to_extranet_adjust_info')

        let inputSelection = []
        for (let i = 1; i < 8; i++)
        {
            inputSelection.push({
                value: moment().locale('en').isoWeekday(i).format('ddd'),
                label: moment().locale(currentLang()).isoWeekday(i).format('dddd')
            })
        }

            
        let autoApproveDom = "";
        if ( this.state.project_auto_approve_enabled) {
            autoApproveDom  = <div className="apFormGroup">
            <div className="apSwitchBlock small">
                    <ApTooltip text={tr('automatic_approval_invoicing_info') } block position="topright">
                    <label htmlFor="auto-approve-switch" className="info">
                        { tr('automatic_approval_invoicing') }
                        <small>{ tr('automatic_approval_invoicing_info') }</small>
                    </label>
                    <ApSwitch
                        small
                        id="auto-approve-switch"
                        on={ project.billing_auto_approval }
                        onChange={ () => { this.changeSettingBillingAutoApprovals( project ) } }
                        disabled={ this.state.loading || this.state.locked || Boolean( project.parent_id ) }
                    />
                </ApTooltip>
            </div>
                <Collapse in={ project.billing_auto_approval }>
                    <div className="indentleft">
                        <ApInputStack gap="0">
                            <ApAddon width="150" noRightBorder> { tr('day_of_approval') } </ApAddon>
                            <ApInput
                                type="select"
                                id="dateWeek"
                                name="dateWeek"
                                value={ project.billing_auto_approval_date }
                                options={ inputSelection }
                                onChange={ ( e ) =>{ 
                                    this.changeSettingBillingAutoApprovalDate( project, e.target.value ) 
                                }}
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                            />
                        </ApInputStack>
                    </div>
                </Collapse>
        </div>
        }



        const continuousDom  = <div className="apFormGroup">
            <div className="apSwitchBlock small">
                <ApTooltip text={  continuousTooltip } block position="topright">
                    <label htmlFor="is-continuous-switch" className="info">
                        { tr('continuous_project') }
                        <small>{ tr('continous_project_info') }</small>
                    </label>
                    <ApSwitch
                        small
                        id="is-continuous-switch"
                        on={ project.is_continuous }
                        onChange={ () => { this.changeSettingIsContinuous( project ) } }
                        disabled={ this.state.loading || this.state.locked || !project.billing_hourly || Boolean( project.parent_id ) }
                    />
                </ApTooltip>
            </div>
        </div>
        const showToExtranet = <div className="apFormGroup">
            <div className="apSwitchBlock small">
                <ApTooltip text={showProjectToExtranetTooltip} block position="topright">
                    <label htmlFor="showProjectToExtranet-switch" className="info">
                        {tr('show_project_to_extranet')}
                        <small>{tr('show_project_to_extranet_info')}</small>
                    </label>
                    <ApSwitch
                        small
                        id="showProjectToExtranet"
                        on={project.show_project_to_extranet}
                        onChange={() => { this.changeShowProjectToExtranet(project) }}
                        disabled={this.state.loading || this.state.locked || Boolean(project.parent_id)}
                    />
                </ApTooltip>
            </div>
        </div>
        const TaxGroups = <div className="apFormGroup">
            <div className="apSwitchBlock small">
                <ApSelect
                    label={tr('component_tax_group')}
                    value={this.state.tree[0] ? this.renderTaxGroupValue(this.state.tree[0].default_item_tax_group) : ''}
                    optionRenderer="tax_group"
                    onChange={(data)=>this.changeTaxGroup(data,'component')}
                    objKeyId="id"
                    clearable
                    apiUrl="order/taxGroups/find"
                    // apiData={{
                    //     canReceive: true,
                    //     projectStorage: false,
                    // }}
                    disabled={parent}
                />
                <ApSelect
                    label={tr('work_tax_group')}
                    value={this.state.tree[0] ? this.renderTaxGroupValue(this.state.tree[0].default_work_tax_group) : ''}
                    optionRenderer="tax_group"
                    onChange={(data)=>this.changeTaxGroup(data,'work')}
                    objKeyId="id"
                    clearable
                    apiUrl="order/taxGroups/find"
                    // apiData={{
                    //     canReceive: true,
                    //     projectStorage: false,
                    // }}
                    disabled={parent}
                />
                <ApSelect
                    label={tr('expense_tax_group')}
                    value={this.state.tree[0] ? this.renderTaxGroupValue(this.state.tree[0].default_expence_tax_group) : ''}
                    optionRenderer="tax_group"
                    onChange={(data) => this.changeTaxGroup(data,'expence')}
                    objKeyId="id"
                    clearable
                    apiUrl="order/taxGroups/find"
                    // apiData={{
                    //     canReceive: true,
                    //     projectStorage: false,
                    // }}
                    disabled={parent}
                />
            </div>
            
        </div>

        let descriptionDoms = null;
        let workEndDate = null;
        let startInfo = null;
        let endInfo = null;
        let userAddProgressDom = null;
        let showRemainingHours = null;

        if( parent )
        {
            startInfo = `${tr('upper_project_begins_at')} ${ sqlToDateInput( parent.start ) }`;
            if( parent.end )
                endInfo = `${tr('upper_project_ends_at')} ${ sqlToDateInput( parent.end ) }`;
        }
        else if( project.ancestors )
        {
            const tmp = project.ancestors[ project.ancestors.length -1 ];
            startInfo = `${tr('upper_project_begins_at')} ${ sqlToDateInput( tmp.start ) }`;
            if( tmp.end )
                endInfo = `${tr('upper_project_ends_at')} ${ sqlToDateInput( tmp.end ) }`;
        }

        if( startInfo || endInfo )
        {
            descriptionDoms = <tr className="descriptionRow">
                <td>{ startInfo }</td>
                <td>{ endInfo }</td>
            </tr>
        }

        if( this.state.project_ext_work_enabled )
        {
            workEndDate =<tr>
            <td>
                <ApInput
                    type="datetimeV2"
                    name="end_work"
                    id="end_work"
                    value={ project.end_work }
                    label={ tr('project_time_tracking_ends_at') }
                    onChange={ ( e ) => { this.changeSettingEndWork( project, e ) } }

                    loading={ this.state.loading }
                    disabled={ this.state.loading || this.state.locked }
                />
            </td>
        </tr>
        }

        if (this.state.work_progress_in_timetracking_enabled) {
            userAddProgressDom =<div className="apFormGroup">
                <div className="apSwitchBlock small">
                    <label htmlFor="show_progrees_entry-switch" className="info">
                        {tr('work_progress_estimate')} 
                        <small>{tr('work_progress_estimate_info')}</small>
                    </label>
                    <ApSwitch
                        small
                        id="show_progrees_entry-switch"
                        on={ project.user_add_progress }
                        onChange={ () => { this.changeUserAddProgress( project ) } }
                        disabled={ this.state.loading || this.state.locked }
                    />
                </div>
            </div>
        }

        if (this.state.show_remaining_hours) {
            const translationMain = this.state.remaining_hours_show_selected_projects
                ? tr('show_remaining_hours_timetracking')
                : tr('hide_remaining_hours_timetracking');

            showRemainingHours = (
                <div className="apFormGroup">
                    <div className="apSwitchBlock small">
                        <label htmlFor="show_remaining_hours-switch" className="info">
                            {translationMain} 
                            <small>{tr('project_remaining_hours_info')}</small>
                        </label>
                        <ApSwitch
                            small
                            id="show_remaining_hours-switch"
                            on={ project.show_remaining_hours === null ? false : project.show_remaining_hours }
                            onChange={ () => { this.changeShowRemainingHours( project ) } }
                            disabled={ this.state.loading || this.state.locked }
                        />
                    </div>
                </div>
            );
        }

        return <div className="settingSchedule">
            {autoApproveDom}
            {project.cost_center_enabled &&
                <div>
                <ApNestedSelect
                label={this.renderLabel}
                valueRenderer={this.renderLabelValue}
                    value={project.cost_center}
                parentRenderer="value"
                parentTooltipRenderer="name"
                optionRenderer={(item) => {
                    return <div className="codeOption">
                        <span className="name">{item.nameTree}</span>
                        <span className="code">{item.code}</span>
                    </div>
                }}
                    onChange={(value) => { this.changeCostCenter(project,value) }}
                apiUrl="costCenter/search"
                loading={this.props.loading}
                disabled={this.props.loading || this.props.skipped}
                validationState={(project.cost_center_is_required && project.cost_center==null) ? "error":""}
                //tailInput={tailInput}
                acceptOnlyLastLevel={true}
                isEndReached={endReached => this.setState({ costCenterError: !endReached })}
                    />
                    {project.parent_id &&
                        <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                            {tr('cost_center_child_project_info')}
                    </div>}
                </div>}
            {this.state.use_tax_groups && TaxGroups}
            <br />
            
            {continuousDom}
            {showToExtranet}
            { userAddProgressDom }
            { showRemainingHours }
            <table><tbody>
                <tr>
                    <td>
                        <ApInput
                            type="datetimeV2"
                            name="start"
                            id="start"
                            value={ project.start }
                            label={ tr('project_start_date') }
                            onChange={ ( e ) => { this.changeSettingStart( project, e ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading || this.state.locked }
                        />
                    </td>
                    <td>
                        <ApInput
                            gapLeft="10"
                            type="datetimeV2"
                            name="end"
                            id="end"
                            value={ project.is_continuous ? '' : project.end }
                            label={ tr('project_end_date') }
                            onChange={ ( e ) => { this.changeSettingEnd( project, e ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading || this.state.locked || project.is_continuous }
                        />
                    </td>
                </tr>
                { descriptionDoms }
                { workEndDate }
            </tbody></table>
            { this.state.showStorageTransfer ? componentTable : ''}
            { this.state.storageTransferDone ? transferDiv : ''}
        </div>
    }

    renderTreeSettingAddress( project, parent ) {
        return (
            <div>
                <hr/>
                <h4>{tr('location_alt')}</h4>
                <div>
                    <ApButton onClick={ () => this.getAddress(project) } size="small" color="green" loading={this.state.loading} disabled={this.state.loading}>
                        <SvgIcon icon="globe" type="solid" /> {tr('get_address')}
                    </ApButton>
                </div>
                <ApInput
                    type="text"
                    name="address"
                    id="address"
                    label={ tr('street_address') }
                    value={ project.site_address }
                    onChange={ ( e ) =>  this.changeSettingAddress( project, e ) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading || this.state.locked }
                />
                <ApInputStack collapseAt={700}>
                    <ApInput
                        type="text"
                        name="postal_code"
                        id="postal_code"
                        label={ tr('postal_code') }
                        value={ project.site_postal_code }
                        onChange={ ( e ) =>  this.changeSettingAddress( project, e ) }
                        loading={ this.state.loading }
                        disabled={ this.state.loading || this.state.locked }
                    />
                    <ApInput
                        type="text"
                        name="city"
                        id="city"
                        label={ tr('post_office') }
                        value={ project.site_city }
                        onChange={ ( e ) =>  this.changeSettingAddress( project, e ) }
                        loading={ this.state.loading }
                        disabled={ this.state.loading || this.state.locked }
                    />
                </ApInputStack>
                <ApInput
                    type="text"
                    name="country"
                    id="country"
                    label={ tr('country') }
                    value={ project.site_country }
                    onChange={ ( e ) =>  this.changeSettingAddress( project, e ) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading || this.state.locked }
                />
                <ApInput
                    type="number"
                    name="max_distance"
                    id="max_distance"
                    label={ tr('max_distance') }
                    value={ project.site_max_distance }
                    onChange={ ( e ) =>  this.changeSettingAddress( project, e ) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading || this.state.locked }
                />
                <div className='apInfo small'>
                    <SvgIcon icon="info-circle" type="solid" /> {tr('max_distance_info')}
                </div>
                <div className='apInfo small'>
                    <SvgIcon icon="info-circle" type="solid" /> {tr('distance_calculation_info')}
                </div>
                <div className='apInfo small'>
                    <SvgIcon icon="info-circle" type="solid" /> {tr('geolocation_inaccurate_info')}
                </div>

                <div>
                    <ApButton onClick={ () => this.testDistance(project) } size="small" color="green" loading={this.state.loading} disabled={this.state.loading}>
                        <SvgIcon icon="search" type="solid" /> {tr('test_distance')}
                    </ApButton>
                </div>
                {this.state.distanceTestMetres ? <div>{tr('distance_from_address')}: {this.state.distanceTestMetres.toFixed(2)}m</div> : null}
            </div>
        )
    }

    testDistance(project) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                this.setState({loading: true});
                let data = {
                    address: project.site_address,
                    postal_code: project.site_postal_code,
                    city: project.site_city,
                    country: project.site_country,
                    max_distance: project.site_max_distance,
                    lat: latitude,
                    lon: longitude
                }
                api({
                    method: 'post',
                    url: 'project/test-distance',
                    data: data,
                }).then(response => {
                    this.setState({loading: false});
                    if (response && !isNaN(response)) {
                        // console.log(response * 1000, "meters");
                        this.setState({distanceTestMetres: response * 1000});
                    }
                }).catch(error => {
                    this.setState({loading: false});
                    console.error(error);
                    errorPopper(error, tr('location_error'));
                });
            }, (error) => {
                console.error(error);
                errorPopper(error, tr('location_error'));
            });
        } else {
            console.error("Geolocation is not supported by this browser!");
        }
    }

    getAddress(project) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                this.setState({loading: true});
                let data = {
                    lat: latitude,
                    lon: longitude,
                }
                api({
                    method: 'post',
                    url: 'project/get-address',
                    data: data,
                }).then(response => {
                    this.setState({loading: false});
                    if (response) {
                        let street = response.address.road;
                        if (street && response.address.house_number) {
                            street += " " + response.address.house_number;
                        }
                        this.changeSettingAddress(project, {target: {name: 'address', value: street}});
                        this.changeSettingAddress(project, {target: {name: 'postal_code', value: response.address.postcode}});
                        this.changeSettingAddress(project, {target: {name: 'city', value: response.address.city}});
                        this.changeSettingAddress(project, {target: {name: 'country', value: response.address.country}});
                    }
                    // console.log(response);
                }).catch(error => {
                    this.setState({loading: false});
                    console.error(error);
                    errorPopper(error, tr('location_error'));
                });
            }, (error) => {
                console.error(error);
                errorPopper(error, tr('location_error'));
            }
            );
        } else {
            console.error("Geolocation is not supported by this browser!");
        }
    }

    renderTreeSettings( project, parent )
    {

        let statusOptions = [{
            value: 'active',
            label: tr('running'),
            selected: !project.ended,
            disabled: ( parent && parent.ended ),
        },{
            value: 'ended',
            label: tr('ended'),
            selected: ( project.ended && !project.done ) ,
            disabled: ( parent && parent.done ),
        },{
            value: 'done',
            label: tr('completed'),
            selected: ( project.ended && project.done ),
        }];

        let statusOptionDoms = statusOptions.map( ( o, i ) => {

            let className = [ 'option' ];
            if( o.selected ) className.push( 'selected' );
            if( o.disabled ) className.push( 'disabled' );

            let onClick = null;
            if( !o.disabled )
                onClick = () => { this.changeSettingStatus( project, o.value ) };

            return <div key={ i } className={ className.join(' ') } onClick={ onClick } >
                <ApTooltip text={ o.tooltip ? o.tooltip : null } block position="topright">
                    { o.label }
                </ApTooltip>
            </div>
        })
        let statusBar = <div className={`projectStatusBar apOptionBar count-${ statusOptions.length }`}>
            { statusOptionDoms }
        </div>

        return <div className="padding">

            { statusBar }
            
            <ApInput
                type="textarea"
                name="description"
                id="description"
                label={ tr('project_description') }
                value={ project.description }
                onChange={ ( e ) =>  this.changeSettingDescription( project, e.target.value ) }
                loading={ this.state.loading }
                disabled={ this.state.loading || this.state.locked }
            />



            { this.renderTreeSettingManager( project, parent ) }

            {this.renderTreeSettingProjectVisibility( project, parent ) }

            { this.renderTreeSettingHourApprover( project, parent ) }

            { this.renderTreeSettingBilling( project, parent ) } 

            { this.renderTreeSettingSchedule( project, parent ) }

            { this.renderTreeSettingAddress( project, parent ) }
        </div>
    }

    // ----------------------------------------
    //  Render tree common
    // ----------------------------------------


    renderTreePath()
    {
        const currentProject = this.state.selectedProject;
        if( !currentProject )
            return null;

        const withDropdown = ( item ) => {

            const rootProject = ( currentProject.parent_id ? false : true );

            let removeDisabled = false;
            if( rootProject ) removeDisabled = true;
            if( this.state.locked ) removeDisabled = true;

            // hmm we should also check children.... when removing

            let gotoDisabled = false;
            if( rootProject ) gotoDisabled = true;
            if( isNaN( parseInt( currentProject.id, 10 ) ) ) gotoDisabled = true;

            //console.log('dd', item, item.id, parseInt( item.id, 10 ) );

            const projectNotRemovable = keyExists(currentProject, 'calculated.all.notRemovable', true, false)

            return <div className={ "inner current" + ( rootProject ? " withIcon" : "" ) }>
                { rootProject && <SvgIcon className="stepIcon" icon={ item.icon } type="solid" /> }
                <strong>{ currentProject.name }</strong>
                <small>{ currentProject.project_code }</small>

                <ApDropdown
                    actionId={ currentProject }
                    actions={[
                        {
                            label: tr('add_subproject'),
                            icon: "plus",
                            disabled: this.state.locked,
                            action: ( item, closeFunc ) => {
                                this.addSubProjectClick( item );
                                closeFunc();
                            },
                        },
                        {
                            label: tr('edit_project'),
                            icon: "edit",
                            disabled: this.state.locked,
                            action: ( item, closeFunc ) => {
                                this.editSubProjectClick( item );
                                closeFunc();
                            },
                        },
                        {
                            label: tr('copy_project'),
                            icon: "copy",
                            disabled: projectNotRemovable,
                            action: ( item, closeFunc ) => {
                                this.copyProjectClick( item );
                                closeFunc();
                            },
                        },
                        {
                            label: tr('copy_project_from_another_project'),
                            icon: "copy",
                            disabled: this.state.locked,
                            action: ( item, closeFunc ) => {
                                this.copyProjectToAnotherClick( item );
                                closeFunc();
                            },
                        },     
                        {
                            label: tr('delete_project'),
                            icon: "trash-alt",
                            disabled: removeDisabled || projectNotRemovable,
                            tooltip: projectNotRemovable,
                            action: ( item, closeFunc ) => {
                                this.removeSubProjectClick( item );
                                closeFunc();
                            }
                        },
                        {
                            label: tr('create_assignment'),
                            icon: "tasks",
                            action: ( item, closeFunc ) => {
                                window.emitter.emit( 'goTo', {
                                    pathname: `/assignmentsNew/project/${ currentProject.id }/`,
                                });
                                closeFunc();
                            }
                        },
                        {
                            label: tr('select_project'),
                            icon: "external-link-square-alt",
                            disabled: gotoDisabled,
                            action: ( item, closeFunc ) => {
                                window.emitter.emit( 'goTo', {
                                    pathname: `/projects/${ currentProject.id }/management`,
                                });
                                closeFunc();
                            }
                        },
                        {
                            label: tr('update_prices'),
                            icon: "money-bill-wave",
                            // disabled: this.state.treeUnsaved,
                            action: (item, closeFunc) => {
                                this.updateSellPrice(item);
                                closeFunc();
                            }
                        },
                        {
                            label: tr('update_prices_all'),
                            icon: "money-bill-wave",
                            // disabled: this.state.treeUnsaved,
                            action: (item, closeFunc) => {
                                this.updateSellPrice(item, true);
                                closeFunc();
                            }
                        },
                        {
                            label: tr('get_component_mass_list'),
                            icon: "file-excel",
                            disabled: this.state.treeUnsaved,
                            tooltip: this.state.treeUnsaved?tr("save_first"):null,
                            action: (item, closeFunc) => {
                                this.getComponentsMassList(item);
                                closeFunc();
                            }
                        },
                        // Progress reports moved to tracking/reports tab
                        // {
                        //     label: tr('get_progress_report_sum'),
                        //     icon: "file-excel",
                        //     disabled: this.state.treeUnsaved,
                        //     tooltip: this.state.treeUnsaved?tr("save_first"):null,
                        //     action: (item, closeFunc) => {
                        //         this.getProgressReport(item, true, true);
                        //         closeFunc();
                        //     }
                        // },
                        // {
                        //     label: tr('get_progress_report_single'),
                        //     icon: "file-excel",
                        //     disabled: this.state.treeUnsaved,
                        //     tooltip: this.state.treeUnsaved?tr("save_first"):null,
                        //     action: (item, closeFunc) => {
                        //         this.getProgressReport(item);
                        //         closeFunc();
                        //     }
                        // },
                        // {
                        //     label: tr('get_progress_report_all'),
                        //     icon: "file-excel",
                        //     disabled: this.state.treeUnsaved,
                        //     tooltip: this.state.treeUnsaved?tr("save_first"):null,
                        //     action: (item, closeFunc) => {
                        //         this.getProgressReport(item, true);
                        //         closeFunc();
                        //     }
                        // },
                    ]}
                />
            </div>
        };

        let items = [];
        const iterate = ( id, selected = false ) => {
            const project = findItemById( this.state.tree, id, 'children' );
            if( project )
            {
                items.unshift({
                    icon:           ( !project.parent_id ? "folder" : false ),
                    selected:       selected,
                    onClick:        ( selected ? false : () => this.selectTreeProject( project ) ),
                    name:           project.name,
                    description:    project.project_code,
                    customRenderer: ( selected ? withDropdown : false ),
                });

                if( project.parent_id )
                    iterate( project.parent_id );
            }
        };

        iterate( currentProject.id, true );

        return (
            <ApSteps className="projectPath" steps={ items } />
        )
    }

    groupsChange(groups,edit=true) {
        console.log(edit);
        if (edit) {
            this.setState({ groups: groups, treeUnsaved: true });
        }
        else {
            this.setState({ groups: groups });
        }
        
    }
    openGroup(type,group) {
        let groups = this.state.groups.slice(0);
        groups = groups.map(g => {
            if (g.id === group.id)
                g.open = !g.open;
            return g;
        });
        this.groupsChange(groups,false);
    }
    setGroupName(type,group, value) {
        let groups = this.state.groups.slice(0);
        groups = groups.map(g => {
            if (g.id === group.id)
                g.name = value;
            return g;
        });
        this.groupsChange(groups,true);
    }
    getNewcounter() {
        this.newCounter++;
        return this.newCounter;
    }

    renderComponentTable( type )
    {
        const project = this.state.selectedProject;
        const tmp = sel.find( f => f.type === type );
        //console.log( sel );
        //console.log( tmp.pName );
        //console.log(project);
        //console.log(project[ tmp.pName ] );
        let locked= this.state.locked;
        if (this.state.locked || (this.state.main_project_logging_disabled && project.parent_id===null)) {
            locked=true;
        }
        
        return <ComponentTable
            type={ type }
            rows= { project[ tmp.pName ] }
            selected={this.state[tmp.sName]}
            groups={this.state.groups}
            groupsChange={this.groupsChange}
            clearSelected={this.clearSelected}

            project={ project }
            selectComponent={ this.selectComponent }
            updateTreeItem={ this.updateTreeItem }
            tallyShow={ this.tallyShow }
            notRemovable={ this.notRemoveble }
            componentRemoveClick={ this.componentRemoveClick }

            colShow={ this.state.colShow }
            changeColShow={ ( data ) => this.setState({ colShow: { ...this.state.colShow, ...data }}) }
            colFilters={ this.state.colFilters }
            changeColFilters={ ( data ) => this.setState({ colFilters: { ...this.state.colFilters, ...data }}) }
            componentAdd={ this.componentAdd }

            openComponentEdit={ this.openComponentEdit }

            downloadQRcode={this.downloadQRcodePopup}
            updateSellPrice={this.updateSellPrice}
            getNewCounter={this.getNewcounter}

            loading={ this.state.loading }
            locked={ locked }
        />
    }

    countComments(type) {
        const project = this.state.selectedProject;
        let count = 0;
        switch (type) {
            case "work":
                count = project.works.filter(x => x.comment).length
                return count>0?count:false;
            case "item":
                count = project.components.filter(x => x.comment ).length
                return count > 0 ? count : false;
            case "other":
                count = project.expenses.filter(x => x.comment).length
                return count > 0 ? count : false;
                
        }
            
    }

    renderTree()
    {
        const project = this.state.selectedProject;
        if( !project ) return null;

        // console.log( 'PROJECT', project );

        const parent = findItemById( this.state.tree, project.parent_id, 'children' );

        return <div>
            <div className="padding">
                { this.renderTreePath() }
            </div>
            <ApTabs>
                <ApTab icon={getTypeIcon('work')} label={tr('works')} badge={this.countComments("work")} badgeColor="orange">
                    { this.renderComponentTable( 'work' ) }
                </ApTab>
                <ApTab icon={getTypeIcon('item')} label={tr('components_alt')} badge={this.countComments("item")} badgeColor="orange">
                    { this.renderComponentTable( 'item' ) }
                </ApTab>
                <ApTab icon={getTypeIcon('other')} label={tr('costs')} badge={this.countComments("other")} badgeColor="orange">
                    { this.renderComponentTable( 'other' ) }
                    { this.renderBillingMargins() }
                </ApTab>
                <ApTab icon="shopping-cart" label={ tr('storage') }>
                    { this.renderSelectedStorage() }
                    { this.renderLocationComponents() }
                </ApTab>
                <ApTab icon="cogs" label={ tr('settings') } align="right">
                    { this.renderTreeSettings( project, parent ) }
                </ApTab>
            </ApTabs>
        </div>
    }

    moveSelectedComponents( project )
    {
        // Iterate tree
        let tree = mapTree( this.state.tree, ( item ) => {

            // Loop the groups ( works, components, expenses )
            sel.forEach( s => {

                let selected = this.state[ s.sName ];
                selected.forEach( sc => {

                    // Remove the component from old project
                    item[ s.pName ] =  item[ s.pName ].filter( c => c.id !== sc.id );

                    // Add project to new component
                    if( item.id === project.id )
                    {
                        let c = { ...sc }
                        c.project_id = project.id;
                        c.moved = true;
                        item[ s.pName ].push( c );
                    }
                });
                this.setState({ [ s.sName ]: [] });
            });
            return item;
        });
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    renderTreeNodeValue( project )
    {
        const showMoveButton = sel.some( s => (this.state[ s.sName ].length > 0 ) );
        if( showMoveButton )
        {
            return <div className="moveToButton">
                <ApTooltip text={ tr('move_selected_components_here') } position='topright'>
                    <ApButton size="tiny" onClick={ () => { this.moveSelectedComponents( project ) } }>
                        <SvgIcon icon="sign-in-alt" type="solid" />
                    </ApButton>
                </ApTooltip>
            </div>
        }

        const group = ( this.state.selectedDetailCumulative ? "all" : "own" );
        const selectedDetail = this.state.selectedDetail;

        let value = null;
        if( selectedDetail  === 'schedule' )
        {
            let icon = 'play-circle';
            let tooltip = tr('running');
            let color = 'green';

            if( moment(project.start).diff(now) > 0 )
            {
                icon = 'arrow-alt-circle-right';
                tooltip = tr('has_not_started_yet');
                color = 'blue';
            }
            else if( moment(project.end ).diff(now) < 0 )
            {
                icon = 'arrow-alt-circle-left';
                tooltip = tr('ended');
                color = 'orange';
            }

            value = <div className={ color }>
                <ApTooltip block text={ tooltip }>
                    <SvgIcon icon={ icon } type="solid" />
                </ApTooltip>
            </div>
        }
        else if( selectedDetail  === 'manager' )
        {
            let manager = project.manager_parent;
            // let hasOwnManager = false;
            if( project.manager )
            {
                manager = project.manager;
                // hasOwnManager = true;
            }

            if( manager )
                value = <div><ApUserImage tooltip={ manager.name } className="image" user={ manager } size="small" /></div>
        }

        else if( selectedDetail  === 'workhour_approver' )
        {
            let hourApprover = project.workhour_approver_parent;
            // let hasOwnApprover = false;
            if( project.workhour_approver )
            {
                hourApprover = project.workhour_approver;
                // hasOwnApprover = true;
            }

            if( hourApprover )
            {
                const tooltip = `${ hourApprover.name }`;
                // const image = ( hourApprover.image ? getImageUrl( hourApprover.image ) : '/img/user_default.jpg' );
                value = <div>
                    <ApTooltip block text={ tooltip }>
                        <ApUserImage className="image" user={ hourApprover } size="small" />
                    </ApTooltip>
                </div>
            }
        }
        else if( selectedDetail ===  'hours_future_difference_percent' )
        {
            let found = detailOptions.find( o => o.value === selectedDetail );
            value = project.calculated[ group ].total[ found.value ]

            if( !isFinite( value ) )
                value = null;
            else if( value === -100 )
                value = <div>
                    <ApTooltip block text={ tr('estimate_missing') }>
                        <SvgIcon icon="question-circle" type="solid" />
                    </ApTooltip>
                </div>
            else
                value = formatMoney( project.calculated[ group ].total[ found.value ], found.decimals ) + " " + found.unit;
        }
        else if( selectedDetail === 'status' )
        {
            let icon = 'play-circle';
            let tooltip = tr('project_running');
            let color = 'gray';
            if( project.calculated.all.status === 'done' )
            {
                icon = 'check-circle';
                tooltip = `${tr('project_completed_at')} ${ sqlToDateInput( project.ended ) }`;
                color = 'green';
            }
            else if( project.calculated.all.status === 'ended' )
            {
                icon = 'stop-circle';
                tooltip = `${tr('project_ended_at')} ${ sqlToDateInput( project.ended ) }`;
                color = 'orange';
            }

            value = <div className={ color }>
                <ApTooltip block text={ tooltip }>
                    <SvgIcon icon={ icon } type="solid" />
                </ApTooltip>
            </div>
        }
        else if( [ "billing_margin_travel", "billing_margin_expense", "billing_margin_allowance" ].indexOf( selectedDetail ) != -1 )
        {
            if( project[ selectedDetail ] != null )
                value = project[ selectedDetail ]
            
            else if ( project[ selectedDetail + "_parent" ] != null )
                value = project[ selectedDetail + "_parent" ]
            
            return value + " %";
        }
        else
        {
            let found = detailOptions.find( o => o.value === selectedDetail );
            if( found )
            {
                value = formatMoney( project.calculated[ group ].total[ found.value ], found.decimals ) + " " + found.unit;
            }
            else
            {
                console.error('Option not found');
                value = null;
            }
        }
        return value;
    }

    renderTreeNode( project, level )
    {
        // Unsaved project id starts with word "new" therefore they cannot be parsed to integer
        // const unsaved = isNaN( parseInt( project.id, 10 ) );
        const value = this.renderTreeNodeValue( project );

        return <div className="project">
            <ApTooltip block text={ project.invalid || null }>
                { project.name }<br />
                <small>{ project.project_code }</small>
            </ApTooltip>
            { value && <div className="value">{ value }</div> }
        </div>
    };

    renderDetailPicker()
    {

        let optionOther = [];
        let optionGroups = [];

        detailOptions.forEach( i => {
            if( i.group )
            {
                let oldGroup = optionGroups.find( f => f.label === i.group );
                if( oldGroup )
                    oldGroup.options.push( i );
                else
                    optionGroups.push( { label: i.group, options: [ i] } )
            }
            else
                optionOther.push( i );
        });

        if( optionOther.length )
            optionGroups.push({ label: 'Muut', options: optionOther });

        return <div className="detailPicker">
            <ApInputStack gap="0">
                <ApAddon noRightBorder width="50px">
                    <ApTooltip block text={ this.state.selectedDetailCumulative ? tr('subprojects_included') : tr('subprojects_not_included') }>
                        <div className="apSimpleButton"
                            onClick={ () => { this.setState({ selectedDetailCumulative: !this.state.selectedDetailCumulative }); } }
                        >
                            <SvgIcon icon={ this.state.selectedDetailCumulative ? "stream" : "minus" } type="solid" />
                        </div>
                    </ApTooltip>
                </ApAddon>
                <ApInput
                    type="select"
                    options={ optionGroups }
                    id="codeTreeDetail"
                    name="codeTreeDetail"
                    value={ this.state.selectedDetail }
                    onChange={ ( e ) => { this.setState({ selectedDetail: e.target.value }); } }
                />
            </ApInputStack>
        </div>
    }

    renderQRcodeSelect()
    {
        return <ApModalInput
            show={ Boolean( this.state.downloadQR.show ) }
            title={tr('download_qr')}
            value={[
                ""
            ]}
            label={tr('additional_info')}
            required={[
                false,
            ]}
            onSave={ ( values ) => this.downloadQRcode( this.state.downloadQR.work_id, values[0] ) }
            onClose={ () => this.setState({ downloadQR: {} }) }
        />
    }
    renderEditStorageAddress() {
        if (this.state.selectedProject == null || this.state.selectedProject.storage_location == null) {
            return null;
        }
        let data = this.state.selectedProject.storage_location;
        return <ApModalInput
            show={Boolean(this.state.showEditStorageAddress)}
            title={tr("edit_address")}
            value={[
                data.delivery_name,
                data.delivery_address,
                data.delivery_zipcode,
                data.delivery_city,
                data.delivery_country,
                data.delivery_contact,
            ]}
            label={[tr("recipient"), tr("address"), tr("postal_code"), tr("locality"), tr("country"), tr("contact_person"),]}
            // required={[
            //     true,
            //     true,
            // ]}
            onSave={(values) => {
                let projectStorageAddress = this.state.selectedProject.storage_location;
                projectStorageAddress.delivery_name = values[0];
                projectStorageAddress.delivery_address = values[1];
                projectStorageAddress.delivery_zipcode = values[2];
                projectStorageAddress.delivery_city = values[3];
                projectStorageAddress.delivery_country = values[4];
                projectStorageAddress.delivery_contact = values[5];
                
                this.updateTreeItem(this.state.selectedProject.id, (item) => {
                    item.storage_location = projectStorageAddress;
                    return item;
                });
                this.setState({ treeUnsaved: true });
                
            }}
            onClose={() => this.setState({ showEditStorageAddress: false })}
        />
    }

    renderProjectEditModal()
    {
        let data = this.state.subProjectEdit.data;

        let title = tr('new_subproject');
        let name = '';
        let code = '';
        let id = null;
        let label=[tr('name'), tr('project_code')];
        if (this.state.subProjectEdit.copy) {
        	label=[tr('new_name'), tr('new_project_code')];
        }

        if( data )
        {
        	if (this.state.subProjectEdit.copy) {
        		this.state.subProjectEdit.parent=findItemById(this.state.tree.slice(0), data.parent_id);
        		id = data.id;
        	
       		 	title = tr('copy_subproject');
       	        name = `${tr('copy_of')} ${data.name}`;
       	        
                if( this.state.subProjectEdit.parent )
                {
                    const siblings = this.state.subProjectEdit.parent.children;
                    let nextCode = 0;
                    siblings.forEach(( s ) => {
                        let code = parseInt( getLastCodePart( s.project_code ), 10);
                        if( code > nextCode ) nextCode = code;
                    });
                    nextCode = String( nextCode + 1 );

                    let defaultLength = this.state.subproject_code_default_length || 0;
                    let prefixLength = ( defaultLength - nextCode.length );
                    code = ( prefixLength > 0 ) ? "0".repeat( prefixLength ) + nextCode : nextCode;
                }

       	}
        	else {
  
	            title = tr('edit_subproject');
	            id = data.id;
	            name = data.name;
	            code = getLastCodePart( data.project_code );
	        	}
        }
        else
        {
            // Get next code

            if( this.state.subProjectEdit.parent )
            {
                const siblings = this.state.subProjectEdit.parent.children;
                let nextCode = 0;
                siblings.forEach(( s ) => {
                    let code = parseInt( getLastCodePart( s.project_code ), 10);
                    if( code > nextCode ) nextCode = code;
                });
                nextCode = String( nextCode + 1 );

                let defaultLength = this.state.subproject_code_default_length || 0;
                let prefixLength = ( defaultLength - nextCode.length );
                code = ( prefixLength > 0 ) ? "0".repeat( prefixLength ) + nextCode : nextCode;
            }
        }
        
        

        return <ApModalInput
            show={ Boolean( this.state.subProjectEdit.show ) }
            title={ title }
            value={[
                name,
                code,
            ]}
            label={label}
            required={[
                true,
                true,
            ]}
            onSave={ ( values ) => this.subProjectSubmitted({
                id:  id,
                name: values[0],
                code: values[1],
                copy: this.state.subProjectEdit.copy,
                parent: this.state.subProjectEdit.parent,
            } ) }
            onClose={ () => this.setState({ subProjectEdit: {} }) }
        />
    }
    
    renderProjectCopyToAnotherModal()
    {
    	let projects = [];
	 	let title = tr('copy_subproject');
 	
    	return <ApModal
        show={ Boolean( this.state.subProjectCopy.show ) }
        handleClose={ () => this.setState({ subProjectCopy: {} }) }
        closeFromBg
        className="padding"
        header={<div className="padding">{ tr('copy_subproject_from_another_project') }</div>}
        body={ <div className="padding">
        <ApSelect
            label={ tr('project_to_be_copied') }
            loading={ this.state.loading }
            value={ keyExists( this.state.subProjectCopy, "origname", true, '' ) }
        	optionRenderer="project"
            onChange={ ( values ) => this.addSubProjectFromExisting(values) }
            objKeyId="id"
            apiUrl="report/projects/find"
            apiData={{
                 formatter: 'select',
                 withoutOffers : 'true'
                // status: 'all',
            }}
        />
        <ApInput
        type="text"
        name="newname"
        label={ tr('new_name') }
        value={  keyExists( this.state.subProjectCopy, "name", true, '' )  }
        onChange={ ( e ) => { this.handleChange( "name", e.target.value ) }}
        tooltip={ " " }
        validationState={ " " }
        loading={ this.props.loading }
        disabled={ this.props.loading }
        
    /><ApInput
        type="text"
        name="newcode"
        label={ tr('new_project_code') }
        value={ keyExists( this.state.subProjectCopy, "code", true, '' ) }
        onChange={ ( e ) => { this.handleChange( "code", e.target.value )  } }
        tooltip={ " " }
        validationState={ " " }
        loading={ this.props.loading }
        disabled={ this.props.loading }
    />
        <ApButton onClick={ this.props.handleClose }>
        <SvgIcon icon="times" type="solid" />
        { tr('cancel') }
        </ApButton>
	    <ApButton color="blue" onClick={ this.addSubProjectFromExistingSelected } disabled={ this.state.loading }>
	        <SvgIcon icon="save" type="solid" />
	        { tr('import_project_copy') }
	    </ApButton>
	        
        </div>}
    />
    }
    
    renderTreeTab()
    {
        return <div className="treeContainer" style={{minWidth: 1050}}>
            <div className="splitView">
                <div className="left">
                    { this.renderDetailPicker() }
                    <ApTree
                        tree={ this.state.tree }
                        nodeRenderer={ this.renderTreeNode }
                        onTreeChange={ this.calculateTree }
                        onNodeClick={ this.selectTreeProject }
                    />
                </div>
                <div className="right">
                    <div className="details">
                        { this.renderTree() }
                    </div>
                </div>
            </div>
            </div>
    }

    getTreeComponents()
    {
        let tree = this.state.tree;
        let cNeeds = [];
        if( tree === null ) tree = this.state.tree.slice( 0 );
        //console.log('tree: ', tree);

        tree.forEach((pr) => {
            if (pr.parent_id === null)
            {
                pr.components.forEach((c) => {
                    var cNeed  = new Object();
                    cNeed.id = c.component_id;
                    cNeed.nimi = c.name;
                    cNeed.tarve = parseFloat(c.alloc_count);
                    //cNeed.varastossa =0;
                    //cNeed.tulossa =0;
                    //console.log('cneed: ', cNeed)
                    cNeeds.push(cNeed); //.push(cNeed);     
                    //cNeeds.push(c.component_id, c.name,c.alloc_count, 0, 0 )             
                });
                //console.log('cNeeds 1: ', cNeeds);
            }
            let bOn = false;
            pr.children.forEach((ch) => {
                ch.components.forEach((c) => {
                    cNeeds.forEach((cn) => {
                        if (!bOn && c.component_id===cn.id)
                        {
                            cn.tarve = parseFloat(cn.tarve) + parseFloat(c.alloc_count);
                            bOn = true;
                        }                                         
                    });
                    if (!bOn)
                    {
                        var cNeed  = new Object();
                        cNeed.id = c.component_id;
                        cNeed.nimi = c.name;
                        cNeed.tarve = parseFloat(c.alloc_count);
                        //cNeed.varastossa =0;
                        //cNeed. tulossa =0;
                        cNeeds.push(cNeed);
                    }
                });
            });
        });
        //cNeeds = this.getBalance(cNeeds)

        return cNeeds;
    }

    editCount(i)
    {
        alert( i);
    }

    renderComponentNeeds()
    {
        const projectID = '/storage/purchase/demands/project/'+this.state.selectedProject.id;
        return <div className="padding"><Link to={projectID}>{tr('to_demands')}</Link></div>
        //
        /*this.props.history.push("/storage/purchase/demands/project/"+this.state.selectedProject.id);
        let data = {};
        data.projectId = this.props.projectId;
        data.storageType = 'B';

        const proj = this.state.tree.length ? this.state.tree[0] : null;
        data.project_code = proj.project_code;
        data.storageBalance = this.state.storageBalance;
        
        return <ProjectStorage
        data={data}
        reload={() => this.getStorageBalance()}/>*/
    }

    // ----------------------------------------
    //  Schedule
    // ----------------------------------------

    onScheduleMove(id, newStart )
    {
        // Calculate move offset in days
        const project = findItemById( this.state.tree, id, "children" );
        const offset = moment( newStart ).diff( moment( project.start ), 'days' );

        // Move current project
        let tree = this.state.tree.slice();

        let edits = {
            'start': moment( project.start ).add( offset, 'days' ).format('YYYY-MM-DD'),
            'end': moment(project.end).add(offset, 'days').format('YYYY-MM-DD'),
            'end_work': moment(project.end).add(offset, 'days').format('YYYY-MM-DD'),
        };

        tree = editItemById( tree, id, edits, "children" );

        // Move all child projects
        const moveChilds = ( data, children ) => {
            if( children && children.length > 0 )
            {
                for( let i = 0; i < children.length; i++ )
                {
                    data = editItemById( data, children[i].id, {
                        start: moment( children[i].start ).add( offset, 'days').format('YYYY-MM-DD'),
                        end: moment(children[i].end).add(offset, 'days').format('YYYY-MM-DD'),
                        end_work: moment(children[i].end_work).add(offset, 'days').format('YYYY-MM-DD'),
                    }, "children" );
                    data = moveChilds( data, children[i].children );
                }
            }
            return data;
        };
        tree = moveChilds( tree, project.children );
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    onScheduleResize( id, time, edge )
    {
        let tree = this.state.tree.slice();
        let edits = {};

        if( edge === "left" )
            edits = { start: moment( time ).format('YYYY-MM-DD') };

        else if ( edge === "right" )
            edits = {
                end: moment(time).format('YYYY-MM-DD'),
                end_work: moment(time).format('YYYY-MM-DD'),
            };

        tree = editItemById( tree, id, edits, "children" );
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    openAllProjects()
    {
        let tree = this.state.tree.slice();
        tree = editItemById( tree, "!0", { '__schedule_child_open': true }, "children" );
        this.calculateTree( tree );
    }

    toggleScheduleChilds( id )
    {
        let tree = this.state.tree.slice();
        tree = editItemById( tree, id, { '__schedule_child_open': "!" }, "children" );
        this.calculateTree( tree );
    }

    renderSchedule( project )
    {
        // console.log(this.state.tree)
        if( !this.state.tree.length ) return <div className="apLoader"></div>

        if( project.is_continuous )
            return <div className="padding text-center">
                { tr('continuous_project_info') }
            </div>

        return <div className="padding">
            <button onClick={ this.openAllProjects }>{ tr('open_all') }</button>

            <ApTimeline
                loading={ this.state.loading }
                items={ this.state.tree }
                onGroupClick={ this.toggleScheduleChilds }
                onMove={ this.onScheduleMove }
                onResize={ this.onScheduleResize }
                padding={ 2 }
                childName="children"
                nodeOpenName="__schedule_child_open"
            />
        </div>
    }


    // ----------------------------------------
    //  Render
    // ----------------------------------------

    renderFooter( project )
    {

        let saveButton = null;
        let menu = null;
        let costCenterValidation = this.state.tree[0] != null && this.state.tree[0].cost_center == null && this.state.tree[0].cost_center_is_required
        let costCenterEndNotReached = this.state.costCenterError && project?.cost_center !== null;
        let billerValidation = this.state.tree[0] != null 
            && this.state.tree[0].billing_hourly 
            && Array.isArray(this.state.tree[0].billers) 
            && !this.state.tree[0].billers.length 
            && !this.state.tree[0].parent_id;

        if( !this.state.locked )
        {
            let tooltip = null;
            if( this.state.locked ) tooltip = tr('locked');
            else if( !this.state.treeUnsaved ) tooltip = tr('no_changes');
            else if( project && project.invalid ) tooltip = project.invalid;

            saveButton = <ApTooltip text={ tooltip } position="topright">
                <ApButton
                    color="blue"
                    onClick={ this.saveTree }
                    loading={ this.state.loading }
                    disabled={this.state.loading || !this.state.treeUnsaved || project.invalid || costCenterValidation || billerValidation || costCenterEndNotReached }
                    className={ this.state.treeUnsaved ? "highlight" : "" }
                >
                    <SvgIcon icon="save" type="solid" />
                    { tr('save') }
                </ApButton>
            </ApTooltip>


            if( project )
            {
                let runningAction = {
                    label: <div className={`apStatusBox success`}> { tr('start') } </div>,
                    icon: 'forward',
                    disabled: this.state.loading,
                    action: ( item, closeFunc ) => {
                        this.saveRunning();
                        closeFunc();
                    },
                };

                if( this.state.treeUnsaved )
                {
                    runningAction.label = <div className={`apStatusBox`}> { tr('start') } ( { tr('save_first') } )</div>;
                    runningAction.disabled = true;
                }

                let menuActions = [];

                if( [ 'prepare', 'wait'].includes( project.status_name ) )
                    menuActions.push( runningAction );

                if( menuActions.length )
                {
                    menu = <ApDropdown
                        position="top"
                        actions={ menuActions }
                        button={ <ApButton className="footerMenu" color="white">
                            <SvgIcon icon="ellipsis-h" type="solid" />
                        </ApButton> }
                    />
                }
            }
        }

        let showSelected = false;
        const selDoms = sel.map( s => {
            //console.log(s);
            const count = this.state[ s.sName ].length;
            if( count ) showSelected = true;

            let tooltip = null;

            if( s.type === 'work')  tooltip = tr('selected_works');
            if( s.type === 'item' ) tooltip = tr('selected_components');
            if( s.type === 'other') tooltip = tr('selected_costs');
            if( s.type === 'storage') tooltip = tr('selected_storage');

            return <ApTooltip text={ tooltip } position="topleft">
                <div className={ count ? 'sel' : 'sel empty'  }>
                    <SvgIcon  icon={ getTypeIcon( s.type ) } type="solid" />
                    { count } { tr('pcs') }
                </div>
            </ApTooltip>
        })
        let selectedDom = null;
        if( showSelected )
        {
            selectedDom = <div className="selContainer">
                { selDoms }
            </div>
        }



        return <div className="saveBar">
            <div style={{float:"left", textAlign:"left", color:"red"}}>
                {costCenterValidation &&
                    <div className="validateMsg">
                    <SvgIcon icon="info-circle" type="solid" />
                    {tr("cost_center_is_required")}
                </div>}
                {costCenterEndNotReached &&
                <div>
                    <div className="validateMsg">
                        <SvgIcon icon="info-circle" type="solid" />
                        {tr("cost_center_end_not_reached")}
                    </div>
                </div>}
                {billerValidation &&
                    <div className="validateMsg">
                    <SvgIcon icon="info-circle" type="solid" />
                    {tr("biller_mandatory")}
                </div>}
            </div>
            { selectedDom }
            { saveButton }
            { menu }
        </div>
    }

    renderBillingMargins()
    {
        const project = this.state.selectedProject;
        if( !project || !project.billing_hourly ) return null;

        //console.log( project );

        return (
            <div className="padding">

                <h4>{ tr('customer_billing_shares') }</h4>
                <p>{ tr('customer_billing_shares_info') }</p>

                <ApInputStack gap="0">
                    <ApAddon width="200" noRightBorder>
                        <SvgIcon className="small-inline" icon="money-bill-wave" type="solid" />
                        { tr('expense_compensation') }
                    </ApAddon>
                    <ApInput
                        width="100"
                        type="number"
                        name="billing_margin_expense"
                        id="billing_margin_expense"
                        align="right"
                        //placeholder={ project.billing_margin_expense_parent || this.state.tree[0].billing_margin_expense ? this.state.tree[0].billing_margin_expense : 100 }
                        value={ project.billing_margin_expense }
                        onChange={ ( e ) => { this.changeSettingSetBillingMargin( project, e.target.name, e.target.value ) } }
                        loading={ this.state.loading }
                        disabled={ this.state.loading || this.state.locked }
                    />
                    <ApAddon noLeftBorder width="20">%</ApAddon>
                    <ApAddon custom gapLeft="10">
                        { /*  (!project.billing_margin_expense && project.parent_id && project.billing_margin_expense_parent &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('comes_from_upper_project') }
                                </span>) 
                            || 
                            (!project.billing_margin_expense_parent && this.state.tree[0].id !== project.id &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('comes_from_main_project') } <br/><small>({ tr('save_to_update') })</small>
                                </span>)
                            || */
                            (project.billing_margin_expense !== null && Number(project.billing_margin_expense) === 0 &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('not_billed') }
                                </span>)
                            }
                    </ApAddon>
                </ApInputStack>

                <ApInputStack gap="0">
                    <ApAddon width="200" noRightBorder>
                        <SvgIcon className="small-inline" icon="suitcase" type="solid" />
                        { tr('daily_allowances') }
                    </ApAddon>
                    <ApInput
                        width="100"
                        type="number"
                        name="billing_margin_allowance"
                        id="billing_margin_allowance"
                        align="right"
                        //placeholder={ project.billing_margin_allowance_parent || this.state.tree[0].billing_margin_allowance ? this.state.tree[0].billing_margin_allowance : 100 }
                        value={ project.billing_margin_allowance }
                        onChange={ ( e ) => { this.changeSettingSetBillingMargin( project, e.target.name, e.target.value ) } }
                        loading={ this.state.loading }
                        disabled={ this.state.loading || this.state.locked }
                    />
                    <ApAddon noLeftBorder width="20">%</ApAddon>
                    <ApAddon custom gapLeft="10">
                        { /*  (!project.billing_margin_allowance && project.parent_id && project.billing_margin_allowance_parent &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('comes_from_upper_project') }
                                </span>) 
                            || 
                            (!project.billing_margin_allowance && this.state.tree[0].id !== project.id &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('comes_from_main_project') } <br/><small>({ tr('save_to_update') })</small>
                                </span>)
                            || */
                            (project.billing_margin_allowance !== null && Number(project.billing_margin_allowance) === 0 &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('not_billed') }
                                </span>)
                            }
                    </ApAddon>
                </ApInputStack>

                <ApInputStack gap="0">
                    <ApAddon width="200" noRightBorder>
                        <SvgIcon className="small-inline" icon="car" type="solid" />
                        { tr('travel_expenses') }
                    </ApAddon>
                    <ApInput
                        width="100"
                        type="number"
                        name="billing_margin_travel"
                        id="billing_margin_travel"
                        align="right"
                        //placeholder={ project.billing_margin_travel_parent || this.state.tree[0].billing_margin_travel ? this.state.tree[0].billing_margin_travel : 100 }
                        value={ project.billing_margin_travel }
                        onChange={ ( e ) => { this.changeSettingSetBillingMargin( project, e.target.name, e.target.value ) } }
                        loading={ this.state.loading }
                        disabled={ this.state.loading || this.state.locked || (project.billing_travel_distance && project.billing_travel_hours) }
                    />
                    <ApAddon noLeftBorder width="20">%</ApAddon>
                    <ApAddon custom gapLeft="10">
                        {  /* (!project.billing_margin_travel && project.parent_id && project.billing_margin_travel_parent &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('comes_from_upper_project') }
                                </span>) 
                            || 
                            (!project.billing_margin_travel && this.state.tree[0].id !== project.id &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('comes_from_main_project') } <br/><small>({ tr('save_to_update') })</small>
                                </span>)
                            || */
                            (project.billing_margin_travel !== null && Number(project.billing_margin_travel) === 0 &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('not_billed') }
                                </span>)
                            }
                    </ApAddon>
                </ApInputStack>
                
                <div className='intend'>
                    <p>{ tr('project_billing_shares_info') }</p>
                    <ApInputStack gap="0">
                        <ApAddon width="200" noRightBorder className={project.billing_travel_distance ? null : 'not-in-use'}>
                            { tr('kilometers') }
                        </ApAddon>
                        <ApInput
                            width="100"
                            type="number"
                            name="billing_travel_distance"
                            id="billing_travel_distance"
                            align="right"
                            //placeholder={ project.billing_travel_distance_parent || "" }
                            value={ project.billing_travel_distance || "" }
                            onChange={ ( e ) => { this.changeSettingSetBillingMargin( project, e.target.name, e.target.value ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading || this.state.locked }
                        />
                        <ApAddon noLeftBorder width="60" className={project.billing_travel_distance ? null : 'not-in-use'}>
                            €/km
                        </ApAddon>
                        <ApAddon custom gapLeft="10">
                            { /*!project.billing_travel_distance && project.parent_id && project.billing_travel_distance_parent &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('comes_from_upper_project') }
                                </span> 
                            */}
                        </ApAddon>
                    </ApInputStack>

                    <ApInputStack gap="0">
                        <ApAddon width="200" noRightBorder className={project.billing_travel_hours ? null : 'not-in-use'}>
                            { tr('travel_hours') }
                        </ApAddon>
                        <ApInput
                            width="100"
                            type="number"
                            name="billing_travel_hours"
                            id="billing_travel_hours"
                            align="right"
                            //placeholder={ project.billing_travel_hours_parent || "" }
                            value={ project.billing_travel_hours }
                            onChange={ ( e ) => { this.changeSettingSetBillingMargin( project, e.target.name, e.target.value ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading || this.state.locked }
                        />
                        <ApAddon noLeftBorder width="60" className={project.billing_travel_hours ? null : 'not-in-use'}>
                            €/h
                        </ApAddon>
                        <ApAddon custom gapLeft="10">
                            { /*!project.billing_travel_hours && project.parent_id && project.billing_travel_hours_parent &&
                                <span>
                                    <SvgIcon className="small-inline" icon="info-circle" type="solid" /> 
                                    { tr('comes_from_upper_project') }
                                </span> 
                            */}
                        </ApAddon>
                    </ApInputStack>
                </div>
                {!project.parent_id && <ApButton color="green" onClick={() => { this.addBillinInfosgToAllChildProjects() }}>
                    <SvgIcon icon="plus" type="solid" />
                    {tr('copy_billing_informations_to_all_child_projects')}
                </ApButton>}
            </div>
        )

    }
    addBillinInfosgToAllChildProjects() {
        let tree = [...this.state.tree];
        let temp = {};
        temp.billing_margin_travel = tree[0].billing_margin_travel;
        temp.billing_margin_expense = tree[0].billing_margin_expense;
        temp.billing_margin_allowance = tree[0].billing_margin_allowance;
        temp.billing_travel_distance = tree[0].billing_travel_distance;
        temp.billing_travel_hours = tree[0].billing_travel_hours;

        tree = mapTree(tree, (item, parent) => {
            item.billing_margin_travel = temp.billing_margin_travel;
            item.billing_margin_expense = temp.billing_margin_expense;
            item.billing_margin_allowance = temp.billing_margin_allowance;
            item.billing_travel_distance = temp.billing_travel_distance;
            item.billing_travel_hours = temp.billing_travel_hours;
            return item;
        });
        this.setState({ tree: tree, treeUnsaved: true }, () => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <div>{tr('copied2')}</div>,
            });});

    }

    OpenEditStorageAddress(storageAddress){
        this.setState({ showEditStorageAddress: true });
    }


    renderSelectedStorage()
    {
        const project = this.state.selectedProject;
       
        //console.log(project);
        if (project.storage_location!=null){
        return (
            <div className="padding">
                <div style={{ float: "right" }}>
                    <ApButton onClick={() => this.OpenEditStorageAddress(project.storage_location) }>
                        <SvgIcon icon="edit" type="solid" />
                        {tr("edit_address")}
                    </ApButton>
                </div>
                <h4>{tr('in_use_project')}</h4>
                <div>
                {project.storage_location.type=='P'?tr('project_storage'):tr('storage')}<br/>
                {project.storage_location.name}<br/>
                {project.storage_location.code}
                </div>
                
                
            </div>
        )
        }
        else if (project.storage_location_parent!=null){
           
           /* view.push([<EditStorageComponent
                show={this.state.componentEditShow}
                onSave={(data) => this.handleComponentEdit(data)}
                onClose={ () => { this.setState({ componentEditShow: false })} }
                component_id={this.state.selected_id}
                />], [<CreateStorage
                    show={this.state.storageCreationShow}
                    onSave={(data) => { this.initializeStorage() }}
                    onClose={ () => { this.setState({ storageCreationShow: false })} }
                    project_id={ this.props.data.projectId }
                />]
            );
*/
           return  <div className="padding">
                <h4>{tr('project_no_storage_linked')}</h4>
                <div>{tr('using_storage_from_upper')}<br/>
                {project.storage_location_parent.type=='P'?tr('project_storage'):tr('storage')}<br/>
                {project.storage_location_parent.name}<br/>
                {project.storage_location_parent.code}
                </div>
                <ApButton onClick={ () => this.setState({ storageCreationShow: true } )}>
                    <SvgIcon icon="plus" type="solid" />
                    {tr('add_storage_to_sub')}
                </ApButton>
                <div>{tr('save_project_or_lose_changes')}</div>
                <CreateStorage
                    show={this.state.storageCreationShow}
                    onSave={(data) => { this.getProject(true); this.setState({ storageCreationShow: false }) }}
                    onClose={ () => { this.setState({ storageCreationShow: false })} }
                    project_id={ project.id }
                />
            </div>
        }
        else if (this.state.project_storage_none || (this.state.main_project_logging_disabled && project.parent_id==null)) {
            return <div className="padding">
                {tr('no_storage_in_project')}
            </div>
        }
        else {
            let addButton="";
            if (!this.state.treeUnsaved) {
                addButton = <ApButton onClick={ () => this.setState({ storageCreationShow: true } )}>
                                <SvgIcon icon="plus" type="solid" />
                                {project.parent_id ? tr('add_storage_to_sub') : tr('add_storage_to_project')}
                            </ApButton>
            }
            return <div className="padding">
                {tr('no_storage_in_project_or_no_save')}<br/>
                    {addButton}
                    <CreateStorage
                        show={this.state.storageCreationShow}
                        onSave={(data) => { this.getProject(true); this.setState({ storageCreationShow: false }) }}
                        onClose={ () => { this.setState({ storageCreationShow: false })} }
                        project_id={ project.id }
                    />
                </div>

        }

    }

    renderComponentInfo( component )
    {
        const tooltip = <ComponentTooltip component={ component } />
        const icon = <SvgIcon icon={ getTypeIcon( component.type_name ) } type="solid" />
        return <ApTooltip position="top" text={ tooltip }>{ icon }</ApTooltip>
    }

    renderLocationComponents()
    {
        let components = this.state.selectedProject.storage;
        if (components) {

            const componentRows = components.map(( c, cIndex ) => {

                const balance = c.balance;
                const balanceFree = c.balance_free;
                let balanceFreeDom = null;
    
                if(this.state.selectedProject.storage_location.type=='S'  || this.state.selectedProject.storage_location.type==null)
                {
                    if( balanceFree !== balance )
                    {
                        balanceFreeDom = <small className="free">
                            <ApTooltip text="Saldo tilausten ja ostotilausten aiheuttamien muutosten jälkeen">
                            { balanceFree }
                            </ApTooltip>
                        </small>
                    }
                }
    
                if(this.state.selectedProject.storage_location.type=='S')
                {
    
                return <tr key={ c.id } className={ "component" }>
                    <td className="icon">
                        { this.renderComponentInfo( c ) }
                    </td>
                    <td className="name">
                        {  c.name }<small className="code">{ c.code }</small>
                    </td>
    
                    <td className="fixed balance">
                        { balance }
                        { balanceFreeDom }
                        <div className="unit">{ c.unit }</div>
                    </td>

                    <td className="">
                        <ApDropdown
                            actionId={ 1 }
                            actions={[
                                {
                                    label: tr('show_storage_component'),
                                    icon: "edit",
                                    action: ( item, closeFunc ) => {
                                        this.openComponentEdit(c.component_id);
                                        this.setState({
                                            componentEditShow: true,
                                            componentEditId: c.id,
                                        });
                                        closeFunc();
                                    }
                                },{
                                    label: tr('show_incoming_changes'),
                                    icon: "truck",
                                    action: ( item, closeFunc ) => {
                                        this.setState({
                                            changesShow: true,
                                            changesIndex: cIndex,
                                        });
                                        closeFunc();
                                    }
                                }
                            ]}
                        />
                    </td>
                </tr>
    
                }
                else if(this.state.selectedProject.storage_location.type=='P')
                {
                    //console.log('comp: ', c);
                    //c.order_amount = this.getChanges(c);
                    //c.balance_free = parseFloat(c.order_amount) + parseFloat(c.balance) - parseFloat(c.limit_alarm);
    
                    return <tr key={ c.id } className={ "component" }>
                    <td className="icon">
                        { this.renderComponentInfo( c ) }
                    </td>
                    <td className="name">
                        {  c.name }
                    </td>
    
                    <td align="right" className="initial need">
                        { c.limit_notification } 
                       
                    </td>
    
                    <td align="right" className="need now">
                        { c.limit_alarm }
                    </td>
    
                    <td align="right" className="saldo">
                        { c.balance }
                    </td>
    
                    <td align="right" className="coming">
                        { parseFloat(c.order_amount_project) }
                    </td>
    
                    <td align="right" className="shortage">
                        { c.balance_free }
                    </td>
    
                    <td align="right" className="unit">
                        { c.unit }
                    </td>
    
                    <td className="">
                        <ApDropdown
                            actionId={ 1 }
                            actions={[
                                {
                                    label: tr('show_storage_component'),
                                    icon: "edit",
                                    action: ( item, closeFunc ) => {
                                        this.openComponentEdit(c.component_id);
                                        this.setState({
                                            componentEditShow: true,
                                            componentEditId: c.id,
                                        });
                                        closeFunc();
                                    }
                                },{
                                    label: tr('show_incoming_changes'),
                                    icon: "truck",
                                    action: ( item, closeFunc ) => {
                                        this.setState({
                                            changesShow: true,
                                            changesIndex: cIndex,
                                        });
                                        closeFunc();
                                    }
                                }
                            ]}
                        />
                    </td>
                </tr>
     
                 }
                });
               
                if(this.state.selectedProject.storage_location.type=='S') //  || this.state.selectedLocation.type==null)
                {
                    let table = null;
    
                    if( components && components.length > 0 )
                    {
                        table = <table className="componentTable">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nimi</th>
                                    <th className="fixed">Saldo</th>
                                    <th className="fixed">
                                        <ApTooltip position="topright" text='Kun tavaran varastosaldo laskee tämän rajan alle tavara tulee näkyviin "Ostoslistaan"'>
                                            Huomioraja
                                        </ApTooltip>
                                    </th>
                                    <th className="fixed">
                                        <ApTooltip position="topright" text='Kun tavaran varastosaldo laskee tämän rajan alle tavara tulee näkyviin "Ostoslistaan"'>
                                            Hälytysraja
                                        </ApTooltip>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { componentRows }
                            </tbody>
                        </table>
    
                        return <div className="padding">
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                Tämä lista sisältää ainoastaan suoraan tähän varastoon määritellyt varastonimikkeet. Alempien varastojen varastonimikkeitä ei näytetä.
                            </div>
                            { table }
    
                            <div className="bottomInfo apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                Uusien varastonimikkeiden määrittäminen varastopaikkoihin tapahtuu nimikkeiden hallinnasta.
                            </div>
                        </div>
                    }
                } 
                else if (this.state.selectedProject.storage_location.type=='P')
                {
                    let table = null;
    
                    if( components && components.length > 0 )
                    {
                        table = <table className="componentTable">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{tr('name')}</th>
                                    <th className="fixed">{tr('initial_demand')}</th>
                                    <th className="fixed">{tr('demand_now')}</th>
                                    <th className="fixed">{tr('balance')}</th>
                                    <th className="fixed">{tr('incoming')}</th>
                                    <th className="fixed">{tr('shortage')}</th>
                                    <th className="fixed">{tr('unit')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { componentRows }
                            </tbody>
                        </table>
    
                        return <div className="padding">
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                {tr('project_storage_info')}
                            </div>
                            { table }
    
                        </div>
                    }
                }
        }
    }
    /*
    renderChangesModal()
    {

        let component = this.state.storageBalance[ this.state.changesIndex ];

        //alert('Komponentti:' , component.name);
        //return;

        let comingTable = null;
        let neededTable = null;

        if( component && component.changes.coming.length )
        {
            let changeRows = component.changes.coming.map( change => {
                const tooltip = <PurchaseOrderTooltip po={ change.po } />
                const icon = <SvgIcon icon={ getTypeIcon( 'po' ) } type="solid" />
                console.log('c: ', component );
                return <tr>
                    <td className="type">
                        <ApTooltip position="right" text={ tooltip }>
                            { icon }
                            <span>Ostotilaus</span>
                            <span>{ change.po ? change.po.number : null }</span>
                        </ApTooltip>
                    </td>
                    <td className="count text-right">
                        Saapuu { change.count } { component.unit }
                    </td>
                    <td className="created text-right">
                        { sqlToDateInput( change.po ? change.po.date : null ) }
                    </td>
                    <td className="delivery_date text-right">
                        { sqlToDateInput( change.po ?  change.po.delivery_date : null ) }
                    </td>
                </tr>
            });
            comingTable = <table className="changesTable">
                <thead>
                    <tr>
                        <th>Saapuva tavara</th>
                        <th className="fixed text-right count">Muutos</th>
                        <th className="fixed text-right created">Päiväys</th>
                        <th className="fixed text-right delivery_date">Toimituspäivä</th>
                    </tr>
                </thead>
                <tbody>
                    { changeRows }
                </tbody>
            </table>
        }
    }
    */
    downloadQRcode( id, description = "")
    {
        api({
            method: 'post',
            url: 'timetracking/qrImage',
            responseType: 'blob',
            data:{'type':'work',
                'work_id':id,
                'description':description }
        }).then(( response ) => {

            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            
            link.setAttribute('download', 'project_work_'+id+'.png' );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.setState({ loading: false });

        }).catch( ( error ) => {
            errorPopper(error, 'Tiedoston lataamisessa tapahtui virhe');
            this.setState({ loading: false });
        });

    }

    updateSellPrice(project, updateAll=false, component=false) {
        let tree = this.state.tree.slice(0);
        let update = false;
        
        tree = mapTree(tree, (item) => {
            if (updateAll) {
                if (item.id === project.id) {
                    update = true;
                }

                if (update) {
                    item.components.map(c => {
                        c = this.handlePriceUpdate(c);
                    })
                    item.works.map(c => {
                        c = this.handlePriceUpdate(c);
                    })
                    item.expenses.map(c => {
                        c = this.handlePriceUpdate(c);
                    })
                }
            }
            else if (project.id === item.id) {
                item.components.map(c => {
                    if (!component) {
                        c = this.handlePriceUpdate(c);
                    } else if (typeof component === 'object' && component.id === c.id) {
                        c = this.handlePriceUpdate(c);
                    }
                })
                item.works.map(c => {
                    if (!component) {
                        c = this.handlePriceUpdate(c);
                    } else if (typeof component === 'object' && component.id === c.id) {
                        c = this.handlePriceUpdate(c);
                    }
                })
                item.expenses.map(c => {
                    if (!component) {
                        c = this.handlePriceUpdate(c);
                    } else if (typeof component === 'object' && component.id === c.id) {
                        c = this.handlePriceUpdate(c);
                    }
                })
            }

            return item;
        })

        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    handlePriceUpdate(c) {
        if (!c.component) {
            return c;
        }
        c.alloc_price_single = c.component.price;
        c.billing_price = c.component.price_sell;
        c.alloc_price_all = Number(c.component.price) * c.alloc_count;
        if (c.hour_types) {
            c.hour_types.map(type => {
                type.value = c.billing_price * type.salary_multiplier;
            });
        }
        return c;
    }


    render()
    {

        const project = this.state.tree.length ? this.state.tree[ 0 ] : null;
        const selectedTab = this.getTabId( this.props.tab );

        let content = null;

        if( project )
        {
            let tabs = [];

            if( project.billing_hourly )
            {
                tabs = [
                    <ApTab icon="calendar" label={ tr('scheduling') } disabled key="timetable">
                    </ApTab>,

                    <ApTab icon="users" label={ tr('personnel') }  key="users">
                        { this.props.history.location.pathname.includes('personnel') &&
                            this.renderPersonnel()
                        }
                    </ApTab>,

                    <ApTab icon="shopping-cart" label={ tr('purchase') } key="buy" mountOnSelect>
                    <div className="padding">
                        <ComponentNeedsTabs 
                            history={this.props.history}
                            loading={this.state.loading}
                            tree={this.state.tree}
                            projectID={this.state.tree[0].id || null}
                            projectNeedsUrl={`/storage/purchase/demands/project/${this.state.selectedProject.id}`}
                            selectedProject={this.state.selectedProject}
                        />
                    </div>
                    </ApTab>,

                    <ApTab icon="receipt" label={ tr('sales') } key="sell" mountOnSelect>
                        <ContractBillingPage projectPage project={project} />
                    </ApTab>,
                    
                ];
            }
            else
            {
                tabs = [
                    <ApTab icon="calendar" label={ tr('scheduling') } key="timetable" mountOnSelect>
                        <div className="padding">
                            { this.renderSchedule( project ) }
                        </div>
                    </ApTab>,

                    <ApTab icon="users" label={ tr('personnel') }  key="users" mountOnSelect>
                        { this.props.history.location.pathname.includes('personnel') &&
                            this.renderPersonnel()
                        }
                    </ApTab>,

                    <ApTab icon="shopping-cart" label={ tr('purchase') } key="buy" mountOnSelect>
                        <ComponentNeedsTabs 
                            history={this.props.history} 
                            loading={this.state.loading} 
                            tree={this.state.tree} 
                            projectID={this.state.tree[0].id || null} 
                            projectNeedsUrl={`/storage/purchase/demands/project/${this.state.selectedProject.id}`}
                            selectedProject={this.state.selectedProject}
                        />
                    </ApTab>,
	                    <ApTab icon="diagnoses" label={ tr('manufacturing') } key="product" mountOnSelect>
	                    <div className="padding">
	                        {this.renderProduction()}
	                    </div>
	                </ApTab>,
                    <ApTab icon="receipt" label={ tr('sales') } key="sell">
                        <ApTabs>
                            <ApTab icon='euro-sign' label={tr('payment_posts')}>
                                <div className="padding">
                                    {this.renderPaymentsAndPosts()}
                                </div>
                            </ApTab>
                            <ApTab icon='redo' label={tr('contract_billing')}>
                                <ContractBillingPage projectPage project={project} />
                            </ApTab>
                        </ApTabs>
                    </ApTab>,
                ];
            }

            content = <ApTabs size="large" fullWidth selected={ selectedTab } onChange={ this.onTabChange } >

                <ApTab icon="project-diagram" label={ tr('projects') }>
                    <div className="padding hideOverflow1500">
                        { this.renderTreeTab() }
                    </div>
                </ApTab>

                { tabs }

            </ApTabs>

        }


        return <div id="projectManagement">
            <ApFormPage
                className="componentsListForm"
                unsaved={ this.state.treeUnsaved }
                onSave={ this.saveTree }
                customFooter={ () => this.renderFooter( project ) }
                noPrompt={ nextUrl => {
                    return nextUrl.startsWith( `/projects/${ this.props.projectId }/management` );
                }}
            >
                <Head
                    form={this.props.tab ? `management/${this.props.tab}` : "management"}
                    projectId={ this.props.projectId }
                    project={ project }
                    loading={ this.state.loading }
                    content={ content }
                    onChange={this.infoBarOnChange}
                    chatUserCount={this.state.chatUserCount}
                    customer_mandatory={this.state.customer_mandatory}
                />
            </ApFormPage>
            {this.renderQRcodeSelect()}
            {this.renderEditStorageAddress()}
            { this.renderProjectEditModal() }
            { this.renderProjectCopyToAnotherModal() }

            <TallyModal
                show={ Boolean( this.state.tallyModal.show ) }
                onClose={ () => this.setState({ tallyModal: {} }) }
                entryId={ this.state.tallyModal.id }
                type={ this.state.tallyModal.type }
            />

            <AddMultipleComponents
                show={ Boolean( this.state.addMultiple.show ) }
                onClose={ () => this.setState({ addMultiple: {} }) }
                onSave={ () => {/*console.log('TODO')*/} }
                tree={ this.state.addMultiple.tree }
            />

            <ApConfirm
                show={ Boolean( this.state.removeComponentConfirm.show ) }
                onClose={ () => { this.setState({ removeComponentConfirm: {} } ) } }
                onConfirm={ () => { this.removeComponents() } }
                header={ tr('delete_storage_components_confirm') }
                body={ this.state.removeComponentConfirm.body }
            />

            <ApConfirm
                show={ Boolean( this.state.removeProjectConfirm.show ) }
                onClose={ () => { this.setState({ removeProjectConfirm: {} } ) } }
                onConfirm={ () =>  this.removeSubProject() }
                header={ tr('delete_project_confirm') }
                body={ this.state.removeProjectConfirm.body }
            />

            <ApConfirm
                show={ Boolean( this.state.markProjectDone.show ) }
                onClose={ () => { this.setState({ markProjectDone: {}, markAllInstalled: false } ) } }
                onConfirm={ () => { this.markProjectDone( this.state.markProjectDone.project ) } }
                header={ tr('mark_project_content_completed_confirm') }
                body={ [this.state.markProjectDone.body, this.state.storageTransferWarning, this.renderMarkAllInstalledSwitch()] }
            />

            <ComponentEdit
                show={ Boolean( this.state.componentEdit.show ) }
                id={ this.state.componentEdit.id }
                onClose={ () => { this.setState({ componentEdit: {} }) } }
                onSave={ () => { this.setState({ componentEdit: {} }) } }

                //id={ this.state.componentEditId }
                //show={ this.state.componentEditShow }
                //onClose={() => {this.setState({componentEditShow: false})}}
                //onSave={ this.onComponentSave }
            />

            { 
                //this.renderChangesModal() 
            }

            <NewPurchaseOrder
                show= { this.state.newPoShow }
                onClose={ () => { this.setState({ newPoShow: false }) } }
                onSave={ () => { } }
                data={ this.state.newPoData }
            ></NewPurchaseOrder>

        </div>
    }
}

export default ProjectManagement;
