import React from 'react';
import autoBind from 'react-autobind';
import { ApInput } from 'common/ApInput/ApInput.js';
import { tr } from 'services/Helpers/Helpers.js'
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import './AddressModal.css';

class ReceiverDataSelect extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            suggests: [],
        };

        this.findTimer = null;
        autoBind(this);


        this.validator = new ApValidate(this, {
            name:        { filter: 'required', textAll: tr('name_missing') },
            address:     { filter: 'required', textAll: tr('address_missing') },
            zipcode:     { filter: 'required', textAll: tr('postal_code_missing') },
            city:        { filter: 'required', textAll: tr('city_missing') },
        });

    }

    resetState()
    {
        this.setState({
            name: this.props.name,
            address: this.props.address,
            zipcode: this.props.zipcode,
            city: this.props.city,
            country: this.props.country,
        } );
    }

    close()
    {
        this.props.handleClose({})
    }

    save()
    {
        const type = this.props.type;
        this.props.handleClose({
            [ `${ type }_name` ]: this.state.name,
            [ `${ type }_address` ]: this.state.address,
            [ `${ type }_zipcode` ]: this.state.zipcode,
            [ `${ type }_city` ]: this.state.city,
            [ `${ type }_country` ]: this.state.country,
        });
    }


    handleChange(e)
    {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleReceiverTypeChange( value )
    {
        this.setState( this.nullReceiver() );
        this.setState({ receiver_type: value });
    }

    nullReceiver()
    {
        return {
            receiver_name:    null,
            receiver_address: null,
            receiver_zipcode: null,
            receiver_city:    null,
            receiver_country: null,
        };
    }

    renderFields()
    {
        const getInput = ( field, label, validate = false ) => {
            let validationState = ( validate ) ? this.validator.getState( field ) : null;

            if( validate ) label = label + ' *'

            return <ApInput
                type="text"
                id={ field }
                name={ field }
                label={ label }
                autoComplete="off"
                onChange={ this.handleChange }
                value={ this.state[ field ] }
                validationState={ validationState }
            />
        }

		if (this.props.type=='company') {
		
			return <div>
				{ getInput('name', tr('company'), true ) }
				<div className="company-indent">
					{ getInput('address', tr('address'), true ) }
					<div className="col-container">
						<div className="col-left">
							{ getInput('zipcode', tr('postal_code'), true ) }
						</div>
						<div className="col-right">
							{ getInput('city', tr('post_office'), true ) }
						</div>
					</div>
					<div className="col-container">
						<div className="col-left">
							{ getInput('country', tr('country') ) }
						</div>
					</div>
				</div>
			</div>
		
		} else {
			
			return <div>
            { getInput('name', tr('recipient'), true ) }
				<div className="receiver-indent">
					{ getInput('address', tr('address'), true ) }
					<div className="col-container">
						<div className="col-left">
							{ getInput('zipcode', tr('postal_code'), true ) }
						</div>
						<div className="col-right">
							{ getInput('city', tr('post_office'), true ) }
						</div>
					</div>
					<div className="col-container">
						<div className="col-left">
							{ getInput('country', tr('country') ) }
						</div>
					</div>
				</div>
			</div>
		
		}
    }

    renderOptions()
    {
        if( !this.props.options ) return null;
        if( !this.props.options.length ) return null;

        let actions  = [
            { "divider": `${tr('set_address')} ` }
        ]

        let icons = {
            'general': "globe",
            'mailing': "envelope", 
            'billing': "file-invoice", 
            'shipping': "truck", 
        };

        this.props.options.forEach( a => {
            let label = <div className="addressRow">
                { a.name }<br />
                { a.street }<br />
                { a.postal_code } { a.city }<br />
                { a.country }
            </div>
            actions.push({
                label: label,
                icon: icons[ a.type ],
                action: ( item, closeFunc ) => {
                    this.setState({
                        name: a.name,
                        address: a.street,
                        zipcode: a.postal_code,
                        city: a.city,
                        country: a.country,
                    });
                    closeFunc();
                },
            });
        })

        return <ApDropdown position="right"
            actions={ actions }
            button={ <div className="defaultButton">
                <SvgIcon icon="book" type="solid" /> 
            </div>}
        />
    }

    render()
    {
        let receiverFields = this.renderFields();
        let optionsDom = this.renderOptions();

        return <div className="addressModal">
            <ApModal
                show={ this.props.show }
                handleClose={ this.props.handleClose }
                className="narrow"
                onOpen={ this.resetState }
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('edit_address') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding bodyContainer">
                        <div className="optionsMenu">
                            { optionsDom }
                        </div>
                        <div className="fields">
                            { receiverFields }
                        </div>
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton onClick={ this.close }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton color="green" onClick={ this.save } disabled={ Boolean( this.validator.getStatusAll() ) }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('continue') }
                        </ApButton>
                    </div>
                }
            />
        </div>

    }
}

export default ReceiverDataSelect;
