import React from 'react';
import autoBind from 'react-autobind';

import ApReactTable         from 'common/ApReactTable/ApReactTable.js';
import auth                 from 'services/Authed/Authed.js';
import api                  from 'services/Api/Api.js';
import File                 from 'common/File/File.js';
import ApButton             from 'common/ApButton/ApButton.js';
import ApTooltip            from 'common/ApTooltip/ApTooltip.js';
import SvgIcon              from 'common/SvgIcon/SvgIcon.js';
import { formatMoney
       , priceToProfitPercent
       , removeRoundOff
       , tr }   from 'services/Helpers/Helpers.js'

import { getTypeIcon } from 'modules/Storage/common/StorageHelpers.js';

import ComponentEdit from 'modules/Storage/Components/ComponentEdit/ComponentEdit.js';

import { ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect			from 'common/ApSelect/ApSelect.js';

import './CitList.css';

class CitList extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            customColumns: [],
            columns: [],

            data: [],
            loading: true,
            pages: -1,


            hoverImage: null,
            hoverImageX: 0,
            hoverImageY: 0,
            hoverImageVisible: false,

            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),

            componentEditShow: false,
            componentEditId: null,

            request_time: 0
        }

        this.getTimer = null;
        this.tableState = {};

        autoBind(this);
    }

    componentDidMount()
    {
        this.getColumns();
    }

    componentDidUpdate( prevProps, prevState, snapshot )
    {
        if( JSON.stringify( this.props.instanceOptions ) !== JSON.stringify( prevProps.instanceOptions ) )
            this.getData();
    }

    refresh()
    {
        this.getData();
    }

    openComponetEdit( id )
    {
        this.setState({
            componentEditShow: true,
            componentEditId: id,
        });
    }

    getData( state = null )
    {
        if( !state ) state = this.tableState;
        this.tableState = state;
        window.clearTimeout(this.getTimer);
        this.getTimer = window.setTimeout(() => {

            const instance = this.props.instance;

            this.setState( { loading: true } );

            const include = [
                'identifiers',
                'calculatables',
                'suppliers',
                'locations',
            ];

            let filtered = JSON.parse( JSON.stringify( state.filtered ) );

            this.setState({ supplierFilter: null });
            if( filtered )
            {
                let supplierFilter = filtered.find( s => s.id === 'suppliers' );
                if( supplierFilter ) this.setState({ supplierFilter: supplierFilter.value });

                const sPriceIndex = filtered.findIndex( s => s.id === 'suppliers_price' );
                if( sPriceIndex !== -1 )
                {
                    filtered[ sPriceIndex ].value = {
                        limits: filtered[ sPriceIndex ].value,
                        supplier_id: supplierFilter ? supplierFilter.value.id : null,
                    }
                }
            }
            let data = {
                include: include,
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: filtered,
                instance: instance,
                instanceOptions: this.props.instanceOptions,
            };

            api({
                method: 'post',
                url: '/storage/components/apt',
                data: data,
            }).then((response) => {
                if (response.request_time!==undefined && response.request_time<this.state.request_time) {
                    console.log("old request");
                }
                else {
                    this.setState({
                        data: response.data,
                        pages: response.pages,
                        request_time: response.request_time, 
                        loading: false,
                    })
                }
                

            }).catch((error) => {
                console.error(error);
                window.emitter.emit('popper', {
                    type: 'danger',
                    content: <strong>{ tr('get_error') }</strong>,
                });
                this.setState({
                    data: [],
                    pages: -1,
                    loading: false,
                })
            });
        }, 500);
    }

    getColumnClickFunction()
    {
        const instance = this.props.instance;
        if( instance === 'management' )
        {
            return ( row = null ) => {
                this.openComponentEdit( row.id );
            };
        }
        return null;
    }

    onComponentSave()
    {
        this.getData();
        this.setState({ componentEditShow: false });
    }

    // ---------------------
    // Column data
    // ---------------------
    getColumns()
    {
        api({
            method: 'get',
            url: 'storage/component/related',

        }).then((response) => {

            // console.log('response', response );

            this.setState({
                maxPrice: response.max_price,
                maxPriceSell: response.max_price_sell,
                maxSupplierPrice: response.max_supplier_price,

            });

            const instance = this.props.instance;

            let columns = [{
                Header: '',
                columns: [],
                customizable: true,
                showInitially: true,

            }];

            if( instance !== 'management' )
                columns[0]['columns'].push(this.getColumnSelect());

            if( instance === 'management' )
                columns[0]['columns'].push(this.getColumnStatus( response.statuses ));

            columns[0]['columns'].push(this.getColumnType( response.types ));


            columns[0]['columns'].push({
                onClick: this.getColumnClickFunction(),
                id: 'name',
                Header: tr('component_alt'),
                showInitially: true,
                customizable: true,
                width: 300,
                accessor: 'name',
                customFilter: {
                    type: "text",
                    placeholder: tr('component_enter_name_code'),
                },
                Cell: props => <div className="nameCell">
                    <div className="nameCellName" >{ props.value }</div>
                    <div className="nameCellCode">{ props.original.code }</div>
                </div>

            });

            columns[0]['columns'].push({
                onClick: this.getColumnClickFunction(),
                id: 'code',
                Header: tr('component_code'),
                customizable: true,
                showInitially: false,
                width: 200,
                accessor: 'code',
                customFilter: {
                    type: "text",
                    placeholder: tr('enter_component_code'),
                },
                Cell: props => <div className="codeCell">{ props.value }</div>
            });

            columns[0]['columns'].push(this.getColumnImage());
            columns[0]['columns'].push(this.getColumnPrice());
            columns[0]['columns'].push(this.getColumnPriceSell());
            columns[0]['columns'].push(this.getColumnProfitPercent());

            columns[0]['columns'].push(this.getColumnBalance( response.types ));
            columns[0]['columns'].push(this.getColumnBalanceFree( response.types ));
            // columns[0]['columns'].push(this.getColumnLocations());
            columns[0]['columns'].push(this.getColumnUnit());
            columns[0]['columns'].push(this.getColumnProjectCount());

            columns[0]['columns'].push( this.getColumnTemporary() );

            if( instance === 'po' )
                columns[0]['columns'].push(this.getSupplierPrice());

            columns.push( this.getColumnSuppliers() );
            columns.push( this.getColumnIdentifiers( response.identifiers ));
            columns.push( this.getColumnCalculatables( response.calculatables ));

            this.setState({columns});

        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('get_error') }</strong>,
            });
        });
    }

    getColumnIdentifiers( identifiers = [] )
    {
        const columns = identifiers.map(identifier => {
            return {
                onClick: this.getColumnClickFunction(),
                id: 'identifier.'+identifier.id,
                Header: identifier.name,

                customizable: true,
                customFilter: {
                    type: "text",
                    placeholder: tr('enter_identifier'),
                },
                accessor: ( row ) => {
                    if( !Array.isArray(row.identifiers) ) return '';
                    let found = row.identifiers.find( item => item.id === identifier.id );
                    if( found ) return found.pivot.value;
                },
            };
        });

        return {
            Header: tr('identifiers'),
            columns: columns,
            customizable: true,
        };
    }

    getColumnCalculatables( calculatables = [] )
    {
        const columns = calculatables.map(calculatable => {

            const maxValue = calculatable.max_value;

            return {
                id: 'calculatable.'+calculatable.id,
                Header: <span>
                    { calculatable.name }
                    { Boolean( calculatable.unit) && <small className="calculatableUnit">{ calculatable.unit }</small> }
                </span>,
                    //calculatable.unit ? `${calculatable.name} (${calculatable.unit})` : calculatable.name,
                customizable: true,
                accessor: ( row ) => {
                    if( !Array.isArray( row.calculatables ) ) return '';
                    let found = row.calculatables.find(item => item.id === calculatable.id );
                    if( found ) return found.pivot.value;
                },
                filterable: ( maxValue > 0 ),
                customFilter: {
                    type: "range",
                    min: 0,
                    step: maxValue > 1000000 ? 10000 : maxValue > 100000 ? 1000 : maxValue > 10000 ? 100 : 1,
                    max: maxValue,
                    valueRenderer: ( value ) => { return formatMoney( value[0], 0 ) + " - " + formatMoney( value[1], 0 ) + " €" }
                }
            };
        });

        return {
            Header: tr('meters'),
            columns: columns,
            customizable: true,
        };

    }

    openComponentEdit( id = null )
    {
        this.setState({
            componentEditShow: true,
            componentEditId: id,
        });
    }

    getColumnSelect()
    {
        return {
            id: 'edit',
            width: 80,
            Cell: (props) => {
                return <ApButton
                    size="xtiny"
                    color="white"
                    title={ tr('edit_storage_component') }
                    onClick={( e ) => this.props.onSelect( props.original.id, e ) }
                >{ tr('select') }</ApButton>
            },
            filterable: false,
            sortable: false,
            customizable: false,
            show: true,
        }
    }

    getColumnType( types )
    {
        let filterOptions = types.map( item => {
            return { value: item.id, label: tr(item.text) }
        })
        filterOptions.unshift({ value: '', label: ` - ${tr('all')} - ` });

        return {
            onClick: this.getColumnClickFunction(),
            id: 'type_id',
            Header: tr('component_type'),
            className: "overflowableOnHover",
            width: 50,
            customizable: true,
            accessor: 'type_id',
            showInitially: true,
            customFilter: {
                type: "select",
                options: filterOptions,
            },
            Cell: props => {
                let c = props.original;
                return <ApTooltip position="topright" text={ c.type_text ? tr(c.type_text) : null } block>
                    <SvgIcon icon={ getTypeIcon( c.type_name ) } type="solid" className={ c.temporary_boolean?"temporary":"" }/>
                </ApTooltip>
            },
        };
    }

    getColumnStatus( statuses )
    {
        let filterOptions = statuses.map( item => {
            return { value: item.id, label: tr(item.text) }
        })
        filterOptions.unshift({ value: '', label: ` - ${tr('all')} - ` });

        return {
            onClick: this.getColumnClickFunction(),
            id: 'status_id',
            Header: tr('status'),
            customizable: true,
            showInitially: true,
            accessor: 'status_id',
            width: 140,
            customFilter: {
                type: "select",
                options: filterOptions,
            },
            Cell: props => {

                let sColor = {
                    plan: 'warning',
                    active: 'success',
                    frozen: 'info',
                    removed: '',
                    temporary: '',
                }
                let item = statuses.find( item => item.id === props.original.status_id );

                return <div className={`apStatusBox ${ sColor[ item.name ] }`}> { tr(item.text) }</div>
            },
        };
    }

    getColumnPrice()
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'price',
            Header: tr('value'),
            customizable: true,
            width: 100,
            accessor: 'price',
            // filterable: true,
            showInitially: true,
            Cell: props => {
                if( !props.value ) return null;
                return <div className="text-right"> { formatMoney( props.value ) } { this.state.currencySign }</div>
            },
            customFilter: {
                type: "range",
                min: 0,
                step: this.state.maxPrice > 1000000 ? 10000 : this.state.maxPrice > 100000 ? 1000 : this.state.maxPrice > 10000 ? 100 : 1,
                max: this.state.maxPrice,
                valueRenderer: ( value ) => { return formatMoney( value[0], 0 ) + " - " + formatMoney( value[1], 0 ) + " €" }
            }

        };
    }

    getColumnPriceSell()
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'price_sell',
            Header: tr('selling_price'),
            customizable: true,
            width: 100,
            accessor: 'price_sell',
            // filterable: true,
            showInitially: ( this.props.instance === 'management' || this.props.instance === 'order' ),
            Cell: props => {
                if( !props.value ) return null;
                return <div className="text-right"> { formatMoney( props.value ) } { this.state.currencySign }</div>
            },
            customFilter: {
                type: "range",
                min: 0,
                step: this.state.maxPriceSell > 1000000 ? 10000 : this.state.maxPriceSell > 100000 ? 1000 : this.state.maxPriceSell > 10000 ? 100 : 1,
                max: this.state.maxPriceSell,
                valueRenderer: ( value ) => { return formatMoney( value[0], 0 ) + " - " + formatMoney( value[1], 0 ) + " €" }
            }
        };
    }

    getColumnProfitPercent()
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'profit_percent',
            Header: tr('gross_profit_percent'),
            customizable: true,
            width: 50,
            filterable: false,
            showInitially: false,
            Cell: props => {
                let value = priceToProfitPercent( props.original.price, props.original.price_sell );
                if( !value || (!props.original.price_sell || props.original.price_sell == 0) ) return null;
                return <div className="text-right"> { formatMoney( value, 0 ) } %</div>
            }
        };
    }

    getColumn()
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'price_sell',
            Header: tr('selling_price'),
            customizable: true,
            width: 100,
            accessor: 'price_sell',
            filterable: false,
            showInitially: true,
            Cell: props => {
                if( !props.value ) return null;
                return <div className="text-right"> { formatMoney( props.value ) } { this.state.currencySign }</div>
            }
        };
    }

    getSupplierPrice()
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'supplier_price',
            Header: tr('supplier_price'),
            customizable: true,
            width: 100,
            filterable: false,
            showInitially: true,
            Cell: props => {

                let found = props.original.suppliers.find( s =>  s.id === this.props.instanceOptions.supplierId )
                if( found )
                    return <div className="text-right"> { formatMoney( found.pivot.purchase_price ) } { this.state.currencySign }</div>
                return null;
            }
        };
    }

    getColumnImage()
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'image_id',
            Header: tr('picture'),
            customizable: true,
            showInitially: ( this.props.instance === 'management'),
            width: 50,
            accessor: 'image_id',
            customFilter: {
                type: "select",
                options: [
                   { value: '', label: ` - ${tr('all')} - ` },
                   { value: '*', label: tr('exists') },
                   { value: '!', label: tr('missing') },
                ],
            },
            Cell: props => {
                if( props.original.image_id )
                    return <File type="img"
                        fileId={ props.original.image_id }
                        className="img-responsive imageCellImage"
                        onMouseEnter={ ( e ) => this.imageMouseEnter( e, props.original.image_id ) }
                        onMouseLeave={ this.imageMouseLeave }
                    />
                return null;
            }
        };
    }
    
    getColumnTemporary()
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'temporary',
            Header: 'Varaston ulkopuolinen',
            showInitially: false,
            customizable: true,
            width: 150,
            accessor: 'temporary',
            customFilter: {
                type: "select",
                options: [
                    { value: '', label: ' Kaikki ' },
                    { value: false, label: 'Varastokomponentit' },
                    { value: true, label: 'Vain ulkopuoliset' },
                ],
            },
        };
    }   

    getColumnUnit()
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'unit',
            Header: tr('unit'),
            customizable: true,
            // filterable: true,
            sortable: false,
            customFilter: {
                type: "text",
                placeholder: tr('enter_unit'),
            },
            accessor: 'unit',
            width: 50,
        };
    }

    getColumnProjectCount()
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'project_count',
            Header: tr('project_count'),
            customizable: true,
            filterable: false,
            sortable: false,
            showInitially: false,
            accessor: 'project_count',
            width: 50,
        };
    }

    getColumnSuppliers()
    {
        const supplierCell = ( c,  show = 'name' ) => {
            if( !Array.isArray( c.suppliers ) ) return null;
            if( c.suppliers.length === 0 ) return null;

            let item = null;

            if( this.state.supplierFilter )
                item = c.suppliers.find( s => s.id === this.state.supplierFilter.id );
            else
                item = c.suppliers.find( s => s.pivot.primary );

            if( !item ) return null;

            if( show === 'name' )
            {
                const rows = c.suppliers.map( s => {
                    return <tr key={ s.id } className={ s.pivot.primary ? 'supplierPrimary' : 'notPrimary' }>
                        <td className="listTooltipText">
                            { s.name }
                        </td>
                        <td className="listTooltipValue">
                            { formatMoney(s.pivot.orig_purchase_price*((100-s.pivot.discount_percent)/100) ) } { this.state.currencySign }
                        </td>
                    </tr>
                });
                const tooltip = <table>
                    <tbody>
                        { rows }
                    </tbody>
                </table>

                let supplierCountDom = null;
                if( c.suppliers.length > 1 )
                    supplierCountDom = <small>{ c.suppliers.length } { tr('different_suppliers_count') }</small>


                return <ApTooltip position="topright" text={ tooltip } block>
                    <div className="supplierNameContainer">
                        <div className="supplierName" >{ item.name }</div>
                        <div className="supplierCount">{ supplierCountDom }</div>
                    </div>
                </ApTooltip>
            }

            if( show === 'price' )
                return <div className="text-right">
                    <strong> { formatMoney( (item.pivot.orig_purchase_price*((100-item.pivot.discount_percent)/100) )) } { this.state.currencySign }</strong>
                </div>

        };

        const max = this.state.maxSupplierPrice;

        const columns = [{
            id: 'suppliers',
            Header: tr('supplier'),
            headerClassName: "overflowable",
            className: "overflowableOnHover",
            customizable: true,
            showInitially: ( this.props.instance === 'management' ),
            // filterable: true,
            sortable: true,
            customFilter: {
                type: "apSelect",
                placeholder: tr('select_supplier'),
                optionRenderer: 'storage_supplier',
                apiUrl: 'storage/components/filter/supplier/find',
                objKeyValue: 'name',
            },

            Cell: props => supplierCell( props.original ),
        }, {
            id: 'suppliers_price',
            Header: tr('purchase_price'),
            customizable: true,
            showInitially: ( this.props.instance === 'management' ),
            // filterable: true,
            sortable: true,
            width: 100,
            customFilter: {
                type: "range",
                min: 0,
                step: max > 1000000 ? 10000 : max > 100000 ? 1000 : max > 10000 ? 100 : 1,
                max: this.state.maxSupplierPrice,
                valueRenderer: ( value ) => { return formatMoney( value[0], 0 ) + " - " + formatMoney( value[1], 0 ) + " €" },
            },
            Cell: props => supplierCell( props.original, 'price' ),
        }];


        return {
            id: 'supplier_container',
            onClick: this.getColumnClickFunction(),
            Header: tr('suppliers'),
            customizable: true,
            columns: columns,
        };
    }

    locationBalanceCell( types, props, useFree = false )
    {
            // Only show balance for items
            let type = types.find( t => t.id === props.original.type_id );
            if( type && type.name !== 'item' ) return null;

            const locations = props.original.locations;

            if( !locations || locations.length === 0 )
                return null;

            let unit = ( props.original.unit ) ? props.original.unit : null;

            let totalBalance = 0;
            const rows = locations.map( l => {

                if( useFree )
                    totalBalance += parseFloat( l.pivot.balance_available );
                else
                    totalBalance += parseFloat( l.pivot.balance );

                return <tr key={ l.id }>
                    <td className="listTooltipText">
                        { l.code }
                    </td>
                    <td className="listTooltipValue">
                        { useFree ? l.pivot.balance_available : l.pivot.balance } { unit }
                    </td>
                </tr>
            });
            const tooltip = <table>
                <tbody>
                    { rows }
                </tbody>
            </table>

            totalBalance = removeRoundOff( totalBalance );

            let storageCountDom = null;
            if( locations.length > 1 )
                storageCountDom = <small>{ locations.length } { tr('different_storages_count') }</small>

            return <ApTooltip position="topright" text={ tooltip } block>
                <div className="locationContainer text-right">
                    <div className="locationBalance">{ totalBalance } <small>{ unit }</small></div>
                    <div className="locationCount">{ storageCountDom }</div>
                </div>
            </ApTooltip>

    }

    getColumnBalance( types )
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'balance',
            className: "overflowableOnHover",
            Header: tr('storage_balance'),
            customizable: true,
            sortable: false,
            filterable: false,
            showInitially: true,
            width: 150,
            Cell: props => this.locationBalanceCell( types, props ),
        };
    }

    getColumnBalanceFree( types )
    {
        return {
            onClick: this.getColumnClickFunction(),
            id: 'balance_available',
            className: "overflowableOnHover",
            Header: `${tr('storage_balance')} (${tr('available')})`, 
            customizable: true,
            sortable: false,
            filterable: false,
            Cell: props => this.locationBalanceCell( types, props, true ),
        };
    }

    // ---------------------
    // Render
    // ---------------------

    imageMouseEnter(e, fileId)
    {
        this.setState({
            hoverImageFile: <File type="img" fileId={fileId} style={{maxWidth: '30vw', maxHeight: '30vh'}} />,
            hoverImageX: e.clientX,
            hoverImageY: e.clientY,
            hoverImageVisible: true,
        });
    }

    imageMouseLeave(e)
    {
        this.setState({
            hoverImage: null,
            hoverImageX: 0,
            hoverImageY: 0,
            hoverImageVisible: false,
        });
    }

    render()
    {
        if( !this.state.columns.length )
            return null;

        return <div className="componentInstanceTable">
            
            <ApInputStack gap="0">
		                	<ApAddon noRightBorder width="50px" labelFor="search">
		                		<SvgIcon icon="search" type="solid" />
		                	</ApAddon>
			                <ApSelect
                                value=""
                                optionRenderer={ (item) => {
                                	
                                		return (
                                			<div className="searchOption components">
	                                			<SvgIcon className="typeIcon" icon="puzzle-piece" type="solid" />
	                                			<strong>{ item.name }</strong><br />
	                                			<small>ID: { item.code }</small>
	                                		</div>
                                		);
                                }}
                                onChange={ ( item ) => { this.openComponentEdit( item.id ) } }
                                objKeyId="id"
                                clearable
                                apiUrl="search/components"
                            />
		                </ApInputStack>

            <ApReactTable
                rememberId={ this.props.rememberId ? this.props.rememberId : null }

                columns={ this.state.columns }
                data={ this.state.data }
                loading={ this.state.loading }

                manual
                onFetchData={ this.getData }
                pages={ this.state.pages }

                filterable

                minRows={ 4 }
                noDataText={ tr('no_components_were_selected') }
                showPagination={ false }

                defaultSorted={[{id: 'name', desc: false}]}
                defaultFiltered={ this.props.initialFilters }

                forceCustomColumns={ true }
                customColumns={ this.state.customColumns }
                onCustomColumnChange={ ( customColumns ) => { this.setState({customColumns: customColumns }) }}

                showFiltersInitially={ true }

                rowActions={ ( row, index ) =>
                    <div className="apSimpleButton editButton" onClick={ () => this.openComponentEdit( row.id ) }><SvgIcon icon="edit" type="solid" /></div>
                }


            />

            {this.state.hoverImageVisible && <div className="img-thumbnail" style={{
                position: 'fixed',
                top: this.state.hoverImageY + 20,
                left: this.state.hoverImageX + 20,
                zIndex: 3,
            }}>{this.state.hoverImageFile}</div>}

            <ComponentEdit
                id={ this.state.componentEditId }
                show={ this.state.componentEditShow }
                onClose={ () => { this.setState({ componentEditShow: false }) } }
                onSave={ this.onComponentSave }
            />

        </div>
    }
}

export default CitList;

