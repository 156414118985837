const config = {
    version: "2.0.1",
    dev: false,
    echo: false,
    echoPort: '6001',
    echoHost: 'https://localhost',
    echoName: 'local',
    echoKey:'EASY_PRO',
    api: {
        // Url to api
        url: 'https://api.demo.easy-pro.fi',

        // Url to Extranet
        extraUrl: 'https://extranet.demo.easy-pro.fi',

        // Minutes remaining when token will be refreshed
        refreshtime: 15,

        // How many seconds user can be idle before automatically logged out
        idleAutoLogoutTime: 900, // 15 min

        // How many seconds is warning popup shown before actually log out user
        // Should be smaller than idleAutoLogoutTime!
        idleWarningTime: 30,

        // Test users that are displayed on login dialog
        // FOR LOVE OF GOD DO NOT ADD THESE TO ANYTHING PUBLIC
        users: [
        ],
    }
};

export default config
