import React from 'react';
import autoBind from 'react-autobind';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import api from 'services/Api/Api.js';
import { errorPopper, tr, keyExists } from 'services/Helpers/Helpers.js'
import {
    ApInput
} from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import './MassExport.css';

class MassExport extends React.Component {
    constructor(props)
    {
        super(props);

        let directs = [
            { checked: true, item: 'name', label: tr('name') },
            { checked: true, item: 'is_active', label: tr('active') },
            { checked: true, item: 'taxnumber', label: tr('business_id') },
            { checked: true, item: 'responsible_person_first_name', label: tr('responsible_person_first_name') },
            { checked: true, item: 'responsible_person_last_name', label: tr('responsible_person_last_name') },
            { checked: true, item: 'parent_company', label: tr('parent_company') },
            { checked: true, item: 'extranet_active', label: tr('extranet_active') },
            { checked: true, item: 'is_customer', label: tr('extranet_customer_active') },
            { checked: true, item: 'is_supplier', label: tr('extranet_supplier_active') },

            { checked: true, item: 'phone', label: tr('phone') },

            { checked: true, item: 'email', label: tr('email') },
            { checked: true, item: 'website', label: tr('website') },
            { checked: false, item: 'no_mailing', label: tr('no_customer_mailing') },
            { checked: false, item: 'groups', label: tr('groups') },

        ];

        let defaults = [

            { checked: false, item: 'default_payment_condition', label: tr('default_payment_term') },
            { checked: false, item: 'default_delivery_term', label: tr('default_delivery_term') },
            { checked: false, item: 'default_delivery_method', label: tr('default_delivery_method') },
            { checked: false, item: 'default_billing_method', label: tr('default_billing_method') },
            { checked: false, item: 'default_tax', label: tr('default_vat') },
            { checked: false, item: 'default_tax_code', label: tr('default_vat_code') },
        ];
        let eInvoice = [

            { checked: false, item: 'e_invoice_ovt', label: tr('edi_id') },
            { checked: false, item: 'e_invoice_address', label: tr('e_invoice_address') },
            { checked: false, item: 'e_invoice_operator', label: tr('e_invoice_operator_id') },
            
        ];
        let suppleriAddresses = [
            
            { checked: false, item: 'street', label: tr('address') },
            { checked: false, item: 'city', label: tr('city') },
            { checked: false, item: 'postal_code', label: tr('postal_code') },
            { checked: false, item: 'country', label: tr('country') },
        ];
        let shippingAddresses = [
            
            { checked: false, item: 'street', label: tr('address') },
            { checked: false, item: 'city', label: tr('city') },
            { checked: false, item: 'postal_code', label: tr('postal_code') },
            { checked: false, item: 'country', label: tr('country') },
        ];
        let billingAddresses = [
            { checked: false, item: 'street', label: tr('address') },
            { checked: false, item: 'city', label: tr('city') },
            { checked: false, item: 'postal_code', label: tr('postal_code') },
            { checked: false, item: 'country', label: tr('country') },
        ];
        let contact = [
            { checked: false, item: 'contact_name', label: tr('first_name') },
            { checked: false, item: 'contact_last_name', label: tr('last_name') },
            { checked: false, item: 'contact_email', label: tr('email') },
            { checked: false, item: 'contact_phone', label: tr('phone') },
            { checked: false, item: 'contact_phone2', label: tr('backup_phone') },
            { checked: false, item: 'contact_title', label: tr('title') },
            { checked: false, item: 'contact_groups', label: tr('contact_groups') },
            { checked: false, item: 'contact_no_mailing', label: tr('no_customer_mailing') },

        ];
        let costCenter = [
            { checked: false, item: 'name', label: tr('name') },
            { checked: false, item: 'code', label: tr('code') },
            { checked: false, item: 'group_name', label: tr('group_name') },
            { checked: false, item: 'group_description', label: tr('group_description') },
        ];

        // if filter has values parameter then checked not counted

        let filters = [
            // { checked: false, value: 'no_mailing', label: tr('no_customer_mailing') },
            // { checked: false, value: 'mailing', label: tr('customer_mailing') },
            {
                value: 'no_mailing', label: tr('customer') + ": "+tr('customer_mailing'),
                values: [
                    { id: 1, value: true, label: tr('no_customer_mailing') },
                    { id: 2, value: false, label: tr('customer_mailing') },
                ],
                selected: [],
            },
            {
                value: 'contacts.no_mailing', label: tr('contact') + ": " + tr('customer_mailing'),
                values: [
                    { id: 1, value: true, label: tr('no_customer_mailing') },
                    { id: 2, value: false, label: tr('customer_mailing') },
                ],
                selected: [],
            },
            {
                value: 'is_active', label: tr('active'),
                values: [
                    {id:1, value: true, label: tr('active') },
                    { id: 2, value: false, label: tr('not_active') },
                ],
                selected:[],
            },
            //todo values going to back
            {
                value: 'language_id', label: tr('language'),
                values: [
                    { id: 1, value: 1, label: tr('finnish') },
                    { id: 2, value: 2, label: tr('english') },
                    { id: 3, value: 3, label: tr('swedish') },
                ],
                selected: [],
            },
            {
                value: 'customer_groups', label: tr('customer_groups'),
                values: [
                    
                ],
                selected: [],
            },
            {
                value: 'supplier_groups', label: tr('supplier_groups'),
                values: [
                    
                ],
                selected: [],
            },
            {
                value: 'customer_contact_groups', label: tr('customer')+": "+tr('contact_groups'),
                values: [
                    
                ],
                selected: [],
            },
            {
                value: 'suplier_contact_groups', label: tr('supplier') + ": " + tr('contact_groups'),
                values: [
                    
                ],
                selected: [],
            },
        ];
        

        this.state = {
            loading: false,
            directs: directs,
            defaults: defaults,
            eInvoice: eInvoice,
            suppleriAddresses: suppleriAddresses,
            shippingAddresses: shippingAddresses,
            billingAddresses: billingAddresses,
            contact: contact,
            costCenter: costCenter,

            filters: filters,

            calculatables: [],
            identifiers: [],
            properties: [],
            counts: {}
        }
        autoBind(this);
    }

    componentDidMount()
    {
        this.getRelated();
    }

    getRelated()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'crm/company/masslist/export',
            errorPopper: tr('get_error')
        }).then((response) => {
            // console.log('response', response );

            const formatOptions = (item, valuesKey, index) => {
                return { id: index, value: item.id, label: item[valuesKey] };
            };

            let filters = this.state.filters;
            let filter = filters.find(item => item.value == 'customer_groups');

            for (let customer_group in response.customer_groups) {

                filter.values.push(formatOptions(response.customer_groups[customer_group], 'name', customer_group));

            }
            // console.log({ filter2: filter });
            filter = filters.find(item => item.value == 'supplier_groups');
            for (let supplier_group in response.supplier_groups) {

                filter.values.push(formatOptions(response.supplier_groups[supplier_group], 'name', supplier_group));
            }

            filter = filters.find(item => item.value == 'customer_contact_groups');
            for (let customer_contact_group in response.customer_contact_groups) {

                filter.values.push(formatOptions(response.customer_contact_groups[customer_contact_group], 'name', customer_contact_group));
            }

            filter = filters.find(item => item.value == 'suplier_contact_groups');
            for (let suplier_contact_group in response.suplier_contact_groups) {

                filter.values.push(formatOptions(response.suplier_contact_groups[suplier_contact_group], 'name', suplier_contact_group));
            }

            // console.log({filters});
            this.setState({
                loading: false,
                filters: filters,
                counts: response.counts,
            });
        }).catch((error) => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getCheckedCols()
    {
        let count = 0;
        const filter = ( field ) => {
            let tmp = [];
            this.state[ field ].forEach( ( r ) => {
                if( r.checked ) {

                    let multiplier = 1;
                    if ( field in this.state.counts )
                        multiplier = this.state.counts[ field ];

                    count += multiplier;

                    tmp.push( r.item );
                }
            });
            return tmp;
        }
       
        let filters = [];
        let skip = [];
        for (let filt of this.state.filters) {
            
            if (filt.selected?.length > 0) {
                if (skip.includes(filt.value)){
                    continue;
                }

                let values = filt.selected.map(row => row.value);
                let columnName = filt.value;
                
                //we want groups to same group
                if (filt.value === "customer_groups") {
                    columnName = "groups";
                    let findGroup = this.state.filters.find(filter => filter.value == "supplier_groups" && filter?.selected?.length > 0);
                    if (findGroup) {
                        values = [...values, ...findGroup.selected.map(row => row.value)]
                        skip.push('supplier_groups');
                    }
                }
                if (filt.value === "supplier_groups") {
                    columnName = "groups";
                    let findGroup = this.state.filters.find(filter => filter.value == "customer_groups" && filter?.selected?.length > 0);
                    if (findGroup) {
                        values = [...values, ...findGroup.selected.map(row => row.value)]
                        skip.push('customer_groups');
                    }
                }

                //we want groups to same group
                if (filt.value === "customer_contact_groups") {
                    columnName = "contact_groups";
                    let findGroup = this.state.filters.find(filter => filter.value == "suplier_contact_groups" && filter?.selected?.length > 0);
                    if (findGroup) {
                        values = [...values, ...findGroup.selected.map(row => row.value)]
                        skip.push('suplier_contact_groups');
                    }
                }
                if (filt.value === "suplier_contact_groups") {
                    columnName = "contact_groups";
                    let findGroup = this.state.filters.find(filter => filter.value == "customer_contact_groups" && filter?.selected?.length > 0);
                    if (findGroup) {
                        values = [...values, ...findGroup.selected.map(row => row.value)]
                        skip.push('customer_contact_groups');
                    }
                }

                filters.push({ column: columnName, values: values });

            }
            else {
                if (filt.checked && !filt.values){
                    filters.push({ column: filt.value, values: [filt.checked] });
                    count++;
                }
            }
        }

        let data = {
            direct: filter('directs'),
            defaults: filter('defaults'),
            eInvoice: filter('eInvoice'),
            suppleriAddresses: filter('suppleriAddresses'),
            shippingAddresses: filter('shippingAddresses'),
            billingAddresses: filter('billingAddresses'),
            contact: filter('contact'),
            costCenter: filter('costCenter'),
            filters:filters,
            
        };
        return {
            cols: data,
            colCount: count,
        }
    }


    
    export()
    {
        const data = this.getCheckedCols().cols;

        //console.log( 'exporting', data );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'crm/company/masslist/export',
            responseType: 'blob',
            data: data,
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', tr("customers")+'.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('get_error'));
            this.setState({ loading: false });
        });

    }

    toggle( type, i )
    {
        let data = this.state[ type ].slice();
        data[ i ].checked = !data[ i ].checked;
        this.setState({ type: data });
    }

    toggleGroup( type, checked )
    {
        let data = this.state[ type ].slice();
        data = data.map( i => {
            i.checked = checked;
            return i;
        });
        this.setState({ type: data });
    }

    renderSection( type, title )
    {
        let count = null;
        if ( type in this.state.counts )
        {
            count = this.state.counts[ type ];
            if( !count ) return null;
        }

        const rowDoms = this.state[ type ].map( ( r, i ) => this.renderRow( type , r, i ));
        if( !rowDoms.length ) return null;

        return <React.Fragment>
            { this.renderHeader( type, title, count )  }
            <tbody>{ rowDoms }</tbody>
        </React.Fragment>
    }

    renderHeader( type, title, count )
    {
        let countDom = null;
        if( count ) countDom = <span>({ count })</span>

        let noRead = null;
        if( [ 'costCenter', ].includes( type ) )
            noRead = <ApTooltip text={ tr('import_values_cannot_be_updated') } position="topleft">
                <span className="headerSmall">({tr('import_only_by_code') })</span>
            </ApTooltip>

        let checked = this.state[ type ].some( i => i.checked );
        return <thead key={ type }>
            <tr className="headerrow">
                <th className="thcheck" onClick={ () => { this.toggleGroup( type, !checked ) } }>
                    <input
                        type="checkbox"
                        checked={ checked }
                        readOnly
                    />
                </th>
                <th colSpan="2"> { title } { countDom } { noRead } </th>
            </tr>
        </thead>
    }
    changeApSelect(row,changingValue) {
        
        let filters = this.state.filters;
        for (let filter of filters) {
            if (filter.value === row.value) {
                filter.selected = changingValue;
            }
        }
        

        this.setState({
            filters: filters
        });
        
    }

    renderRow(type, r, i )
    {
        if (r.values) {
            // console.log(type);
            // console.log(r);
            // console.log(i);
            return <tr className="fieldrow" key={r.item}>
                <td className="tdindent">
                </td>
                <td className="tdcheck">
                </td>
                <td className="tdname">
                    <ApSelect
                        options={r.values}
                        label={r.label}
                        value={r.selected}
                        valueRenderer="label"
                        optionRenderer="label"
                        onChange={(value) => { this.changeApSelect(r,value) }}
                        objKeyId="id"
                        objKeySearchable="label"
                        multiselect
                        clearable

                    />
                    {/* <ApInput
                        type="select"
                        id={r.label+"_id"}
                        name={r.label + "_id"}
                        // label={r.label}
                        options={r.values}
                        value={1}
                        onChange={(e) => {
                            this.props.onChange("contract_detail.collective_agreement_id", e.target.value)
                        }}
                        loading={this.props.loading}
                        disabled={this.props.loading}
                        // tooltip={this.props.validator.getText("data.contract_detail.collective_agreement_id")}
                        // validationState={this.props.validator.getState("data.contract_detail.collective_agreement_id")}

                    /> */}
                    
                </td>
                {/* <td className="tdname">
                    {r.label}
                </td>
                <td className="tdinfo">
                    {r.info}
                </td> */}
            </tr>
        }
        
        return <tr className="fieldrow" key={r.item}>
            <td className="tdindent">
            </td>
            <td className="tdcheck" onClick={ () => { this.toggle( type, i ) } }>
                <input
                    type="checkbox"
                    checked={ r.checked }
                    readOnly
                />
            </td>
            <td className="tdname">
                { r.label }
            </td>
            <td className="tdinfo">
                { r.info }
            </td>
        </tr>
    }

    renderFooter()
    {
        const colCount = this.getCheckedCols().colCount;

        const rowCount = this.state.counts.rows || 0;

        let buttonError = null;
        if( colCount === 0 ) buttonError = tr('no_columns_selected');
        if( rowCount === 0 ) buttonError = tr('no_storage_rows');

        return <div className="footer padding">
            <div className="footerCounts">
                <table>
                    <tbody>
                        <tr>
                            <td>{ tr('rows_count') }:</td>
                            <td>{ rowCount }</td>
                        </tr><tr>
                            <td>{ tr('columns') }:</td>
                            <td>{ colCount }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ApTooltip text={ buttonError }>
                <ApButton
                    className="save"
                    color="blue"
                    onClick={ this.export }
                    disabled={ this.state.loading || buttonError }
                    loading={ this.state.loading }
                >
                    <SvgIcon icon="file-excel" type="solid" />
                    { tr('generate_xlsx') }
                </ApButton>
            </ApTooltip>
        </div>
    }

    render()
    {

        return <div id="storageMassExport">
            <ApFormPage 
                className=""
                unsaved={ false }
                onSave={ () => {} }
                customFooter={ () => this.renderFooter() }
            >
                <div className="topInfo">
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('select_data_to_excel') }
                    </div>
                </div>
                <div className="clear">
                    <div className="apColumn w50">
                        <div className="padding">
                            <table className="fieldsTable">
                                { this.renderSection('directs', tr('customers') )  }
                                
                                {this.renderSection('defaults', tr('billing'))}
                                {this.renderSection('eInvoice', tr('online_billing_info'))}
                            </table>
                        </div>
                    </div>
                    <div className="apColumn w50">
                        <div className="padding">
                            <table className="fieldsTable">
                                {this.renderSection('filters', tr('filters'))}
                                {this.renderSection('contact', tr('contact'))}
                                {this.renderSection('suppleriAddresses', tr('supplier_address'))}
                                {this.renderSection('shippingAddresses', tr('delivery_address'))}
                                {this.renderSection('billingAddresses', tr('billing_address'))}
                                {this.renderSection('costCenter', tr('cost_center'))}
                                
                            </table>
                        </div>
                    </div>
                </div>
            </ApFormPage>
        </div>
    }
}

export default MassExport;
