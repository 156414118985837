import React, {Component} from 'react';
import styles from './Workgroups.module.css';

import ApButton from 'common/ApButton/ApButton';
import ApReactTable from 'common/ApReactTable/ApReactTable';
import { sortByField, tr } from 'services/Helpers/Helpers';
import WorkgroupModal from './WorkgroupModal';
import api from 'services/Api/Api';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import ApDropdown from 'common/ApDropdown/ApDropdown';
import ApModal from 'common/ApModal/ApModal';

class Workgroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            workgroups: [],
            groupModalOpen: false,
            deleteGroupModalOpen: false,

            personnel: [],
            groups: [],

            selectedGroup: null,
        }
    }

    componentDidMount() {
        this.getWorkgroups();
    }

    removeGroup = (group) => {
        this.setState({loading: true});
        api({
			method: 'post',
			url: 'project/management/deleteWorkgroup',
			data: group
		}).then(response => {
            // console.log(response);
            this.setState({loading: false});
            this.closeModal(true)
        }).catch(error => {
            console.error(error);
            this.setState({loading: false});
            this.closeModal(true);
        })
    }

    getWorkgroups = () => {
        this.setState({loading: true});
        api({
            method: 'get',
			url: 'project/management/getWorkgroups',
        }).then(response => {
            const personnel = response.users;
            sortByField(personnel, 'name');
            this.setState({personnel, groups: response.groups, loading: false});
        }).catch(error => {
            console.error(error);
            this.setState({loading: false});
        })
    }

    closeModal = (refresh=false) => {
        this.setState({
            groupModalOpen: false,
            deleteGroupModalOpen: false,
            selectedGroup: null
        });

        if(refresh) {
            this.getWorkgroups();
        }
    }

    render() {
        return (
            <div id="workGroups">
                <div className="apBox">
                    <div className="apBoxHeader">
                        <h1>{tr('workgroups')}</h1>
                        <p>{tr('create_new_workgroups')}</p>
                        <div className="apBoxCorner">
                            <ApButton 
                                onClick={() => this.setState({groupModalOpen: true})}
                                color="green"
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            >
                                {tr('new_workgroup')}
                            </ApButton>
                        </div>
                    </div>
                    <div className='padding'>
                        <ApReactTable 
                            loading={this.state.loading}
                            filterable
        					showFiltersInitially={true}
                            columns={[
                                {
                                    id: 'name',
                                    Header: tr('name'),
                                    accessor: 'name',
                                    onClick: (row) => this.setState({selectedGroup: row, groupModalOpen: true}),
                                    customFilter: {
                                        type: "text",
                                        placeholder: tr('name'),
                                    },
                                },
                                {
                                    id: 'leader_name',
                                    Header: tr('team_leader'),
                                    accessor: 'leader_name',
                                    onClick: (row) => this.setState({selectedGroup: row, groupModalOpen: true}),
                                    customFilter: {
                                        type: "text",
                                        placeholder: tr('team_leader'),
                                    },
                                },
                                {
                                    id: 'description',
                                    Header: tr('description'),
                                    accessor: 'description',
                                    onClick: (row) => this.setState({selectedGroup: row, groupModalOpen: true}),
                                    filterable: false
                                },
                            ]} 
                            data={this.state.groups}
                            rowActions={ (row) => 
						        <ApDropdown
                                    actions={[
                                        {
                                            label: tr('edit'), 
                                            action: () => this.setState({selectedGroup: row, groupModalOpen: true}),
                                            icon: 'edit'
                                        },
                                        {
                                            label: tr('delete'), 
                                            action: () => this.setState({selectedGroup: row, deleteGroupModalOpen: true}),
                                            icon: 'trash'
                                        }
                                    ]}
                                    button={ <SvgIcon icon="ellipsis-h" type="solid" />}
                                />
					        }
                        />
                    </div>
                </div>
                <WorkgroupModal 
                    show={this.state.groupModalOpen} 
                    closeModal={this.closeModal} 
                    personnel={this.state.personnel}
                    selectedGroup={this.state.selectedGroup}
                />
                <ApModal
                    handleClose={this.closeModal}
                    show={this.state.deleteGroupModalOpen}
                    header={<h4 className='padding'>{tr('delete_group_confirm', [this.state.selectedGroup && this.state.selectedGroup.name])}?</h4>}
                    body={""}
                    footer={
                        <div className={`padding ${styles.modalBtnContainer}`}>
                            <ApButton onClick={() => this.setState({deleteGroupModalOpen: false})}>{tr('cancel')}</ApButton>
                            <ApButton onClick={() => this.removeGroup(this.state.selectedGroup)} color="red" >
                                <SvgIcon icon="trash" type="solid" />
                                { tr('delete') }
                            </ApButton>
                        </div>
                    }
                    closeFromBg
                    className='narrow'
                />
            </div>
        )
    }
}

export default Workgroups;
