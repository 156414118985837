import React from 'react';

import ApList from 'common/ApList/ApList';

import { keyExists, tr } from 'services/Helpers/Helpers';
import ApModal from 'common/ApModal/ApModal';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import ApButton from 'common/ApButton/ApButton';
import {ApInput, ApInputStack,ApAddon } from 'common/ApInput/ApInput';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import moment from 'moment';
import ApSelect from 'common/ApSelect/ApSelect.js';


const commonAgreements = [
    'Kiinteistöalan toimihenkilöitä koskeva työehtosopimus',
    'Kiinteistöpalvelualan työntekijöitä koskeva työehtosopimus',
    'Maalausalan työehtosopimus urakkahinnoitteluineen',
    'Pelti- ja teollisuuseristysalan työehtosopimus',
    'Rakennusalan toimihenkilöiden työehtosopimus',
    'Rakennusalan työehtosopimus urakkahinnoitteluineen',
    'Sähköalan tes - energia - ICT - verkosto työehtosopimus',
    'Sähköistys- ja sähköasennusalan työehtosopimus',
    'Talotekniikka-alan LVI-toimialan työehtosopimus työntekijöille',
    'Talotekniikka-alan toimihenkilöiden työehtosopimus',
    'Teknologiateollisuuden ylempien toimihenkilöiden työehtosopimus',
    'Teknologiateollisuuden toimihenkilöiden työehtosopimus',
    'Teknologiateollisuuden työehtosopimus'
];

class CollectiveAgreement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCollectiveAgreementModal: false,
            showPersonnelGroupModal: false,

            selectedItem: {},
            createNewItem: false,
            nextId: 0,
        }
    }

    renderItem(item) {
        return (
            <div>
                <strong>{item.name}</strong><br />
               <small>{item.description && item.description.substring(0,70) || tr('no_description')}</small>
            </div>
        )
    }

    handleItemClick = (modalState, item = false) => {
        if(item) {
            this.setState({ selectedItem: item });
        }
        this.setState({[modalState]: true, createNewItem: item ? false : true});
    }

    handleModalClose = () => {
        this.setState({
            showCollectiveAgreementModal: false,
            showPersonnelGroupModal: false,
            selectedItem: {},
        });
    }

    handleChange = (e, key, targetValue = true) => {
        if (targetValue) {
            this.setState({selectedItem: { ...this.state.selectedItem, [key]: e.target.value}});
            
        }
        else {
            let selected = this.state.selectedItem;
            selected[key] = e;
            this.setState({ selectedItem: selected });
        }
    }

    handleItemEdit = (type) => {
        const data = [...this.props[type]];
        if (!this.state.createNewItem) {
            const foundItem = data.find(item => {
                return item.id == this.state.selectedItem.id
            });
            foundItem.name = this.state.selectedItem.name;
            if (type == 'personnelGroups') {
                foundItem.no_payroll = !!this.state.selectedItem.no_payroll;
                foundItem.hide_organization = !!this.state.selectedItem.hide_organization;
                foundItem.exclude_timetracking_entries_in_reports = !!this.state.selectedItem.exclude_timetracking_entries_in_reports;
            }
            foundItem.description = this.state.selectedItem.description;
        } else {
            const item = {
                name: this.state.selectedItem.name,
                description: this.state.selectedItem.description,
                id: `new_${this.state.nextId}`,
                unsaved: true,
                time_attendance_settings: this.state.selectedItem.time_attendance_settings
            }
            if (type == 'personnelGroups') {
                item.no_payroll = !!this.state.selectedItem.no_payroll;
                item.hide_organization = !!this.state.selectedItem.hide_organization;
                item.exclude_timetracking_entries_in_reports = !!this.state.selectedItem.exclude_timetracking_entries_in_reports;
            }
            this.setState({nextId: this.state.nextId+1});
            data.push({ ...item })
        }
        this.props.handleChange( type, data, this.handleModalClose );
    }

    handleCommonAgreements = () => {
        const data = [...this.props.collectiveAgreements];
        let nextId = this.state.nextId;
        commonAgreements.forEach(item => {
            const foundAgreement = data.find(agreement => agreement.name.toLowerCase() === item.toLowerCase());
            if (!foundAgreement) {
                data.push({
                    name: item,
                    description: '',
                    id: `new_${nextId}`,
                    unsaved: true,
                });
            }
            nextId++;
        });
        this.setState({nextId});
        this.props.handleChange( 'collectiveAgreements', data);
    }

    handleSwitch = (e) => {
        this.setState({
            selectedItem: {
                ...this.state.selectedItem,
                [e.target.name]: !this.state.selectedItem[e.target.name]
            }
        });
    }

    handleItemRemove = (item, type) => {
        const data = [...this.props[type]];
        const foundItem = data.find(it => it.id === item.id);
        if (foundItem) {
            foundItem.removed ? delete foundItem.removed : foundItem.removed = true;
        }

        this.props.handleChange( type, data );
    }

    handleRequiredSwitch = (key) => {
        const data = { ...this.props.company };
		data[ key ] = !data[ key ];
		this.props.handleChange( 'company', data );
    }

    render() {
        return (
            <div id='collective-agreement' className='padding'>
                <h3>{tr('collective_agreements')}</h3>
                <div className="apSwitchBlock">
                    <label htmlFor="collective-agreement-required" className="info">
                        {tr('collective_agreement_required')}
                        <small>{tr('collective_agreement_required_info')}</small>
                    </label>
                    <ApSwitch
                        id="collective-agreement-required"
                        on={ keyExists(this.props, 'company.collective_agreement_required', true, false) }
                        onChange={ () => this.handleRequiredSwitch('collective_agreement_required') }
                        disabled={ this.props.loading }
                    />
                </div>
                <ApButton onClick={this.handleCommonAgreements} className='size-small' color='white'>
                    {tr('import_common_collective_agreements')}
                </ApButton>
                <ApList
                    loading={ this.props.loading }
                    items={ this.props.collectiveAgreements }
                    itemRenderer={ this.renderItem }
                    onClick={ ( item ) => this.handleItemClick('showCollectiveAgreementModal', item) }
                    actions={
                        [
                            {
                                label: tr('edit'),
                                icon: "edit",
                                action: ( item, closeFunc ) => { 
                                    this.handleItemClick('showCollectiveAgreementModal', item);
                                    closeFunc();
                                },
                            },
                            {
                                label: tr('delete'),
                                icon: "trash",
                                action: ( item, closeFunc ) => {
                                    this.handleItemRemove(item, 'collectiveAgreements');
                                    closeFunc();
                                },
                            }
                        ]
                    }
                    icon={ ( item ) => <SvgIcon icon="file-contract" type="solid" /> }
                    sortable
                    onSort={ ( items ) => this.props.handleChange( "collectiveAgreements", items ) }
                    addNewItem={ ( item ) => this.handleItemClick('showCollectiveAgreementModal') }
                    validationState={ ( item ) => 
                        item.removed ? "removed" : 
                        item.unsaved ? "warning" : 
                        "" 
                    }
                />

                <h3>{tr('personnel_group')}</h3>
                <div className="apSwitchBlock">
                    <label htmlFor="personnel-group-required" className="info">
                        {tr('personnel_group_required')}
                        <small>{tr('personnel_group_required_info')}</small>
                    </label>
                    <ApSwitch
                        id="personnel-group-required"
                        on={ keyExists(this.props, 'company.personnel_group_required', true, false) }
                        onChange={ () => this.handleRequiredSwitch('personnel_group_required') }
                        disabled={ this.props.loading }
                    />
                </div>
                <ApList
                    loading={ this.props.loading }
                    items={ this.props.personnelGroups }
                    itemRenderer={ this.renderItem }
                    columns={[
                        {value: (item) => item.no_payroll && <small>{tr('no_payroll_info')}</small>}
                    ]}
                    onClick={( item ) => this.handleItemClick('showPersonnelGroupModal', item) }
                    actions={
                        [
                            {
                                label: tr('edit'),
                                icon: "edit",
                                action: ( item, closeFunc ) => { 
                                    this.handleItemClick('showPersonnelGroupModal', item);
                                    closeFunc();
                                },
                            },
                            {
                                label: (item) => item.removed ? tr('undo_deletion') : tr('delete'),
                                icon: "trash",
                                action: ( item, closeFunc ) => {
                                    this.handleItemRemove(item, 'personnelGroups');
                                    closeFunc();
                                },
                            }
                        ]
                     }
                    icon={ ( item ) => <SvgIcon icon="users" type="solid" /> }
                    sortable
                    onSort={ ( items ) => this.props.handleChange( "personnelGroups", items ) }
                    addNewItem={( item ) => this.handleItemClick('showPersonnelGroupModal') }
                    validationState={ ( item ) => 
                        item.removed ? "removed" : 
                        item.unsaved ? "warning" : 
                        "" 
                    }
                />

                {/* Collective agreement modal */}
                <ApModal
                    show={this.state.showCollectiveAgreementModal}
                    handleClose={ this.handleModalClose }
                    closeFromBg
                    className='narrow'

                    header={(
                        <div className='padding'>
                            <h3>{tr('collective_agreement')}</h3>
                        </div>
                    )}

                    body={(
                        <div className='padding'>
                            <ApInput
                                label={tr('name')}
                                value={this.state.selectedItem && this.state.selectedItem.name}
                                onChange={(e)=>this.handleChange(e, 'name')}
                                id='new-collective-agreement'
                                name='new-collective-agreement'
                            />
                            <ApInput
                                type='textarea'
                                label={tr('description')}
                                value={this.state.selectedItem && this.state.selectedItem.description}
                                onChange={(e)=>this.handleChange(e, 'description')}
                                id='new-collective-agreement-description'
                                name='new-collective-agreement-description'
                            />
                        </div>
                    )}

                    footer={(
                        <div className='padding'>
                            <div style={{display: 'flex', justifyContent:'space-between'}}>
                                <ApButton onClick={ this.handleModalClose }>
                                    {tr('cancel')}
                                </ApButton>
                                <ApButton onClick={() => this.handleItemEdit('collectiveAgreements')} color='green'>
                                    <SvgIcon icon='check' type='solid' /> {tr('continue')}
                                </ApButton>
                            </div>
                        </div>
                    )}
                />

                {/* Personnel groups modal */}
                <ApModal
                    show={this.state.showPersonnelGroupModal}
                    handleClose={ this.handleModalClose }
                    closeFromBg
                    className='narrow'

                    header={(
                        <div className='padding'>
                            <h3>{tr('personnel_group')}</h3>
                        </div>
                    )}

                    body={(
                        <div className='padding'>
                            <ApInput
                                label={tr('name')}
                                value={this.state.selectedItem && this.state.selectedItem.name}
                                onChange={(e)=>this.handleChange(e, 'name')}
                                id='new-personnel-group'
                                name='new-personnel-group'
                            />
                            <ApInput
                                type='textarea'
                                label={tr('description')}
                                value={this.state.selectedItem && this.state.selectedItem.description}
                                onChange={(e)=>this.handleChange(e, 'description')}
                                id='new-personnel-group-description'
                                name='new-personnel-group-description'
                            />
                            <div className={`apFormGroup ${this.state.selectedItem && this.state.selectedItem.no_payroll ? 'success' : ''}`}>
                                <div className='apSwitchBlock small'>
                                    <label htmlFor="no_payroll" className="info" style={{marginRight: '7px'}}>
                                        {tr('no_payroll')}
                                    </label>
                                    <ApSwitch
                                        name="no_payroll"
                                        id="no_payroll"
                                        on={this.state.selectedItem && this.state.selectedItem.no_payroll} 
                                        onChange={ this.handleSwitch }
                                    />
                                </div>
                            </div>
                            
                            <div className={`apFormGroup ${this.state.selectedItem && this.state.selectedItem.exclude_timetracking_entries_in_reports ? 'success' : ''}`}>
                                <div className='apSwitchBlock small'>
                                    <label htmlFor="exclude_timetracking_entries_in_reports" className="info">
                                        {tr('exclude_timetracking_entries_reports')}<br />
                                        <small>{tr('exclude_timetracking_entries_reports_info')}</small>
                                    </label>
                                    <ApSwitch
                                        name="exclude_timetracking_entries_in_reports"
                                        id="exclude_timetracking_entries_in_reports"
                                        on={this.state.selectedItem && this.state.selectedItem.exclude_timetracking_entries_in_reports}
                                        onChange={this.handleSwitch}
                                    />
                                </div>
                            </div>

                            <div className={`apFormGroup ${this.state.selectedItem && this.state.selectedItem.hide_organization ? 'success' : ''}`}>
                                <div className='apSwitchBlock small'>
                                    <label htmlFor="hide_organization" className="info" style={{marginRight: '7px'}}>
                                        {tr('hide_organization')}
                                        <small>{tr('hide_organization_info')}</small>
                                    </label>
                                    <ApSwitch
                                        name="hide_organization"
                                        id="hide_organization"
                                        on={this.state.selectedItem && this.state.selectedItem.hide_organization} 
                                        onChange={ this.handleSwitch }
                                    />
                                </div>
                            </div>
                            <label htmlFor="flexitime-switch" className="info">
                                {tr('default_project')} <br/>
                                <small>{tr('default_project_in_time_attendance')}</small>
                            </label>
                            <ApSelect
                                label={tr('select_project')}
                                disabled={this.props.loading}
                                value={keyExists(this.state.selectedItem, "project", true) ? this.state.selectedItem.project.name + ": " + this.state.selectedItem.project.project_code : ""}
                                optionRenderer="project_assignment"
                                onChange={(e) => {
                                    this.handleChange(e, 'project',false);
                                    this.handleChange(null, 'work', false);
                                    this.handleChange(null, 'hour_types', false);
                                }}
                                objKeyId="id"
                                clearable
                                request_time={true}
                                apiUrl="timetracking/projects"
                                apiData={{
                                    date: moment().format('YYYY-MM-DD'),
                                    //exlude: [].map( p => p.id ),
                                }}
                            />
                            <label htmlFor="flexitime-switch" className="info">
                                {tr('default_project_work')}<br />
                                <small>{tr('default_project_work_in_time_attendance')}</small>
                            </label>
                            <ApSelect
                                label={tr('work')}
                                loading={this.props.loading}
                                disabled={this.props.loading || !keyExists(this.state.selectedItem, "project", true)}
                                value={keyExists(this.state.selectedItem, "work", true) ? this.state.selectedItem.work.name : ""}
                                onChange={(e) => {
                                    this.handleChange(e, 'work', false);
                                    this.handleChange(null, 'hour_types', false);
                                }}
                                options={keyExists(this.state.selectedItem, "project", true) ? this.state.selectedItem.project.works : []}
                                clearable
                                optionRenderer="work"
                                objKeyId="id"
                                objKeySearchable="name"
                                objKeyValue="name"
                            //validationState={row.send_type ? null : 'error'}
                            />
                            <label htmlFor="flexitime-switch" className="info">
                                {tr('default_hour_type')}<br />
                                <small>{tr('default_hour_type_in_time_attendance')}</small>
                            </label>
                            <ApSelect
                                label={tr('hour_type')}
                                loading={this.props.loading}
                                disabled={this.props.loading || !keyExists(this.state.selectedItem, "work", true)}
                                value={keyExists(this.state.selectedItem, "hour_type", true) ? this.state.selectedItem.hour_type.name : ""}
                                onChange={(e) => this.handleChange(e, 'hour_types', false) }
                                options={keyExists(this.state.selectedItem, "hour_types", true) || []}
                                clearable
                                optionRenderer="hour_type"
                                objKeyId="id"
                                objKeySearchable="name"
                                objKeyValue="name"
                            //validationState={row.send_type ? null : 'error'}
                            />
                            <label htmlFor="flexitime-switch" className="info">
                                {tr("time_attendance_max_time")}<br />
                                <small>{tr("time_attendance_time_limit_info")}</small>
                            </label>
                            <ApInputStack gap="0">
                                <ApInput
                                    type="number"
                                    label={tr('time_attendance_max_time')}
                                    //className="text-right"
                                    value={keyExists(this.state.selectedItem, "time_attendance_settings.time_limit", true) ? this.state.selectedItem.time_attendance_settings.time_limit / 60 : ""}
                                    clearable
                                    onChange={(e) => this.handleChange(e, 'ta_lunch_break')}
                                //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                                />
                                <ApAddon noLeftBorder width="25px">
                                    h
                                </ApAddon>
                            </ApInputStack>
                            <label htmlFor="flexitime-switch" className="info">
                                {tr("lunch_break")}<br />
                                <small>{tr("lunch_break_length_info")}</small>
                            </label>
                            <ApInputStack gap="0">
                                <ApInput
                                    type="number"
                                    label={tr('lunch_break_length')}
                                    //className="text-right"
                                    value={keyExists(this.state.selectedItem, "ta_lunch_break", true) ? this.state.selectedItem.ta_lunch_break : ""}
                                    clearable
                                    onChange={(e) => this.handleChange(e, 'ta_lunch_break')}
                                //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                                />
                                <ApAddon noLeftBorder width="25px">
                                    min
                                </ApAddon>
                            </ApInputStack>
                            <label htmlFor="flexitime-switch" className="info">
                                {tr('lunch_break_min_time')}<br />
                                <small>{tr('time_when_accept_lunch_break_info')}</small>
                            </label>
                            <ApInputStack gap="0">
                                <ApInput
                                    type="number"
                                    label={tr('lunch_break_min_time')}
                                    //className="text-right"
                                    value={keyExists(this.state.selectedItem, "ta_min_time_accept_lunch_break", true) ? this.state.selectedItem.ta_min_time_accept_lunch_break / 60 : ""}
                                    clearable
                                    onChange={(e) => this.handleChange(e, 'ta_min_time_accept_lunch_break')}
                                //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                                />
                                <ApAddon noLeftBorder width="25px">
                                    h
                                </ApAddon>
                            </ApInputStack>
                        </div>
                    )}

                    footer={(
                        <div className='padding'>
                            <div style={{display: 'flex', justifyContent:'space-between'}}>
                                <ApButton onClick={ this.handleModalClose }>
                                    {tr('cancel')}
                                </ApButton>
                                <ApButton onClick={() => this.handleItemEdit('personnelGroups')} color='green'>
                                    <SvgIcon icon='check' type='solid' /> {tr('continue')}
                                </ApButton>
                            </div>
                        </div>
                    )}
                />
            </div>
        );
    }
}

export default CollectiveAgreement;