/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import ApModal from 'common/ApModal/ApModal.js';
import api from 'services/Api/Api.js';
import SvgIcon           from 'common/SvgIcon/SvgIcon.js';

import moment from 'moment';
import 'moment/locale/fi';
import { ApReactTable }     from 'common/ApReactTable/ApReactTable.js';

import { capitalize, currentLang, formatMoney, tr } from 'services/Helpers/Helpers.js';

const titles = {
    work_entries: tr('recorded_hours_alt'),
    work_entries_not_billed: tr('entries_not_in_billing'),
    done_estimate: tr('work_readiness_assessments'),
    items_installed: tr('installations'),
    items_installed_not_billed: tr('installations_not_in_billing'),
    expense_chargeable: tr('billing_costs'),
    expense_chargeable_not_billed: tr('billing_costs_not_in_billing'),
};

class TallyModal extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: true,
            open: false,
            data: [],
            pages: -1,
        }

        this.tableState = {};

        autoBind(this);
    }

    resetState()
    {
        this.tableState = {};
        this.setState({
            data: [],
            pages: -1,
            open: true,
            loading: true,
        });
    }

    onOpen()
    {
        this.resetState();
    }

    getData( state = null )
    {
        if( !state ) state = this.tableState;
        this.tableState = state;
        window.clearTimeout(this.getTimer);

        this.getTimer = window.setTimeout(() => {

            this.setState({ loading: true});

            let url = `project/tally/${ this.props.type }`;
            api({
                method: 'post',
                url: url,
                errorPopper: 'Tietojen haussa tapahtui virhe',
                data: {
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,

                    entryId: this.props.entryId,
                },
            }).then((response) => {

                //console.log('Data', response );

                this.setState({
                    data: response.data,
                    pages: response.pages,
                    loading: false,
                })

            }).catch((error) => {
                this.setState({
                    data: [],
                    pages: -1,
                    loading: false,
                })
            });
        }, 500);
    }

    onClose()
    {
        this.setState({ open: false, });
        this.props.onClose( false);
    }

    getColumns()
    {
        const date = {
            id: 'date',
            Header: tr('date'),
            accessor: 'date',
            Cell: props => {
                return <div>
                    { moment( props.value ).format('DD.MM.YYYY') }
                </div>
            },
        };
        const ago = {
            id: 'ago',
            Header: '',
            accessor: 'date',
            sortable: false,
            Cell: props => {
                return <div>
                    <small>{ moment().locale(currentLang()).to( moment( props.value ) ) }</small>
                </div>
            },
        };
        const user = {
            id: 'user_id',
            Header: capitalize(tr('users_count_one')),
            accessor: 'user.name',
            sortable: false,
        };

        const description = {
            id: 'description',
            Header: tr('description'),
            accessor: "description",
            sortable: false,
        };

        if( ['work_entries_not_billed', 'work_entries'].includes( this.props.type ) )
        {
            return [
                date,
                ago,
                user,
                {
                    Header: tr('type'),
                    accessor: "type",
                    sortable: false,
                },
                {
                    Header: tr('hours'),
                    accessor: "hours",
                    sortable: false,
                    Cell: ( props ) => {
                        return <div className="text-right">{ formatMoney( props.value, 1 ) }</div>
                    },
                },
                description,
            ];
        }

        else if( this.props.type === 'done_estimate' )
        {
            return [
                date,
                ago,
                user,
                {
                    Header: "Valmius %",
                    accessor: "progress",
                    Cell: ( props ) => {
                        return <div className="text-right">{ props.value } %</div>
                    },
                },
                description,
            ];
        }
        else if( ['items_installed', 'items_installed_not_billed'].includes( this.props.type ) )
        {
            return [
                date,
                ago,
                user,
                {
                    Header: tr('amount'),
                    accessor: "quantity",
                    Cell: ( props ) => {
                        return <div className="text-right">{ props.value }</div>
                    },
                },
                {
                    Header:     tr('from_timetracking'),
                    accessor:   "from_timetracking",
                    width: 70,
                    Cell: ( props ) => {
                        if( props.value )
                            return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                    },
                },
            ];
        }
        else if( ['expense_chargeable', 'expense_chargeable_not_billed'].includes( this.props.type ) )
        {
            return [
                date,
                ago,
                user,
                {
                    Header: tr('amount'),
                    accessor: "quantity",
                    Cell: ( props ) => {
                        return <div className="text-right">{ props.value }</div>
                    },
                },
                {
                    Header:     tr('from_timetracking'),
                    accessor:   "from_timetracking",
                    width: 70,
                    Cell: ( props ) => {
                        if( props.value )
                            return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                    },
                },
            ];
        }


        return [];
    }

    render()
    {

        let body = null;

        if( this.state.open )
        {
            body = <ApReactTable
                columns={ this.getColumns() }
                data={ this.state.data }
                loading={ this.state.loading }

                manual
                onFetchData={ this.getData }
                pages={ this.state.pages }

                defaultSorted={[{id: 'date', desc: true }]}
            />
        }

        return <div id="tallyModal">
            <ApModal
                show={ this.props.show }
                onOpen={ this.onOpen }
                handleClose={ this.onClose }
                closeFromBg
                // className="narrow"
                header={
                    <div className="padding-small">
                        <h4>{ titles[ this.props.type ] }</h4>
                    </div>
                }
                body={
                    <div className="padding-small">
                        { body }
                    </div>
                }
            />
        </div>
    }

}

export default TallyModal;
