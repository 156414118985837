export const timerReducer = (state = { status: "stopped", dayIsLocked: false }, action) => {
    switch (action.type) {
        case 'STATUS_CHANGE':
            return {
                ...state,
                status: action.payload,
            }
        case 'DAY_IS_LOCKED':
            return {
                ...state,
                dayIsLocked: action.payload,
            }
        default:
            return state;
    }
}