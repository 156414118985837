import React, {Component} from 'react';
import styles from './Workgroups.module.css';

import { errorPopper, sortByField, tr } from 'services/Helpers/Helpers';
import ApButton from 'common/ApButton/ApButton';
import ApModal from 'common/ApModal/ApModal';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import api from 'services/Api/Api';

class WorkgroupModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            name: "",
            description: "",
            leaderId: null,
            memberIds: [],
            personnel: [],
            edited:false,
        }
    }

    componentDidMount() {
        this.getPersonnel();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.selectedGroup && this.props.selectedGroup) {
            this.setState({
                name: this.props.selectedGroup.name,
                description: this.props.selectedGroup.description,
                leaderId: this.props.selectedGroup.team_leader,
                memberIds: this.props.selectedGroup.members,
                edited: false 
            })
        }
    }

    getPersonnel = () => {
        this.setState({loading:true});
        api({
            method: 'get',
			url: 'project/management/getActiveUsers',
        }).then(response => {
            const personnel = response;
            sortByField(personnel, 'name');
            this.setState({personnel, loading: false});
        }).catch(error => {
            console.error(error);
            this.setState({loading: false});
        })
    }

    handleSave = () => {
        const data = {
            name: this.state.name,
            description: this.state.description,
            members: this.state.memberIds,
            leader: this.state.leaderId,
            id: this.props.selectedGroup ? this.props.selectedGroup.id : null
        }

        const url = this.props.selectedGroup
            ? 'project/management/editWorkgroup'
            : 'project/management/addWorkgroup'

        this.setState({loading: true});

        api({
			method: 'post',
			url: url,
			data: data
		}).then(response => {
            // console.log(response);
            this.setState({loading: false});
            if (typeof this.props.closeModal === 'function') {
                this.props.closeModal(true);
            }
            if(typeof this.props.onSave === 'function') {
                this.props.onSave(response);
            }
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('save_error'));
            this.setState({loading: false});
            if (typeof this.props.closeModal === 'function') {
                this.props.closeModal();
            }
        })
    }

    handleClose = () => {
        this.setState({
            name: "",
            description: "",
            leaderId: null,
            memberIds: [],
        });
        this.props.closeModal();
    }

    handlePersonnelToggle = (user) => {
        this.setState({clickedUser: user})
        let newMemberList = [...this.state.memberIds];
        if (this.state.memberIds.includes(user.id)) {
            const foundIndex = newMemberList.findIndex(id => id === user.id);
            newMemberList.splice(foundIndex, 1);
            if (user.id === this.state.leaderId) {
                this.setState({ leaderId: null });
            }
        } else {
            newMemberList.push(user.id);
            if (this.state.memberIds.length === 0) {
                this.setState({ leaderId: user.id });
            }
        }
        this.setState({ memberIds: newMemberList, edited: true });
    }

    renderBody = () => {
        const members = this.state.memberIds.reduce((users, id) => {
                const foundUser = this.state.personnel.find((user) => user.id === id && user.is_active === true)
                if (foundUser) {
                    return [...users, foundUser];
                } else {
                    return users;
                }
            }, []
        );
        sortByField(members, 'name');

        return (
            <div className='padding'>
                <ApInput
                    name="name"
                    id="name"
                    label={ tr('name') }
                    value={ this.state.name }
                    type="text"
                    onChange={ (e) => this.setState({ name: e.target.value ,edited:true }) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    // validationState={ this.validator.getState('name') }
                />

                <ApInput
                    type="textarea"
                    rows="2"
                    id="description"
                    name="description"
                    label={ tr('description') }
                    value={ this.state.description }
                    onChange={(e) => this.setState({ description: e.target.value, edited: true }) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
                
                <div className="apColumn">
                    <div className="apColumn w50">
                        <div className="padding">
                            {this.state.memberIds.length > 0 && 
                            <div className={styles['members-heading']}>
                                <h5>{tr('team_members')}</h5>
                                <h5>{tr('team_leader')}</h5>
                            </div>}
                            {members.map(member => {
                                return (
                                    <ApInputStack key={member.id}>
                                        <ApAddon labelFor={`${member.id}-member`}>
                                            <div className={styles['member-row']}>
                                                <span>{member.name}</span>
                                                <ApSwitch
                                                    key={member.id}
                                                    id={`${member.id}-member`}
                                                    on={this.state.leaderId === member.id}
                                                    onChange={() => this.setState({ leaderId: member.id, edited: true })}
                                                />
                                            </div>
                                        </ApAddon>
                                    </ApInputStack>
                                );
                            })}
                        </div>
                    </div>

                    <div className="apColumn w50">
                        <div className="padding">
                            <h5>{tr('personnel')}</h5>
                            {this.state.personnel.map(user => (
                                <ApInputStack key={user.id}>
                                    <ApAddon labelFor={`p-${user.id}`}>
                                        <div className={styles['member-row']}>
                                            <span>{user.name}</span>
                                            <ApSwitch
                                                key={user.id}
                                                id={`p-${user.id}`}
                                                on={this.state.memberIds.includes(user.id)}
                                                onChange={() => this.handlePersonnelToggle(user)}
                                            />
                                        </div>
                                    </ApAddon>
                                </ApInputStack>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let saveDisabled = false;
        let error = null;
        if (this.state.loading) {
            saveDisabled = true;
        } else if (this.state.memberIds.length === 0) {
            saveDisabled = true;
            error = tr('no_members');
        } else if (this.state.leaderId === null) {
            saveDisabled = true;
            error = tr('no_leader');
        } else if (!this.state.name) {
            saveDisabled = true;
            error = tr('no_group_name');
        }

        return (
            <ApModal
                show={this.props.show}
                handleClose={this.handleClose}
                closeFromBg
                header={<div className='padding'><h1>{this.props.selectedGroup ? tr('edit_workgroup') : tr('new_workgroup')}</h1></div>}
                body={this.renderBody()}
                footer={
                    <div className='padding'>
                        <div className={`${styles['error-container']}`}>
                            {error}
                        </div>
                        <div className={`${styles.modalBtnContainer}`}>
                            <ApButton onClick={this.handleClose}>{tr('cancel')}</ApButton>
                            <ApButton onClick={this.handleSave} disabled={saveDisabled} color="blue"
                                className={"save" + (this.state.edited && !this.state.loading ? " highlight" : "")}>
                                <SvgIcon icon="save" type="solid" />
                                { tr('save') }
                            </ApButton>
                        </div>
                    </div>
                }
            />
        );
    }
}

export default WorkgroupModal;
