import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { ApInputSmall } from 'common/ApInputSmall/ApInputSmall.js';
import ApSelect from 'common/ApSelect/ApSelect.js';

import NewPaymentPost from 'modules/Projects/Payments/PaymentPosts/NewPaymentPost.js';
import PaymentPostEdit from 'modules/Projects/Payments/PaymentPosts/PaymentPostEdit.js';
import NewPayment from 'modules/Projects/Payments/NewPayment/NewPayment.js';
//import NewPaymentPostOrder from './../Payments/NewOrder/NewPaymentPostOrder.js'; 

import { ApReactTable }     from 'common/ApReactTable/ApReactTable.js';
import { formatMoney, errorPopper, tr } from 'services/Helpers/Helpers.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApModal from 'common/ApModal/ApModal.js';

import './Production.css';

class Production extends React.Component
{

	constructor(props)
	{
		super(props);
		this.state = {
				exclude: [],
				StorageAddModal: {},
				cols: [
		            {
		                label: tr('product'),
		                tooltip: tr('product_to_be_manufactured'),
		            },{
		                label: tr('storage_location'),
		                tooltip: tr('product_storage_location_info'),
		            },{
		                label: tr('quantity_to_be_manufactured'),
		                tooltip: tr('quantity_to_be_manufactured_info'),
		            },{
		                label: tr('quantity_manufactured'),
		                tooltip: tr('quantity_manufactured_info'),
		            },
		        ]
		}
		autoBind(this);
	}

	componentDidMount()
	{
		this.updateExclude();
	}
	
	componentAdd( product ) {
		this.state.exclude.push(product.id);
		let products = this.props.project.component_production.products;
		this.props.productionAdd( product );
	}
	
	updateExclude() {
		let exclude = [];
		this.props.project.component_production.forEach(product => {
			exclude.push(product.id);
        });
		this.setState({exclude:exclude});
	}
	
    renderHeader()
    {

        let cols = [];
        this.state.cols.forEach( ( col, i ) => {
            cols.push( <th key={ i } className="fixed">
                <ApTooltip text={ col.tooltip ? col.tooltip : null } block position="top">
                    { col.label }
                </ApTooltip>
            </th>)
        });
        return cols;
    }
    
	renderTable()
    {
        let rows = this.props.project.component_production;

        if( !rows || rows.length == 0 )
        {
            return <div className="apMsg">{ tr('no_product_to_be_manufactured_added') }</div>
        }

        return <table className="productionTable">
            <thead>
            	{this.renderHeader()}
            </thead>
            <tbody>
            	{this.renderProducts()}
            </tbody>
        </table>
    }
	
	StorageAddModalOpen() {
		
	}
	
	StorageAddDone() {
		this.props.productionAddStorageProduction(this.state.StorageAddModal.componentId,this.state.StorageAddModal.storage );
		this.setState({ StorageAddModal: {} });
	}
	
	StorageAdd(selected) {
		let StorageAddModal = this.state.StorageAddModal
		selected['is_new']=true;
		StorageAddModal['storage']=selected;
		this.setState({ StorageAddModal });
		//console.log(selected);
		
	}
	
	addLocation(componentId) {
		 this.setState({ StorageAddModal: {
			 show: true,
			 componentId:componentId
		 } });
	}
	
    renderStorageAddModal()
    {
    	//console.log( this.state);
        return <ApModal
            show={ Boolean( this.state.StorageAddModal.show ) }
            handleClose={ () => this.setState({ StorageAddModal: {} }) }
            className="narrow overflow"

            onOpen={ this.StorageAddModalOpen }
            header={
                <div className="padding">
                  { tr('add_storage_for_product') }
                </div>
            }
            body={ <div className="padding">
		            <ApSelect
				        label={ tr('add_storage') }
				        value={ this.state.StorageAddModal.storage }
				        optionRenderer="storage_location"
				        onChange={ this.StorageAdd }
				        apiUrl="storage/locations/find"
				        apiData={{
				            canComponents: true,
				        }}
		            	objKeyId="id"
	                    objKeyValue="name"
				        loading={ this.state.loading }
				        disabled={ this.state.loading }
				    />
            </div> }
            footer={ <div className="padding text-right">

                    <ApButton onClick={ () => this.setState({ StorageAddModal: {} }) }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>

                    <ApButton color="green" onClick={ this.StorageAddDone }>
                        <SvgIcon icon="check" type="solid" />
                        { tr('continue') }
                    </ApButton>

            </div> }
        />
    }


	storageLocationSelect(c) {
        if( !c.locations )
            return tr('storages_are_missing').toUpperCase();

        let title = <div> { tr('not_selected') } </div>
        


        if( c.storage_id != null)
        {
            let selected = c.locations.find( l => l.id === c.storage_id );
            title = <div>
                <span>{ tr('storage') }</span>
                <small> { selected.code } </small> <br />
            </div>

            title = <div className="target-container">
                <div>&nbsp;</div>
                <div className="target-name">{ tr('storage') }</div>
                <div className="target-code"> { selected.code } </div>
            </div>
        }

        let options = [];
        c.locations.forEach( l => {
        	
            let dom = <div>
                { tr('storage') } <small> { l.code } </small><br />
            </div>
            if (l.type!=='P') {
	            options.push({
	                label: dom,
	                icon: "archive",
	                action: ( id, closeFunc ) => {
	                    this.props.productionChangeFinalLocation( c.id, l.id );
	                    closeFunc();
	                },
	            })}
        });
        if (c.locations.length==0) {
        	title = <div> { tr('product_storage_location_missing') } </div>
        	let dom=<div>
              { tr('add_storage') }
            </div>
            options.push({
                label: dom,
                icon: "plus",
                action: ( id, closeFunc ) => {
                    this.addLocation( c.id );
                    closeFunc();
                },
            });
        }
        
        let disabled = false;
        if( this.props.productionChangeStorageLocked ) disabled = true;

        if( disabled )
            return <div className="input-menu disabled" disabled>{ title }</div>

        let className = [ 'input-menu' ];
        if( !c.storage_id ) className.push( 'input-error' );

        const dropdown  = <ApDropdown
            fullWidth
            button={ <div className={ className.join(' ') }>{ title } <div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
            actions={ options }
        />
        return dropdown;
	}
	
	 renderMenu( c )
	    {

	        let actions = [];

	        if( c.id  )

	            actions.push({
	                label: tr('edit'),
	                icon: "edit",
	                action: ( id, closeFunc ) => {
	                    this.props.openComponentEdit( c.id );
	                    closeFunc();
	                },
	            });

	            if( !this.props.removeComponentLocked )
	            {
	                actions.push({
	                    label: tr('delete'),
	                    icon: "trash-alt",
	                    action: ( id, closeFunc ) => {
	                    	this.props.productionRemoveClick( c.id );
	                        closeFunc();
	                        this.updateExclude();
	                    },
	                });
	            }

	            return <ApDropdown
	                actions={ actions }
	            />

	        }
	
	renderProducts()
	{
		
		if  (this.props.project.component_production !== null)	
			
			return(
				this.props.project.component_production.map((product, index) => (
				<tr> 
					<td class="name">
						{product.name}
					</td>
					<td class="fixed">
						{this.storageLocationSelect(product)}
					</td>
					<td  class="fixed">
		                <ApInputSmall
		                    value={ product.production_count }
		                    onChange={ ( e ) => { this.props.productionChangeProductionCount(product.id, e.target.value ) } }
		                    unit={ product.unit }
		                    noFocusDecimals={ 2 }
		                />
		            </td>
					<td  class="fixed">
						{ product.production_count_done }
		            </td>
		            <td className="fixed actions">
	                { this.renderMenu( product ) }
	            </td>
				</tr>
			)));

	}
	
	render() {
		
		return <div>	
		{this.renderStorageAddModal()}
		<div className="apBoxHeader">
			<h1> { tr('manufacturing') } </h1>
			<p>{ tr('manufacturing_info') }</p>
		</div>				
		<div id="productionTable">
			{this.renderTable()}
		</div>
        <ApSelect
            label={ tr('add_product_to_be_manufactured') }
            loading={ this.props.loading }
            value=""
            optionRenderer="component_detail"
            onChange={ ( c ) => this.componentAdd( c ) }
            objKeyId="id"
            objKeyValue="name"
            apiUrl="storage/components"
            apiData={{
                include: [ 'locations' ],
                formatter: 'production',
                exludeIds: this.state.exclude
            }}
        />
		</div>
		
	}



}
export default Production;