import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import moment from 'moment';
import 'moment/locale/fi';
import { ApReactTable } from 'common/ApReactTable/ApReactTable.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

class LoginLogs extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            data: [],
            loading: false,
            pages: -1,
        }

        this.tableState = {};

        autoBind(this);
    }

    getData( state = null )
    {
        if( !state ) state = this.tableState;
        this.tableState = state;
        window.clearTimeout(this.getTimer);

        this.getTimer = window.setTimeout(() => {

            this.setState({ loading: true});

            api({
                method: 'post',
                url: 'management/loginlogs/apt',
                errorPopper: 'Kirjautumisien tapahtui virhe',
                data: {
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                },
            }).then((response) => {
                this.setState({
                    data: response.data,
                    pages: response.pages,
                    loading: false,
                })

            }).catch((error) => {
                this.setState({
                    data: [],
                    pages: -1,
                    loading: false,
                })
            });
        }, 500);

    }

    getColumns()
    {
        return [
            {
                id: 'created_at',
                Header: 'Päiväys',
                accessor: 'created',
                Cell: props => {
                    return <div>{ moment( props.value ).format('DD.MM.YYYY HH:mm') }</div>
                },
            },{
                id: 'ago',
                Header: 'Päiväys',
                accessor: 'created',
                sortable: false,
                Cell: props => {
                    return <div>{ moment().to( moment( props.value ) ) }</div>
                },
            },{
                id: 'user_id',
                Header: 'Käyttäjä',
                accessor: 'user.name',
            },{
                Header:     "Kirjannut ulos",
                accessor:   "logout",
                width: 70,
                customFilter: { type: "toggle" },
                Cell: ( props ) => {
                    if( props.value )
                        return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                },
            },
        ];
    }


    render()
    {
        return <div className="apBox">
            <div className="apBoxHeader">
                <h1>Kirjautumishistoria</h1>
                <p>Katso milloin henkilöt ovat kirjautuneet järjestelmään.</p>
            </div>
            <div className="padding">
                <ApReactTable
                    columns={ this.getColumns() }
                    data={ this.state.data }
                    loading={ this.state.loading }

                    manual
                    onFetchData={ this.getData }
                    pages={ this.state.pages }

                    defaultSorted={[{id: 'created_at', desc: true }]}
                />
            </div>
        </div>
    }

}

export default LoginLogs;
