import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import api                from 'services/Api/Api.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import { keyExists,
         //errorPopper,
         dateInputToSql,
         sqlToDateInput,
         parseNumber, 
         tr,
         currentLang,
         translateLangCode,
         availableLanguages, 
         hasPermissions}    from 'services/Helpers/Helpers.js'

import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApModal            from 'common/ApModal/ApModal.js';
import ApButton           from 'common/ApButton/ApButton.js';
import { ApTabs, ApTab }  from 'common/ApTabs/ApTabs.js';

import Account            from './Account/Account.js';
import PersonInfo         from './PersonInfo/PersonInfo.js';
import ContractDetail     from './ContractDetail/ContractDetail.js';
import Permissions        from './Permissions/Permissions.js';

import './UserEdit.css';
import UserNetvisorInfo from './UserNetvisorInfo/UserNetvisorInfo.js';

/*
TODO features: 
    - Validate phone numbers, bank account, address etc.
    - Contract start/end dates set available dates according to each other
    - ...
*/

class UserEdit extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            data: {},
            unsavedChanges: false,
            error: false,
            languageOptions: [],
            roles: [],
            collectiveAgreements: []
        }

        // Form validator
        //  - text:     Error message shown in tooltip when input is focused
        //  - textAll:  Field name shown at the bottom of the modal (next to save/cancel buttons) 
        this.validator = new ApValidate( this, {
            "data.password": [
                {
                    filter: ( value ) => {
                        if( !this.state.data.change_password ) return true;
                        return ( value && value.length > 0 ? true : false )
                    }, 
                    state: 'error', 
                    text: tr('password_empty'),
                    textAll: tr('password')
                },
                {
                    filter: ( value ) => {
                        if( !this.state.data.change_password ) return true;
                        return ( value && value.length >= 8 ? true : false );
                    }, 
                    state: 'warning', 
                    text: tr('change_password_note2'),
                    textAll: null,
                },
            ],
            "data.person_detail.first_name":        { filter: 'required', state: 'error', text: tr('mandatory'), textAll: tr('first_name') },
            "data.person_detail.last_name":         { filter: 'required', state: 'error', text: tr('mandatory'), textAll: tr('last_name') },
            "data.person_detail.birthday":          { filter: 'dateOptional', state: 'error', text: tr('incorrect_info'), textAll: tr('date_of_birth') },
            
            "data.contact_info.email":              { filter: 'emailOptional', state: 'warning', text: tr('incorrect_info'), textAll: tr('email') },
            
            "data.contract_detail.contract_start":  { filter: 'date', state: 'error', text: tr('incorrect_info'), textAll: tr('employment_start_date') },
            "data.contract_detail.contract_end":    { filter: 'dateOptional', state: 'error', text: tr('incorrect_info'), textAll: tr('employment_end_date') },
            "data.active_end":                      { filter: 'dateOptional', state: 'error', text: tr('incorrect_info'), textAll: tr('login_end_date') },
            "data.contract_detail.hour_approvers": { 
                filter: (value) => {
                    if (!keyExists(this.state.data, 'contract_detail.hour_entry_required', true)) return true;
                    if (!keyExists(this.state.data, 'contract_detail.hour_entry_approval_required', true)) return true;
                    const approvers = keyExists(this.state.data, 'contract_detail.hour_approvers', true, []);
                    return (approvers.length ? true : false);
                },
                state: 'error',
                text: tr('mandatory'),
                textAll: tr('approver_of_hours')
            },
            "data.contract_detail.salary": { 
                filter: ( value ) => {
                    if( !keyExists( this.state.data, 'can_see_salary', true ) ) return true;
                    if( !keyExists( this.state.data, 'contract_detail.hour_entry_required', true ) ) return true;
                    return ( parseNumber( value ) >= 0 ? true : false ); 
                }, 
                state: 'error', 
                text: tr('mandatory'),
                textAll: tr('salary')
            },
            "data.contract_detail.cost_center": {
                filter: (value) => {
                    if (!keyExists(this.state.data, 'contract_detail.cost_center_is_required', true)) return true;
                    return (value!=null ? true : false);
                },
                state: 'error',
                text: tr('mandatory'),
                textAll: tr('cost_center')
            },
            "data.contract_detail.salary_hourly_divider": { 
                filter: ( value ) => {
                    if( !keyExists( this.state.data, 'can_see_salary', true ) ) return true;
                    if( !keyExists( this.state.data, 'contract_detail.hour_entry_required', true ) ) return true;
                    if( keyExists( this.state.data, 'contract_detail.salary_unit', true ) === "hourly" ) return true;
                    return ( parseNumber( value ) > 0 ? true : false );
                }, 
                state: 'error',
                text: tr('mandatory'),
                textAll: tr('hourly_rate_multiplier')
            },
            "data.contract_detail.hours_weekly": [
                {
                    filter: ( value ) => {
                        if( !keyExists( this.state.data, 'contract_detail.hour_entry_required', true ) ) return true;
                        if( !keyExists( this.state.data, 'contract_detail.regular_worktime', true ) ) return true;
                        return ( parseNumber( value ) > 0 ? true : false );
                    }, 
                    state: 'error', 
                    text: tr('mandatory'),
                    textAll: tr('regular_working_hours') 
                },
                {
                    filter: ( value ) => {
                        if( !keyExists( this.state.data, 'contract_detail.hour_entry_required', true ) ) return true;
                        if( !keyExists( this.state.data, 'contract_detail.regular_worktime', true ) ) return true;
                        return ( parseNumber( value ) <= 40 ? true : false );
                    }, 
                    state: 'warning', 
                    text: tr('max_hours_finland'),
                    textAll: null,
                },
            ],
            'data.contract_detail.collective_agreement_id':  { 
                filter: (value) => {
                    if (!keyExists(this.state.data, 'collective_agreement_required', true)) return true;
                    if (this.state.collectiveAgreements.length <= 1) return true;
                    const foundGroup = this.state.roles && 
                        this.state.roles.find(role =>
                            role.value == (this.state.data && this.state.data.contract_detail && this.state.data.contract_detail.role_id));
                    if (foundGroup && foundGroup.noPayroll) return true;
                    return (value ? true : false);
                },
                state: 'error', 
                text: tr('mandatory'),
                textAll: tr('collective_agreement') 
            },
            'data.contract_detail.role_id':  { 
                filter: (value) => {
                    if (!keyExists(this.state.data, 'personnel_group_required', true)) return true;
                    return (value ? true : false);
                },
                state: 'error', 
                text: tr('mandatory'),
                textAll: tr('personnel_group') 
            },
            'data.contract_detail.collectivebargain': {
                filter: (value) => {
                    if (!keyExists(this.state.data, 'collective_agreement_required', true)) return true;
                    if (this.state.collectiveAgreements.length > 1) return true;
                    return ((value != null && value != "") ? true : false);
                },
                state: 'error',
                text: tr('mandatory'),
                textAll: tr('collective_agreement')
            },
            'data.contract_detail.job_description': {
                filter: (value) => {
                    if (!keyExists(this.state.data, 'isNetvisorPayroll', true)) return true;
                    if (keyExists(this.state.data, 'netvisor_salary_models', true, []).length <= 1) return true
                    if (keyExists(this.state, 'data.contract_detail.role_id')) {
                        const foundRole = this.state.roles.find(role => role.value == this.state.data.contract_detail.role_id);
                        if (foundRole && foundRole.noPayroll) {
                            return true;
                        }
                    }
                    return (value ? true : false);
                },
                state: 'warning',
                text: tr('mandatory'),
                textAll: tr('job_description'),
                optional: true,
            },
            'data.contract_detail.netvisor_salary_model_id': {
                filter: (value) => {
                    if (!keyExists(this.state.data, 'isNetvisorPayroll', true)) return true;
                    if (keyExists(this.state.data, 'netvisor_salary_models', true, []).length <= 1) return true
                    if (keyExists(this.state, 'data.contract_detail.role_id')) {
                        const foundRole = this.state.roles.find(role => role.value == this.state.data.contract_detail.role_id);
                        if (foundRole && foundRole.noPayroll) {
                            return true;
                        }
                    }
                    return (value ? true : false);
                },
                state: 'warning',
                text: tr('mandatory'),
                textAll: tr('salary_model'),
                optional: true,
            },
            // 'data.contract_detail.netvisor_advance_debt_account': {
            //     filter: (value) => {
            //         if (!keyExists(this.state.data, 'isNetvisorPayroll', true)) return true;
            //         return (value ? true : false);
            //     },
            //     state: 'error',
            //     text: tr('mandatory'),
            //     textAll: tr('advance_debt_account')
            // },
            'data.contract_detail.netvisor_payslip_delivery_method': {
                filter: (value) => {
                    if (!keyExists(this.state.data, 'isNetvisorPayroll', true)) return true;
                    if (keyExists(this.state.data, 'netvisor_salary_models', true, []).length <= 1) return true
                    if (keyExists(this.state, 'data.contract_detail.role_id')) {
                        const foundRole = this.state.roles.find(role => role.value == this.state.data.contract_detail.role_id);
                        if (foundRole && foundRole.noPayroll) {
                            return true;
                        }
                    }
                    return (value ? true : false);
                },
                state: 'warning',
                text: tr('mandatory'),
                textAll: tr('payslip_delivery_method'),
                optional: true,
            },
            // 'data.contract_detail.netvisor_contract_end_reason_id': {
            //     filter: (value) => {
            //         if (!keyExists(this.state.data, 'isNetvisorPayroll', true)) return true;
            //         if (keyExists(this.state.data, 'netvisor_salary_models', true, []).length <= 1) return true
            //         if (keyExists(this.state, 'data.contract_detail.role_id')) {
            //             const foundRole = this.state.roles.find(role => role.value == this.state.data.contract_detail.role_id);
            //             if (foundRole && foundRole.noPayroll) {
            //                 return true;
            //             }
            //         }
            //         if (!keyExists(this.state.data, 'contract_detail.contract_end', true)) return true;
            //         return (value ? true : false);
            //     },
            //     state: 'error',
            //     text: tr('mandatory'),
            //     textAll: tr('contract_end_reason')
            // },
            'data.address.city': {
                filter: (value) => {
                    if (!keyExists(this.state.data, 'isNetvisorPayroll', true)) return true;
                    if (keyExists(this.state.data, 'netvisor_salary_models', true, []).length <= 1) return true
                    if (keyExists(this.state, 'data.contract_detail.role_id')) {
                        const foundRole = this.state.roles.find(role => role.value == this.state.data.contract_detail.role_id);
                        if (foundRole && foundRole.noPayroll) {
                            return true;
                        }
                    }
                    return (value ? true : false);
                },
                state: 'warning',
                text: tr('mandatory'),
                textAll: tr('locality'),
                optional: true,
            },
            'data.address.postal_code': {
                filter: (value) => {
                    if (!keyExists(this.state.data, 'isNetvisorPayroll', true)) return true;
                    if (keyExists(this.state.data, 'netvisor_salary_models', true, []).length <= 1) return true
                    if (keyExists(this.state, 'data.contract_detail.role_id')) {
                        const foundRole = this.state.roles.find(role => role.value == this.state.data.contract_detail.role_id);
                        if (foundRole && foundRole.noPayroll) {
                            return true;
                        }
                    }
                    return (value ? true : false);
                },
                state: 'warning',
                text: tr('mandatory'),
                textAll: tr('postal_code'),
                optional: true,
            },
            'data.address.street': {
                filter: (value) => {
                    if (!keyExists(this.state.data, 'isNetvisorPayroll', true)) return true;
                    if (keyExists(this.state.data, 'netvisor_salary_models', true, []).length <= 1) return true
                    if (keyExists(this.state, 'data.contract_detail.role_id')) {
                        const foundRole = this.state.roles.find(role => role.value == this.state.data.contract_detail.role_id);
                        if (foundRole && foundRole.noPayroll) {
                            return true;
                        }
                    }
                    return (value ? true : false);
                },
                state: 'warning',
                text: tr('mandatory'),
                textAll: tr('street_address'),
                optional: true,
            },
        });

        autoBind(this);
        
    }

    getUserInfo()
    {
        this.setState({loading: true});
        api({
            method: 'get',
            url: 'usermanagement/user/get/' + this.props.userID,
        }).then((response) => {

            //console.log('The user', response);

            // Remove suffix from username 
            if( response.username.indexOf("@") > -1 )
                response.username = response.username.substring( 0, response.username.lastIndexOf("@") )

            // Change date formats
            response.person_detail.birthday         = sqlToDateInput( response.person_detail.birthday );
            response.contract_detail.contract_start = sqlToDateInput( response.contract_detail.contract_start );
            response.contract_detail.contract_end   = sqlToDateInput( response.contract_detail.contract_end );
            response.active_end                     = sqlToDateInput( response.active_end );
            
            // Add some additional data mainly for interface purposes
            response.change_password = false;
            response.email_new_password = false;
            response.contract_detail.regular_worktime = response.contract_detail.hours_weekly ? true : false;

            response.contract_detail.salary_hourly_divider = '';
            if( response.contract_detail.salary && response.contract_detail.salary_hourly )
                response.contract_detail.salary_hourly_divider = Math.round( response.contract_detail.salary / response.contract_detail.salary_hourly * 10 ) / 10;

            if (response.contract_detail.salary_hourly == response.contract_detail.salary && response.contract_detail.salary_unit != "hourly") {
                response.contract_detail.salary_hourly =this.reCalcSalaryHourly(response);
                // response.contract_detail.salary_hourly =response.contract_detail.salary;
                // response.contract_detail.salary_unit = "hourly";

            }

            // By default set 5 work days per week
            if( !response.contract_detail.days_weekly )
                response.contract_detail.days_weekly = 5; 

            this.setState({
                data: response,
                loading: false,
                unsavedChanges: false,
                error: false,
            });

        }).catch((error) => {
            //errorPopper( error, 'Käyttäjän tietojen haussa tapahtui virhe' );
            this.setState({
                loading: false,
                error: error
            });
        });
    }

    getLanguages()
	{
		api({
			method: 'get',
			url: 'languages/all'
		}).then((response) => {
			const languageOptions = [];
			response.forEach(lang => {
				if (availableLanguages().some(item => item.code === lang.code)) {
					languageOptions.push({
						id: lang.id,
						value: lang.id,
						label: translateLangCode(lang.code)
					});
				}
			});
			this.setState({languageOptions: languageOptions});
		}).catch((error) => {
			console.log('Error while fetching languages.');
		})
    }
    getRoles() {
        api({
            method: 'post',
            url: 'usermanagement/rolesandagreements'
        }).then((response) => {
            const roleOptions = [
                { value: null, label: "" }
            ];
            const collectiveAgreementOptions = [
                { value: null, label: "" }
            ];

            response.personnelGroups.forEach(role => {
                roleOptions.push({
                    value: role.id,
                    label: role.name,
                    noPayroll: role.no_payroll
                });
            });
            response.collectiveAgreements.forEach(item => {
                collectiveAgreementOptions.push({
                    value: item.id,
                    label: item.name
                });
            });
            this.setState({ roles: roleOptions, collectiveAgreements: collectiveAgreementOptions });
        }).catch((error) => {
            console.log('Error while fetching roles.')
        });
    }

    save()
    {
        // Deep clone data
        let data = JSON.parse( JSON.stringify( this.state.data ) );

        // Change date formats back to SQL dates
        data.contract_detail.contract_start = dateInputToSql( data.contract_detail.contract_start );
        data.contract_detail.contract_end   = dateInputToSql( data.contract_detail.contract_end );
        data.person_detail.birthday         = dateInputToSql( data.person_detail.birthday );
        data.active_end = dateInputToSql(data.active_end);
        data.contract_detail.default_project_id = data.contract_detail.project ? data.contract_detail.project.id : null;
        data.contract_detail.default_project_work_id = data.contract_detail.work ? data.contract_detail.work.id : null;
        data.contract_detail.default_hour_type_id = data.contract_detail.hour_type ? data.contract_detail.hour_type.id : null;
        data.contract_detail.ta_time_limit = data.contract_detail.ta_time_limit ? Math.round(data.contract_detail.ta_time_limit): null;
        data.contract_detail.ta_lunch_break = data.contract_detail.ta_lunch_break ? Math.round(data.contract_detail.ta_lunch_break) : null;
        data.contract_detail.ta_min_time_accept_lunch_break = data.contract_detail.ta_min_time_accept_lunch_break ? Math.round(data.contract_detail.ta_min_time_accept_lunch_break) : null;

        if (!data.contract_detail.salary_hourly || data.contract_detail.salary_hourly === "-") {
            data.contract_detail.salary_hourly = "0";
            data.contract_detail.salary = "0";
        }

        //console.log( "Saving...", data );

        this.setState({ loading: true, error: false });
        api({
            method: 'post',
            url: 'usermanagement/user/save',
            data: data,
        }).then(( response ) => {

            //console.log( 'saved', response );

            if(typeof this.props.onSave === 'function')
                this.props.onSave();

            if (keyExists(response, "netvisorResponse.ResponseStatus.Status") && response.netvisorResponse.ResponseStatus.Status[0] == 'FAILED') {
                console.error(response.netvisorResponse.ResponseStatus.Status);
                window.emitter.emit('popper', {
                    type: 'info',
                    content: <strong>Virhe henkilön viennissä Netvisoriin: {response.netvisorResponse.ResponseStatus.Status[1]}</strong>, // TODO
                    time: 8000
                });            }

            this.props.onClose();

        }).catch((error) => {
            //errorPopper( error, 'Käyttäjän tietojen tallennuksessa tapahtui virhe' );
            this.setState({
                loading: false,
                error: error.response,
            });
        });
    }

    handleDataChange( name, value )
    {
        let data = { ...this.state.data };
        const setValue = ( obj, propertyPath, value ) => {
            let properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(".");
            if (properties.length > 1) {
                if( !obj.hasOwnProperty( properties[0] ) || typeof obj[ properties[0] ] !== "object" ) 
                    obj[ properties[0] ] = {};

                return setValue( obj[ properties[0] ], properties.slice(1), value );
            }
            else 
            {
                obj[ properties[0] ] = value;
                return true;
            }
        };
        setValue( data, name, value );
        
        switch( name )
        {
            case "contract_detail.salary":
            case "contract_detail.salary_unit":
            case "contract_detail.salary_hourly_divider":
                data.contract_detail.salary_hourly = this.reCalcSalaryHourly(data);
                // if( data.contract_detail.salary_unit !== "hourly" )
                // {
                //     if( data.contract_detail.salary && data.contract_detail.salary_hourly_divider > 0 )
                //         data.contract_detail.salary_hourly = Math.round( data.contract_detail.salary / data.contract_detail.salary_hourly_divider * 100 ) / 100;
                //     else 
                //         data.contract_detail.salary_hourly = '-';
                // }
                // else {
                //     // switch (data.contract_detail.salary_unit) {
                //     //     case "w":
                //     // }
                //     data.contract_detail.salary_hourly = data.contract_detail.salary;
                // }
                break;

            case "contract_detail.regular_worktime":
                if ( data.contract_detail.regular_worktime ==false) {
                    data.contract_detail.salary= data.contract_detail.salary_hourly ;
                }
                break;

            case "contract_detail.hours_weekly":
            case "contract_detail.days_weekly":
                
                if( data.contract_detail.hours_weekly && data.contract_detail.days_weekly > 0 )
                    data.contract_detail.hours_daily = Math.round( data.contract_detail.hours_weekly / data.contract_detail.days_weekly * 100 ) / 100;
                else 
                    data.contract_detail.hours_daily = '-';
                   

                data.contract_detail.salary_hourly = this.reCalcSalaryHourly(data);
                break;

            case "type":
                if( value === 'limited')
                {
                    data.is_admin = false;
                    data.is_limited = true;
                }
                else if( value === 'normal')
                {
                    data.is_admin = false;
                    data.is_limited = false;
                }
                else if( value === 'admin')
                {
                    data.is_admin = true;
                    data.is_limited = false;
                }
                break;
            case "contract_detail.hour_entry_approval_required":
                if (value === false) {
                    data.contract_detail.hour_entry_approver = null;
                }
                break;
            case "contract_detail.regular_worktime":
                data.contract_detail.regular_worktime = value;
                break;

            default:
                break;
        }

        this.setState({ 
            data: data,
            unsavedChanges: true,
        });
    }

    reCalcSalaryHourly(data) {
        if (data.contract_detail.salary) {
            var salary = data.contract_detail.salary;
            var  hourlySalary = salary;
            
            switch (data.contract_detail.salary_unit){
                case "hourly":
                    hourlySalary =salary;
                    break;

                case "daily":
                    if ( data.contract_detail.hours_daily>0) {
                        hourlySalary = salary / data.contract_detail.hours_daily;
                    }
                    break;

                case "weekly":
                    if ( data.contract_detail.hours_weekly>0) {
                        hourlySalary = salary / data.contract_detail.hours_weekly;
                    }
                    break;

                case "monthly":
                    if ( data.contract_detail.hours_weekly>0) {
                    
                        var divider = 21 * data.contract_detail.hours_daily
                        hourlySalary = salary / divider;
                    }
                    break;
            }         
            hourlySalary =Math.round( hourlySalary  * 100 ) / 100;
        }
        else {
            hourlySalary ="-";
        }
        return hourlySalary;

    }

    validateMsg()
    {
        if( this.state.loading )
            return `${tr('loading')}...`;

        if( this.state.error )
        {
            const msg = keyExists( this.state.error, 'data.message', true );
            return (
                <span style={{ color: "red" }}>
                    { msg && msg }
                    { !msg && `${tr('error')} (${this.state.error.status})` } 
                </span>
            );
        }

        // Get validator result
        let validator = this.validator.getStatusAll('array');
        validator = [ ...new Set( validator )]; // Remove dublicates
        if( validator && validator.length > 0 )
            return <span style={{color: "var(--clr-warning-main)"}}>{tr('check_following_fields')}: {validator.join(", ")}</span>;

        if( this.state.unsavedChanges )
            return tr('save_changes');

        let savedTime = "";
        if( this.state.data.updated_at )
        {
            const time = moment( this.state.data.updated_at );
            const offset = moment().diff( time, 'days', true );
            if( offset > 2 )
                savedTime = `(${time.locale(currentLang()).format('LLL')})`; 
            else if ( offset > 1 )
                savedTime = `(${tr('yesterday')} ${time.locale(currentLang()).format('LT')})`; 
            else 
                savedTime = `(${tr('today')} ${time.locale(currentLang()).format('LT')})`; 
        }

        return `${tr('saved')} ` + savedTime;
    }

    renderBody()
    {
        return (
            <div>
                <ApTabs fullWidth>
                    <ApTab icon="user" label={ tr('user_account') }>

                        <Account 
                            loading={ this.state.loading }
                            data={ this.state.data }
                            onChange={ this.handleDataChange }
                            validator={ this.validator }
                        />

                    </ApTab>
                    <ApTab icon="address-book" label={ tr('basics') }>

                        <PersonInfo
                            loading={ this.state.loading }
                            data={ this.state.data }
                            onChange={ this.handleDataChange }
                            validator={ this.validator }
                            languageOptions={this.state.languageOptions}
                        />

                    </ApTab>
                    <ApTab icon="briefcase" label={ tr('employment_relationship') }>

                        <ContractDetail
                            loading={ this.state.loading }
                            data={ this.state.data }
                            onChange={ this.handleDataChange }
                            validator={this.validator}
                            roles={this.state.roles}
                            collectiveAgreements={this.state.collectiveAgreements}
                           
                        />
                        

                    </ApTab>             
                    <ApTab icon="key" label={ tr('permissions') }>
                
                        <Permissions
                            loading={ this.state.loading }
                            data={ this.state.data }
                            onChange={ this.handleDataChange }
                            validator={ this.validator }
                        />

                    </ApTab>
                    {this.state.data.isNetvisorPayroll && hasPermissions(['timetracking.payroll']) &&
                        <ApTab icon="sync" label="Netvisor">

                            <UserNetvisorInfo
                                loading={this.state.loading}
                                data={this.state.data}
                                onChange={this.handleDataChange}
                                validator={this.validator}
                            />

                        </ApTab>
                    }
                </ApTabs>
               
            </div>
           
        );
    }

    onModalOpen()
    {
        this.getUserInfo();
        this.getLanguages();
        this.getRoles();
      
        
    }

    render()
    {
        return (
            <ApModal
                show={ this.props.show }
                onOpen={ this.onModalOpen }
                handleClose={ this.props.onClose }
                //closeFromBg
                header={ 
                    <div className="padding">
                        <h3>{ tr('edit_user') } {this.state.loading ? '' : keyExists(this.state.data, 'person_detail.full_name', true, null)}</h3>
                    </div>
                }
                body={ 
                    <div id="UserEditBody">
                        { this.renderBody() }
                       
                     
                    </div>
                }
                footer={
                    <div className="padding" id="UserEditFooter">
                        
                        <div className="validateMsg">
                            <SvgIcon icon="info-circle" type="solid" />
                            { this.validateMsg() }
                        </div>

                        <ApButton onClick={ this.props.onClose }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton className={"save" + (this.state.unsavedChanges? " highlight" : "")}
                            color="blue"
                            onClick={this.save}
                            disabled={!this.state.unsavedChanges || Boolean(this.validator.getStatusAll())}>
                            <SvgIcon icon="save" type="solid" />
                            { tr('save') }
                        </ApButton>

                    </div>
                }
               

            />
           
        )
       

    }

}

export default UserEdit;
