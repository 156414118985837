import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import './Payments.css';

import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import moment from 'moment';

import NewPaymentPost from 'modules/Projects/Payments/PaymentPosts/NewPaymentPost.js';
import PaymentPostEdit from 'modules/Projects/Payments/PaymentPosts/PaymentPostEdit.js';
import NewPayment from 'modules/Projects/Payments/NewPayment/NewPayment.js';
import NewPaymentPostOrder from 'modules/Storage/Orders/NewOrder/NewOrder.js';
//import NewPaymentPostOrder from './../Payments/NewOrder/NewPaymentPostOrder.js'; 

import { ApReactTable }     from 'common/ApReactTable/ApReactTable.js';
import { formatMoney, errorPopper, tr } from 'services/Helpers/Helpers.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';

class Payments extends React.Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			loading: true,
			payment: null,
			posts: [],
			postsFormatted: [],

			allContacts: [],
			contacts: null,
			customer: null, //Asiakkaiden tiedot - crm_companies taulukkoon viite
			customer_contact: null, //Kontaktihenkilö maksuposteihin
			customer_ref: '', //Asiakkaan viite
			project_manager: null,
			total_sum: 0, //Summa
			paydays: -1, //Maksuaika
			pay_days: 0,
			unallocated_sum: null, //Summa mikä koostuu kokonaissummasta josta erotettu jo allokoidut osiot
			allocated_perc: '0.0%',
			selected_id: null,
			billing_permission: null,

			original_data: {},
			show_save: false,
			pc: [],
			invalidSum: false,

			paymentPostEditShow: false,
			paymentPostDeleteShow: false,
			paymentPostBillingShow: false,
            newPaymentPostShow: false,
            NewPaymentPostData: [],
            newPaymentShow: false,
            newOrderShow: false,
            newOrderData: [],

            selectedItems: [],
            selectedWorks: [],
            selectedExpenses: [],

			total_sum_editable: false
		}
		autoBind(this);
	}

	componentDidMount()
	{
		this.getRelated();
		this.getCustomerInfo();
		
		this.setOriginalData();
	}

	setupButtons(row, c) {
		//Määrittele toiminnot
        let actions = [];
		actions.push({
			label: tr('edit'),
			icon: "edit",
			action: ( id, closeFunc ) => {
				this.setState({
					paymentPostEditShow: true,
					selected_id: row,
				});
				closeFunc();
				},
			}, {
			label: tr('delete'),
			icon: "trash-alt",
			action: ( id, closeFunc ) => {
				this.setState({
					paymentPostDeleteShow: true,
					selected_id: row,
				})
				closeFunc();
			}
		});

		return <ApDropdown
			actions={ actions }
			dropdownPos='relative'
		/>
	}
	

	getRelated() {
		if (!this.state.pc.length) {
			return api({
				method: 'get',
				url: 'storage/order/new/related',
				errorPopper: tr('get_error'),
			}).then( ( response ) => {
				this.setState({
					pc: response.pc,
				});
				this.getPayments();
			});
		}
		else {
			this.getPayments();
		}
	}

	getPayments() {

        const pId = this.props.data.projectId;

		let totalSumIsEditable=false;

        api({
            method: 'get',
            url: `projects/payments/${pId}`,
            errorPopper: tr('get_error'),
        }).then((response) => {
			if (response === 0) { //Jos projektilla ei ole maksuja/maksuposteja
                this.setState({ loading: false });
			} else { //Jos projektilla on maksuja/maksuposteja
                //Laita raha oikeaan formaattiin.
				let totSum = this.props.data.total_sum;
				if (this.props.data.total_sum==0) {
					totalSumIsEditable=true;
					totSum = response.sum;
				}

				this.setState({
					payment: response,
					paydays: response.paydays,
					paymentCondition: this.state.pc.find(pc => pc.days === response.paydays),
					pay_days: response.paydays,
					customer_contact: response.cust_person, 
                    customer_ref: response.cust_ref, 
                    total_sum: totSum,
					total_sum_editable: totalSumIsEditable
				});

				//Käsittele maksupostit
				if (response.posts.length == 0) { //Jos maksuposteja ei ole luotu
					this.setState ({ 
						unallocated_sum: this.formatToNum(totSum),
						postsFormatted: [],
					});
				} else {
					this.setState({ posts: response.posts });
					this.calculateUnallocated(response.posts, totSum);
				}
				this.setState({ loading: false });
				this.setOriginalData();
            }
		}).catch((error) => {
			console.error(error);
            this.setState({
                loading: false
            });
        })
		
   	}

	getColumns() {
		let columns = [ 
			{
				id:         "id",
				Header:     "ID",
				accessor:   "id",
				showInitially: false,
				customizable: true,
				width: 100,
			},   
			{
				id:         "description",
				Header:     tr('description'),
				accessor:   "description",
				showInitially: true,
			},
			{
				id:         "payment_amount",
				Header:     tr('amount'),
				accessor:   "payment_amount",
				showInitially: true,
				Cell: props => {
					return <div className="text-right">
						{ formatMoney( props.value ) } { this.props.data.currencySign }
					</div>
				}
			},
			{
				id:         "percentage",
				Header:     tr('percentages'),
				accessor:   "percentage",
				showInitially: true,
				width: 130,
				Cell: props => {
					return <div className="text-right">
						{ props.value.toFixed(2) } % 
					</div>
				}
			},
			{
				id:         "billing_date",
				Header:     tr('billing_date'),
				accessor: (row) => {
					let date = moment(row.billing_date, "DD.MM.YYYY").format("YYYY-MM-DD");
					return date
				},
				Cell: props => {
					return props.original.billing_date
					
				},
				showInitially: true,
				width: 150,
			},
			{
				id:         "payment_date",
				Header:     tr('payment_date'),
				accessor: (row) => {
					let date = moment(row.payment_date, "DD.MM.YYYY").format("YYYY-MM-DD");
					return date
				},
				Cell: props => {
					return props.original.payment_date

				},
				showInitially: true,
				width: 150,
			},
			{
				Header: tr('billing_permission'),
				id: 'billing_permission',
				showInitially: true,
				Cell: ({ row }) => { return this.renderBillingBtn(row) },
				accessor: (row) => {
					let boolean = row.billing_permission == "Kyllä" ? true : false;//TODO: fix billing_permission to true or false...
					return boolean
				},
			},
			{
				Header: tr('shipment'),
				width: 250,
				id: 'send-button',
				showInitially: true,
				accessor: (row) => {

					let orderNumber = Number(row.order_number) || 0;
					return orderNumber;
				},
				Cell: ({ row }) => {
					return <div>
						{row._original.order_id ? this.renderOrderExistsBtn(row) :
							row._original.billing_permission === 'Kyllä'
								? this.renderCreateOrderBtn(row, true)
								: this.renderCreateOrderBtn(row, false)
						}
						
					</div>

				},
				
			},
			
			{
				Header: tr('functions'),
				id: 'functions_button',
				showInitially: true,
				sortable: false,
				Cell: ({row}) => { return this.setupButtons(row._original.id, row._original) }
			}

		];
		return columns;
	}

	getCustomerInfo(id) {
        //Yritä hakea asiakkaan tiedot, tarvitaan jotta maksupostit voidaan lähettää eteenpäin
        const projectId = this.props.data.projectId;
         api({
            method: 'get',
			url: `crm/extranet/getCustomerAndContact/${projectId}`
            }).then((response) => {
				this.setState({
					contacts: response[0], 
					customer: response[1],
					allContacts: response[2]
				});
            }).catch((error) => {
                console.warn(error);
        });
    
    }


	//Functions
	calculateUnallocated(posts, totalSum) { //Laskee allokoimattoman summan maksusta
		this.setState({postsFormatted: []}); //Alusta tyhjänä

		let projectPaymentsSum = 0;
		let postsFormat = [];
		let formatted;
		if (posts.length > 0 && totalSum) { 
		 	posts.forEach(element => {
				projectPaymentsSum += this.formatToNum(element.payment_amount, 'num');
				//Laita maksupostit oikeaan formaattiin jotta voidaan näyttää.
				formatted = element;
				formatted.payment_amount = element.payment_amount;
				formatted.percentage = (element.payment_amount/totalSum)*100;
				/*if (formatted.percentage != null) {
					formatted.percentage = String(`${parseFloat(element.percentage).toFixed(1)}%`);
				} else {
					formatted.percentage = tr('not_defined');
				}*/
				
				///Laita SQL päivämäärät string formaattiin.
				formatted.billing_date = this.formatTime(formatted.billing_date);
				formatted.payment_date = this.formatTime(formatted.payment_date);

				formatted.billing_permission = formatted.billing_permission == true ? tr('yes') : tr('no');
				postsFormat.push(formatted);
		});
		const totSum = this.formatToNum(totalSum);

		let percentage = ((projectPaymentsSum/totSum)*100).toFixed(1) + ' %';

		if (percentage == 'NaN %') {
			percentage = '0.0%';
		}
		 
		const unalloc_sum = totSum - projectPaymentsSum;
 
		this.setState ({
			allocated_perc: percentage,
			unallocated_sum: unalloc_sum,
			postsFormatted: postsFormat,
		});
		 
		}
		
	 }

	 //Formatting
	 formatTime(toFormat) {
		//Formatoi ajan SQL-datetime muodosta muotoon dd.mm.yyyy
		if (toFormat != null) {
			var chunks = toFormat.split("-");
			var sYear = chunks[0];
			var sMonth = chunks[1].toString();
			var chunks2 = chunks[2].split(" ");
			var sDay = chunks2[0];
			toFormat = `${sDay}.${sMonth}.${sYear}`;
			return toFormat;
		}
		return toFormat = tr('not_defined');
	}

	formatToNum(input, type) { //Palauttaa defaulttina string arvon, jos tyyppi mainittu num, palauttaa numeroarvon.
        let retVal = String(input);
        retVal = parseFloat(retVal.replace(/\s/g, '').replace(',', '.')).toFixed(2)

        if (type == 'num') {
            return parseFloat(retVal);
        }
        return retVal; 
	}

	handleChange(e, obj = null, type = null, isComposite = false)
	{
		if (obj != null) {
			if (!isComposite) {
				this.setState({ pay_days:obj.days, [type]: obj.name});
			} else {
				const name = `${obj.first_name} ${obj.last_name}`;
				this.setState({ [type]: name});
			}
			this.setState({show_save: true});
		} else {
			const property = e.target.name;
			//console.log(`HandleChange: ${property} - ${e.target.value}`);
			this.setState({
				[property]: e.target.value,
			});

			let changed = false;

			for (const [key] of Object.entries(this.state.original_data)) {
				if (key != property) {
					if (this.state[key] != this.state.original_data[key]) {
						changed = true;
						break;
					}
				} else {
					if (this.state.original_data[property] != e.target.value) {
						changed = true;
					} 
				}	
			}
			
			changed ? this.setState({show_save: true}) : this.setState({show_save: false});

			if (property == 'total_sum') {
				this.handlePaymentSumEdit(e.target.value);
			}
		}
	}

	setOriginalData() {
		this.setState({
			original_data: {
				paydays: this.state.paydays,
				customer_contact: this.state.customer_contact,
				customer_ref: this.state.customer_ref,
				total_sum: this.state.total_sum,
			}
		});
	}

	handlePaymentSumEdit(newSum) { 
        let originalSum = this.formatToNum(this.state.original_data['total_sum']);
        
        //Formatoi summa oikein jottei väliviivat haittaa
        newSum = this.formatToNum(newSum);
        let unAllocated = parseFloat(this.state.unallocated_sum);
        const minValue = originalSum - unAllocated; //Minimi jonka maksun määrä voi olla

        if (isNaN(newSum)) {
            this.setState({ 
                invalidSum: true,
                sum: '',
            });
        } else {
            newSum < minValue ? this.setState({ invalidSum: true })
            : this.setState({ invalidSum: false });   
			this.setState({ sum: `${newSum}`});
		}

    }

	handleSavedChanges() {
		this.getPayments();
		this.render();
	}

	handleSaveAndClose() {
		this.setState({ 
			newPaymentShow: false,
			newPaymentPostShow: false,
			paymentPostEditShow: false,
		});

		this.getPayments();
	}
	
	
	handleDelete(index) {
		this.setState({ paymentPostEditShow: false });
		this.getPayments();

		//Poista indeksi, ongelma esiintyy muuten siinä että 
		//viimeinen maksuposti jää kummittelemaan.
		let postsFormat = this.state.postsFormatted;
		if (this.state.posts.length > 0) {
			const element = postsFormat.findIndex(p => p.id == index);
			postsFormat.splice(element, 1);
		} else { postsFormat = []; }
		
		this.setState({ postsFormatted: postsFormat });
		this.render();
	}
	
	//Handlers
	handlePaymentPostEdit(id) {
        this.setState({
            paymentPostEditShow: true,
            selected_id: id,
        });
	}

	handleOrderClick(orderId) {
        //Tämän funktion tulee hakea orderIdn perusteella storage_orders taulukosta, ID, jonka jälkeen ohjaa tälle myyntitilaukselle

        return api({
            method: 'post',
            url: 'projects/payments/paymentPosts/findOrder',
            data: { id: orderId },
        }).then( ( response ) => {
            if (response !== -1) {
                const orderNum = response.number;
                window.emitter.emit( 'goTo', {pathname: `../../../storage/order/${orderNum}`});
            }
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
        });

    }

	paymentDataParse(id) {
		//Varmista että kaikki tarvittava data annettu
		const defCustomer = this.state.customer;
		let data = {};
		data.related_id = defCustomer.id;
	
		const payment = this.state.payment;
		data.reference_your = payment.cust_ref;
				
			api({
				method: 'get',
				url: `projects/payments/paymentPost/${id}`,
			}).then((response) => {
				data.payment_post_id = id; //Maksupostin ID
				data.date = response.billing_date;
				data.due_date = response.payment_date;
				data.details = response.details;
				data.payment_amount = response.payment_amount;

				//data.percentage = response.percentage;
				data.percentage = ( this.state.total_sum / response.payment_amount )*100;

				data.description = response.description;
				data.project_code = this.props.data.project_code;
				data.paydays = this.state.paydays;
				data.cost_center = this.props.data.project.cost_center ? this.props.data.project.cost_center.id : null;
				data.project = this.props.data.project;
		
				this.setState({
					newOrderData: data,
					newOrderShow: true,
				});
			})
	
		//Tässä vaiheessa näytetään formi josta tarkastetaan tiedot ja lisätään puuttuvat.
	}
	
	sendPayment(id) {
		if (this.state.customer != null) { //Jos asiakas on määritelty
			this.paymentDataParse(id);
		} else {
			window.emitter.emit('popper', {
				type: 'danger',
				content: <strong>{ tr('customer_not_yet_defined') }</strong>,
			});
		}
	}

	createPayment() {

		let pc = null;
		let data = {};
		
		data.projId = this.props.data.projectId;
		data.name = this.props.data.project.name;
		data.project_code = this.props.data.project_code;
		data.sum = this.state.total_sum;
		data.customer_reference = this.state.customer_ref;

		if (this.state.contacts && this.state.contacts.length > 0) {
			data.customer_person = `${this.state.contacts[0].first_name} ${this.state.contacts[0].last_name}`; //this.state.customer_contact; 
		} else { data.customer_person = null; }

		data.project_manager = this.state.project_manager;
		data.paydays_id = this.state.customer ? this.state.customer.default_payment_condition_id:null;

		//console.log('saving...', data );
		
		this.setState({loading: true });
		api({
			method: 'post',
			url: 'projects/payments/payments/new',
			data: data,
		}).then((response) => {
			window.emitter.emit('popper', {
				type: 'success',
				content: <strong>{ tr('payment_created') }</strong>,
			});
			
			//Hae sitten uusi maksu
			this.getPayments();

			this.setState({ loading: false });
		}).catch((error) => {
			console.error(error);
			errorPopper( error, tr('save_error') );
			this.setState({ loading: false });
		});
		
		
		
	}

	deletePost() {
		const data = {
			post_id: this.state.selected_id,
		}

		api({
            method: 'post',
            url: 'projects/payments/paymentPosts/delete',
            data: data,
        }).then((response) => {
            if (response != 0) {
				this.setState({paymentPostDeleteShow: false});
				this.getRelated();
				this.getPayments();
            } else {
				console.error(`Maksupostia ei löytynyt IDllä ${this.state.selected_id}`);
			}
        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('delete_error') );
            this.setState({ loading: false });
        });
	}

	updateBillingPermission() {
		const bp = this.state.billing_permission == 'Kyllä' ? false : true;

		const data = {
			billing_permission: bp,
			id: this.state.selected_id,
		}

		api({
            method: 'post',
            url: 'projects/payments/paymentPosts/updateBilling',
            data: data,
        }).then((response) => {
            if (response != 0) {
				this.getRelated();
				this.setState({selected_id: null});
            } else {
				console.error(`Maksupostia ei löytynyt IDllä ${this.state.selected_id}`);
			}

			this.getPayments();
        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('save_error') );
            this.setState({ loading: false });
        });
	}

	savePaymentChanges() {
		let data = {};
		
        data.projId = this.state.payment.project_id;
        data.paymentId = this.state.payment.id;
        data.project_code = this.props.data.project_code;
        
        //Summa pitää formatoida 
        data.sum = this.formatToNum(this.state.total_sum);
        data.unallocated_sum = this.formatToNum(this.state.unallocated_sum);

        data.paydays = this.state.pay_days>0?this.state.pay_days:parseInt(this.state.paydays);
        data.customer_reference = this.state.customer_ref;
        data.customer_person = this.state.customer_contact;
        data.projectManager = this.state.project_manager;
		
		this.setState({loading: true });
        return api({
            method: 'post',
            url: 'projects/payments/payments/edit',
            data: data,
        }).then((response) => {
            if (response !== 2) { //Jos maksuposteja löytyy
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>{ tr('saved') }</strong>,
                });

				this.getRelated();
				this.setState({show_save: false, loading: false});
				this.getPayments();
            }
            
        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('save_error') );
            this.setState({ loading: false });
        });
		
	}

	renderOrderExistsBtn(row) {
		return <ApButton 
			color="green"
			onClick={ () => {
				this.handleOrderClick(row._original.order_id);
			} }
			>
				<SvgIcon icon="info-circle" type="solid" />
				{ tr('sales_order') } #{row._original.order_number}
				</ApButton>
	}

	renderCreateOrderBtn(row, active) {
		return <div>
			<ApButton 
				color="blue"
				onClick={ () => {
					//console.log(`ROW ID [renderCreateOrderBtn]: ${row.id}`);
					this.sendPayment(row._original.id);
				} }
				disabled={ !active }
				>
				<SvgIcon icon="paper-plane" type="solid" />
				{ tr('create_sales_order') }
			</ApButton>
		</div>
	}

	renderBillingBtn(row) {

		const orig = row._original;
		const id = `billing_permission_${row._original.id}`;
		
		return <ApSwitch
			id={ id }
			on={ orig.billing_permission == 'Kyllä' ? true : false }
			onChange={ () => { 
				this.setState({
					selected_id: row._original.id,
					billing_permission: orig.billing_permission,
					paymentPostBillingShow: true
				});
				} }
			disabled={ orig.order_id ? true : false}
		/>
	}
	

	render() {
		let view = this.state.payment != null ? this.renderPaymentView() : this.renderNoPaymentView();
		let buttons = this.renderButtons();
		return <div>
			{view}
			{buttons}
		</div>
		
	}

	renderButtons() {

		const data={
			total_sum: this.state.total_sum,
			unalloc: this.state.unallocated_sum,
			contacts: this.state.contacts,
			payment_id: this.state.payment != null ? this.state.payment_id : '',
			payment_post_id: this.state.selected_id ? this.state.selected_id : null,
			currency_sign: this.props.data.currencySign ? this.props.data.currencySign: '',
			paydays: this.state.paydays ? this.state.paydays : null
		}
		const pData={
			project_code: this.props.data.project_code,
			project_id: this.props.data.projectId,
			project: this.props.data.project,

			unalloc: this.state.unallocated_sum,
			posts: this.state.posts,
			project_id: this.props.data.projectId
		}

		return <div>

			{/*Maksu*/}
			<NewPayment 
				id={ this.props.projectId }
				show={ this.state.newPaymentShow }
				onClose={() => {this.setState({newPaymentShow: false})}}
				onSave={ () => { this.handleSaveAndClose() } }
				data={pData}
            />
			{/*Maksupostit*/}
			<PaymentPostEdit
                show= { this.state.paymentPostEditShow }
				onClose={ () => { this.setState({ paymentPostEditShow: false }) } }
				onDelete={ (index) => { this.handleDelete(index) } }
				onSave={ () => { this.handleSaveAndClose() } }
				data={data}
        	/>
			<NewPaymentPost
                show= { this.state.newPaymentPostShow }
                onClose={ () => { this.setState({ newPaymentPostShow: false }) } }
				onSave={ () => { this.handleSaveAndClose() } }
				data={data}
				payment_id={this.state.payment != null ? this.state.payment.id : ''}
            />
            <NewPaymentPostOrder
				show={ this.state.newOrderShow }
				onClose={ () => { this.setState({ newOrderShow: false }) } }
				project={this.state.newOrderData.project}
				date= {this.state.newOrderData.date}
				due_date= {this.state.newOrderData.due_date}
				customerId={this.state.newOrderData.related_id}
				paymentCondition={this.state.paymentCondition}
				type= 'no_delivery'
				payment_post_id={this.state.newOrderData.payment_post_id}
				reference_your={this.state.customer_ref}
            />

			<ApConfirm
				show= { this.state.paymentPostDeleteShow }
				header={ tr('confirm_payment_post_delete') }
				body={ tr('confirm_payment_post_delete_info') }
				onConfirm={() => {this.deletePost()}}
				onClose={ () => { this.setState({ paymentPostDeleteShow: false })} }
			/>

			<ApConfirm
				show= { this.state.paymentPostBillingShow }
				header={ tr('confirm_billing_permission_change') }
				body={ tr('confirm_billing_permission_change_info') }
				onConfirm={() => {this.updateBillingPermission()}}
				onClose={ () => { this.setState({ paymentPostBillingShow: false })} }
			/>
			
		</div>
	}

	renderPaymentView() {
		return <div className="header">
			<div id="projectPayment">
				<div className="apBox">
	
					<div className="apBoxHeader">
						<h1>{ tr('payments') }</h1>
						<p>{ tr('payments_info') }</p>
					</div>
				</div>
	
				<div className="apColumn w50">
				<ApInputStack key="pSum" gap="0" className="valueNumber">
					<ApAddon width="200px" noRightBorder>
					{ tr('sum') }
						</ApAddon>
					<ApInput
						className="text-left"
						type="number"
						width='200'
						id="total_sum"
						name="total_sum"
						align='left'
					 	unit= '€'
						value={ this.state.total_sum }
						loading={ this.state.loading }
						disabled={ this.state.loading  || !this.state.total_sum_editable}
						onChange={ this.handleChange } 
		
					/>
				</ApInputStack>
				<ApInputStack key="custPerson" gap="0">
					<ApAddon width="200px" noRightBorder>
					{ tr('customer_contact_person') }
						</ApAddon>
					<ApSelect
						label={ tr('contact_person') }
						value={ this.state.customer_contact ? this.state.customer_contact : tr('not_defined')  }
						optionRenderer={ ( item ) => {
							return <div>
								{ `${item.first_name} ${item.last_name}` }
							</div>
						}}
						onChange={ ( item ) => this.handleChange(null, item, 'customer_contact', true)  }
						objKeyId="id"
						objKeySearchable={["first_name", "last_name"]}
						objKeyValue="name"
						options={ this.state.allContacts }
						width='200'
						loading={ this.state.loading }
						disabled={ this.state.loading }
					/>
				</ApInputStack>
				</div>

				<div className="apColumn w50">
				<ApInputStack gap="0">
					<ApAddon width="200px" noRightBorder>
					{ tr('payment_period') } ({tr('day_abbr')})
					</ApAddon>
					<ApSelect
						label={ tr('payment_term') }
							value={this.state.paymentCondition? this.state.paymentCondition.name : "" }
						optionRenderer={ ( item ) => {
							return <div>
								{ `${item.name}` }
							</div>
						}}
						onChange={(item) => {
							this.handleChange(null, item, 'paydays');//koska teppo
							this.setState({ paymentCondition: item });
						}}
						objKeyId="id"
						objKeySearchable="name"
						objKeyValue="name"
						clearable
						options={ this.state.pc }
						loading={ this.state.loading }
						disabled={ this.state.loading }
					/>
				</ApInputStack>
				<ApInputStack gap="0">
					<ApAddon width="200px" noRightBorder>
					{ tr('customer_reference') }
					</ApAddon>
					<ApInput
						className="text-left"
						type="text"
						id="customer_ref"
						name="customer_ref"
						align='left'
						value = {this.state.customer_ref ? this.state.customer_ref : null}
						loading={ this.state.loading }
						disabled={ this.state.loading }
						onChange={ this.handleChange }
					/>
				</ApInputStack>
				</div>
				
				{this.state.show_save && !this.state.invalidSum ? <ApButton
					color="blue"
					onClick={ () => {this.savePaymentChanges()}}>
						<SvgIcon icon="exclamation-circle" type="solid" />
						{ tr('save') }
					</ApButton>
				:	''}

				{this.state.invalidSum ? <p style={{ color: 'red' }}>{ tr('payment_sum_error') }</p> : ''}

				<div>
					<h2>{ tr('payment_posts') }</h2>
	
				<div className="apColumn w50">
				<ApInputStack key="allocPerc" gap="0">
					<ApAddon width="200px" noRightBorder>
					{ tr('allocated_percentage') }
						</ApAddon>
					<ApInput
						className="text-left"
						type="text"
						width='200'
						id="alloc_perc"
						name="allocated_perc"
						align='left'
						value={ this.state.allocated_perc ? this.state.allocated_perc : tr('not_defined') }
						loading={ this.state.loading }
						disabled={true}
					/>
				</ApInputStack>
				</div>

				<div className="apColumn w50">
				<ApInputStack gap="0">
					<ApAddon width="200px" noRightBorder>
					{ tr('undefined_sum') }
					</ApAddon>
					<ApInput
						className="text-left"
						type="text"
						id="undefined_sum"
						name="undefined_sum"
						align='left'
						value = {this.state.unallocated_sum != null
							? formatMoney(this.state.unallocated_sum) + this.props.data.currencySign 
							: tr('not_defined')}
						loading={ this.state.loading }
						disabled={true}
					/>
				</ApInputStack>
				</div>
	
				<ApReactTable
					rememberId="paymentsTable"
					loading={ this.state.loading }
					data={this.state.postsFormatted}
					filterable                        
					columns={ this.getColumns() }
					minRows={ 4 }
					noOverflow={true}
				/>
				
				</div>
				<div className="padding" align='right'>
					<ApButton
						color="blue"
						onClick={ () => { this.setState({ newPaymentPostShow: true }) } }
						>
						<SvgIcon icon="plus" type="solid" />
						{ tr('add_payment_post') }
					</ApButton>				
				</div>
			</div>
			</div>
	}

	renderNoPaymentView() {
		return <div className="header">
		<div id="projectPayment">
			<div className="apBox">
				<div className="apBoxHeader">
					<h1>{ tr('payment_posts') }</h1>
					<p>{ tr('no_payment_defined_project') }</p>
				</div>
			</div>

			<div className="padding" align='right'>
				<ApButton
					color="green"
					onClick={() => {this.createPayment()}}>
					<SvgIcon icon="plus" type="solid" />
					{ tr('create_payment') }
				</ApButton>
			</div>
			
		</div>
		</div>
	}
}
export default Payments;