import React, {useState, useEffect} from 'react'
import api from 'services/Api/Api.js'
import { errorPopper, tr }  from 'services/Helpers/Helpers.js'
import { ApReactTable } from 'common/ApReactTable/ApReactTable.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js'
import ApTooltip from 'common/ApTooltip/ApTooltip';

import './Collections.css'

const CollectionsList = (props) => {
    const [items, setItems] = useState(null)
    const [loading, setLoading] = useState(false)

    const [returnCollection, setReturnCollection] = useState(null)
    const [returnIds, setReturnIds] = useState(null)
    const [showReturn, setShowReturn] = useState(false)

    useEffect(() => {
        getItems()
    }, [])

    useEffect(() => {
        getItems()
    }, [props.refetch])

    const removeCollection = async (id) => {
        try {
            await api({
                method: 'post',
                url: 'sales/collections/delete/id',
                data: {id}
            })

            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('deleted') }</strong>,
            });
            setLoading(false)
            getItems()
        } catch (e) {
            setLoading(false)
            errorPopper(e, tr('delete_error'));
        }
    }

    const getItems = async () => {
        setLoading(true)
        try {
            const response = await api({
                method: 'post',
                url: 'sales/collections/get',
                data: {
                    type: props.type
                }
            })
            setItems(response)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            errorPopper(e, tr('get_error'));
        }
    }

    const getReturnCollectionData = (id, locationId, destinationId) => {
        setReturnIds(
            {
                sending_storage_id: destinationId,
                receiving_storage_id: locationId
            }
        )

        fetchCollection(id)
        setShowReturn(true)
    }

    const submitCollectionReturn = () => {
        //TODO
        setReturnIds(null)
        setReturnCollection(null)
        setShowReturn(false)
    }


    const fetchCollection = (id) => {
        setLoading(true)
        api({
            method: 'post',
            url: 'sales/collections/get/id',
            data: {
                id: id,
                components: true
            }
        }).then((response) => {
            setReturnCollection(response)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            errorPopper(error, tr('get_error'));
        })
    }

    const tableColumns = () => {
        return [    
            {
				Header: 'Id',
				id: 'id',
				Cell: ({row}) => {
                    if (props.return) {
                        return <div>{row._original.id}</div>
                    }
                    
                    return (
					    <div className='collection-list-row collection-list-row-text' onClick={() => props.selectCollection(row._original.id)}
                        >{row._original.id}</div>
				    )
                }              
            },
            {
				Header: tr('description'),
				id: 'description',
				Cell: ({row}) => {
                    if (props.return) {
                        return <div>{row._original.description}</div>
                    }
                    
                    return (
					    <div className='collection-list-row collection-list-row-text' onClick={() => props.selectCollection(row._original.id)}
                        >{row._original.description}</div>
				    )
                }               
            },
            {
				Header: '',
				id: 'delete',
                width: 50,
                className: 'overflowableOnHover',
				Cell: ({row}) => {
                    if (props.return) {
                        return (
                            <div className='collection-list-row' 
                                onClick={() => getReturnCollectionData(row._original.id, row._original.storage_location_id, row._original.destination_id)}>
                                <ApTooltip position='left' text={ tr('return_collection') }>
                                    <SvgIcon icon="undo-alt" type="solid" style={{padding: '2px'}}/>
                                </ApTooltip>
                            </div>
                        )
                    }
                
                    return (
                        <div className='collection-list-row' 
                            onClick={() => removeCollection(row._original.id)}>
                            <ApTooltip position='left' text={ tr('delete_collection') }>
                                <SvgIcon icon="times-circle" type="solid" fill='red' style={{padding: '2px'}}/>
                            </ApTooltip>
                        </div>
                    )
                }              
            },
		]
    }

    return(
        <>
            <div className="padding">
                <ApReactTable
                loading={ loading }
                data={items ? items : []}                     
                columns={ tableColumns() }
                minRows={ 4 }
                />
            </div>

        </>
    )
}

export default CollectionsList