/* eslint-disable no-template-curly-in-string */
import React from 'react';
import autoBind from 'react-autobind';
import Measure from 'react-measure';

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApDropdown       from 'common/ApDropdown/ApDropdown.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect';
import FileUpload  from 'common/FileUpload/FileUpload.js';
import File        from 'common/File/File.js';
import ApButton    from 'common/ApButton/ApButton.js';
import SvgIcon     from 'common/SvgIcon/SvgIcon.js';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';
import api         from 'services/Api/Api.js';
import ApModal from 'common/ApModal/ApModal.js';
import { ApReactTable, colPreset } from 'common/ApReactTable/ApReactTable.js';

import { errorPopper
       , onlyNumber
       , tr } from 'services/Helpers/Helpers.js';

import { ApTabs
       , ApTab }       from 'common/ApTabs/ApTabs.js';
import ApTooltip   from 'common/ApTooltip/ApTooltip.js';

import Relations from 'modules/Storage/common/PoAndORelated/PoAndORelated.js';

import BankRelations from 'modules/Storage/common/BankRelated/BankRelated.js';

import { Collapse } from 'react-bootstrap';

import VatEdit from './VatEdit/VatEdit.js';

import './Settings.css';
import { connect } from 'react-redux';
import moment from 'moment';

const templateField = {
    current_date: tr('current_date_form'),
    // date: tr('order_created_at'),
    number: tr('so_number'),
    dispatch_number: tr('referral_number'),
    reference_number: tr('sales_order_reference_number'),
    customer_name: tr('orderer_name'),
    customer_taxnumber: tr('orderer_company_id'),
    receiver_name: tr('recipient_name'),
    receiver_address: tr('recipient_address'),
    receiver_zipcode: tr('recipient_postal_code'),
    receiver_city: tr('recipient_city'),
    receiver_country: tr('recipient_country'),
    receiver_contact_name: tr('recipient_contact_person'),
    project_code: tr('project_code'),
    project_name: tr('project_name'),
    delivery_term_name: tr('delivery_term'),
    delivery_method_name: tr('delivery_method'),
    payment_condition_name: tr('payment_term'),
    reference_our: tr('reference_number_our'),
    reference_your: tr('reference_number_your'),
    need_date: tr('day_of_need'),
    details: tr('additional_info'),
    price: tr('total_price'),
    due_date: tr('due_date'),
    date: tr('so_creation_date'),
    delivery_date: tr('delivery_date'),
    project_code: tr("project_code"),
    contact_person_name: tr("contact_name"),
    contact_person_email: tr("contact_email"),
    contact_person_phone: tr("contact_phone"),
    contact_person_title: tr("contact_title"),

    company_taxnumber: tr('seller_taxnumber'),
    company_name: tr('seller_name'),
    company_address: tr('seller_address'),
    company_zipcode: tr('seller_zipcode'),
    company_city: tr('seller_city'),
    company_country: tr('seller_country'),
};

const templateRowField = {
    row_name: tr('storage_component_name'),
    row_code: tr('storage_component_code'),
    row_count: tr('storage_component_amount'),
    row_unit: tr('storage_component_unit'),
    row_price: tr('storage_component_price_with_discount'),
    row_tprice: tr('storage_component_price_quantity_with_discount'),
    row_dispatch_ref: tr('reference_number_your_and_example'),
    row_vat: tr('vat'), 

    row_ucount: tr('storage_component_unsent_quantity'),
    row_location: tr('storage_location'),
    row_description: tr('storage_component_work_description'),

    row_discount_percent: tr('discount_percentage'),
    row_price_wd: tr('storage_component_price_without_discount'),
    row_tprice_wd: tr('storage_component_tprice_without_discount'),
    row_discount_amount: tr('storage_component_discount_amount'),
    row_discount_tamount: tr('storage_component_discount_tamount'), 

};

const templateBankAccountsRowField = {
    row_bankname: tr('name_of_beneficiarys_bank'),
    row_iban: tr('beneficiarys_IBAN'),
    row_bic: tr('beneficiarys_BIC'),
};
const locations = [
    {label: tr('storage')},
    {label: tr('project')},  
]

class Settings extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {

            nextNumber: null,
            nextNumberMin: null,
            numberLength: null,

            templateFile: {},
            templateDocxId: null,
            templatePdfExampleId: null,

            clFile: {},
            clDocxId: null,
            clPdfExampleId: null,

            dispatchNoteFile: {},
            dispatchNoteDocxId: null,
            dispatchNotePdfExampleId: null,

            billFile: {},
            billDocxId: null,
            billPdfExampleId: null,

            receiptFile: {},
            receiptDocxId: null,
            receiptPdfExampleId: null,

            infoShow: false,

            pc: [],
            dm: [],
            dt: [],
			bd: [],

            pc_enabled: false,
            pc_required: false,
            dm_enabled: false,
            dm_required: false,
            dt_enabled: false,
            dt_required: false,
            
            component_order_use_price_id: 1,
            component_min_profit_percent: null,
            work_order_use_price_id: 1,
            work_min_profit_percent: null,
            expense_order_use_price_id: 1,
            expense_min_profit_percent: null, 
            
            vats: [],
            taxGroups: [],
            taxCodes: [],
            taxcodeTranslations:{},
            vatEdit: {},

            languageId: null,
            languages: [],

            files: [],

            checkoutStorageType: 'storage',
            checkoutStorage: null,

            woocommerce_enabled: false,
            woocommerce_user_key: null,
            woocommerce_user_secret: null,
            woocommerce_token: null,
            woocommerce_webhook_secret: null,
            woocommerce_store_url: null,
            online_store_storage: null,
            woocommerce_use_storage_code: false,
            woocommerce_identifier: null,
            online_store_product_price: 1,
            online_store_profit_percent: null,

            showPassword: false,
            showSecret: false,

            loading: false,
            unsaved: false

        }

        autoBind(this);
    }

    componentDidMount()
    {
        this.getSettings();
        this.getLanguages();
    }

    getSettings()
    {
        this.setState({ loading: true });

        return api({
            method: 'get',
            url: 'storage/order/settings',
        }).then((response) => {
            //console.log('r', response );

            if( response.pdf_error )
            {
                window.emitter.emit('popper', {
                    type: 'danger',
                    content: <strong>{ tr('pdf_converter_not_working') }</strong>,
                });
            }

            let files = {};
            if (response.files) {
                response.files.forEach(file => {
                    files[file.language_id] = {
                        id: file.id,
                        templateDocxId: file.docx_id,
                        templatePdfExampleId: file.example_pdf_id,
                        billDocxId: file.bill_docx_id,
                        billPdfExampleId: file.bill_example_pdf_id,
                        receiptDocxId: file.receipt_docx_id,
                        receiptPdfExampleId: file.receipt_example_pdf_id,
                        clDocxId: file.cl_docx_id,
                        clPdfExampleId: file.cl_example_pdf_id,
                        dispatchNoteDocxId: file.dispatch_note_docx_id,
                        dispatchNotePdfExampleId: file.dispatch_note_example_pdf_id,
                        settingOrdersId: file.setting_orders_id
                    }
                });
            }
            let taxcodeTranslations = {};
            taxcodeTranslations.null = "";
            let taxCodes = [{ label: "", value: null }]
            response.tax_codes.map(code => {
                taxcodeTranslations[code.value] = tr(code.label);
                taxCodes.push({ label: tr(code.label), value: code.value });
            })

            this.setState({
                templateFile: {},
                clFile: {},
                dispatchNoteFile: {},

                files: files,

                nextNumber: response.next_number,
                nextNumberMin: response.next_number,
                numberLength: response.number_length,
                
                nextDispatchNumber: response.next_dispatch_number,
                nextDispatchNumberMin: response.next_dispatch_number,
                dispatchNumberLength: response.dispatch_number_length,

                pc: response.pc,
                dm: response.dm,
                dt: response.dt,
				bd: response.bd,

                pc_enabled:  response.pc_enabled,
                pc_required: response.pc_required,
                dm_enabled:  response.dm_enabled,
                dm_required: response.dm_required,
                dt_enabled:  response.dt_enabled,
                dt_required: response.dt_required,
                
                component_order_use_price_id: response.component_order_use_price_id,
                component_min_profit_percent: response.component_min_profit_percent,
                work_order_use_price_id: response.work_order_use_price_id,
                work_min_profit_percent: response.work_min_profit_percent,
                expense_order_use_price_id: response.expense_order_use_price_id,
                expense_min_profit_percent: response.expense_min_profit_percent,
                
                vats: response.vats,
                taxGroups: response.tax_groups,
                use_tax_groups: response.use_tax_groups,
                languageId: response.language_id,
                taxCodes: taxCodes,
                taxcodeTranslations: taxcodeTranslations,

                checkoutStorageType: response.checkout_storage && response.checkout_storage.type === 'P' ? tr('project') : tr('storage'),
                checkoutStorage: response.checkout_storage ? response.checkout_storage : null,

                woocommerce_enabled: response.woocommerce_enabled,
                woocommerce_user_key: response.woocommerce_user_key,
                woocommerce_user_secret: response.woocommerce_user_secret,
                woocommerce_token: response.woocommerce_token,
                woocommerce_webhook_secret: response.woocommerce_webhook_secret,
                woocommerce_store_url: response.woocommerce_store_url,
                online_store_storage: response.online_store_storage,
                woocommerce_identifier: response.woocommerce_identifier,
                woocommerce_use_storage_code: response.woocommerce_use_storage_code,
                online_store_product_price: response.online_store_product_price,
                online_store_profit_percent: response.online_store_profit_percent,
                auto_send_to_ext_enabled: response.auto_send_to_ext,
                sending_to_ext_use_current_day_enabled: response.sending_to_ext_use_current_day,
                order_grouping_type_options: response.order_grouping_type_options,
                order_grouping_type_id: response.order_grouping_type_id,
                is_project_mandatory_enabled: response.is_project_mandatory,
                show_in_timetracking_description_to_billable_enabled: response.show_in_timetracking_description_to_billable,
            });

            // this.setSettings( response );
            this.setState({ loading: false });
            if (this.state.languageId==null) {        
                this.setState({
                    languageId: 1
                });
            }
            this.props.saveOrderSettings(response);
        }).catch(( error ) => {
            console.log(error);

            errorPopper( error, tr('get_error') );
            this.setState({ loading: false });
        });
    }

    getLanguages()
    {
        api({
            method: 'get',
            url: 'languages/all'
        }).then((response) => {
            const languageOptions = [];
            response.forEach(lang => {
                if (lang.id === 1 || lang.id === 2 || lang.id === 3) {
                    languageOptions.push({
                        value: lang.id,
                        label: lang.name
                    });
                }
            });
            this.setState({languages: languageOptions});
        }).catch((error) => {
            console.log('Error while fetching languages.');
        })
    }

    getTemplate( current = null)
    {
        let url = 'storage/order/settings/template';
        if( current ) url += '/current';
        else url += '/default';
        url += `/${this.state.languageId}`;

        this.setState({ loading: true });
        api({
            method: 'get',
            url: url,
            responseType: 'blob',
        }).then(( response ) => {
            let fileName = current ? `${tr('order_template')}.docx` : `${tr('order_template_example')}.docx`;
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName );
            document.body.appendChild(link);
            link.click();
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('file_download_error'));
            this.setState({ loading: false });
        });
    }

    getBillTemplate( current = null)
    {
        let url = 'storage/order/settings/billtemplate';
        if( current ) url += '/current';
        else url += '/default';
        url += `/${this.state.languageId}`;

        this.setState({ loading: true });
        api({
            method: 'get',
            url: url,
            responseType: 'blob',
        }).then(( response ) => {
            let fileName = current ? `${tr('invoice_template')}.docx` : `${tr('invoice_template_example')}.docx`;
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName );
            document.body.appendChild(link);
            link.click();
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('file_download_error'));
            this.setState({ loading: false });
        });
    }

    getReceiptTemplate( current = null)
    {
        let url = 'storage/order/settings/receipttemplate';
        if( current ) url += '/current';
        else url += '/default';
        url += `/${this.state.languageId}`;

        this.setState({ loading: true });
        api({
            method: 'get',
            url: url,
            responseType: 'blob',
        }).then(( response ) => {
            let fileName = current ? `${tr('receipt_template')}.docx` : `${tr('receipt_template_example')}.docx`;
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName );
            document.body.appendChild(link);
            link.click();
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('file_download_error'));
            this.setState({ loading: false });
        });
    }

    getDispatchNoteTemplate( current = null)
    {
        let url = 'storage/order/settings/dispatchnotetemplate';
        if( current ) url += '/current';
        else url += '/default';
        url += `/${this.state.languageId}`;

        this.setState({ loading: true });
        api({
            method: 'get',
            url: url,
            responseType: 'blob',
        }).then(( response ) => {
            let fileName = current ? `${tr('referral_template')}.docx` : `${tr('referral_template_example')}.docx`;
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName );
            document.body.appendChild(link);
            link.click();
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('file_download_error'));
            this.setState({ loading: false });
        });
    }

    getClTemplate( current = null )
    {
        let url = 'storage/order/settings/cltemplate';
        if( current ) url += '/current';
        else url += '/default';
        url += `/${this.state.languageId}`;

        this.setState({ loading: true });
        api({
            method: 'get',
            url: url,
            responseType: 'blob',
        }).then(( response ) => {
            let fileName = current ? `${tr('collection_list_template')}.docx` : `${tr('collection_list_template_example')}.docx`;
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName );
            document.body.appendChild(link);
            link.click();
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('file_download_error'));
            this.setState({ loading: false });
        });
    }


        /*
    fileUpload( file )
    {
        this.setState({
            file: {
                name: file.original_name,
                id: file.id,
                extension: file.extension,
            }
        });
    }
    */

    saveClicked()
    {
        let  data = {};

        data.language_id = this.state.languageId;
        data.new_files = {};

        this.state.languages.forEach(lang => {
            if( this.state.templateFile[lang.value] ) {
                if (!data.new_files[lang.value]) {
                    data.new_files[lang.value] = {};
                }
                data.new_files[lang.value].template_id = this.state.templateFile[lang.value].id;
            }
    
            if( this.state.clFile[lang.value] ) {
                if (!data.new_files[lang.value]) {
                    data.new_files[lang.value] = {};
                }
                data.new_files[lang.value].cl_id = this.state.clFile[lang.value].id;
            }
    
            if( this.state.billFile[lang.value] ) {
                if (!data.new_files[lang.value]) {
                    data.new_files[lang.value] = {};
                }
                data.new_files[lang.value].bill_id = this.state.billFile[lang.value].id;
            }

            if( this.state.receiptFile[lang.value] ) {
                if (!data.new_files[lang.value]) {
                    data.new_files[lang.value] = {};
                }
                data.new_files[lang.value].receipt_id = this.state.receiptFile[lang.value].id;
            }
    
            if (this.state.dispatchNoteFile[lang.value]) {
                if (!data.new_files[lang.value]) {
                    data.new_files[lang.value] = {};
                }
                data.new_files[lang.value].dispatch_note_id = this.state.dispatchNoteFile[lang.value].id;
            }
        });

        data.next_number = this.state.nextNumber;
        data.number_length = this.state.numberLength;

        data.next_dispatch_number = this.state.nextDispatchNumber;
        data.dispatch_number_length = this.state.dispatchNumberLength;
        
        data.pc = this.state.pc;
        data.dm = this.state.dm;
        data.dt = this.state.dt;
		data.bd = this.state.bd;

        data.pc_enabled  = this.state.pc_enabled;
        data.pc_required = this.state.pc_required;
        data.dm_enabled  = this.state.dm_enabled;
        data.dm_required = this.state.dm_required;
        data.dt_enabled  = this.state.dt_enabled;
        data.dt_required = this.state.dt_required;
        
        data.component_order_use_price_id = this.state.component_order_use_price_id;
        data.component_min_profit_percent = this.state.component_min_profit_percent;
        data.work_order_use_price_id = this.state.work_order_use_price_id;
        data.work_min_profit_percent = this.state.work_min_profit_percent;
        data.expense_order_use_price_id = this.state.expense_order_use_price_id;
        data.expense_min_profit_percent = this.state.expense_min_profit_percent;

        data.vats = this.state.vats;
        data.use_tax_groups = this.state.use_tax_groups;
        data.tax_groups = this.state.taxGroups;

        data.checkout_storage = {
            id: this.state.checkoutStorage ? this.state.checkoutStorage.id : null,
            type: this.state.checkoutStorageType === tr('storage') ? 'storage' : 'project'
        }

        data.woocommerce_enabled = this.state.woocommerce_enabled;
        data.woocommerce_user_key = this.state.woocommerce_user_key;
        data.woocommerce_user_secret = this.state.woocommerce_user_secret;
        data.woocommerce_webhook_secret = this.state.woocommerce_webhook_secret;
        data.woocommerce_store_url = this.state.woocommerce_store_url;
        data.online_store_storage = this.state.online_store_storage;
        data.woocommerce_use_storage_code = this.state.woocommerce_use_storage_code;
        data.woocommerce_identifier = this.state.woocommerce_identifier;
        data.online_store_product_price = this.state.online_store_product_price;
        data.online_store_profit_percent = this.state.online_store_profit_percent;
        data.auto_send_to_ext = this.state.auto_send_to_ext_enabled;
        data.sending_to_ext_use_current_day = this.state.sending_to_ext_use_current_day_enabled;
        data.order_grouping_type_id = this.state.order_grouping_type_id;
        data.is_project_mandatory = this.state.is_project_mandatory_enabled;
        data.show_in_timetracking_description_to_billable = this.state.show_in_timetracking_description_to_billable_enabled;

        return api({
            method: 'post',
            url: 'storage/order/settings',
            data: data,
        }).then((response) => {
            //console.log('saver', response );
            this.getSettings();
            this.setState({ loading: false, unsaved: false });
        }).catch(( error ) => {
            console.log(error);

            errorPopper( error, tr('save_error') );
            this.setState({ loading: false });
        });

    }

    handleNextNumberChange( type, value, blur = false )
    {
        value = onlyNumber( value )
        if( blur )
        {
            value = parseInt( value , 10 );
            if (type === 'dispatch') {
                if( !value || value < this.state.nextDispatchNumberMin )
                {
                    window.emitter.emit('popper', {
                        type: 'warning',
                        content: <strong>{ tr('next_number_min_warning') }</strong>,
                    });
                    value = this.state.nextDispatchNumberMin;
                }
            }  else {
                if( !value || value < this.state.nextNumberMin )
                {
                    window.emitter.emit('popper', {
                        type: 'warning',
                        content: <strong>{ tr('next_number_min_warning') }</strong>,
                    });
                    value = this.state.nextNumberMin;
                }
            }
        }
        if (type === 'dispatch') {
            this.setState({ nextDispatchNumber: value, unsaved: true });
        } else {
            this.setState({ nextNumber: value, unsaved: true });
        }
    }

    getPriceTypes()
    {
        return [
            { value: 1, label: tr('project_selling_price') },
            { value: 6, label: tr('project_selling_price_min_margin') },
            { value: 2, label: tr('supplier_price_without_discounts') },
            { value: 3, label: tr('supplier_price_product_margin_without_discounts') },
            { value: 4, label: tr('supplier_price_min_margin') },
            // { value: 5, label: tr('supplier_price_target_min_margin_with_discounts') },
            //{ value: 7, label: ' Viimeisin hinta katteella '},
            //{ value: 8, label: ' Viimeisin hinta vähimmäiskatteella '},
        ]
    }
    
    handlePriceChange(value, name)
    {
        this.setState({ [name]: value, unsaved: true });
    }
    
    handleMinProfitPercentChange(value, name) {
    	value = onlyNumber( value )
    	if (value<0) {value=0}
    	if (value>99) {value=99}
        this.setState({ [name]: value, unsaved: true });
    }
    
    handlNumberLengthChange( type, value, blur = false )
    {
        value = onlyNumber(value )

        if( parseInt( value, 10 ) > 50 ) return null;

        if( blur )
        {
            value = parseInt( value , 10 );
            if( !value ) value = null;
        }
        this.setState({ numberLength: value, unsaved: true });
    }

    handleDispatchNumberLengthChange( type, value, blur = false )
    {
        value = onlyNumber(value )

        if( parseInt( value, 10 ) > 50 ) return null;

        if( blur )
        {
            value = parseInt( value , 10 );
            if( !value ) value = null;
        }
        this.setState({ dispatchNumberLength: value, unsaved: true });
    }

    changeLanguage = (e) => {
        this.setState({
            languageId: e.target.value
        });
    };

    renderNumberEdit()
    {
        let previewNumber = this.state.nextNumber;
        if( previewNumber )
        {
            previewNumber = String( previewNumber );
            previewNumber = previewNumber.padStart( this.state.numberLength, '0' );
        }
        let previewDispatchNumber = this.state.nextDispatchNumber;
        if (previewDispatchNumber) {
	        previewDispatchNumber = String(previewDispatchNumber);
	        previewDispatchNumber = previewDispatchNumber.padStart(this.state.dispatchNumberLength, '0');
        }
        return <div>
            <h4 className="formHeader">
                <SvgIcon icon="list-ol" type="solid" /> { tr('sales_order_number') }
            </h4>
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('sales_order_number_info') }
            </div>

            <ApInputStack gap="0">
                <ApAddon width="200px" noRightBorder>
                    { tr('sales_order_number') }
                </ApAddon>
                <ApInput
                    type="text"
                    id="poNumber"
                    name="poNumber"
                    label={ tr('next_sales_order_number') }
                    value={ this.state.nextNumber ? this.state.nextNumber : '' }
                	onChange={ ( e ) => this.handleNextNumberChange( 'order', e.target.value ) }
                	onBlur={ ( e ) => this.handleNextNumberChange( 'order', e.target.value, true ) }
                />
                <ApInput
                    type="text"
                    id="poNumberLenght"
                    name="poNumberLenght"
                    label={ tr('number_length') }
                    value={ this.state.numberLength ? this.state.numberLength : '' }
                	onChange={ ( e ) => this.handlNumberLengthChange( 'order', e.target.value ) }
                	onBlur={ ( e ) => this.handlNumberLengthChange( 'order', e.target.value, true ) }
                />
            </ApInputStack>

            <div className="previewContainer">
                <div className="top-left">{ tr('next_sales_order_number') }</div>
                { previewNumber }
            </div>
            <h4 className="formHeader">
            <SvgIcon icon="list-ol" type="solid" /> { tr('referral_number') }
	        </h4>
	
	        <div className="apInfo small">
	            <SvgIcon icon="info-circle" type="solid" />
	            { tr('referral_number_info') }
	        </div>
	
	        <ApInputStack gap="0">
	            <ApAddon width="200px" noRightBorder>
	                { tr('referral_number') }
	            </ApAddon>
	            <ApInput
	                type="text"
	                id="dispatchNumber"
	                name="dispatchNumber"
	                label={ tr('next_referral_number') }
	                value={ this.state.nextDispatchNumber ? this.state.nextDispatchNumber : '' }
	                onChange={ ( e ) => this.handleNextNumberChange( 'dispatch', e.target.value ) }
	                onBlur={ ( e ) => this.handleNextNumberChange( 'dispatch', e.target.value, true ) }
	            />
	            <ApInput
	                type="text"
	                id="dispatchNumberLenght"
	                name="dispatchNumberLenght"
	                label={ tr('number_length') }
	                value={ this.state.dispatchNumberLength ? this.state.dispatchNumberLength : '' }
	                onChange={ ( e ) => this.handleDispatchNumberLengthChange( 'dispatch', e.target.value ) }
	                onBlur={ ( e ) => this.handleDispatchNumberLengthChange( 'dispatch', e.target.value, true ) }
	            />
	
	        </ApInputStack>
	
	        <div className="previewContainer">
	            <div className="top-left">{ tr('next_referral_number') }</div>
	            { previewDispatchNumber }
	        </div>
        </div>
    }

    renderTemplateEdit()
    {
        
        let pdfDom = null;
        if( this.state.files && this.state.files[this.state.languageId] && this.state.files[this.state.languageId].templatePdfExampleId )
        {
            const name = `${tr('order_confirmation_example')}.pdf`;
            pdfDom = <div>
                <File fileId={ this.state.files[this.state.languageId].templatePdfExampleId } type="pdf" name={ name } />
                <File fileId={ this.state.files[this.state.languageId].templatePdfExampleId } name={ name }>{ tr('download') } { name }</File>
            </div>
        }

        let menuActions = [];

        if( this.state.files && this.state.files[this.state.languageId] && this.state.files[this.state.languageId].templateDocxId )
        {
            menuActions.push({
                label: tr('download_current_template'),
                icon: 'file-alt',
                action: ( item, closeFunc ) => {
                    this.getTemplate( 'current' );
                    closeFunc();
                },
            });
        }

        menuActions.push({
            label: tr('download_general_base_template'),
            icon: 'file',
            action: ( item, closeFunc ) => {
                this.getTemplate();
                closeFunc();
            },
        });

        let menuDom = <ApAddon noRightBorder width="40px">
            <ApDropdown
                actions={ menuActions }
                button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
                position="right"
            />
        </ApAddon>

        const DOCX = <ApTooltip text={ tr('docx_edit_info') }>
            <span className="hasTooltip">docx</span>
        </ApTooltip>

        return <div className="poTemplateContainer">
            <h4 className="formHeader">
                <SvgIcon icon="file" type="solid" /> { tr('order_confirmation_template') }
            </h4>
            <div className="poTemplateInfoButton">
                <ApButton size="tiny" color="blue" onClick={ () => this.setState({ infoShow: true }) }>
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('template_specs') }
                </ApButton>
            </div>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('template_info', [<strong>.pdf</strong>, <strong>.{ DOCX }</strong>]) }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="pen-square" type="solid" />
                { tr('template_edit_info', [<strong>.{ DOCX }</strong>]) }
            </div>

            <div className="apInfo small warning">
                <SvgIcon icon="exclamation-circle" type="solid" />
                { tr('po_template_font_info') }
            </div>

            <ApInputStack gap="0">
                <ApAddon noRightBorder>
                    { tr('template_language') }
                </ApAddon>
                <ApInput
                    type="select"
                    id="template_language"
                    name="template_language"
                    value={this.state.languageId}
                    options={this.state.languages}
                    onChange={this.changeLanguage}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                />
            </ApInputStack>

            <ApInputStack gap="0">
                { menuDom }
                <ApAddon width="200px" noRightBorder>
                    { tr('new_template') }
                </ApAddon>
                <FileUpload
                    onUpload={(file) => {
                        this.setState(prevState => ({
                            ...prevState,
                            templateFile: {
                                ...prevState.templateFile,
                                [prevState.languageId]: file
                            },
                            unsaved: true
                        }))
                    }}
                    onClear={() => {
                        this.setState(prevState => ({
                            ...prevState,
                            templateFile: {
                                ...prevState.templateFile,
                                [prevState.languageId]: null
                            }
                        }))
                    }}
                    description="order-template"
                    accept=".docx"
                    label={ `${tr('select_docx_file')}...` }
                    value={ this.state.templateFile[this.state.languageId] ? this.state.templateFile[this.state.languageId] : null }
                />
            </ApInputStack>
            { pdfDom }
        </div>
    }

    renderBillEdit()
    {
        let pdfDom = null;
        if (this.state.files && this.state.files[this.state.languageId] && this.state.files[this.state.languageId].billPdfExampleId)
        {
            const name = `${tr('invoice_example')}.pdf`;
            pdfDom = <div>
                <File fileId={this.state.files[this.state.languageId].billPdfExampleId} type="pdf" name={ name } />
                <File fileId={this.state.files[this.state.languageId].billPdfExampleId} name={ name }>{ tr('download') } { name }</File>
            </div>
        }

        let menuActions = [];

        if (this.state.files && this.state.files[this.state.languageId] &&  this.state.files[this.state.languageId].billDocxId)
        {
            menuActions.push({
                label: tr('download_current_template'),
                icon: 'file-alt',
                action: ( item, closeFunc ) => {
                    this.getBillTemplate( 'current' );
                    closeFunc();
                },
            });
        }

        menuActions.push({
            label: tr('download_general_base_template'),
            icon: 'file',
            action: ( item, closeFunc ) => {
                this.getBillTemplate();
                closeFunc();
            },
        });


        let menuDom = <ApAddon noRightBorder width="40px">
            <ApDropdown
                actions={ menuActions }
                button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
                position="right"
            />
        </ApAddon>

        const DOCX = <ApTooltip text={ tr('docx_edit_info') } >
            <span className="hasTooltip">docx</span>
        </ApTooltip>

        return <div className="poTemplateContainer">
            <h4 className="formHeader">
                <SvgIcon icon="file" type="solid" /> { tr('invoice_template') }
            </h4>
            <div className="poTemplateInfoButton">
                <ApButton size="tiny" color="blue" onClick={ () => this.setState({ infoShow: true }) }>
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('template_specs') }
                </ApButton>
            </div>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('template_info', [<strong>.pdf</strong>, <strong>.{ DOCX }</strong>]) }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="pen-square" type="solid" />
                { tr('template_edit_info', [<strong>.{ DOCX }</strong>]) }
            </div>

            <div className="apInfo small warning">
                <SvgIcon icon="exclamation-circle" type="solid" />
                { tr('po_template_font_info') }
            </div>

            <ApInputStack gap="0">
                <ApAddon noRightBorder>
                    { tr('template_language') }
                </ApAddon>
                <ApInput
                    type="select"
                    id="template_language"
                    name="template_language"
                    value={this.state.languageId}
                    options={this.state.languages}
                    onChange={this.changeLanguage}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                />
            </ApInputStack>

            <ApInputStack gap="0">
                { menuDom }
                <ApAddon width="200px" noRightBorder>
                    { tr('new_template') }
                </ApAddon>
                <FileUpload
                    onUpload={(file) => {
                        this.setState(prevState => ({
                            ...prevState,
                            unsaved: true,
                            billFile: {
                                ...prevState.billFile,
                                [prevState.languageId]: file
                            }
                        }))
                    }}
                    onClear={() => {
                        this.setState(prevState => ({
                            ...prevState,
                            billFile: {
                                ...prevState.billFile,
                                [prevState.languageId]: null
                            }
                        }))
                    }}
                    description="order-bill-template"
                    accept=".docx"
                    label={ `${tr('select_docx_file')}...` }
                    value={ this.state.billFile[this.state.languageId] ? this.state.billFile[this.state.languageId] : null }
                />
            </ApInputStack>
            { pdfDom }
        </div>
    }

    renderReceiptEdit()
    {
        let pdfDom = null;
        if (this.state.files && this.state.files[this.state.languageId] && this.state.files[this.state.languageId].receiptPdfExampleId)
        {
            const name = `${tr('receipt_example')}.pdf`;
            pdfDom = <div>
                <File fileId={this.state.files[this.state.languageId].receiptPdfExampleId} type="pdf" name={ name } />
                <File fileId={this.state.files[this.state.languageId].receiptPdfExampleId} name={ name }>Lataa { name }</File>
            </div>
        }

        let menuActions = [];

        if (this.state.files && this.state.files[this.state.languageId] &&  this.state.files[this.state.languageId].receiptDocxId)
        {
            menuActions.push({
                label: tr('download_current_template'),
                icon: 'file-alt',
                action: ( item, closeFunc ) => {
                    this.getReceiptTemplate( 'current' );
                    closeFunc();
                },
            });
        }

        menuActions.push({
            label: tr('download_general_base_template'),
            icon: 'file',
            action: ( item, closeFunc ) => {
                this.getReceiptTemplate();
                closeFunc();
            },
        });


        let menuDom = <ApAddon noRightBorder width="40px">
            <ApDropdown
                actions={ menuActions }
                button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
                position="right"
            />
        </ApAddon>

        const DOCX = <ApTooltip text={ tr('docx_edit_info') } >
            <span className="hasTooltip">docx</span>
        </ApTooltip>

        return <div className="poTemplateContainer">
            <h4 className="formHeader">
                <SvgIcon icon="file" type="solid" /> { tr('receipt_template') }
            </h4>
            <div className="poTemplateInfoButton">
                <ApButton size="tiny" color="blue" onClick={ () => this.setState({ infoShow: true }) }>
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('template_specs') }
                </ApButton>
            </div>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('template_info', [<strong>.pdf</strong>, <strong>.{ DOCX }</strong>]) }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="pen-square" type="solid" />
                { tr('template_edit_info', [<strong>.{ DOCX }</strong>]) }
            </div>

            <div className="apInfo small warning">
                <SvgIcon icon="exclamation-circle" type="solid" />
                { tr('po_template_font_info') }
            </div>

            <ApInputStack gap="0">
                <ApAddon noRightBorder>
                    { tr('template_language') }
                </ApAddon>
                <ApInput
                    type="select"
                    id="template_language"
                    name="template_language"
                    value={this.state.languageId}
                    options={this.state.languages}
                    onChange={this.changeLanguage}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                />
            </ApInputStack>

            <ApInputStack gap="0">
                { menuDom }
                <ApAddon width="200px" noRightBorder>
                    { tr('new_template') }
                </ApAddon>
                <FileUpload
                    onUpload={(file) => {
                        this.setState(prevState => ({
                            ...prevState,
                            unsaved: true,
                            receiptFile: {
                                ...prevState.receiptFile,
                                [prevState.languageId]: file
                            }
                        }))
                    }}
                    onClear={() => {
                        this.setState(prevState => ({
                            ...prevState,
                            receiptFile: {
                                ...prevState.receiptFile,
                                [prevState.languageId]: null
                            }
                        }))
                    }}
                    description="order-receipt-template"
                    accept=".docx"
                    label={ `${tr('select_docx_file')}...` }
                    value={ this.state.receiptFile[this.state.languageId] ? this.state.receiptFile[this.state.languageId] : null }
                />
            </ApInputStack>
            { pdfDom }
        </div>
    }

    renderInfoModalRows( template = false )
    {
        const values = template ? templateRowField : templateField;

        let rows = [];
        for( let key in values )
        {
            rows.push(<tr key={ key }>
                <td className="text">{ '${'+key+'}' }</td>
                <td className="description">{ values[ key ] }</td>
            </tr>);
        }
        return rows;
    }
	
	renderInfoModalBankAccountsRows( template = false )
    {
        const values = template ? templateBankAccountsRowField : templateField;

        let rows = [];
        for( let key in values )
        {
            rows.push(<tr key={ key }>
                <td className="text">{ '${'+key+'}' }</td>
                <td className="description">{ values[ key ] }</td>
            </tr>);
        }
        return rows;
    }

    renderTemplateInfoModal()
    {
        return <div id="PoTemplateInfo">
            <ApModal
                show={ this.state.infoShow }
                handleClose={ () => this.setState({ infoShow: false }) }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { tr('template_specs') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <p>
                            { tr('template_specs_info', [<a href="https://en.wikipedia.org/wiki/Office_Open_XML" target="_blank" rel="noopener noreferrer">Microsoft Office Open XML</a>, <strong>.docx</strong>]) }
                        </p>
                        <p>
                            <strong>{ tr('listing_of_storage_components') }</strong>
                        </p>
                        <p>
                            { tr('listing_of_storage_components_info1', ['${row_name}']) }
                        </p>
                        <p>
                            { tr('listing_of_storage_components_info2') }
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>{ tr('text') }</th>
                                    <th>{ tr('description') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderInfoModalRows() }
                            </tbody>
                        </table>
                        <table>
                            <thead>
                                <tr>
                                    <th>{ tr('storage_component_text') }</th>
                                    <th>{ tr('description') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderInfoModalRows( 'rows' ) }
                            </tbody>
                        </table>
						<table>
                            <thead>
                                <tr>
                                    <th>{tr('beneficiarys_bank_connections')}</th>
                                    <th>{tr('description')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderInfoModalBankAccountsRows( 'rows' ) }
                            </tbody>
                        </table>
                    </div>
                }
            />
        </div>
    }

    renderClEdit()
    {
        let pdfDom = null;
        if (this.state.files && this.state.files[this.state.languageId] && this.state.files[this.state.languageId].clPdfExampleId)
        {
            const name = `${tr('collection_list_example')}.pdf`;
            pdfDom = <div>
                <File fileId={this.state.files[this.state.languageId].clPdfExampleId} type="pdf" name={ name } />
                <File fileId={this.state.files[this.state.languageId].clPdfExampleId} name={ name }>{tr('download')} { name }</File>
            </div>
        }

        let menuActions = [];

        if (this.state.files && this.state.files[this.state.languageId] && this.state.files[this.state.languageId].templateDocxId)
        {
            menuActions.push({
                label: tr('download_current_template'),
                icon: 'file-alt',
                action: ( item, closeFunc ) => {
                    this.getClTemplate( 'current' );
                    closeFunc();
                },
            });
        }

        menuActions.push({
            label: tr('download_general_base_template'),
            icon: 'file',
            action: ( item, closeFunc ) => {
                this.getClTemplate();
                closeFunc();
            },
        });


        let menuDom = <ApAddon noRightBorder width="40px">
            <ApDropdown
                actions={ menuActions }
                button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
                position="right"
            />
        </ApAddon>

        const DOCX = <ApTooltip text={ tr('docx_edit_info') } >
            <span className="hasTooltip">docx</span>
        </ApTooltip>

        return <div className="poTemplateContainer">
            <h4 className="formHeader">
                <SvgIcon icon="file" type="solid" /> { tr('collection_list_template') }
            </h4>
            <div className="poTemplateInfoButton">
                <ApButton size="tiny" color="blue" onClick={ () => this.setState({ infoShow: true }) }>
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('collection_list_specs') }
                </ApButton>
            </div>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('template_info', [<strong>.pdf</strong>, <strong>.{ DOCX }</strong>]) }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="pen-square" type="solid" />
                { tr('template_edit_info', [<strong>.{ DOCX }</strong>]) }
            </div>

            <div className="apInfo small warning">
                <SvgIcon icon="exclamation-circle" type="solid" />
                { tr('po_template_font_info') }
            </div>

            <ApInputStack gap="0">
                <ApAddon noRightBorder>
                    { tr('template_language') }
                </ApAddon>
                <ApInput
                    type="select"
                    id="template_language"
                    name="template_language"
                    value={this.state.languageId}
                    options={this.state.languages}
                    onChange={this.changeLanguage}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                />
            </ApInputStack>

            <ApInputStack gap="0">
                { menuDom }
                <ApAddon width="200px" noRightBorder>
                    { tr('new_template') }
                </ApAddon>
                <FileUpload
                    onUpload={(file) => {
                        this.setState(prevState => ({
                            ...prevState,
                            unsaved: true,
                            clFile: {
                                ...prevState.clFile,
                                [prevState.languageId]: file
                            }
                        }))
                    }}
                    onClear={() => {
                        this.setState(prevState => ({
                            ...prevState,
                            clFile: {
                                ...prevState.clFile,
                                [prevState.languageId]: null
                            }
                        }))
                    }}
                    description="order-cl-template"
                    accept=".docx"
                    label={ `${tr('select_docx_file')}...` }
                    value={ this.state.clFile[this.state.languageId] ? this.state.clFile[this.state.languageId] : null }
                />
            </ApInputStack>
            { pdfDom }
        </div>
    }

    renderDispatchNoteEdit()
    {
        let pdfDom = null;
        if (this.state.files && this.state.files[this.state.languageId] && this.state.files[this.state.languageId].dispatchNotePdfExampleId)
        {
            const name = `${tr('referral_example')}.pdf`;
            pdfDom = <div>
                <File fileId={ this.state.files[this.state.languageId].dispatchNotePdfExampleId } type="pdf" name={ name } />
                <File fileId={ this.state.files[this.state.languageId].dispatchNotePdfExampleId } name={ name }>{ tr('download') } { name }</File>
            </div>
        }

        let menuActions = [];

        if( this.state.files && this.state.files[this.state.languageId] && this.state.files[this.state.languageId].dispatchNoteDocxId )
        {
            menuActions.push({
                label: tr('download_current_template'),
                icon: 'file-alt',
                action: ( item, closeFunc ) => {
                    this.getDispatchNoteTemplate( 'current' );
                    closeFunc();
                },
            });
        }

        menuActions.push({
            label: tr('download_general_base_template'),
            icon: 'file',
            action: ( item, closeFunc ) => {
                this.getDispatchNoteTemplate();
                closeFunc();
            },
        });


        let menuDom = <ApAddon noRightBorder width="40px">
            <ApDropdown
                actions={ menuActions }
                button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
                position="right"
            />
        </ApAddon>

        const DOCX = <ApTooltip text={ tr('docx_edit_info') } >
            <span className="hasTooltip">docx</span>
        </ApTooltip>

        return <div className="poTemplateContainer">
            <h4 className="formHeader">
                <SvgIcon icon="file" type="solid" /> { tr('referral_template') }
            </h4>
            <div className="poTemplateInfoButton">
                <ApButton size="tiny" color="blue" onClick={ () => this.setState({ infoShow: true }) }>
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('referral_specs') }
                </ApButton>
            </div>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('template_info', [<strong>.pdf</strong>, <strong>.{ DOCX }</strong>]) }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="pen-square" type="solid" />
                { tr('template_edit_info', [<strong>.{ DOCX }</strong>]) }
            </div>

            <div className="apInfo small warning">
                <SvgIcon icon="exclamation-circle" type="solid" />
                { tr('po_template_font_info') }
            </div>

            <ApInputStack gap="0">
                <ApAddon noRightBorder>
                    { tr('template_language') }
                </ApAddon>
                <ApInput
                    type="select"
                    id="template_language"
                    name="template_language"
                    value={this.state.languageId}
                    options={this.state.languages}
                    onChange={this.changeLanguage}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                />
            </ApInputStack>

            <ApInputStack gap="0">
                { menuDom }
                <ApAddon width="200px" noRightBorder>
                    { tr('new_template') }
                </ApAddon>
                <FileUpload
                    onUpload={(file) => {
                        this.setState(prevState => ({
                            ...prevState,
                            unsaved: true,
                            dispatchNoteFile: {
                                ...prevState.dispatchNoteFile,
                                [prevState.languageId]: file
                            }
                        }))
                    }}
                    onClear={() => {
                        this.setState(prevState => ({
                            ...prevState,
                            dispatchNoteFile: {
                                ...prevState.dispatchNoteFile,
                                [prevState.languageId]: null
                            }
                        }))
                    }}
                    description="order-dispatch-note-template"
                    accept=".docx"
                    label={ `${tr('select_docx_file')}...` }
                    value={ this.state.clFile[this.state.languageId] ? this.state.clFile[this.state.languageId] : null }
                />
            </ApInputStack>
            { pdfDom }
        </div>
    }

    addVatRow()
    {
        this.setState({
            vatEdit: {
                show: true,
                data: { id: null, value: '', default: false },
            }
        })
    }
    addTaxGroupRow() {
        this.setState({
            taxGroupEdit: {
                show: true,
                data: { id: null},
            }
        })
    }
    editVatRow( row )
    {
        this.setState({
            vatEdit: {
                show: true,
                data: row,
            }
        })

    }
    editTaxGroupRow(row) {
        this.setState({
            taxGroupEdit: {
                show: true,
                data: row,
            }
        })
    }
    removeVatRows( keys )
    {
        let vats = this.state.vats.slice(0);
        vats = vats.filter( ( v, key ) => !keys.includes( key ))
        this.setState({ vats: vats });
    }
    removetaxGroupRows(keys) {
        let vats = this.state.taxGroups.slice(0);
        vats = vats.filter((v, key) => !keys.includes(key))
        this.setState({ taxGroups: vats });
    }

    vatSave( data )
    {  
        this.setState({ vatEdit: {} })

        //console.log('data', data );

        if( data )
        {
            let vats = this.state.vats.slice(0);
            if( !data.id )
                vats.push( data )
            else
            {
                vats = vats.map( v => {
                    if( data.default ) v.default = false;
                    if( v.id === data.id ) v = data;
                    return v;
                })
            }

            this.setState({ vats: vats, unsaved: true });
        }
    }

    renderVatEdit()
    {
        return <div className="poVatContainer">
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('vat_settings_info') }
            </div>
            <ApReactTable
                loading={ this.state.loading }
                data={ this.state.vats }
                columns={[ colPreset({
                        type: "percent",
                        decimals: 1,
                        id: 'value',
                        Header: tr('value'),
                        accessor: "value"
                    }),{
                        id:         "default", 
                        Header:     tr('default_vat'),
                        accessor:   "default",
                        width: 150,
                        Cell: ( props ) => {
                            if( props.value )
                                return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                        },
                }]}
                filterable
                multiselect={[
                    { icon: "trash-alt", label: tr('delete_selected'), action: ( ids ) => { this.removeVatRows( ids ) }, unselectAfter: true, closeAfter: true },
                ]}
                minRows={ 5 }
                rowActions={ ( row, index ) =>
                    <div className="apSimpleButton" onClick={ () => this.editVatRow( row ) }><SvgIcon icon="edit" type="solid" /></div>
                }
            />
            <ApButton size="small" color="green" onClick={ () => this.addVatRow() }>
                <SvgIcon icon="plus-square" type="solid" />
                    { tr('add_vat') }
            </ApButton>
            
            <div className="poVatContainer">
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    {tr('vat_settings_info')}
                </div>
                <div className="apSwitchBlock">
                    <label htmlFor={`use_tax_groups-switch`} className="info">
                        {tr('use_tax_groups')}
                        {/* <small> {"s.title"}</small> */}
                    </label>
                    <ApSwitch
                        id={`use_tax_groups-switch`}
                        on={this.state.use_tax_groups}
                        onChange={() => { this.setState({ use_tax_groups: !this.state.use_tax_groups, unsaved: true }) }}
                        disabled={this.state.loading}
                    />
                </div>
                <Collapse in={this.state.use_tax_groups}>
                    {this.renderTaxGroups()}
                </Collapse>
            </div>
            
        </div>


    }
    renderTaxGroups() {
        return (
            <div>
                <ApReactTable
                    loading={this.state.loading}
                    data={this.state.taxGroups}
                    columns={[
                        {
                            id: "account",
                            Header: tr('account'),
                            accessor: "account",
                            // width: 150,
                            // Cell: (props) => {
                            //     if (props.value)
                            //         return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                            // },
                        },
                        {
                            id: "vat",
                            Header: tr('vat'),
                            accessor: "vat_id",
                            Cell: (props) => {
                                let vat = this.state.vats.find(vat => {
                                    return vat.id == props.value
                                });

                                return <div>
                                    {vat?vat.value:""}
                                    </div>
                            },
                        },
                        {
                            id: "tax_code",
                            Header: tr('vat_code'),
                            accessor: "tax_code",
                            Cell: (props) => {
                                
                                return <div>
                                    {this.state.taxcodeTranslations[props.value] ? this.state.taxcodeTranslations[props.value]:""}
                                </div>
                            },

                        },
                        {
                            id: "name",
                            Header: tr('name'),
                            accessor: "name",

                        },
                    ]}
                    filterable
                    multiselect={[
                        { icon: "trash-alt", label: tr('delete_selected'), action: (ids) => { this.removetaxGroupRows(ids) }, unselectAfter: true, closeAfter: true },
                    ]}
                    minRows={5}
                    rowActions={(row, index) =>
                        <div className="apSimpleButton" onClick={() => this.editTaxGroupRow(row)}><SvgIcon icon="edit" type="solid" /></div>
                    }
                />
                <ApButton size="small" color="green" onClick={() => this.addTaxGroupRow()}>
                    <SvgIcon icon="plus-square" type="solid" />
                    {tr('add_tax_group')}
                </ApButton>
            </div>
        );
    }
    handleVatGroupChange(key, value) {
        let taxGroupEdit = { ...this.state.taxGroupEdit };
        let data = taxGroupEdit.data;
        data[key] = value;
        taxGroupEdit.data = data;
        this.setState({ taxGroupEdit });
    }

    renderVatGroupModal() {
        const data = this.state.taxGroupEdit ? this.state.taxGroupEdit.data : {};
        const vats = [{ value: null, label:"" }];
        this.state.vats.map((vat) => {
            vats.push({ value: vat.id, label: vat.value });
        });
        return <div id="StorageSettingVatEdit">
            <ApModal
                show={this.state.taxGroupEdit ? this.state.taxGroupEdit.show:false}
                onOpen={this.resetState}
                handleClose={() => {
                    let taxGroup = this.state.taxGroupEdit;
                    taxGroup.show = false;
                    this.setState({taxGroupEdit:taxGroup});
                    }}
                //closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            {Boolean(data) && data.id ? tr('edit') : tr('new')} {tr('tax_group')}
                        </h4>
                    </div>
                }
                body={
                    <div id="StorageSettingsVatEditBody">
                        <div className="padding">
                            <ApInput
                                type="text"
                                id="account"
                                name="account"
                                label={tr('accounting_account')}
                                value={data.account}
                                onChange={(e) => { this.handleVatGroupChange('account', e.target.value) }}
                                
                                inputRef={ref => this.valueRef = ref}
                            />
                            <ApInput
                                type="text"
                                id="name"
                                name="name"
                                label={tr('name')}
                                value={data.name}
                                onChange={(e) => { this.handleVatGroupChange('name', e.target.value) }}

                                inputRef={ref => this.valueRef = ref}
                            />
                            <h6>{tr("vat_percent")}</h6>
                            <ApInput
                                type="select"
                                id="vat"
                                name="vat"
                                label={tr('vat')}
                                options={vats}
                                value={data.vat_id}
                                validationState={!data.vat_id ? "error" : ""}
                                onChange={(e) => { this.handleVatGroupChange('vat_id', e.target.value) }}
                                
                                inputRef={ref => this.valueRef = ref}
                            />
                            <h6>{tr("vat_code")}</h6>
                            <ApInput
                                type="select"
                                id="taxCode"
                                name="taxCode"
                                label={tr('vat_code')}
                                validationState={!data.tax_code ? "error" : ""}
                                options={this.state.taxCodes}
                                value={data.tax_code}
                                onChange={(e) => { this.handleVatGroupChange('tax_code', e.target.value) }}
                                
                                inputRef={ref => this.valueRef = ref}
                            />
                            

                            

                        </div>
                    </div>
                }
                footer={
                    <div className="padding" id="StorageSettingsVatEditFooter">
                        <ApButton className="cancel" onClick={() => {
                            let taxGroupEdit = this.state.taxGroupEdit;
                            taxGroupEdit.show = false;
                            taxGroupEdit.data = { id: null };
                            this.setState({ taxGroupEdit });
                        }}>
                            <SvgIcon icon="times" type="solid" />
                            {tr('cancel')}
                        </ApButton>

                        <ApButton className="save" color="green" onClick={this.saveTaxGroupEdit} disabled={this.state.loading ||
                            (!data.vat_id || !data.tax_code)}>
                            <SvgIcon icon="check" type="solid" />
                            {tr('continue')}
                        </ApButton>
                    </div>
                }
            />
        </div>
    }
    saveTaxGroupEdit() {
        let taxGroupEdit = this.state.taxGroupEdit;
        taxGroupEdit.show = false;
        let data = taxGroupEdit.data;
        let taxGroups = [...this.state.taxGroups];
        if (data.id) {
            let founded = taxGroups.find(taxGroup => taxGroup.id == data.id);
            founded = data;
        }
        else {
            taxGroups.push(data);
            
        }
        taxGroupEdit.data={id:null}
        this.setState({ taxGroups, taxGroupEdit,unsaved:true });
    }

    renderMiscSettings()
    {

        return <div className={ "apFormGroup" + ( this.state.checkoutStorage ? " success" : "" ) }>
                <div className="apSwitchBlock miscSettingBlock">
                    <label htmlFor="checkout-storage-input" className="info">
                        { tr('checkout_collection_storage') }
                        <small>{ tr('checkout_collection_storage_info') }</small>
                    </label>
                    <ApInputStack gap="0" id='checkout-storage-input'>
                        <ApInput 
                            type="select" 
                            id="sendingtype" 
                            name="sendingtype"
                            value={ this.state.checkoutStorageType }
                            options={ locations }
                            onChange={ (e) => {
                                this.setState({
                                checkoutStorageType: e.target.value,
                                checkoutStorage: null,
                                unsaved: true
                                })

                            }}
                        />
                        <ApSelect
                            label={this.state.checkoutStorageType === tr('storage') ? tr('sales_storage') : tr('project_storage')}
                            value={  this.state.checkoutStorage && this.state.checkoutStorage.name ? this.state.checkoutStorage.name : '' }
                            optionRenderer={this.state.checkoutStorageType === tr('storage') ? 'storage_location_address' : 'project'}
                            onChange={ e => {
                                this.setState({
                                    checkoutStorage: e,
                                    unsaved: true
                                    })
                            }}
                            objKeyId="id"
                            clearable
                            apiUrl={this.state.checkoutStorageType === tr('storage') ? 'storage/locations/find' : 'storage/delivery/findprojects'}
                            apiData={{
                                //projectStorage: true, 
                                canSend: true,
                            }}
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                </div>
            </div>
        
    }

    render()
    {
        const getRelatedSwitch = ( args ) => {
            const sName = `${ args.related }_${ args.type }`;
            return <div className="apSwitchBlock">
                <label htmlFor={`${sName}-switch`} className="info">
                    { args.title }
                    <small> { args.sTitle }</small>
                </label>
                <ApSwitch
                    id={`${sName}-switch`}
                    on={ this.state[ sName ] }
                    onChange={ () => { this.setState({ [ sName ]: !this.state[ sName ], unsaved: true }) } }
                    disabled={ this.state.loading }
                />
            </div>
        }

        const identifierOptions = this.props.apStorageSettings && this.props.apStorageSettings.identifiers
            ? this.props.apStorageSettings.identifiers 
            : [];

        return <div>
            <Measure
                onResize={ () => {
                    if( this.stickyBar )
                        this.stickyBar.updateBounds();
                }}
            >
            {({ measureRef }) =>
                <div className="apBox" id="oSettings" ref={ measureRef }>
                    <div className="apBoxHeader">
                        <h1>{ tr('sales_settings') }</h1>
                        <p></p>
                    </div>
                    <ApTabs selected={ this.state.selectedTab } onChange={ this.tabChange } >
                        <ApTab icon="file" label={ tr('order_confirmation') }>
                            <div className="padding">
                                {this.renderTemplateEdit() }
                            </div>
                        </ApTab>
                        <ApTab icon="file" label={ tr('invoice') }>
                            <div className="padding">
                                {this.renderBillEdit() }
                            </div>
                        </ApTab>
                        
                        <ApTab icon="file" label={ tr('receipt') }>
                            <div className="padding">
                                {this.renderReceiptEdit() }
                            </div>
                        </ApTab>

                        <ApTab icon="file-alt" label={ tr('collection_list') }>
                            <div className="padding">
                                {this.renderClEdit() }
                            </div>
                        </ApTab>
                        <ApTab icon="file-alt" label={ tr('referral') }>
                            <div className="padding">
                                {this.renderDispatchNoteEdit() }
                            </div>
                        </ApTab>
                        <ApTab icon="list-ol" label={ tr('numbering') }>
                            <div className="padding">
                                { this.renderNumberEdit() }
                            </div>
                        </ApTab>

                        <ApTab icon="percentage" label={ tr('vat') }>
                            <div className="padding">
                                { this.renderVatEdit() }
                            </div>
                        </ApTab>

                        <ApTab icon="money-check" label={ tr('payment_terms') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                        { tr('payment_terms_info') }
                                </div>
                                { getRelatedSwitch({
                                    related: 'pc',
                                    type: 'enabled',
                                    title: tr('payment_terms_in_use'),
                                    sTitle: tr('payment_terms_in_use_info'),
                                }) }
                                <Collapse in={ this.state.pc_enabled }>
                                    <div className="indentleft">
                                        { getRelatedSwitch({
                                            related: 'pc',
                                            type: 'required',
                                            title: tr('payment_term_selection_mandatory'),
                                            sTitle: tr('payment_term_selection_mandatory_info'),
                                        }) }
                                        <Relations
                                            instance="pc"
                                            loading={ this.state.loading }
                                            rows={ this.state.pc }
                                            onChange={ ( data ) =>  {
                                                this.setState( data )
                                                this.setState({unsaved: true})
                                            } }
                                        />
                                    </div>
                                </Collapse>
                            </div>
                        </ApTab>
						<ApTab icon="money-check" label={ tr('bank_connection') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    { tr('bank_connection_info') }
                                </div>
                                    <div className="indentleft">
                                        <BankRelations
                                            instance="bd"
                                            loading={ this.state.loading }
                                            rows={ this.state.bd }
                                            onChange={ ( data ) =>  this.setState( data ) }
                                        />
                                    </div>
                            </div>
                        </ApTab>
                        <ApTab icon="truck" label={ tr('delivery_terms') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                        { tr('delivery_terms_info') }
                                </div>
                                { getRelatedSwitch({
                                    related: 'dt',
                                    type: 'enabled',
                                    title: tr('delivery_terms_in_use'),
                                    sTitle: tr('delivery_terms_in_use_info'),
                                }) }
                                <Collapse in={ this.state.dt_enabled }>
                                    <div className="indentleft">
                                        { getRelatedSwitch({
                                            related: 'dt',
                                            type: 'required',
                                            title: tr('delivery_term_selection_mandatory'),
                                            sTitle: tr('delivery_term_selection_mandatory_info'),
                                        }) }
                                        <Relations
                                            instance="dt"
                                            loading={ this.state.loading }
                                            rows={ this.state.dt }
                                            onChange={ ( data ) =>  {
                                                this.setState( data )
                                                this.setState({unsaved: true})
                                            } }
                                        />
                                    </div>
                                </Collapse>
                            </div>
                        </ApTab>

                        <ApTab icon="dolly-flatbed" label={ tr('delivery_methods') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                        { tr('delivery_methods_info') }
                                </div>
                                { getRelatedSwitch({
                                    related: 'dm',
                                    type: 'enabled',
                                    title: tr('delivery_methods_in_use'),
                                    sTitle: tr('delivery_methods_in_use_info'),
                                }) }
                                <Collapse in={ this.state.dm_enabled }>
                                    <div className="indentleft">
                                        { getRelatedSwitch({
                                            related: 'dm',
                                            type: 'required',
                                            title: tr('delivery_method_selection_mandatory'),
                                            sTitle: tr('delivery_method_selection_mandatory_info'),
                                        }) }
                                        <Relations
                                            instance="dm"
                                            loading={ this.state.loading }
                                            rows={ this.state.dm }
                                            onChange={ ( data ) =>  {
                                                this.setState( data )
                                                this.setState({unsaved: true})
                                            } }
                                        />
                                    </div>
                                </Collapse>
                            </div>
                        </ApTab>
                        <ApTab icon="euro-sign" label={ tr('price_to_be_used') }>
                                <div className="padding">
                                    <h5 className='formHeader small' >
                                        <SvgIcon icon="info-circle" type="solid" />
                                        {" " + tr('price_to_be_used_info')}
                                    </h5>
                                </div>
                                <div className="padding">
                                    <h4 className="">
                                        {tr('price_to_be_used_for_component')}
                                    </h4>
                                    <ApInput type='select'
                                        options={this.getPriceTypes()}
                                        onChange={(e) => this.handlePriceChange(e.target.value, 'component_order_use_price_id')}
                                        value={this.state.component_order_use_price_id}
                                    />
                                    <Collapse in={this.state.component_order_use_price_id > 3}>
                                    {/* <Collapse in={true}> */}
                                        <div>
                                            <ApInputStack gap="0">
                                                <ApAddon width="200px" noRightBorder>
                                                    {this.state.component_order_use_price_id > 3 ? tr('min_margin_percentage') : tr('default_vat')}
                                                </ApAddon>
                                                <ApInput
                                                    type="text"
                                                    id="minProfitComponent"
                                                    name="minProfit"
                                                    label={this.state.component_order_use_price_id > 3 ? tr('min_margin_percentage') : tr('default_vat')}
                                                    value={this.state.component_min_profit_percent ? this.state.component_min_profit_percent : ''}
                                                    onChange={(e) => this.handleMinProfitPercentChange(e.target.value, 'component_min_profit_percent')}
                                                    onBlur={(e) => this.handleMinProfitPercentChange(e.target.value, 'component_min_profit_percent')}
                                                />
                                            </ApInputStack>
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="padding">
                                    <h4 className="">
                                        {tr('price_to_be_used_for_work')}
                                    </h4>
                                    <ApInput type='select'
                                        options={this.getPriceTypes()}
                                        onChange={(e) => this.handlePriceChange(e.target.value, 'work_order_use_price_id')}
                                        value={this.state.work_order_use_price_id}
                                    />
                                    <Collapse in={this.state.work_order_use_price_id > 3}>
                                    {/* <Collapse in={true}> */}
                                        <div>
                                            <ApInputStack gap="0">
                                                <ApAddon width="200px" noRightBorder>
                                                    {this.state.work_order_use_price_id > 3 ? tr('min_margin_percentage') : tr('default_vat')}
                                                </ApAddon>
                                                <ApInput
                                                    type="text"
                                                    id="minProfitWork"
                                                    name="minProfit"
                                                    label={this.state.work_order_use_price_id > 3 ? tr('min_margin_percentage') : tr('default_vat')}
                                                    value={this.state.work_min_profit_percent ? this.state.work_min_profit_percent : ''}
                                                    onChange={(e) => this.handleMinProfitPercentChange(e.target.value, 'work_min_profit_percent')}
                                                    onBlur={(e) => this.handleMinProfitPercentChange(e.target.value, 'work_min_profit_percent')}
                                                />
                                            </ApInputStack>
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="padding">
                                    <h4 className="">
                                        {tr('price_to_be_used_for_expence')}
                                    </h4>
                                    <ApInput type='select'
                                        options={this.getPriceTypes()}
                                        onChange={(e) => this.handlePriceChange(e.target.value, 'expense_order_use_price_id')}
                                        value={this.state.expense_order_use_price_id}
                                    />
                                    <Collapse in={this.state.expense_order_use_price_id > 3}>
                                    {/* <Collapse in={true}> */}
                                        <div>
                                            <ApInputStack gap="0">
                                                <ApAddon width="200px" noRightBorder>
                                                    {this.state.expense_order_use_price_id > 3 ? tr('min_margin_percentage') : tr('default_vat')}
                                                </ApAddon>
                                                <ApInput
                                                    type="text"
                                                    id="minProfitExpence"
                                                    name="minProfit"
                                                    label={this.state.expense_order_use_price_id > 3 ? tr('min_margin_percentage') : tr('default_vat')}
                                                    value={this.state.expense_min_profit_percent ? this.state.expense_min_profit_percent : ''}
                                                    onChange={(e) => this.handleMinProfitPercentChange(e.target.value, 'expense_min_profit_percent')}
                                                    onBlur={(e) => this.handleMinProfitPercentChange(e.target.value, 'expense_min_profit_percent')}
                                                />
                                            </ApInputStack>
                                        </div>
                                    </Collapse>
                                </div>
	                    </ApTab>
                        <ApTab icon="shopping-cart" label={tr('online_store')}>
                            <div className="padding">
                                {getRelatedSwitch({
                                    related: 'woocommerce',
                                    type: 'enabled',
                                    title: tr('woocommerce_store_enabled'),
                                })}
                                <Collapse in={this.state.woocommerce_enabled}>
                                    <div style={{paddingLeft: '20px'}}>
                                        {this.state.woocommerce_token && this.state.woocommerce_enabled &&
                                        <div>
                                            <small>
                                                <SvgIcon icon='info-circle' type='solid' className='size-small' /> {tr('online_store_token_info', [moment(this.state.woocommerce_token.valid_to).format("L")])}

                                            </small>
                                            <pre>{this.state.woocommerce_token.auth_token}</pre>
                                        </div>}
                                        <small>
                                            <SvgIcon icon='info-circle' type='solid' className='size-small' /> {tr('online_store_url_info',)}
                                        </small>
                                        <ApInput
                                            type="text"
                                            id="woocommerce_store_url"
                                            name="woocommerce_store_url"
                                            label={ tr('online_store_url') }
                                            value={ this.state.woocommerce_store_url ? this.state.woocommerce_store_url : '' }
                                            onChange={ ( e ) => this.setState({woocommerce_store_url: e.target.value, unsaved: true}) }
                                        />
                                        <ApInput
                                            type="text"
                                            id="woocommerce_user_key"
                                            name="woocommerce_user_key"
                                            label={ tr('online_store_user_key') }
                                            value={ this.state.woocommerce_user_key ? this.state.woocommerce_user_key : '' }
                                            onChange={ ( e ) => this.setState({woocommerce_user_key: e.target.value, unsaved: true}) }
                                        />
                                        <ApInputStack gap="0">
                                            <ApInput
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                id="woocommerce_user_secret"
                                                name="woocommerce_user_secret"
                                                label={ tr('online_store_user_secret') }
                                                value={ this.state.woocommerce_user_secret ? this.state.woocommerce_user_secret : '' }
                                                onChange={ ( e ) => this.setState({woocommerce_user_secret: e.target.value, unsaved: true}) }
                                                autoComplete="new-password"
                                                role="presentation"
                                            />
                                            <ApAddon
                                                noLeftBorder
                                                width="50px"
                                                tooltip={ this.state.showPassword ? tr('hide_password') : tr('show_password') }
                                                onClick={ () => this.setState({ showPassword: !this.state.showPassword }) }
                                            >
                                                <SvgIcon icon={ this.state.showPassword ? "eye" : "eye-slash" } type="solid" />
                                            </ApAddon>
                                        </ApInputStack>
                                        <ApInputStack gap="0">
                                            <ApInput
                                                type={this.state.showSecret ? 'text' : 'password'}
                                                id="woocommerce_webhook_secret"
                                                name="woocommerce_webhook_secret"
                                                label={ tr('woocommerce_webhook_secret') }
                                                value={ this.state.woocommerce_webhook_secret ? this.state.woocommerce_webhook_secret : '' }
                                                onChange={ ( e ) => this.setState({woocommerce_webhook_secret: e.target.value, unsaved: true}) }
                                            />
                                            <ApAddon
                                                noLeftBorder
                                                width="50px"
                                                tooltip={ this.state.showSecret ? tr('hide_password') : tr('show_password') }
                                                onClick={ () => this.setState({ showSecret: !this.state.showSecret }) }
                                            >
                                                <SvgIcon icon={ this.state.showSecret ? "eye" : "eye-slash" } type="solid" />
                                            </ApAddon>
                                        </ApInputStack>
                                        <ApInputStack gap="0">
                                            <ApAddon width="200px">{tr('storage_to_be_used')}</ApAddon>
                                            <ApSelect
                                                label={tr('storage_to_be_used')}
                                                value={ this.state.online_store_storage ? this.state.online_store_storage.name : '' }
                                                optionRenderer={'storage_location_address'}
                                                onChange={ e => {
                                                    this.setState({
                                                        online_store_storage: e,
                                                        unsaved: true
                                                        })
                                                }}
                                                objKeyId="id"
                                                clearable
                                                apiUrl={'storage/locations/find'}
                                                apiData={{
                                                    //projectStorage: true, 
                                                    canSend: true,
                                                }}
                                                disabled={ this.state.loading }
                                            />
                                        </ApInputStack>
                                        {getRelatedSwitch({
                                            related: 'woocommerce',
                                            type: 'use_storage_code',
                                            title: tr('use_storage_code_as_identifier'),
                                        })}
                                        <Collapse in={!this.state.woocommerce_use_storage_code}>
                                                <div>
                                                    <ApInputStack gap="0">
                                                        <ApAddon width="200px">{tr('identifier_to_be_used')}</ApAddon>
                                                        <ApSelect
                                                            label={tr('identifier_to_be_used')}
                                                            value={ this.state.woocommerce_identifier ? this.state.woocommerce_identifier.name : '' }
                                                            optionRenderer={'group'}
                                                            valueRenderer={'group'}
                                                            onChange={ e => {
                                                                this.setState({
                                                                    woocommerce_identifier: e,
                                                                    unsaved: true
                                                                    })
                                                            }}
                                                            objKeyId="id"
                                                            objKeySearchable="name"
                                                            clearable
                                                            options={identifierOptions}
                                                            disabled={ this.state.loading }
                                                        />
                                                    </ApInputStack>
                                                </div>
                                        </Collapse>
                                        <small>
                                            <SvgIcon icon='info-circle' type='solid' className='size-small' /> {tr('online_store_used_price')}
                                        </small>
                                        <ApInput
                                            type="select"
                                            options={this.getPriceTypes()}
                                            id="online_store_product_price"
                                            name="online_store_product_price"
                                            label={ tr('price_to_be_used') }
                                            value={ this.state.online_store_product_price ? this.state.online_store_product_price : '' }
                                            onChange={ ( e ) => this.setState({online_store_product_price: e.target.value, unsaved: true}) }
                                        />
                                        <ApInputStack gap="0">
                                            <ApAddon width="200px" noRightBorder>
                                                        {this.state.online_store_product_price > 3 ? tr('min_margin_percentage') : tr('default_vat') }
                                            </ApAddon>
                                            <ApInput
                                                type="number"
                                                id="online_store_profit_percent"
                                                name="online_store_profit_percent"
                                                label={ this.state.online_store_product_price > 3 ? tr('min_margin_percentage') : tr('default_vat') }
                                                value={ this.state.online_store_profit_percent ? this.state.online_store_profit_percent : '' }
                                                onChange={ ( e ) => this.setState({online_store_profit_percent: e.target.value, unsaved: true}) }
                                            />
                                        </ApInputStack>
                                    </div>
                                </Collapse>
                            </div>
                        </ApTab>
                        <ApTab icon="cogs" label={ tr('other_sales_settings') }>
                            <div className="padding">
                                    {this.renderMiscSettings()}
                                    {getRelatedSwitch({
                                        related: 'auto_send_to_ext',
                                        type: 'enabled',
                                        title: tr('automatically_send_to_external'),
                                    })}
                                    {getRelatedSwitch({
                                        related: 'sending_to_ext_use_current_day',
                                        type: 'enabled',
                                        title: tr('sending_to_ext_use_current_day'),
                                    })}
                                    {getRelatedSwitch({
                                        related: 'is_project_mandatory',
                                        type: 'enabled',
                                        title: tr('is_project_mandatory_order_info'),
                                    })}
                                    {getRelatedSwitch({
                                        related: 'show_in_timetracking_description_to_billable',
                                        type: 'enabled',
                                        title: tr('show_in_timetracking_description_to_billable'),
                                    })}
                                <h4>
                                    <SvgIcon icon='layer-group' type='solid' /> {tr('order_grouping_type')}
                                </h4>
                                <ApInput
                                    type="select"
                                    options={this.state.order_grouping_type_options || []}
                                    id="order_grouping_type"
                                    name="order_grouping_type"
                                    label={ tr('price_to_be_used') }
                                    value={ this.state.order_grouping_type_id }
                                    onChange={ ( e ) => this.setState({order_grouping_type_id: e.target.value, unsaved: true}) }
                                />
                                <small>
                                    <SvgIcon icon='info-circle' type='solid' className='size-small' /> {tr('order_grouping_type_info')}
                                </small>
                            </div>
                        </ApTab>
                    </ApTabs>
                    <ApStickyBar bottomMode zIndex={10} ref={ node => this.stickyBar= node }>
                        <div className="saveBar">
                            <div className="left">
                            </div>
                            <div className="right">
                                <ApButton 
                                    className={ "save" + ( this.state.unsaved && !this.state.loading ? " highlight" : "" ) } 
                                    color="blue" 
                                    onClick={ this.saveClicked } 
                                    disabled={ !this.state.unsaved || this.state.loading } 
                                    loading={ this.state.loading }
                                >
                                    <SvgIcon icon="save" type="solid" />
                                    { tr('save') }
                                </ApButton>
                            </div>
                        </div>
                    </ApStickyBar>
                </div>
            }
            </Measure>

            { this.renderTemplateInfoModal() }

            <VatEdit
                show={ Boolean( this.state.vatEdit.show ) }
                data={ this.state.vatEdit.data }

                onClose={ this.vatSave }
            />
            {this.renderVatGroupModal()}
        </div>
    }
}

const mapStateToProps = state => ({
    apOrderSettings: state.apOrderSettings,
    apStorageSettings: state.apStorageSettings,
});

const mapDispatchToProps = dispatch => ({
    saveOrderSettings: (settings) => dispatch({type: 'SAVE_ORDER_SETTINGS', payload: settings})
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
