import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import auth               from 'services/Authed/Authed.js';
import api                from 'services/Api/Api.js';
import ApReactTable       from 'common/ApReactTable/ApReactTable.js';
import ApButton           from 'common/ApButton/ApButton.js';
import ApTooltip          from 'common/ApTooltip/ApTooltip.js';
import { ApTabs, ApTab }  from 'common/ApTabs/ApTabs.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import { errorPopper, formatMoney
       , sqlToDateInput
       , tr } from 'services/Helpers/Helpers.js';

import { orderStatusBoxColor
       , getTypeName
       , getTypeIcon }       from 'modules/Storage/common/StorageHelpers.js';

import { ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect			from 'common/ApSelect/ApSelect.js';

import NewOrder from './NewOrder/NewOrder.js';

import { setOrderList, 
         getOrderCurrentPage,
         setOrderCurrentPage,
         orderCurrentPageIsLocked,
         setOrderPageLock } from './OrderHelper.js';
import { setComponentName } from '../PurchaseOrders/PurchaseOrderHelper'

import './Orders.css';
import CommentDialog from './EditOrder/CommentDialog/CommentDialog.js';
import { connect } from 'react-redux';
import WooCommerceOrders from './OnlineStore/WooCommerceOrders.js';

const tabIds = {
    0: "list",
    1: "archived", 
}
if (auth.hasModule('sales') && auth.hasModule('sales.checkout')){
    tabIds["2"] = "checkout_archived";
} 
if (auth.hasModule('sales') && auth.hasModule('sales.onlinestore')){
    tabIds["3"] = "online_store";
}


class Orders extends React.Component {

    constructor(props)
    {
        super(props);

        const params = this.props.match.params;
        let tab = (params.tab) ? this.getTabId(params.tab) : 0;

        this.state = {
            data: [],      // should default to []
            pages: -1,     // should default to -1 (which means we don't know how many pages we have)
            loading: true,
            selectedTab: tab,

            newOrderShow: false,

            statusOptions: [],

            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),
            copyId: null,
            isCreditNote: false,
            commentDialogShow: false,    
          
        }
        this.state.columns = this.getColumns();

        this.getTimer = null;

        autoBind(this);
    }

    onTabChange(tabId) {
        setOrderCurrentPage(0);
        this.props.history.replace(`/storage/orders/${tabIds[tabId]}`);
    }

    getTabId(param) {

        if (param in tabIds)
            return parseInt(param, 10);
        return parseInt(Object.keys(tabIds).find(key => tabIds[key] === param), 10);
    }

    componentDidMount()
    {
        setOrderCurrentPage(0);
        this.getColumns();
        this.getRelated();
        setComponentName('saleOrders')
    }

    componentDidUpdate( prevProps, prevState, snapshot )
    {
        if( JSON.stringify( this.props.instanceOptions ) !== JSON.stringify( prevProps.instanceOptions ) )
            this.getData();
    }

    refresh()
    {
        this.getData();
    }

    goToEdit( id = null)
    {
        let path = `/storage/order/new`;
        if( id ) path = `/storage/order/id/${id}`;
        window.emitter.emit( 'goTo', { pathname: path } );
        setOrderPageLock(true)
    }

    getRelated()
    {
        return api({
            method: 'get',
            url: 'storage/order/related',
        }).then((response) => {
            this.setState({
                loading: false,
                statusOptions: response.statuses,
            });
        }).catch(error => {
            errorPopper(error, tr('get_error'));
            console.log(error);
        });
    }

    getColumns()
    {
        api({
            method: 'get',
            url: 'storage/order/related',
        }).then((response) => {
            this.setState({
                maxPrice: response.max_price,
            });
        }).catch(error => {
            errorPopper(error, tr('get_error'));
            console.log(error);
        });

        return [{
            id: 'number',
            Header: tr('number'),
            accessor: 'number',
            // filterable: true,
            sortable: true,
            showInitially: true,
            customizable: true,
            width: 70,
            onClick: row => this.goToEdit(row.id),
        }, {
            id: 'date',
            Header: tr('date'),
            accessor: 'date',
            filterable: false,
            sortable: true,
            showInitially: true,
            customizable: true,
            width: 150,
            onClick: row => this.goToEdit(row.id),
            Cell: props => {
                return sqlToDateInput(props.value);
                //{ sqlToDateInput( props.value ) } <small>( { moment( props.value ).fromNow() } )</small>
            },
        },

        {

            id: 'related_type',
            Header: tr('order_type'),
            className: "overflowableOnHover",
            // filterable: true,
            sortable: true,
            showInitially: true,
            customizable: true,
            onClick: row => this.goToEdit(row.id),
            width: 50,
            Filter: ({ filter, onChange }) =>

                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                >
                    <option value="">{tr('all')}</option>
                    <option value="project">{tr('project')}</option>
                    <option value="company">{tr('company')}</option>
                </select>,

            Cell: props => {
                let icon = <SvgIcon icon={getTypeIcon(props.original.related_type)} type="solid" />
                let name = getTypeName(props.original.related_type);

                return <ApTooltip position="top" text={name} block>
                    <div>{icon}</div>
                </ApTooltip>
            },
        }, {
            id: 'receiver',
            Header: tr('orderer'),
            onClick: row => this.goToEdit(row.id),
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            customFilter: {
                type: "text",
                placeholder: tr('enter_orderer_name')
            },
            accessor: 'related_title',
        }, {
            id: 'handler',
            Header: tr('original_creator'),
            accessor: 'created_by',
            onClick: row => this.goToEdit(row.id),
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            customFilter: {
                type: "text",
                placeholder: tr('enter_creator_name')
            },
        }, {
            id: 'need_date',
            Header: tr('day_of_need'),
            accessor: 'need_date',
            sortable: true,
            filterable: false,
            showInitially: true,
            customizable: true,
            onClick: row => this.goToEdit(row.id),
            Cell: props => {
                if (!props.value)
                    return null;

                let value = sqlToDateInput(props.value);
                return <div className="text-ellipsis">
                    {value} <small>( {moment(value, 'DD.MM.YYYY').fromNow()} )</small>
                </div>
            },
            },
            {
                id: 'delivery_date',
                Header: tr('delivery_date'),
                accessor: 'delivery_date',
                sortable: true,
                filterable: false,
                showInitially: true,
                customizable: true,
                onClick: row => this.goToEdit(row.id),
                Cell: props => {
                    if (!props.value)
                        return null;

                    let value = sqlToDateInput(props.value);
                    return <div className="text-ellipsis">
                        {value} <small>( {moment(value, 'DD.MM.YYYY').fromNow()} )</small>
                    </div>
                },
            },
        {
            id: 'reference_number',
            Header: tr('reference_number'),
            accessor: 'reference_number',
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            onClick: row => this.goToEdit(row.id),
            customFilter: {
                type: "text",
                placeholder: tr('enter_reference_number')
            },
        },
        {
            id: 'reference_our',
            Header: tr('reference_number_our'),
            accessor: 'reference_our',
            sortable: true,
            // filterable: true,
            showInitially: false,
            customizable: true,
            onClick: row => this.goToEdit(row.id),
            customFilter: {
                type: "text",
                placeholder: tr('enter_reference_number_our')
            },
        },
        {
            id: 'reference_your',
            Header: tr('reference_number_your'),
            accessor: 'reference_your',
            sortable: true,
            // filterable: true,
            showInitially: false,
            customizable: true,
            onClick: row => this.goToEdit(row.id),
            customFilter: {
                type: "text",
                placeholder: tr('enter_reference_number_your')
            },
        },
        {
            id: 'price',
            Header: tr('price'),
            accessor: 'price',
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            customFilter: {
                type: "range",
                min: 0,
                step: 500,
                max: 1000000,
            },
            onClick: row => this.goToEdit(row.id),
            Cell: props => {
                if (props.value)
                    return <div className="text-right"> {formatMoney(props.value)} {this.state.currencySign}</div>
                return null;
            },
        },
        {
            id: 'due_date',
            Header: tr('due_date'),
            accessor: 'due_date',
            filterable: false,
            sortable: true,
            showInitially: true,
            customizable: true,
            width: 150,
            onClick: row => this.goToEdit(row.id),
            Cell: props => {
                return <div style={{
                    color: props.value !== null ?
                        (moment() > moment(props.value) ? "red" : "black") : "black"
                }}>{sqlToDateInput(props.value)}</div>
                //{ sqlToDateInput( props.value ) } <small>( { moment( props.value ).fromNow() } )</small>
            },
        }, {
            id: 'paid',
            Header: tr('paid'),
            accessor: 'paid',
            className: "overflowableOnHover",
            onClick: row => this.goToEdit(row.id),
            sortable: true,
            filterable: false,
            showInitially: true,
            customizable: true,
            Cell: props => {
                if (props.value)
                    return <div className="apStatusBox success">{tr('paid')}</div>

                let statusClass = ['apStatusBox ']

                let dueDate = null;
                if (props.original.due_date)
                    dueDate = `${tr('due_date')}: ${sqlToDateInput(props.original.due_date)}`;

                if (props.original.due_today)
                    statusClass.push('info');
                else if (props.original.paid_late)
                    statusClass.push('warning');

                return <ApTooltip position="top" text={dueDate} block>
                    <div className={statusClass.join(' ')}>{tr('not_paid')}</div>
                </ApTooltip>
            },
        }, {
            id: 'sended_to_ext_interface',
            Header: tr('sended_to_external_interface'),
            accessor: 'sended_to_ext_interface',
                className: "overflowableOnHover",
                onClick: row => this.goToEdit(row.id),
                sortable: true,
                filterable: false,
                showInitially: true,
                customizable: true,
                Cell: props => {
                    if (props.value)
                        return <div className="apStatusBox success">{tr('sent')}</div>

                    let statusClass = ['apStatusBox ']

                    // let dueDate = null;
                    if (props.original.due_date)
                        // dueDate = `${tr('due_date')}: ${sqlToDateInput(props.original.due_date)}`;

                    
                        if (props.original.paid_late) {
                            statusClass.push('error');
                        }
                        else {
                            statusClass.push('warning');
                        }

                    return <div className={statusClass.join(' ')}>{tr('not_sent')}</div>
                    // return <ApTooltip position="top" text={dueDate} block>
                    //     <div className={statusClass.join(' ')}>{tr('not_sent')}</div>
                    // </ApTooltip>
                },
            },
            {
            id: 'status_id',
            Header: tr('status'),
            accessor: 'status_id',
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            onClick: row => this.goToEdit(row.id),
            sortable: false,
            filterable: false,
            Cell: props => {

                if (props.original.archived)
                    return <div className="apStatusBox">{tr('archived')}</div>

                if (props.original.status_name === 'late') {
                    const message = `${tr('delivery_late_or_not_received')}: ${sqlToDateInput(props.original.delivery_date)}`

                    return <ApTooltip position="top" block text={message}>
                        <div className={`apStatusBox ${orderStatusBoxColor(props.original.status_name)}`}>{tr(props.original.status_title)}</div>
                    </ApTooltip>
                }

                return <div className={`apStatusBox ${orderStatusBoxColor(props.original.status_name)}`}> {tr(props.original.status_title)}</div>
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                >
                    <option value=""> </option>
                    {this.state.statusOptions.map(s => {
                        return <option key={s.id} value={s.id}> {tr(s.text)} </option>
                    })}
                </select>,
        }, {
            id: 'project_code',
            Header: tr('project_code'),
            accessor: 'project_code',
            className: "overflowableOnHover",
            onClick: row => this.goToEdit(row.id),
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            customFilter: {
                type: "text",
                placeholder: tr('enter_project_code')
            },
            Cell: props => {
                if (props.value)
                    return <div className="text-right">{props.value}</div>

                let statusClass = ['apStatusBox '];

                return <ApTooltip position="top" block>
                    <div className={statusClass.join(' ')}>{tr('not_related_to_project')}</div>
                </ApTooltip>
            }
        }];
    }

    getData(state, archived = false, checkoutArchived = false )
    {
        window.clearTimeout(this.getTimer);
        this.getTimer = window.setTimeout(() => {
            this.setState({loading: true})
            api({
                method: 'post',
                url: 'storage/orders/apt',
                data: {
                    archived: archived,
                    checkoutArchived: checkoutArchived,
                    page: getOrderCurrentPage(),
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                    minAndMax: this.state.minAndMaxSetted ? null : ["price"]
                },
            }).then((response) => {
                if (response.price_max) {
                    let columns = [...this.state.columns]
                    let priceCol = columns.find(item => item.id == "price");
                    priceCol.customFilter.min = Math.ceil(response.price_min);
                    priceCol.customFilter.max = Math.ceil(response.price_max);
                    priceCol.customFilter.step = Math.ceil(response.price_max / 100);
                    this.setState({
                        data: response.data,
                        pages: response.pages,
                        loading: false,
                        minAndMaxSetted:true,
                    })
                }
                else {
                    this.setState({
                        data: response.data,
                        pages: response.pages,
                        loading: false
                    })
                }

                setOrderList(response.data)
                if (response.pages > 1) {
                    const size = response.pages * state.pageSize

                    api({
                        method: 'post',
                        url: 'storage/orders/apt',
                        data: {
                            archived: archived,
                            checkoutArchived: checkoutArchived,
                            pageSize: size,
                            sorted: state.sorted,
                            filtered: state.filtered,
                        },
                    }).then((response) => {
                        setOrderList(response.data)
                    }).catch((error) => {
                        console.error(error)
                    })
                }

                setOrderPageLock(false)
            }).catch((error) => {
                console.error(error);
                this.setState({
                    data: [],
                    pages: -1,
                    loading: false
                })
            });
        }, 500);
    }

    archive(indexes) {
        this.setState({
            commentDialogShow: true,
            commentDialogAcceptor: false,
            commentDialogMeta: {
                action: "archive",
                color: "green",
                icon: "archive",
                text: tr("archive"),
            },
            selectedRows: indexes,
        });
    }

    archiveOrders(action, comment, acceptor, formdata) {
        this.setState({
            commentDialogShow: false,
            commentDialogMeta: {},
        });

        let idList = [];
        let message = "";
        let warningMessage = "";

        for (let rowId of this.state.selectedRows) {
            let row = this.state.data[rowId];
            if (row.paid === true) {
                idList.push(row.id);
            }
            else {
                warningMessage += row.number + ": " + tr('archiving_canceled') + " " + tr("not_paid") + ",";
            }
        }

        if (idList.length > 0) {
            let data = {
                latest_comment: comment,
                archived: true,
                ids: idList
            }

            api({
                method: 'post',
                url: `storage/order/archive/list`,
                data: data,
            }).then((response) => {
                let ids = [];
                for (let r of response) {
                    if (r.archived) {
                        message += r.number + ": " + tr('archived') + ",";
                        ids.push(r.id);
                    }
                    else {
                        warningMessage += r.number + ": " + tr('archiving_canceled') + ",";
                    }
                }

                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>{message}</strong>,
                    time: 8000,
                });
                if (warningMessage !== "") {
                    window.emitter.emit('popper', {
                        type: 'alert',
                        content: <strong>{warningMessage}</strong>,
                        time: 8000,
                    });
                }

                let tableData = this.state.data.filter(function (value, index, arr) {
                    return !ids.includes(value.id);
                });

                this.setState({ data: tableData });

            }).catch((error) => {
                errorPopper(error, tr('save_error'));
            });
        }
        else {
            if (warningMessage !== "") {
                window.emitter.emit('popper', {
                    type: 'alert',
                    content: <strong>{warningMessage}</strong>,
                    time: 8000,
                });
            }
        }



    }

    getProcountorCsv(indexes) {
        let idList = [];
        for (let rowId of indexes) {
            let row = this.state.data[rowId];
            if (true) {
                idList.push(row.id);
            }
            else {
                //some error if needed
                // warningMessage += row.number + ": " + tr('archiving_canceled') + " " + tr("not_paid") + ",";
            }
        }

        if (idList.length > 0) {
            let data = {
                ids: idList
            }

            api({
                method: 'POST',
                url: `storage/order/procountor/getCsv`,
                data: data,
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Procountor.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // console.log(response);

                //different request because if file download
                this.markToSendedToExternal(data);

            }).catch((error) => {
                errorPopper(error, tr('save_error'));
            });
        }
    }

    markToSendedToExternal(data) {
        api({
            method: 'POST',
            url: `storage/order/procountor/csvCreated`,
            data: data,
        }).then((response) => {
            let ids = response.ids;
            let data = this.state.data.map((row) => {
                if (ids.includes(row.id)) {
                    row.sended_to_ext_interface = true;
                }
                return row;
            });
            this.setState({data: data});

        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
    }

    render()
    {
        const orderTableMultiselects = [];
        orderTableMultiselects.push(
            {
                icon: "archive",
                label: tr("archive"),
                action: (indexes) => this.archive(indexes),
                closeAfter: true,
                disabled: !auth.hasModule('clients.edit'),
            },
            {
                    divider: true
            }
        );

        let procountorIsActive = this.props?.apExternalSettings?.prcountorEnabled;
        if (procountorIsActive) {
            orderTableMultiselects.push(
                {
                    icon: "file",
                    label: tr("csv_file"),
                    action: (indexes) => this.getProcountorCsv(indexes),
                    closeAfter: true,
                    disabled: !auth.hasModule('clients.edit'),
                },
                {
                    divider: true
                }
            );
        }
        
        
        
        orderTableMultiselects.push(
            {
                icon: "copy",
                label: tr('copy'),
                action: (indexes) => {
                    this.setState({
                        copyId: this.state.data[indexes[0]].id,
                        newOrderShow: true,
                    })
                },
                unselectAfter: true,
                maxSelectedCount: 1,
            },
            {
                icon: "file-invoice-dollar",
                label: tr('credit_note'),
                action: (indexes) => {
                    this.setState({
                        copyId: this.state.data[indexes[0]].id,
                        newOrderShow: true,
                        isCreditNote: true,
                    })
                },
                unselectAfter: true,
                maxSelectedCount: 1,
            }
        );
        
        
        return (
            <div className="apBox" id="pageSuppliers">
                <div className="apBoxHeader">
                    <h1>
                       { tr('orders') }
                    </h1>
                    <p> { tr('orders_info') } </p>
                    <div className="apBoxCorner">
                        <ApButton color="green"
                            onClick={ () => { this.setState({ newOrderShow: true }) } }
                        > { tr('new_order') } </ApButton>
                    </div>
                </div>

                <ApInputStack gap="0">
		                	<ApAddon noRightBorder width="50px" labelFor="search">
		                		<SvgIcon icon="search" type="solid" />
		                	</ApAddon>
			                <ApSelect
                                value=""
                                optionRenderer={ (item) => {
                                	
                                		return (
                                			<div className="searchOption orders">
	                                			<SvgIcon className="typeIcon" icon="receipt" type="solid" />
                                                <div className="itemnumber">
                                                    <strong>{ item.number }</strong>
                                                </div>
                                                <div className="iteminfo">
                                                    <strong> { item.name }</strong><br />
                                                    <small> { item.project_code } {sqlToDateInput(item.date)}</small>
                                                </div>
	                                		</div>
                                		);
                                }}
                                onChange={ ( item ) => { this.goToEdit( item.id ) } }
                                objKeyId="id"
                                clearable
                                noEmptySearch
                                debounce={500}
                                apiUrl="search/orders"
                            />
		                </ApInputStack>

                <ApTabs selected={this.state.selectedTab} onChange={this.onTabChange}>
                    <ApTab icon="list" label={ tr('list') } mountOnSelect>
                        <div className="padding">

                            <ApReactTable
                                rememberId="Orders"
                                defaultSorted={[{id: 'id', desc: true}]}
                                showFiltersInitially={ true }
                                manual
                                onFetchData={( state ) => { 
                                    if (orderCurrentPageIsLocked()) {
                                        state.page = getOrderCurrentPage()
                                    }

                                    if (!orderCurrentPageIsLocked()) {  
                                        setOrderCurrentPage(state.page)
                                    }

                                    this.getData(state)
                                } }
                                pages={ this.state.pages }
                                loading={ this.state.loading }
                                data={ this.state.data }
                                columns={ this.state.columns }
                                filterable
                                minRows={ 4 }
                                multiselect={orderTableMultiselects}
                            />

                        </div>
                    </ApTab>
                    <ApTab icon="archive" label={ tr('archived_multiple') } align="right" mountOnSelect>
                        <div className="padding">

                            <ApReactTable
                                defaultSorted={[{id: 'id', desc: true}]}
                                manual
                                onFetchData={( state ) => { this.getData( state, true ) } }
                                pages={ this.state.pages }
                                loading={ this.state.loading }
                                data={ this.state.data }
                                columns={ this.state.columns }
                                filterable
                                minRows={ 4 }
                                showFiltersInitially={ true }
                            />

                        </div>
                    </ApTab>
                    {auth.hasModule('sales') && auth.hasModule('sales.checkout') && <ApTab icon="archive" label={tr('cash_register_sales_archive')} align="right" mountOnSelect>
                        <div className="padding">

                            <ApReactTable
                                defaultSorted={[{ id: 'id', desc: true }]}
                                manual
                                onFetchData={(state) => { this.getData(state, true, true) }}
                                pages={this.state.pages}
                                loading={this.state.loading}
                                data={this.state.data}
                                columns={this.state.columns}
                                filterable
                                minRows={ 4 }
                            />

                        </div>
                    </ApTab>}
                    { (auth.hasModule('sales') && auth.hasModule('sales.onlinestore') && this.props.apOrderSettings.woocommerce_enabled) &&
                    <ApTab icon="shopping-cart" label={ tr('online_store') } align="left" mountOnSelect>
                        <div className="padding">
                            {this.props.apOrderSettings.woocommerce_enabled ? <WooCommerceOrders /> : null }
                        </div>
                    </ApTab>}
                </ApTabs>

                <NewOrder
                    show={ this.state.newOrderShow }
                    onClose={ () => { this.setState({ newOrderShow: false, copyId: null, isCreditNote: false }) } }
                    onSave={ () => { } }
                    selectProject={true}
                    copyId={this.state.copyId}
                    isCreditNote={this.state.isCreditNote}
                />

                <CommentDialog
                    show={this.state.commentDialogShow}
                    meta={this.state.commentDialogMeta}
                    selectAcceptor={this.state.commentDialogAcceptor}
                    onClose={() => this.setState({ commentDialogShow: false })}
                    onSave={this.archiveOrders}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    apOrderSettings: state.apOrderSettings,
    apExternalSettings: state.apExternalSettings,
})

export default connect(mapStateToProps)(Orders);
