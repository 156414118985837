import { tr } from 'services/Helpers/Helpers.js';
import Home from 'modules/Home/Home.js';
import config from 'env.js';
import HomeDemo                     from 'modules/Home/Home_BAK.js';

import UserPages                from 'modules/UserPages/UserPages.js';
import UserChangePassword       from 'modules/UserPages/ChangePassword.js';

import StorageComponents        from 'modules/Storage/Components/Components.js';

import StoragePurchaseOrders    from 'modules/Storage/PurchaseOrders/PurchaseOrders.js';
import ImportPOlist             from 'modules/Storage/PurchaseOrders/ImportPurchaseOrder/ImportPOlist.js';
import ImportPO                 from 'modules/Storage/PurchaseOrders/ImportPurchaseOrder/ImportPO.js';
import StoragePurchaseOrderEdit from 'modules/Storage/PurchaseOrders/EditPurchaseOrder/EditPurchaseOrder.js';
import StoragePurchaseOrderSettings from 'modules/Storage/PurchaseOrders/Settings/Settings.js';
import StorageLocations         from 'modules/Storage/Locations/Locations.js';
import StorageArrivals          from 'modules/Storage/Arrivals/Arrivals.js';
import StorageArrivalEdit       from 'modules/Storage/Arrivals/ArrivalEdit/ArrivalEdit.js';
import StorageSuppliers         from 'modules/Storage/Suppliers/Main.js';
import StorageOrders            from 'modules/Storage/Orders/Orders.js';
import StorageOrder             from 'modules/Storage/Orders/EditOrder/EditOrder.js';
import StorageOrderSettings     from 'modules/Storage/Orders/Settings/Settings.js';
import Transports               from 'modules/Storage/Transports/Transports.js'
import TransportEdit            from 'modules/Storage/Transports/Edit/TransportEdit.js'
import StorageReader            from 'modules/Storage/Reader/StorageReader.js'

import StorageDeliveries        from 'modules/Storage/Deliveries/Deliveries.js';
import StorageDeliveryEdit      from 'modules/Storage/Deliveries/DeliveryEdit/DeliveryEdit.js';
import StorageDemands           from 'modules/Storage/Demands/Demands.js';
import StorageTracking          from 'modules/Storage/Tracking/Tracking.js';

import StorageSettings          from 'modules/Storage/Settings/Settings.js';
import Consumables from 'modules/Storage/Consumables/Consumables.js';


import WorkHours                from 'modules/WorkHours/WorkHours.js';
import Approve                  from 'modules/WorkHours/Approve/Approve.js';
import Confirm                  from 'modules/WorkHours/Confirm/Confirm.js';
import SalaryExtras             from 'modules/WorkHours/SalaryExtras/SalaryExtras.js';
import Payroll                  from 'modules/WorkHours/Payroll/Payroll.js';
import Holidays                 from 'modules/WorkHours/Holidays/Holidays.js';
import WorkHourSettings         from 'modules/WorkHours/Settings/Settings.js';

import TimetrackingEntry        from 'modules/Timetracking/Entry/Entry.js';
import TimetrackingApprove      from 'modules/Timetracking/Approve/Main.js';
import TimetrackingConfirm      from 'modules/Timetracking/Confirm/Main.js';
import TimetrackingSalaryExtras from 'modules/Timetracking/SalaryExtras/SalaryExtras.js';
import TimetrackingPayrollMain  from 'modules/Timetracking/Payroll/tabs/Main.js';
import TimetrackingPayrollBatches   from 'modules/Timetracking/Payroll/PayrollBatches/PayrollBatches.js';
import TimetrackingSettings     from 'modules/Timetracking/Settings/Settings.js';
import TimetrackingOverview from 'modules/Timetracking/Overview/Overview.js';
import TimetrackingTimeAttendance from 'modules/Timetracking/TimeAttendance/TimeAttendance.js';
// import TimetrackingSubordinatesOverview     from 'modules/Timetracking/SubordinatesOverview/SubordinatesOverview.js';

import Projects                 from 'modules/Projects/Projects.js';
import ProjectReports           from 'modules/Projects/Reports/Reports.js';
import ProjectSettings          from 'modules/Projects/Settings/Settings.js';
// import ProjectOfferCalculation  from 'modules/Projects/OfferCalculation/OfferCalculation.js';
import ProjectManagement        from 'modules/Projects/Management/Management.js';
// import ProjectTracking          from 'modules/Projects/Tracking/Tracking.js';

import ProjectPersonnel         from 'modules/Projects/Management/Personnel/PersonnelManagement.js';

import CrmMain                  from 'modules/Crm/tabs/Main.js';
// import Crm                      from 'modules/Crm/Crm.js';
import CrmCompany               from 'modules/Crm/Company/Company.js';
import CrmGroups                from 'modules/Crm/Groups/Groups.js';
import CrmSettings              from 'modules/Crm/CustomerSettings/CustomerSettings.js';
import Extranet                 from 'modules/Crm/Extranet/Extranet.js'
import Users                    from 'modules/UserManagement/Users.js';
import UserGroups               from 'modules/UserManagement/UserGroups/UserGroups.js';
import UserWorkPermits          from 'modules/UserManagement/UserWorkPermits/UserWorkPermits.js';
import UserPermissions          from 'modules/UserManagement/UserPermissions/UserPermissions.js';

import CustomerManagement       from 'modules/Management/CustomerManagement.js';

import GeneralSettings          from 'modules/Company/Settings/GeneralSettings/GeneralSettings.js';
import WorkPermits              from 'modules/Company/Settings/WorkPermits/WorkPermits.js';
import ExtranetSettings         from 'modules/Company/Settings/Extranet/ExtranetSettings.js';

import BugReports               from 'modules/Management/BugReports.js';
import ExcelImport              from 'modules/Management/ExcelImport.js';
import FrontErrors              from 'modules/Management/FrontErrors.js';
import UserTracking             from 'modules/Management/UserTracking/UserTracking.js';
import WholesaleLogs            from 'modules/Management/WholesaleLogs/WholesaleLogs.js';
import LoginLogs                from 'modules/Management/LoginLogs/LoginLogs.js';
import BillOfUse                from 'modules/Management/BillOfUse/BillOfUse.js';
import News                     from 'modules/Management/News/News.js';

import Reports                  from 'modules/Reports/Reports.js';
import Billable                 from 'modules/Billable/Billable.js';

import AssignmentSettings       from 'modules/Company/Settings/Assignments/AssignmentSettings.js';
import Assignments              from 'modules/Assignments/Assignments.js';
import AssignmentPage           from 'modules/Assignments/AssignmentPage/AssignmentPage.js';
import EditAssignment           from 'modules/Assignments/EditAssignment/EditAssignment.js';
import ExtAssignmentPage        from 'modules/Assignments/ExtAssignments/ExtAssignmentPage.js';

import TaskQueue                from 'modules/TaskQueue/TaskQueue.js';

//import Notifications            from 'modules/Notifications/Notifications.js';
import Notifications            from 'modules/Notifications/Notifications.js';
import NotificationSettings     from 'modules/Notifications/Settings/NotificationSettings.js';
import Checkout                 from 'modules/Checkout/Checkout.js';
import Collections              from 'modules/Checkout/Collections/Collections.js';

import Viestisuora     			from 'modules/viestisuora/viestisuora.js';
import CRMViestisuora     		from 'modules/Crm/Viestisuora/Viestisuora.js';
import ExtInterfaces     		from 'modules/Management/ExtInterfaces.js';
import Workgroups               from 'modules/Workgroups/Workgroups';
import Translations             from 'modules/Management/Translations';
import MainChat                 from 'modules/Chat/MainChat';

import costCenterSettings       from 'modules/CostCenter/Settings/CostCenterSettings.js';
import CashFlowForecast         from 'modules/CashFlowForecast/CashFlowForecast';
import OnlineStore              from 'modules/OnlineStore/OnlineStore';
import WooCommerceOrder from 'modules/Storage/Orders/OnlineStore/WooCommerceOrder';
import TimetrackingAssignments from 'modules/Timetracking/Assignments/TimetrackingAssignments';
import ContractBillingPage from 'modules/ContractBilling/ContractBillingPage';


// Routing is made by react-router4
// https://reacttraining.com/react-router/web/api/Route/path-string
//
// path:        passed to react-route
// component:   component that will be displayed
// menu:        marks menu with that id as selected
// breadcrumbs: array of breadcrumbs to be added at the top of the page

const routes = [
    {
        path: '/',
        component: Home,
        title: tr('frontpage'),
        menu: 'home',
    },
    {
        path: '/chat/:roomId?',
        component: MainChat,
        title: tr('message'),
        //menu: 'home',
    },

    // User profile page
    {
        path: '/user',
        component: UserPages,
        title: tr('user_page'),
    },
    {
        path: '/user/changepassword',
        component: UserChangePassword,
        title: tr('change_password'),
    },

    // Työajanhallinta (new)
    {
        path: '/timetracking/entry/:year?/:week?',
        component: TimetrackingEntry,
        title: tr('own_working_time'),
        menu: 'timetracking.entry',        
    },
    {
        path: '/timetracking/approval',
        exact: true,
        component: TimetrackingApprove,
        title: tr('approval_of_hours'),
        menu: 'timetracking.approval',
    },
    {
        path: '/timetracking/confirm/',
        component: TimetrackingConfirm,
        title: tr('administrator_approval'),
        menu: 'timetracking.confirm',
    },
    {
        path: '/timetracking/salary_extras/',
        component: TimetrackingSalaryExtras,
        title: tr('fees_supplements'),
        menu: 'timetracking.salary_extras',
    },
    {
        path: '/timetracking/payroll/',
        component: TimetrackingPayrollMain,
        title: tr('payroll'),
        menu: 'timetracking.payroll',
    },
    {
        path: '/timetracking/payroll/batches/:id?',
        component: TimetrackingPayrollBatches,
        title: tr('payroll'),
        menu: 'timetracking.payroll',
    },
    {
        path: '/timetracking/settings',
        component: TimetrackingSettings,
        menu: 'timetracking.settings',        
    },
    {
        path: '/timetracking/overview/:date_start?/:date_end?',
        component: TimetrackingOverview,
        title: tr('overview'),
        menu: 'timetracking.overview',        
    },
    {
        path: '/timetracking/time_attendance/',
        component: TimetrackingTimeAttendance,
        title: tr('time_attendance'),
        menu: 'timetracking.time_attendance',
    },
    {
        path: '/workhours/holidays/:form?',
        component: Holidays,
        title: tr('holidays'),
        menu: 'workhours.holidays',
    },
    {
        path: '/timetracking/assignments/',
        component: TimetrackingAssignments,
        menu: 'timetracking.assignments',
    },

    // Työajanhallinta
    {
        path: '/workhours/overview/:year?/:week?',
        component: WorkHours,
        menu: 'workhours.entry',
    },
    {
        path: '/workhours/approval/:year?/:week?',
        component: Approve,
        menu: 'workhours.approval',
    },
    {
        path: '/workhours/confirm/',
        component: Confirm,
        menu: 'workhours.confirm',
    },
    {
        path: '/workhours/salary_extras/',
        component: SalaryExtras,
        menu: 'workhours.salary_extras',
    },
    {
        path: '/workhours/payroll/',
        component: Payroll,
        menu: 'workhours.payroll',
    },

    // Toiminnanohjaus
    {
        path: '/crm/customers',
        component: CrmMain,
        title: tr('customers'),
        menu: 'crm.customers',
    },
    {
        path: '/crm/company/:id/:tab?/:contact?',
        component: CrmCompany,
        title: tr('customers'),
        menu: 'crm.customers',
    },
    {
        path: '/crm/groups/:tab?',
        component: CrmGroups,
        title: tr('customer_groups'),
        menu: 'crm.groups',
    },
    {
        path: '/crm/viestisuora',
        component: CRMViestisuora,
        title: 'Viestisuora',
        menu: 'crm.viestisuora',
    },
    {
        path: '/settings/customers',
        component: CrmSettings,
        title: tr('customer_settings'),
        menu: 'crm.settings',
    },
    {
        path: '/crm/extranet',
        component: Extranet,
        title: 'Extranet',
        menu: 'crm.extranet',
    },
    {
        path: '/storage/components/:tab?',
        component: StorageComponents,
        title: tr('components'),
        menu: 'storage.items',
    },
    {
        path: '/storage/purchase/orders',
        component: StoragePurchaseOrders,
        title: tr('purchase_orders'),
        menu: 'purchases.orders',
    },
    {
        path: '/storage/purchase/orders/import',
        component: ImportPOlist,
        title: tr('purchase_orders'),
        menu: 'purchases.orders',
    },
    {
        path: '/storage/purchase/order/import/:id?',
        component: ImportPO,
        title: tr('purchase_order'),
        menu: 'purchases.orders',
    },
    {
        path: '/storage/purchase/order/:type/:id?',
        component: StoragePurchaseOrderEdit,
        title: tr('purchase_order'),
        menu: 'purchases.orders',
    },
    {
        path: '/storage/locations',
        component: StorageLocations,
        title: tr('storage_locations'),
        menu: 'storage.locations',
    },
    {
        path: '/storage/consumables',
        component: Consumables,
        title: tr('storage_locations'),
        menu: 'storage.consumables',
    },
    {
        path: '/storage/purchase/suppliers',
        component: StorageSuppliers,
        title: tr('suppliers'),
        menu: "purchases.suppliers",
    },
    {
        path: '/storage/purchase/demands/:mode?/:id?',
        component: StorageDemands,
        title: tr('order_requirements'),
        menu: "purchases.list",
    },
    {
        path: '/storage/orders/:tab?',
        component: StorageOrders,
        title: tr('orders'),
        menu: "orders",
    },
    {
        path: '/orders/checkout',
        component: Checkout,
        title: tr('checkout'),
        menu: "financial.sales.checkout",
    },
    {
        path: '/orders/checkout/collection',
        component: Collections,
        title: tr('collection'),
        menu: "financial.sales.collection",
    },
    {
        path: '/cash-flow-forecast',
        component: CashFlowForecast,
        title: tr('cash_flow_forecast'),
        menu: "cash-flow-forecast",
    },
    {
        path: '/online-store',
        component: OnlineStore,
        title: tr('online_store'),
        menu: "online-store",
    },
    {
        path: '/storage/order/:type/:id?',
        component: StorageOrder,
        title: tr('order'),
        menu: "orders",
    },
    {
        path: '/storage/contract-billing',
        component: ContractBillingPage,
        title: tr('contract_billing'),
        menu: "contract_billing",
    },
    {
        path: '/storage/online_store/order/:id?',
        component: WooCommerceOrder,
        title: tr('order'),
        menu: "orders",
    },

    {
        path: '/storage/logistic/arrivals',
        component: StorageArrivals,
        title: tr('logistics_arrivals'),
        menu: "logistic.in",
    },
    {
        path: '/storage/logistic/arrival/:type/:id?',
        component: StorageArrivalEdit,
        title: tr('logistics_arrivals'),
        menu: "logistic.in",
    },
    {
        path: '/storage/logistic/deliveries',
        component: StorageDeliveries,
        title: tr('logistics_deliveries'),
        menu: "logistic.out",
    },
    {
        path: '/storage/logistic/delivery/:type/:id?',
        component: StorageDeliveryEdit,
        title: tr('logistics_deliveries'),
        menu: "logistic.out",
    },
    {
        path: '/storage/logistic/tracking',
        component: StorageTracking,
        title: tr('logistics_tracking'),
        menu: "logistic.tracking",
    },
    {
        path: '/storage/transports',
        component: Transports,
        title: tr('storage_transfers'),
        menu: "logistic.transmit",
    },
    {
        path: '/storage/transports/edit',
        component: TransportEdit,
        title:  tr('storage_transfers'),
        menu: "logistic.transport",
    },
    {
        path: '/storage/reader',
        component: StorageReader,
        title: tr('logistics_reader'),
        menu: "logistic.storage.reader",
    },

    {
        path: '/projects/:id?/:form?/:tab?',
        component: Projects,
        title: tr('projects'),
        menu: 'projects',
    },
    /*
    {
        path: '/projects/offercalculation/:id?/:tab?',
        component: ProjectOfferCalculation,
        menu: 'projects.projects',
    },
    {
        path: '/projects/management/:id?/:tab?',
        component: ProjectManagement,
        menu: 'projects.projects',
    },
    {
        path: '/projects/tracking/:id?/:tab?',
        component: ProjectTracking,
        menu: 'projects.projects',
    },
    {
        path: '/project/:id/offercalculation/:tab?',
        component: ProjectOfferCalculation,
        menu: 'projects.projects',
    },
    {
        path: '/project/:id/management/:tab?',
        component: ProjectManagement,
        menu: 'projects.projects',
    },
    {
        path: '/project/:id/tracking/:tab?',
        component: ProjectTracking,
        menu: 'projects.projects',
    },
    */
    {
        path: '/projects/reports',
        component: ProjectReports,
        menu: 'projects.reports',
    },

    {
        path: '/projectspersonnel',
        component: ProjectPersonnel,
        title: tr('production_management'),
        menu: 'projects.personnel'
    },

    // HR
    {
        path: '/hr/users',
        component: Users,
        title: tr('personnel'),
        menu: 'hr.users',
    },
    {
        path: '/hr/usergroups',
        component: UserGroups,
        title: tr('user_groups_management'),
        menu: 'hr.usergroups',
    },
    {
        path: '/hr/permits',
        component: UserWorkPermits,
        title: tr('permits'),
        menu: 'hr.permits'
    },
    {
        path: '/hr/permissions',
        component: UserPermissions,
        title: tr('user_permissions'),
        menu: 'hr.permissions',
    },
    /*
    {
        path: '/recruitment/advertisements',
        component: TODO,
        title: 'Rekrytointi',
        menu: 'recruitment.advertisements',
    },
    {
        path: '/recruitment/applications',
        component: TODO,
        title: 'Rekrytointi',
        menu: 'recruitment.applications',
    },
    */

    {
        path: '/billable/:company?/:date?',
        component: Billable,
        title: tr('billing_work'),
        menu: 'financial.billable',
    },
    {
        path: '/notifications',
        component: Notifications,
    },
    {
        path: '/reports/:foobar?',
        component: Reports,
        menu: 'reports',
    },

    // Toimeksiannot

    {
        path: '/assignments/:tab?',
        component: Assignments,
        title: tr('assignments'),
        menu: 'assignments'
    },
    {
        path: '/assignments/:id?/:tab?',
        component: AssignmentPage,
        menu: 'assignments.assignmentpage'
    },
    {
        path: '/assignmentsNew/:newAssignment?',
        component: Assignments,
        menu: 'assignments'
    },
    {
        path: '/assignmentsNew/project/:id?',
        component: Assignments,
        menu: 'assignments'
    },
    {
        path: '/ext/assignments/:id?/:tab?',
        component: ExtAssignmentPage,
    },

    {
        path:'/workgroups',
        component: Workgroups,
        menu: 'workgroups',
    },

    {
        path:'/taskqueue',
        component: TaskQueue,
        menu: 'taskqueue',
    },
    
    {
        path: '/viestisuora/',
        component: Viestisuora,
        title: 'Viestisuora',
        menu: 'viestisuora'
    },

    // Yritys
    {
        path: '/settings/general',
        component: GeneralSettings,
        title: tr('general_settings'),
        menu: 'company.settings.general',
    },
    {
        path: '/settings/workhour',
        component: WorkHourSettings,
        menu: 'company.settings.workhour',
    },
    {
        path: '/settings/workpermits',
        component: WorkPermits,
        title: tr('work_permit_settings'),
        menu: 'company.settings.workpermits',
    },
    {
        path: '/settings/storage/:tab?',
        component: StorageSettings,
        title: tr('storage_settings'),
        menu: 'company.settings.storage',
    },
    {
        path: '/settings/purchase',
        component: StoragePurchaseOrderSettings,
        title: tr('purchase_settings'),
        menu: 'company.settings.purchase',
    },
    {
        path: '/settings/order',
        component: StorageOrderSettings,
        title: tr('sales_settings'), 
        menu: 'company.settings.order',
    },
    {
        path: '/settings/costCenter',
        component: costCenterSettings,
        title: tr('cost_center_settings'),
        menu: 'company.settings.costCenter',
    },
    {
        path: '/settings/assignments',
        component: AssignmentSettings,
        title: 'Toimeksiantoasetukset',
        menu: 'company.settings.assignments',
    },
    {
        path: '/settings/projects/:tab?',
        component: ProjectSettings,
        title: tr('project_settings'),
        menu: 'company.settings.projects',
    },
    {
        path: '/settings/extranet',
        component: ExtranetSettings,
        title: tr('extranet_settings'),
        menu: 'company.settings.extranet',
    },
    {
        path: '/settings/notifications',
        component: NotificationSettings,
        title: tr('notification_settings'),
        menu: 'company.settings.notifications',
    },

    // Pääkäyttäjä
    {
        path: '/management/customermanagement',
        component: CustomerManagement,
        menu: 'admin.customers',
    },
    {
        path: '/management/billofuse',
        component: BillOfUse,
        menu: 'admin.billofuse',
    },
    {
        path: '/management/bugs',
        component: BugReports,
        menu: 'admin.bugs',
    },
    {
        path: '/management/import',
        component: ExcelImport,
        menu: 'admin.import',
    },
    {
        path: '/management/fronterrors',
        component: FrontErrors,
        menu: 'admin.fronterrors',
    },
    {
        path: '/management/usertracking',
        component: UserTracking,
        menu: 'admin.usertracking',
    },
    {
        path: '/management/wholesales',
        component: WholesaleLogs,
        menu: 'admin.wholesales',
    },
    {
        path: '/management/loginslog',
        component: LoginLogs,
        menu: 'admin.loginslog',
    },
    {
        path: '/management/news',
        component: News,
        menu: 'admin.news',
    },
    {
        path: '/management/interfaces',
        component: ExtInterfaces,
        menu: 'admin.interfaces',
    },
    {
        path: '/management/translations',
        component: Translations,
        menu: 'admin.translations',
    },

//DEMO

    {
        path: '/demo',
        component: config.dev?HomeDemo:Home,
        title: tr('frontpage'),
        menu: 'home',
    },



];

export default routes;
