import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import { Collapse }    from 'react-bootstrap';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import Files from './Files/Files.js';

import api           from 'services/Api/Api.js';
import { keyExists,
         pluralizeCount,
         tr,
         validateDate }     from 'services/Helpers/Helpers.js'

import { ApInput,
         ApInputStack,
         ApAddon }          from 'common/ApInput/ApInput.js';
import ApSelect             from 'common/ApSelect/ApSelect.js';
import ApSwitch             from 'common/ApSwitch/ApSwitch.js';
import ApButton             from 'common/ApButton/ApButton.js';
import SvgIcon              from 'common/SvgIcon/SvgIcon.js';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';
import { connect } from 'react-redux';

/*
TODO features: 
    - Show probation end date
    - Warning when salary is not reasonable (wrong unit maybe?)
    - ... 
*/

class ContractDetail extends React.Component
{
    constructor(props)
    { 
        super(props);
        this.state = {
            filesModalShow: false,
            
        };
        autoBind(this);
    }

    componentDidMount() {
        //this.getRoles();
    }

    getFileButton()
    {
        if( this.props.loading ) return null;
        return <div className="fileButtonContainer" onClick={ () => this.setState({ filesModalShow: true }) }>
            <ApTooltip text={tr('contracts_of_employment')} position="topleft">
                <ApButton className="fileButton" color="white">
                    <SvgIcon icon="file-alt" type="solid" />
                </ApButton>
            </ApTooltip>
        </div>
    }
/*
    getRoles()
    {
        api({
            method: 'post',
            url: 'usermanagement/rolesandagreements'
        }).then((response) => {
            const roleOptions = [
                {value: null, label: ""}
            ];
            const collectiveAgreementOptions = [
                {value: null, label: ""}
            ];

            response.personnelGroups.forEach(role => {
                roleOptions.push({
                    value: role.id,
                    label: role.name
                });
            });
            response.collectiveAgreements.forEach(item => {
                collectiveAgreementOptions.push({
                    value: item.id,
                    label: item.name
                });
            });
            this.setState({roles: roleOptions, collectiveAgreements: collectiveAgreementOptions});
        }).catch((error) => {
            console.log('Error while fetching roles.')
        });
    }*/
    renderLabel() {
        return <span>{tr('cost_center')}</span>
    };

    renderLabelValue(code) {
        return code.full_name;
        if (!this.currentlyInputingTail()) return code.fullName;
        const separator = this.props.codeSeparator ? this.props.codeSeparator : '';
        const tail = this.props.tail ? this.props.tail : ''
        return `${code.code}${separator}${tail}`;
    };
    currentlyInputingTail() {
        // When code format is free, we only input the tail
        if (this.props.codeLimit && this.props.codeLimit.is_free)
            return true;
        if (!this.props.code) return false;
        return (this.props.code.ancestors.length + 1 === this.props.codeLevelCount);
    }

    render()
    {
        
        let start = keyExists( this.props.data, "contract_detail.contract_start", true );
        let end = keyExists( this.props.data, "contract_detail.contract_end", true );
        

        // let contractValid = true;
        // if( validateDate( start ) && moment().isBefore( moment( start, "DD.MM.YYYY" ), 'day' ) )
            // contractValid = false;
        // if( validateDate( end ) && moment().isAfter( moment( end, "DD.MM.YYYY" ), 'day' ) )
            // contractValid = false;


        let contractTimeInfo = [];
        if( validateDate( start ) )
        {
            const startOffset = moment().diff(moment(start, 'DD.MM.YYYY'), 'days', true);
            const startDate = moment(start, 'DD.MM.YYYY');
            const endDate = moment(end, 'DD.MM.YYYY');
            
            if( startOffset > 0 )
                contractTimeInfo.push(tr('employment_relationship_started') + " " + startDate.fromNow());
            
            else if ( startOffset < 0 )
                contractTimeInfo.push(tr('employment_relationship_starting') + " " + startDate.fromNow());

            if( validateDate( end ) )
            {
                const endOffset = moment().diff( moment( end, 'DD.MM.YYYY' ), 'days', true );
     
                if( endOffset > 0 )
                    contractTimeInfo.push(tr('employment_relationship_ended') + " " + endDate.fromNow() );
                
                else if ( endOffset < 0 )
                    contractTimeInfo.push(tr('employment_relationship_ending') + " " + endDate.fromNow() ); 

                contractTimeInfo.push(tr('employment_relationship_total_duration')+" " + startDate.from(endDate,true));
            }
        }

        let approverRequired = true;
        if (keyExists(this.props.data, "contract_detail.hour_entry_approval_required")) {
            if (this.props.data.contract_detail.hour_entry_approval_required === true) {
                approverRequired = true;
            } else {
                approverRequired = false;
            }
        }
        let showSocialExpenses = true;
        if (this.props.data?.contract_detail?.role_id) {
            let find = this.props.roles.find(role => role.value == (this.props.data?.contract_detail?.role_id));
            showSocialExpenses = find?.noPayroll?false:true;
        }

      
        
        let validationState = this.props.validationState;
        
        
        return (
            <div className="padding">
                <div className="clear">

                    <div className="apColumn w50">
                        <div className="padding">

                            <h4 className="formHeader">{ tr('employment_info') }</h4>

                            <ApInput
                                type="text"
                                id="job_description"
                                name="job_description"
                                label={ tr('job_description') }
                                value={ keyExists( this.props.data, "contract_detail.job_description", true ) }
                                onChange={ ( e ) => { this.props.onChange( "contract_detail.job_description", e.target.value ) } }
                                loading={ this.props.loading }
                                disabled={ this.props.loading }
                                tooltip={this.props.validator.getText("data.contract_detail.job_description")}
                                validationState={this.props.validator.getState("data.contract_detail.job_description")}
                            />

                            <ApInputStack gap="0">
                                <ApAddon noRightBorder>
                                    { tr('personnel_group') }
                                </ApAddon>
                                <ApInput
                                    type="select"
                                    id="role_id"
                                    name="role_id"
                                    options={this.props.roles}
                                    value={keyExists(this.props.data, "contract_detail.role_id", true)}
                                    onChange={(e) => {
                                        this.props.onChange("contract_detail.role_id", e.target.value)
                                    }}
                                    loading={this.props.loading}
                                    disabled={this.props.loading}
                                    tooltip={this.props.validator.getText("data.contract_detail.role_id")}
                                    validationState={this.props.validator.getState("data.contract_detail.role_id")}
                                    
                                />
                            </ApInputStack>

                            {this.props.collectiveAgreements.length > 1 && <ApInputStack gap="0">
                                <ApAddon noRightBorder>
                                    { tr('collective_agreement') }
                                </ApAddon>
                                <ApInput
                                    type="select"
                                    id="collectiveAgreement_id"
                                    name="collectiveAgreement_id"
                                    options={this.props.collectiveAgreements}
                                    value={keyExists(this.props.data, "contract_detail.collective_agreement_id", true)}
                                    onChange={(e) => {
                                        this.props.onChange("contract_detail.collective_agreement_id", e.target.value)
                                    }}
                                    loading={this.props.loading}
                                    disabled={this.props.loading}
                                    tooltip={this.props.validator.getText("data.contract_detail.collective_agreement_id")}
                                    validationState={this.props.validator.getState("data.contract_detail.collective_agreement_id")}
                                    
                                />
                            </ApInputStack>}

                            {this.props.collectiveAgreements.length < 2 && <ApInput
                                type="text"
                                id="collectivebargain"
                                name="collectivebargain"
                                label={ tr('collective_agreement') }
                                value={ keyExists( this.props.data, "contract_detail.collectivebargain", true ) }
                                onChange={ ( e ) => { this.props.onChange( "contract_detail.collectivebargain", e.target.value ) } }
                                loading={ this.props.loading }
                                disabled={this.props.loading}
                                tooltip={this.props.validator.getText("data.contract_detail.collectivebargain")}
                                validationState={this.props.validator.getState("data.contract_detail.collectivebargain")}
                            />}

                            <ApInput
                                type="text"
                                id="probation"
                                name="probation"
                                label={ tr('length_of_probation') }
                                tooltip={ tr('probation_note1') + ( keyExists( this.props.data, "contract_detail.contract_end", true ) ? ` ${tr('probation_note2')}` : "" ) }
                                value={ keyExists( this.props.data, "contract_detail.probation", true ) }
                                onChange={ ( e ) => { this.props.onChange( "contract_detail.probation", e.target.value ) } }
                                loading={ this.props.loading }
                                disabled={ this.props.loading }
                            />

                            <ApInputStack>
                                <ApInput
                                    type="date"
                                    id="contract_start"
                                    name="contract_start"
                                    label={ tr('start_date') }
                                    value={ keyExists( this.props.data, "contract_detail.contract_start", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contract_detail.contract_start", e.target.value ) } }
                                    tooltip={ this.props.validator.getText( "data.contract_detail.contract_start" ) }
                                    validationState={ this.props.validator.getState( "data.contract_detail.contract_start" ) }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />

                                <ApInput
                                    type="date"
                                    id="contract_end"
                                    name="contract_end"
                                    label={ tr('end_date') }
                                    value={ keyExists( this.props.data, "contract_detail.contract_end", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contract_detail.contract_end", e.target.value ) } }
                                    tooltip={ this.props.validator.getText( "data.contract_detail.contract_end" ) }
                                    validationState={ this.props.validator.getState( "data.contract_detail.contract_end" ) }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                    clearable
                                />
                            </ApInputStack>
                            {/* {
                                keyExists(this.props, 'data.isNetvisorPayroll', true, false)
                                && keyExists(this.props.data, "contract_detail.contract_end", true)
                                && <ApInputStack>
                                    <ApInput
                                        width="50%"
                                        options={[
                                            { value: null, label: null },
                                            { value: 2, label: tr('contract_end_retirement') },
                                            { value: 4, label: tr('contract_end_own_request') },
                                            { value: 5, label: tr('contract_end_attr_to_employee') },
                                            { value: 6, label: tr('contract_end_fixed_term') },
                                            { value: 11, label: tr('contract_end_financial_production') },
                                            { value: 12, label: tr('contract_end_mutual_agreement') },
                                            { value: 13, label: tr('other_reason') },
                                        ]}
                                        type="select"
                                        id="netvisor_contract_end_reason_id"
                                        name="netvisor_contract_end_reason_id"
                                        label={tr('payslip_delivery_method')}
                                        value={keyExists(this.props.data, "contract_detail.netvisor_contract_end_reason_id", true)}
                                        onChange={(e) => { this.props.onChange("contract_detail.netvisor_contract_end_reason_id", e.target.value) }}
                                        tooltip={this.props.validator.getText("data.contract_detail.netvisor_contract_end_reason_id")}
                                        validationState={this.props.validator.getState("data.contract_detail.netvisor_contract_end_reason_id")}
                                        loading={this.props.loading}
                                        disabled={this.props.loading}
                                    />
                                </ApInputStack>
                            } */}
                            
                            {contractTimeInfo.length > 0 &&
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    {contractTimeInfo.map((line, index) => <div key={index}>{line}</div>)}
                                </div>
                            }
                            {keyExists(this.props.data, "contract_detail.cost_center_enabled", true)&&
                            <ApNestedSelect
                                label={this.renderLabel}
                                valueRenderer={this.renderLabelValue}
                                value={keyExists(this.props.data, "contract_detail.cost_center", true)}
                                parentRenderer="value"
                                parentTooltipRenderer="name"
                                optionRenderer={(item) => {
                                    return <div className="codeOption">
                                        <span className="name">{item.nameTree}</span>
                                        <span className="code">{item.code}</span>
                                    </div>
                                }}
                                onChange={(e) => { this.props.onChange("contract_detail.cost_center", e)}}
                                apiUrl="costCenter/search"
                                loading={this.props.loading}
                                disabled={this.props.loading || this.props.skipped}
                                tooltip={this.props.validator.getText("data.contract_detail.cost_center")}
                                validationState={this.props.validator.getState("data.contract_detail.cost_center")}
                                //tailInput={tailInput}
                            />}

                            
                            
                            <br />

                            {this.props.data.can_see_salary && 
                            <>
                            <div style={{overflowX: 'auto'}}>
                                <div style={{minWidth: 500}}>
                                    <ApInputStack gap="0" >
                                        <ApAddon width="120px" noRightBorder>
                                            { tr('time_rate') }
                                        </ApAddon>
                                        <ApInput
                                            noRightBorder
                                            type="number"
                                            id="salary"
                                            name="salary"
                                            align="right"
                                            value={ keyExists( this.props.data, "contract_detail.salary", true ) }
                                            onChange={ ( e ) => { this.props.onChange( "contract_detail.salary", e.target.value ) } }
                                            tooltip={ this.props.validator.getText( "data.contract_detail.salary" ) }
                                            validationState={ this.props.validator.getState( "data.contract_detail.salary" ) }
                                            loading={ this.props.loading }
                                            disabled={ this.props.loading }
                                            
                                        />
                                        <ApInput
                                            width="100px"
                                            noLeftBorder
                                            type="select"
                                            options={[
                                                { value: "monthly", label: "€/" + tr('month_abbr') },
                                                { value: "weekly", label: "€/" + tr('week_abbr') },
                                                { value: "daily", label: "€/" + tr('day_abbr') },
                                                { value: "hourly", label: "€/h" },
                                            ]}
                                            id="salary_unit"
                                            name="salary_unit"                                           
                                            value={ this.props.data.contract_detail.regular_worktime ? keyExists( this.props.data, "contract_detail.salary_unit", true ):"hourly" }
                                            onChange={ ( e ) => { this.props.onChange( "contract_detail.salary_unit", e.target.value ) } }
                                            loading={ this.props.loading }
                                            disabled={ this.props.loading || !this.props.data.contract_detail.regular_worktime}
                                            
                                            
                                        />
                                        <ApInput
                                            gapLeft={10}
                                            width="80px"
                                            type="text"
                                            id="salary_hourly"
                                            name="salary_hourly"
                                            align="right"
                                            value={ keyExists( this.props.data, "contract_detail.salary_hourly", true ) }
                                            readOnly
                                        />
                                        <ApAddon noLeftBorder width="80px">
                                            €/h
                                        </ApAddon>
                                    </ApInputStack>
                                </div>
                            </div>
                            {/* <Collapse in={ keyExists( this.props.data, "contract_detail.salary_unit", true ) !== "hourly" }>
                                <div>
                                    <div className="salaryMultiplier">
                                      
                                        <ApInputStack gap="0">
                                            <ApAddon custom>
                                                <strong>{ tr('hourly_rate_multiplier') }</strong><br />
                                                <small>{ tr('hourly_rate_multiplier_note') }</small>
                                            </ApAddon>
                                            <ApInput
                                                width="100px"
                                                type="number"
                                                id="salary_hourly_divider"
                                                name="salary_hourly_divider"
                                                align="right"
                                                value={ keyExists( this.props.data, "contract_detail.salary_hourly_divider", true ) }
                                                onChange={ ( e ) => { this.props.onChange( "contract_detail.salary_hourly_divider", e.target.value ) } }
                                                tooltip={ this.props.validator.getText( "data.contract_detail.salary_hourly_divider" ) }
                                                validationState={ this.props.validator.getState( "data.contract_detail.salary_hourly_divider" ) }
                                                loading={ this.props.loading }
                                                disabled={ this.props.loading }
                                            />
                                        </ApInputStack>
                                    </div>
                                </div>
                            </Collapse> */}

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('salary_info1') } <br /><br />
                                { tr('salary_info2') }
                                </div>
                                {showSocialExpenses &&<div>
                                    <ApInputStack gap="0">
                                        <ApAddon width="120px" noRightBorder>
                                            {tr('social_expenses')}
                                        </ApAddon>
                                        
                                    <ApInput
                                        width="100px"
                                        type="text"
                                        id="social_expense_percentage"
                                        name="social_expense_percentage"
                                        align="right"
                                        value={keyExists(this.props.data, "contract_detail.social_expense_percentage", true,"0")+" %"}
                                        // onChange={(e) => { this.props.onChange("contract_detail.social_expense_percentage", e.target.value) }}
                                        // tooltip={this.props.validator.getText("data.contract_detail.social_expense_percentage")}
                                        validationState={this.props.validator.getState("data.contract_detail.social_expense_percentage")}
                                        loading={this.props.loading}
                                        disabled={true}
                                        />
                                    </ApInputStack>
                                </div>}
                            
                            {(this.props.data && 
                            this.props.data.isNetvisorPayroll && 
                            this.props.data.netvisor_salary_parameters && 
                            this.props.data.netvisor_salary_parameters.length) ?
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('netvisor_salary_parameter_reminder') }
                            </div>
                            : ''
                            }
                            </>
                            }


                            {this.props.data.can_see_salary && this.getFileButton() }

                            <div className="apSwitchBlock small">
                                <label htmlFor="project-personnel-switch" className="info">
                                    { tr('project_personnel') }
                                    <small>{ tr('project_personnel_info') }</small>
                                </label>
                                <ApSwitch
                                    small
                                    id="project-personnel-switch"
                                    on={ keyExists( this.props.data, "is_project_personnel", true ) }
                                    onChange={ () => { this.props.onChange( "is_project_personnel", !this.props.data.is_project_personnel ) } }
                                    disabled={ this.props.loading }
                                />
                            </div>

                            <div className="apSwitchBlock small">
                                <label htmlFor="client-manager-switch" className="info">
                                    { tr('customer_manager') }
                                    <small>{ tr('customer_manager_info') }</small>
                                </label>
                                <ApSwitch
                                    small
                                    id="client-manager-switch"
                                    on={ keyExists( this.props.data, "is_client_manager", true ) }
                                    onChange={ () => { this.props.onChange( "is_client_manager", !this.props.data.is_client_manager ) } }
                                    disabled={ this.props.loading }
                                />
                            </div>
                            
                            <Files
                                userId={ this.props.data.id }
                                show={ this.state.filesModalShow }
                                onClose={ () => { this.setState({ filesModalShow: false } ) } }
                                locked={ this.props.locked }
                            />

                        </div>
                    </div>
                    <div className="apColumn w50">
                        <div className="padding">
                            
                            <h4 className="formHeader">{ tr('supervisors_working_hours') }</h4>

                            <ApSelect
                                label={ tr('supervisors') }
                                value={ this.props.data.supervisors ? this.props.data.supervisors : [] }
                                valueRenderer="user"
                                optionRenderer="user"
                                onChange={ ( newSups ) => { this.props.onChange( "supervisors", newSups ) } }
                                objKeyId="id"
                                multiselect
                                clearable
                                apiUrl="usermanagement/user/supervisors"
                                apiData={{ 
                                    company_id: keyExists( this.props.data, "company_id", true ),
                                    exludeIds: keyExists( this.props.data, "id" ) ? [ this.props.data.id ] : [], 
                                }}
                                loading={ this.props.loading }
                                disabled={ this.props.loading }
                                filterNonActives={true}
                            />

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('supervisors_info') }
                            </div>
                            
                            {this.props.data.isHourApprover &&
                            <>
                                <ApSelect
                                    label={ tr('approver_of_hours_substitute') }
                                    value={ keyExists( this.props.data, "contract_detail.hour_approver_substitutes", true, [] ) }
                                    optionRenderer="user"
                                    onChange={ ( substitutes ) => { this.props.onChange( "contract_detail.hour_approver_substitutes", substitutes ) } }
                                    objKeyId="id"
                                    clearable
                                    apiUrl="usermanagement/user/approvers"
                                    apiData={{ 
                                        company_id: keyExists( this.props.data, "company_id", true ),
                                        exludeIds: keyExists( this.props.data, "id" ) ? [ this.props.data.id ] : [], 
                                        is_active: true,
                                    }}
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading || !approverRequired }
                                    multiselect
                                    valueRenderer="user"
                                    objKeySearchable="user"
                                />
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    { tr('approver_of_hours_substitute_info') }
                                </div>
                            </>
                            }

                            <div className={"apFormGroup" + ( keyExists( this.props.data, "contract_detail.hour_entry_required", true ) ? " success" : "" ) }>

                                <div className="apSwitchBlock">
                                    <label htmlFor="hour-entry-required-switch" className="info">
                                        { tr('working_time_tracking') }
                                        <small>{ tr('working_time_tracking_info') }</small>
                                    </label>
                                    <ApSwitch
                                        id="hour-entry-required-switch"
                                        on={ keyExists( this.props.data, "contract_detail.hour_entry_required", true ) }
                                        onChange={ () => { this.props.onChange( "contract_detail.hour_entry_required", !this.props.data.contract_detail.hour_entry_required ) } }
                                        disabled={ this.props.loading }
                                    />
                                </div>

                                <Collapse in={ keyExists( this.props.data, "contract_detail.hour_entry_required", true ) }>
                                    <div>
                                        <hr />

                                        <div className="apSwitchBlock">
                                            <label htmlFor="hour-entry-approval-required-switch" className="info">
                                                { tr('approval_of_hours') }
                                                <small>{ tr('user_approval_of_hours_info') }</small>
                                            </label>
                                            <ApSwitch
                                                id="hour-entry-approval-required-switch"
                                                on={keyExists(this.props.data, "contract_detail.hour_entry_approval_required", true)}
                                                onChange={() => {
                                                    this.props.onChange("contract_detail.hour_entry_approval_required", !this.props.data.contract_detail.hour_entry_approval_required)}
                                                }
                                                disabled={this.props.loading}
                                            />
                                        </div>

                                        <ApSelect
                                            label={ tr('approval_of_hours') }
                                            value={ keyExists( this.props.data, "contract_detail.hour_approvers", true, [] ) }
                                            optionRenderer="user"
                                            onChange={ ( approver ) => { this.props.onChange( "contract_detail.hour_approvers", approver ) } }
                                            objKeyId="id"
                                            clearable
                                            tooltip={ this.props.validator.getText( "data.contract_detail.hour_approvers" ) }
                                            validationState={ this.props.validator.getState( "data.contract_detail.hour_approvers" ) }
                                            apiUrl="usermanagement/user/approvers"
                                            apiData={{ 
                                                company_id: keyExists( this.props.data, "company_id", true ),
                                                exludeIds: keyExists(this.props.data, "id") ? [this.props.data.id] : [], 
                                                is_active:true,
                                            }}
                                            loading={ this.props.loading }
                                            disabled={ this.props.loading || !approverRequired }
                                            multiselect
                                            valueRenderer="user"
                                            objKeySearchable="user"
                                        />

                                        <hr />

                                        <div className="apSwitchBlock small">
                                            <label htmlFor="regular-work-time-switch" className="info">
                                                { tr('regular_working_hours') }
                                                <small>{ tr('regular_working_hours_info') }</small>
                                            </label>
                                            <ApSwitch
                                                small
                                                id="regular-work-time-switch"
                                                on={ keyExists( this.props.data, "contract_detail.regular_worktime", true ) }
                                                onChange={ () => { this.props.onChange( "contract_detail.regular_worktime", !this.props.data.contract_detail.regular_worktime ) } }
                                                disabled={ this.props.loading }
                                            />
                                        </div>

                                        <Collapse in={ keyExists( this.props.data, "contract_detail.regular_worktime", true ) }>
                                            <div>
                                                
                                                <br />

                                                <div style={{overflowX: 'auto'}}>
                                                <div style={{minWidth: 500}}>
                                                <ApInputStack gap="0">
                                                    <ApInput
                                                        width="80px"
                                                        type="number"
                                                        id="hours_weekly"
                                                        name="hours_weekly"
                                                        align="right"
                                                        value={ keyExists( this.props.data, "contract_detail.hours_weekly", true ) }
                                                        onChange={ ( e ) => { this.props.onChange( "contract_detail.hours_weekly", e.target.value ) } }
                                                        tooltip={ this.props.validator.getText('data.contract_detail.hours_weekly') }
                                                        validationState={ this.props.validator.getState('data.contract_detail.hours_weekly') }
                                                        loading={ this.props.loading }
                                                        disabled={ this.props.loading }
                                                    />
                                                    <ApAddon noLeftBorder width="80px">
                                                        { `h/${tr('week_abbr')}` }
                                                    </ApAddon>

                                                    <ApInput
                                                        gapLeft={10}
                                                        type="select"
                                                        options={[
                                                            { value: 1, label: "1" },
                                                            { value: 2, label: "2" },
                                                            { value: 3, label: "3" },
                                                            { value: 4, label: "4" },
                                                            { value: 5, label: "5" },
                                                            { value: 6, label: "6" },
                                                            { value: 7, label: "7" },
                                                        ]}
                                                        id="days_weekly"
                                                        name="days_weekly"
                                                        align="right"
                                                        value={ keyExists( this.props.data, "contract_detail.days_weekly", true ) }
                                                        onChange={ ( e ) => { this.props.onChange( "contract_detail.days_weekly", e.target.value ) } }
                                                        loading={ this.props.loading }
                                                        disabled={ this.props.loading }
                                                    />
                                                    <ApAddon noLeftBorder width="80px">
                                                        { `${tr('day_abbr')}/${tr('week_abbr')}` }
                                                    </ApAddon>

                                                    <ApInput
                                                        gapLeft={10}
                                                        width="80px"
                                                        type="text"
                                                        id="hours_daily"
                                                        name="hours_daily"
                                                        align="right"
                                                        value={ keyExists( this.props.data, "contract_detail.hours_daily", true ) }
                                                        readOnly
                                                    />
                                                    <ApAddon noLeftBorder width="80px">
                                                        { `h/${tr('day_abbr')}` }
                                                    </ApAddon>
                                                </ApInputStack>
                                                </div>
                                                </div>

                                                <hr />

                                                <div className="apSwitchBlock small">
                                                    <label htmlFor="flexitime-switch" className="info">
                                                        { tr('flexitime') }
                                                        <small>{ tr('flexitime_info') }</small>
                                                    </label>
                                                    <ApSwitch
                                                        small
                                                        id="flexitime-switch"
                                                        on={ keyExists( this.props.data, "contract_detail.flex_time_allowed", true ) }
                                                        onChange={ () => { this.props.onChange( "contract_detail.flex_time_allowed", !this.props.data.contract_detail.flex_time_allowed ) } }
                                                        disabled={ this.props.loading }
                                                    />
                                                </div> 
                                                {keyExists(this.props.apTimetrackingSettings, 'company.travel_dont_add_flextime', true, false) 
                                                    ? <Collapse in={keyExists(this.props.data, "contract_detail.flex_time_allowed", true ) }>
                                                    <div className="apSwitchBlock small">
                                                        <label htmlFor="flextimetravel-switch" className="info">
                                                        { tr('travel_dont_count_to_flextime') }
                                                        </label>
                                                        <ApSwitch
                                                            small
                                                            id="flextimetravel-switch"
                                                            on={ keyExists( this.props.data, "travel_dont_add_flextime", true ) }
                                                            onChange={ () => { this.props.onChange( "travel_dont_add_flextime", !this.props.data.travel_dont_add_flextime ) } }
                                                            disabled={ this.props.loading }
                                                        />
                                                    </div>
                                                </Collapse>
                                                : null}
                                                <hr />
                                                

                                                {/* 
                                                <hr />
                                                <div className="apSwitchBlock small">
                                                    <label htmlFor="project-required-switch" className="info">
                                                        Projekti vaaditaan tuntikirjauksissa 
                                                        <small>Projekti on pakollinen tieto kun käyttäjä syöttää tunteja</small>
                                                    </label>
                                                    <ApSwitch
                                                        small
                                                        id="project-required-switch"
                                                        on={ keyExists( this.props.data, "contract_detail.hour_entry_project_required", true ) }
                                                        onChange={ () => { this.props.onChange( "contract_detail.hour_entry_project_required", !this.props.data.contract_detail.hour_entry_project_required ) } }
                                                        disabled={ this.props.loading }
                                                    />
                                                </div>
                                                */}

                                            </div>
                                        </Collapse>
                                        <div className="apSwitchBlock small">
                                            <label htmlFor="flexitime-switch" className="info">
                                                {tr('time_attendance')}
                                            </label>
                                            <div className="apInfo small">
                                                <SvgIcon icon="info-circle" type="solid" />
                                                {tr('if_value_is_empty_then_use_company_value')}
                                            </div>
                                            <label htmlFor="flexitime-switch" className="info">
                                                {tr('time_attendance_key_card')}
                                            </label>

                                            <ApInput
                                                type="text"
                                                id="time_attendance_card"
                                                name="time_attendance_card"
                                                label={tr('key_card')}
                                                value={keyExists(this.props.data, "time_attendance_card", true)}
                                                onChange={(e) => { this.props.onChange("time_attendance_card", e.target.value) }}
                                                loading={this.props.loading}
                                                disabled={this.props.loading}
                                            />
                                            <label htmlFor="flexitime-switch" className="info">
                                                {tr('default_project')}
                                                <small>{tr('default_project_in_time_attendance')}</small>
                                            </label>
                                            <ApSelect
                                                label={tr('select_project')}
                                                disabled={this.props.loading}
                                                value={keyExists(this.props.data, "contract_detail.project", true) ? this.props.data.contract_detail.project.name + ": " + this.props.data.contract_detail.project.project_code : ""}
                                                optionRenderer="project_assignment"
                                                onChange={(e) => {
                                                    this.props.onChange("contract_detail.project", e);
                                                    this.props.onChange("contract_detail.work", null);
                                                    this.props.onChange("contract_detail.hour_type", null);
                                                }}
                                                objKeyId="id"
                                                clearable
                                                request_time={true}
                                                apiUrl="timetracking/projects"
                                                apiData={{
                                                    date: moment().format('YYYY-MM-DD'),
                                                    //exlude: [].map( p => p.id ),
                                                }}
                                            />
                                            <label htmlFor="flexitime-switch" className="info">
                                                {tr('default_project_work')}
                                                <small>{tr('default_project_work_in_time_attendance')}</small>
                                            </label>
                                            <ApSelect
                                                label={tr('work')}
                                                loading={this.props.loading}
                                                disabled={this.props.loading || !keyExists(this.props.data, "contract_detail.project", true)}
                                                value={keyExists(this.props.data, "contract_detail.work", true) ? this.props.data.contract_detail.work.name : ""}
                                                onChange={(e) => {
                                                    this.props.onChange("contract_detail.work", e);
                                                    this.props.onChange("contract_detail.hour_type", null);
                                                }}
                                                options={keyExists(this.props.data, "contract_detail.project", true) ? this.props.data.contract_detail.project.works : []}
                                                clearable
                                                optionRenderer="work"
                                                objKeyId="id"
                                                objKeySearchable="name"
                                                objKeyValue="name"
                                            //validationState={row.send_type ? null : 'error'}
                                            />
                                            <label htmlFor="flexitime-switch" className="info">
                                                {tr('default_hour_type')}
                                                <small>{tr('default_hour_type_in_time_attendance')}</small>
                                            </label>
                                            <ApSelect
                                                label={tr('hour_type')}
                                                loading={this.props.loading}
                                                disabled={this.props.loading || !keyExists(this.props.data, "contract_detail.work", true)}
                                                value={keyExists(this.props.data, "contract_detail.hour_type", true) ? this.props.data.contract_detail.hour_type.name : ""}
                                                onChange={(e) => this.props.onChange("contract_detail.hour_type", e)}
                                                options={keyExists(this.props.data, "contract_detail.hour_types", true) || []}
                                                clearable
                                                optionRenderer="hour_type"
                                                objKeyId="id"
                                                objKeySearchable="name"
                                                objKeyValue="name"
                                            //validationState={row.send_type ? null : 'error'}
                                            />
                                            <label htmlFor="flexitime-switch" className="info">
                                                {tr("time_attendance_max_time")}
                                                <small>{tr("time_attendance_time_limit_info")}</small>
                                            </label>
                                            <ApInputStack gap="0">
                                                <ApInput
                                                    type="number"
                                                    label={tr('time_attendance_max_time')}
                                                    //className="text-right"
                                                    value={keyExists(this.props.data, "contract_detail.ta_time_limit", true) ? this.props.data.contract_detail.ta_time_limit / 60 : ""}
                                                    clearable

                                                    onChange={(e) => this.props.onChange("contract_detail.ta_time_limit", e.target.value * 60)}
                                                //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                                                />
                                                <ApAddon noLeftBorder width="25px">
                                                    h
                                                </ApAddon>
                                            </ApInputStack>
                                            <label htmlFor="flexitime-switch" className="info">
                                                {tr("lunch_break")}
                                                <small>{tr("lunch_break_length_info")}</small>
                                            </label>
                                            <ApInputStack gap="0">
                                                <ApInput
                                                    type="number"
                                                    label={tr('lunch_break_length')}
                                                    //className="text-right"
                                                    value={keyExists(this.props.data, "contract_detail.ta_lunch_break", true) ? this.props.data.contract_detail.ta_lunch_break : ""}
                                                    clearable


                                                    onChange={(e) => this.props.onChange("contract_detail.ta_lunch_break", e.target.value)}
                                                //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                                                />
                                                <ApAddon noLeftBorder width="25px">
                                                    min
                                                </ApAddon>
                                            </ApInputStack>
                                            <label htmlFor="flexitime-switch" className="info">
                                                {tr('lunch_break_min_time')}
                                                <small>{tr('time_when_accept_lunch_break_info')}</small>
                                            </label>
                                            <ApInputStack gap="0">
                                                <ApInput
                                                    type="number"
                                                    label={tr('lunch_break_min_time')}
                                                    //className="text-right"
                                                    value={keyExists(this.props.data, "contract_detail.ta_min_time_accept_lunch_break", true) ? this.props.data.contract_detail.ta_min_time_accept_lunch_break / 60 : ""}
                                                    clearable


                                                    onChange={(e) => this.props.onChange("contract_detail.ta_min_time_accept_lunch_break", e.target.value * 60)}
                                                //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                                                />
                                                <ApAddon noLeftBorder width="25px">
                                                    h
                                                </ApAddon>
                                            </ApInputStack>
                                        </div>

                                    </div>
                                </Collapse>
                            </div>

                            {/*
                            <div className={"apFormGroup" + ( keyExists( this.props.data, "contract_detail.contract_piecework", true ) ? " success" : "" ) }>

                                <div className="apSwitchBlock">
                                    <label htmlFor="piecework-switch" className="info">
                                        Urakkapalkka
                                        <small>Työntekijälle voidaan maksaa palkkaa myös toteutuneiden kappalemäärien mukaan</small>
                                    </label>
                                    <ApSwitch
                                        id="piecework-switch"
                                        on={ keyExists( this.props.data, "contract_detail.contract_piecework", true ) }
                                        onChange={ () => { this.props.onChange( "contract_detail.contract_piecework", !this.props.data.contract_detail.contract_piecework ) } }
                                        disabled={ this.props.loading }
                                    />
                                </div>

                                <Collapse in={ keyExists( this.props.data, "contract_detail.contract_piecework", true ) }>
                                    <div>
                                        <hr />

                                        <ApInputStack gap="0">
                                            <ApInput
                                                type="number"
                                                id="piecework_pay"
                                                name="piecework_pay"
                                                align="right"
                                                value={ keyExists( this.props.data, "contract_detail.piecework_pay", true ) }
                                                onChange={ ( e ) => { this.props.onChange( "contract_detail.piecework_pay", e.target.value ) } }
                                                loading={ this.props.loading }
                                                disabled={ this.props.loading }
                                            />
                                            <ApAddon noLeftBorder width="150px">
                                                € / kpl
                                            </ApAddon>
                                        </ApInputStack>

                                    </div>
                                </Collapse>
                            </div>
                            */}

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    apTimetrackingSettings: state.apTimetrackingSettings,
});

export default connect(mapStateToProps)(ContractDetail);
