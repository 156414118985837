/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import SvgIcon from '../../../common/SvgIcon/SvgIcon.js'
import api from 'services/Api/Api.js';

import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import { ApInputSmall } from 'common/ApInputSmall/ApInputSmall.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import { ApReactTable }     from 'common/ApReactTable/ApReactTable.js';

import { errorPopper, tr } from 'services/Helpers/Helpers.js';
import EditStorageComponent from './EditStorage/EditStorageComponent.js';
import CreateStorage from './EditStorage/CreateStorage.js';

class ProjectStorage extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            loading: true,
            storageBalance: [],
            storageName: null,
            components: [],
            projectStorageExists: false,
            regularStorageId: null,

            selected_id: null,
            confirmDeleteShow: false,
            confirmDeleteStorageShow: false,
            storageCreationShow: false,
            componentEditShow: false,

            incomingPoComponents: [],
            incomingStorageComponents: [],
        };

        autoBind(this);
    }

    componentDidMount() {
        if (!this.state.projectStorageExists && this.state.regularStorageId == null) { 
            this.checkBalance();
        }
        
        this.setState({loading: false});
    }

    componentDidUpdate() {

        //Jos saldoja päivitetään projektille.
        if (this.props.data.storageBalance.length > 0) {
            if (this.props.data.storageBalance != this.state.storageBalance) {
                this.setState({storageBalance: this.props.data.storageBalance});
            }
        }
    }

    initializeStorage() {
        this.setState({storageCreationShow: false});
        setTimeout(() => { this.checkBalance(); }, 1000);
    }

    checkBalance() {
        this.getStorageBalance();
        this.getStorageIncoming();
    }

    //Getterit
    getStorageBalance()
    {
        const pId = this.props.data.projectId;

        api({
            method: 'get',
            url: `project/management/${pId}/storageBalanceAndType`
        }).then((response) => {
            if (response[0] == 'P') { this.setState({projectStorageExists: true})}
            else {
                response[0] != null ? this.setState({ regularStorageId: response[0] }) : console.log("Varastoa ei olemassa!");
            }
            this.setState({ storageBalance: response[1],
                storageName: response[2]
            });
        })
    }

    getStorageIncoming() {
        const propId = this.props.data.projectId;
    
        api({
            method: 'get',
            url: `storage/delivery/${propId}/getIncoming`,
        }).then((response) => {
            if (response == 0) {
                console.warn(`getStorageIncoming => Projektia ei löydy IDllä ${propId}`);
                return;
            }
            if (response != 1) {
                const storageComponents = response.filter(c => c.hasOwnProperty('sender_name'));
                const poComponents = response.filter(c => c.hasOwnProperty('purchase_order_number'));

                this.setState({
                    incomingStorageComponents: storageComponents,
                    incomingPoComponents: poComponents
                });
            } else {
                console.log(`Ei löytynyt tilauksia tai varastosiirtoja.`);
            }
            
        }).catch((error) => {
            console.warn(`getStorageIncoming ei onnistunut!`);
        });
        
    }

    getSuppliers()
    {
        let suppliers = [];
        let k = [];
        let sc = this.filterSuppliers();

        sc.map((s) => {
            let sup = s.supplier;
            sup.map((r) => {
                if(r.name !== tr('not_given'))
                {
                    k = { 
                        'value' : r.id ,
                        'label' : r.name 
                    }
                    suppliers.unshift(k);
                }
            });
        })

        suppliers = suppliers.filter((elem, index, self) => self.findIndex(
            (t) => {return (t.value === elem.value)}) === index)

        const vl = 
        {
            value: ' ',
            label: ` - ${tr('select_supplier')} - `
        }
        suppliers.unshift(vl);
        return suppliers;
    }

    getPSColumns() {
        let columns = [    
			{
				id:         "name",
				Header:     tr('name'),
				accessor:   "name",
				showInitially: false,
				width: 300,
            },  
			{
				id:         "initial_need",
				Header:     tr('initial_demand'),
				accessor:   "initial_need",
				showInitially: false,
				width: 130,
            }];

        if (this.state.regularStorageId == null) {
            columns.push({
                    id:         "onstock",
                    Header:     tr('in_own_storages'),
                    accessor:   "onstock",
                    showInitially: false,
                    width: 160,
                }
            );
        }

        columns.push({
            id:         "installed",
            Header:     tr('made_or_installed'),
            accessor:   "installed",
            showInitially: false,
            width: 130,
        },  
        {
            id:         "current_need",
            Header:     tr('demand_now'),
            accessor:   "current_need",
            showInitially: false,
            width: 150,
        },
        {
            id:         "balance",
            Header:     tr('balance'),
            accessor:   "balance",
            showInitially: false,
        },
        {
            id:         "order_amount",
            Header:     tr('incoming'),
            accessor:   "order_amount_project",
            showInitially: false,
        });

        if (this.state.regularStorageId == null) {
            columns.push({
                id:         "shortage",
                Header:     tr('shortage'),
                accessor:   "shortage",
                showInitially: false,
            });
        }

        columns.push({
                id:         "sOletus",
                Header:     tr('default_supplier'),
                accessor:   "sOletus",
                showInitially: false,
            },
            {
                id:         "unit",
                Header:     tr('unit'),
                accessor:   "unit",
                showInitially: false,
            },
            {
                Header: tr('external'),
                id: 'dropdown',
                //width: 150,
                Cell: ({row}) => {
                    if (row._original.isExt) {
                        return <SvgIcon icon="check-circle" type="solid" />
                    } 
                }            
            },
            {
                id: 'in_billing',
                Header: tr('in_billing'),
                accessor:   "in_billing",
                showInitially: false,
            },
            {
                id: 'not_billable',
                Header: tr('not_billed'),
                accessor:   "not_billable",
                showInitially: false,
            });
            
        if (!this.state.regularStorageId) {
            columns.push({
                Header: tr('edit'),
                id: 'edit-button',
                Cell: ({row}) => {  return this.setupButtons(row._original.id)}     
            });
        }

		return columns;
    }

    getPoColumns() {
        let columns = [    
			{
				id:         "id",
				Header:     "ID",
				accessor:   "id",
				showInitially: false,
				width: 100,
            },
			{
				id:         "name",
				Header:     tr('name'),
				accessor:   "name",
				showInitially: false,
				//width: 200,
            },  
			{
				id:         "count",
				Header:     tr('amount'),
				accessor:   "count",
				showInitially: false,
				width: 130,
            },
            {
				id:         "unit",
				Header:     tr('unit'),
				accessor:   "unit",
				showInitially: false,
				width: 130,
            },  
            {
				id:         "supplier_name",
				Header:     tr('supplier'),
				accessor:   "supplier_name",
				showInitially: false,
				width: 250,
            },
            {
				Header: tr('payment_order'),
				id: 'edit-button',
				//width: 150,
				Cell: ({row}) => (
					<ApButton
						color="blue"
						onClick={ () => {
                            this.redirectToPage('po', row._original.purchase_order_number);
						} }
						>
						<SvgIcon icon="search" type="solid" />
						{ tr('order') }
					</ApButton>
				)                
            },
		]
		return columns;
    }

    getStorageColumns() {
        let columns = [    
			{
				id:         "id",
				Header:     tr('transfer_id'),
				accessor:   "id",
				showInitially: false,
				width: 100,
            }, 
            {
				id:         "name",
				Header:     tr('component'),
				accessor:   "name",
				showInitially: false,
				//width: 200,
            }, 
			{
				id:         "count",
				Header:     tr('amount'),
				accessor:   "count",
				showInitially: false,
				width: 130,
            }, 
            {
				id:         "unit",
				Header:     tr('unit'),
				accessor:   "unit",
				showInitially: false,
				width: 100,
            }, 
            {
				id:         "sender_name",
				Header:     tr('sending_storage'),
				accessor:   "sender_name",
				showInitially: false,
				//width: 200,
            }, 
            {
				Header: tr('storage_transfer'),
				id: 'edit-button',
				//width: 150,
				Cell: ({row}) => (
					<ApButton
						color="blue"
						onClick={ () => {
                            sessionStorage.setItem('transportId', row._original.id);
                            window.emitter.emit( 'goTo', { pathname: `../../../../storage/transports/edit` } );
						} }
						>
						<SvgIcon icon="search" type="solid" />
						{ tr('order') }
					</ApButton>
				)                
            },
		]
		return columns;
    }

    /* TODO: Laita toimintaan
    getAllColumns() {
        let columns = [    
			{
				id:         "id",
				Header:     "ID",
				accessor:   "id",
				showInitially: false,
				width: 100,
            }, 
            {
				id:         "name",
				Header:     "Komponentti",
				accessor:   "name",
				showInitially: false,
				//width: 200,
            }, 
			{
				id:         "count",
				Header:     "Määrä",
				accessor:   "count",
				showInitially: false,
				width: 130,
            }, 
            {
				id:         "unit",
				Header:     "Yksikkö",
				accessor:   "unit",
				showInitially: false,
				width: 100,
            }, 
            {
				id:         "sender_name",
				Header:     "Lähettäjä",
				accessor:   "sender_name",
				showInitially: false,
				//width: 200,
            }, 
            {
				Header: 'Varastosiirto',
				id: 'edit-button',
				//width: 150,
				Cell: ({row}) => (
					<ApButton
						color="blue"
						//onClick={() => {this.createNewItem()}}
						onClick={ () => {
                            localStorage.setItem('transportId', row._original.purchase_order_number);
                            window.emitter.emit( 'goTo', { pathname: `../../../../storage/transports` } );
                            //window.emitter.emit( 'goTo', { pathname: `../../../../storage/transports/edit` } );
						} }
						>
						<SvgIcon icon="search" type="solid" />
						Tilaus
					</ApButton>
				)                
            },
		]
		return columns;
    }
    */

    setupButtons(cId) {
        //Määrittele toiminnot
        
        let actions = [];
        actions.push({
            label: tr('edit'),
            icon: "edit",
            action: ( id, closeFunc ) => {
                this.setState({
                    componentEditShow: true,
                    selected_id: cId,
                });
                closeFunc();
                },
            });
            
        if (!this.state.regularStorageId) {
            actions.push({
                label: tr('delete'),
                icon: "trash-alt",
                action: ( id, closeFunc ) => {
                    this.setState({
                        selected_id: cId,
                        confirmDeleteShow: true,
                    })
                    closeFunc();
                }
            });
        }

        return <ApDropdown
            actions={ actions }
            dropdownPos='relative'
        />
    }

    filterSuppliers() {
        let sc = this.state.storageBalance.filter(s => s.component_id != null);
        sc = this.state.regularStorageId 
        ? sc.filter(s => (s.initial_need - s.installed - s.balance) > 0 || s.balance < 0)
        : sc.filter(s => s.balance_free < 0 || s.balance < 0);

        return sc;
    }

    render() {
        let storageBalance = this.state.storageBalance;
        
        let view = [];

        switch (this.state.storageType)
        {
            case 'T' : //Varastosiirtoja tehdessä
                view.push(this.renderStorageTransportView(storageBalance));
                break; 
            case 'O' :  // Ostotilauksen valmistelu
                view.push(this.renderPaymentOrderView(storageBalance));
                break;
            default: //Perusnäkymä
                view.push(this.renderDefaultView(storageBalance));
                break;
        }

        view.push([<ApConfirm
            show= { this.state.confirmDeleteShow }
            header={ tr('delete_component_header') }
            body={ tr('delete_component_desc') }
            onConfirm={() => this.handleComponentDelete()}
            onClose={ () => { this.setState({ confirmDeleteShow: false })} }
        />], [<ApConfirm
            show= { this.state.confirmDeleteStorageShow }
            header={!this.state.regularStorageId ? tr('delete_project_storage_header1') : tr('delete_project_storage_header2')}
            body={ tr('delete_project_storage_body') }
            onConfirm={() => this.handleStorageDelete()}
            onClose={ () => { this.setState({ confirmDeleteStorageShow: false })} }
        />]);
        
        view.push([<EditStorageComponent
            show={this.state.componentEditShow}
            onSave={(data) => this.handleComponentEdit(data)}
            onClose={ () => { this.setState({ componentEditShow: false })} }
            component_id={this.state.selected_id}
            />], [<CreateStorage
                show={this.state.storageCreationShow}
                onSave={(data) => { this.initializeStorage() }}
                onClose={ () => { this.setState({ storageCreationShow: false })} }
                project_id={ this.props.data.projectId }
            />]
        );
        
        return view;
    }

    renderRegularStorageView(storageBalance) {

        if(storageBalance.length>0) { //Jos varasto ei tyhjä
            const cRows = this.mapNeeds(storageBalance);
            return this.renderStorageView(cRows);
        } else { //Jos varasto olemassa mutta tyhjä
            return <div className="treeContainer">
            <div className="splitView">
                <div className="left">
                { tr('normal_storage_already_exists') };
                <ul>
                    <li>{ tr('normal_storage_already_exists_note1') }</li>
                    <li>{ tr('normal_storage_already_exists_note2') }</li>
                </ul>
                </div>
            </div>
            </div>
        }
    }

    renderStorageNotExisting() {
        return <div>
        <div className="treeContainer">
            <div className="splitView">
                <div className="left">
                { tr('no_storage_for_this_project') }
                </div>
            </div>
        </div>

        <table className="buttonTable" style={{ marginTop: '4em' }}>
        <tbody>
        <tr>
            <td>
                <ApButton size="large" color="green" onClick={ () => { this.setState({storageCreationShow: true}); } }>
                    { tr('create_storage') }
                </ApButton>
            </td>
        </tr>
        </tbody>
        </table>
    </div>
    }

    renderIncomingFields() {

        return <div>
            {this.state.incomingPoComponents.length > 0 ? this.renderIncomingPOFields() : '' }
            {this.state.incomingStorageComponents.length > 0 ? this.renderIncomingStorageFields() : ''}
        </div>
    }

    renderIncomingPOFields() {
        return <div style={{ marginTop: '3em' }}>
        <h4 style={{ fontWeight: 600 }}>{ tr('incoming_purchase_orders') }</h4>
        <ApReactTable
         rememberId="incomingPOsTable"
         //showFiltersInitially={ true }
         loading={ this.state.loading }
         data={ this.state.incomingPoComponents}
         filterable                        
         columns={ this.getPoColumns() }
         minRows={ 4 }
         />
     </div>
    }

    renderIncomingStorageFields() {
        return <div style={{ marginTop: '3em' }}>
            <h4 style={{ fontWeight: 600 }}>{ tr('incoming_purchase_orders') }</h4>
            
            <ApReactTable
            rememberId="incomingPOsTable"
            //showFiltersInitially={ true }
            loading={ this.state.loading }
            data={ this.state.incomingStorageComponents}
            filterable                        
            columns={ this.getStorageColumns() }
            minRows={ 4 }
            />
        </div>
    }

    renderAllIncoming() {
                
        return <div style={{ marginTop: '3em' }}>
            <h4 style={{ fontWeight: 600 }}>{ tr('incoming_transfers') }</h4>
            <ApReactTable
            rememberId="allIncomingTable"
            //showFiltersInitially={ true }
            loading={ this.state.loading }
            data={ JSON.parse(this.state.allIncomingData) }
            filterable                        
            columns={ this.getAllColumns() }
            minRows={ 4 }
            />
        </div>
    }

    //Formatointi
    roundNum = (num) => Math.round(num * 100 + Number.EPSILON ) / 100; 


    //Renderöinti osat
    renderStorageTransportInfo(){
        //Näytä tämä nappula AINOASTAAN jos on olemassa komponentteja joita voi tilata varastosta
        let matches = this.filterSuppliers().length > 0;

        if (matches) {
            return <td>
            <ApButton size="large" onClick={ () => { this.prepareStorageTransport() } }>
                { tr('prepare_storage_transfer') }
            </ApButton>
            </td>
        }
    }

    renderPaymentOrderInfo() {
        //Näytä AINOASTAAN jos on olemassa komponentteja, jotka tilattavissa.
        let matches = this.filterSuppliers().length > 0;

        if (matches) {
            return <React.Fragment>
            <td>
            <ApButton size="large" disabled = {!this.state.supplierSelected} onClick={ () => { this.prepareOrder() } }>
                { tr('prepare_purchase_order') }
            </ApButton>
            </td><td>
            <ApInput
                id='toiselect'
                label={ tr('supplier') }
                type="select"
                name='toiselect'
                options={ this.getSuppliers() }
                value={ this.state.selectedSupplierId }
                onChange={ ( e ) => this.handleSupplierChange( e.target.value ) }
            >
            </ApInput>
            </td>
        </React.Fragment>
        } else {
            return <td>
                <h6>{ tr('no_active_need_to_order_products') }</h6>
            </td>
        }
    }

    renderOrderButtons() {
        return <table className="buttonTable">
        <tbody>
        <tr>
        { this.renderStorageTransportInfo() }
        { this.renderPaymentOrderInfo() }
        </tr>
        </tbody>
        </table>
    }

    renderActions() {
        const exists = this.state.regularStorageId || this.state.projectStorageExists;

        if (exists) {
            const actionType = this.state.regularStorageId ? tr('unlink_from_storage') : tr('delete_project_storage');

            return <table className="buttonTable" style={{ marginTop: '4em' }}>
            <tbody>
            <tr>
                <td>
                    <ApButton size="small" color="red" onClick={ () => { this.setState({confirmDeleteStorageShow: true}); } }>
                        {actionType}
                    </ApButton>
                </td>
            </tr>
            </tbody>
            </table>
        } else { return ''; }
       
    }

    renderStorageTransportView(projectStorage) {
        if(projectStorage.length>0) { 
            const cRows = this.mapStorageNeeds();
    
                return <div className="treeContainer">
    
                    <div className="left">
    
                    <table className="componentTable">
                    <thead><tr>                          
                            <td><h2>{ tr('storage_transfer') }</h2></td>
                            </tr>
                        </thead>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>{ tr('name') }</th>
                                    <th align="right" className="fixed">{ tr('shortage') }</th>
                                    <th align="right" className="fixed">{ tr('in_storages') }</th>
                                    <th align="right" className="fixed">{ tr('unit') }</th>
                                    <th align="right" className="changable">{ tr('will_be_transferred') }</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            { cRows }
                            </tbody>                                      
                    </table>
                    <ApButton size="large" onClick={ () => { this.backToBasic() } }>
                        { tr('return_to_basic_view') }
                    </ApButton>
                    <ApButton size="large" disabled = {!this.state.bTransport} onClick={ () => { this.doStorageTransport( cRows) } }>
                        { tr('make_transfer') }
                    </ApButton>
    
                    </div>
                </div>
        }
    }

    renderPaymentOrderView(projectStorage) {
        let toimittaja = "";
        if(projectStorage.length>0) {
            //Filteraa pois varaston ulkopuoliset ja komponentit joille ei tarvetta
            let filtered = this.filterSuppliers();

            const totalSum = this.calculatePoSum(filtered); //Käytetään kokonaissumman laskemiseen
            let mappedData = [];
            filtered.map(( c, cIndex ) => {
                let bOn = false;
                c.supplier.map((s) => {
                    if(s.id == this.state.selectedSupplierId)
                    {
                        bOn=true;
                        toimittaja = s;
                        c.price=s.price;
                    }
                    if(s.name === tr('not_given'))
                    {
                        bOn = true;
                        c.price = s.price;
                    }
                })
                if(bOn)
                {    
                    const shortage = this.state.regularStorageId 
                    ? c.balance > 0 ? -(c.balance - c.initial_need) : -c.balance
                    : -c.balance_free;
                    
                    mappedData.push(<tr key={ c.id } className={ "c" }>
                        <td width= "40" className="id">
                            {  c.component_id  }
                        </td>
    
                        <td width= "260" className="name">
                            {  c.name  }
                        </td>
    
                        <td  width= "100" align="right" className="shortage">
                            { shortage }
                        </td>
                                                    
                        <td  width= "60" align="right" className="unit">
                            { c.unit }
                        </td>
    
                        <td  width= "100" align="right" className="price">
                            { Number(c.price).toFixed(2) }
                        </td>
    
                        <td width="80">
                        <ApInputSmall
                            type="text"
                            id='count'
                            name='count'
                            width="80"
                            value={ c.count }
                            //loading={ this.editRow(cIndex, c.count) }
                            onChange={ ( e ) => this.editRow( cIndex, e.target.value, 'po' ) }
                        />
                        </td>
                    </tr>);
                }
                });

            return <div className="treeContainer">

                <div className="left">

                <table className="componentTable">  
                    <thead><tr><td></td>                          
                        <td><h2> { toimittaja.name }</h2></td>
                        </tr>
                    </thead> 
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>{ tr('name') }</th>
                            <th align="right" className="fixed">{ tr('shortage') }</th>
                            <th align="right" className="fixed">{ tr('unit') }</th>
                            <th align="right" className="fixed">{ tr('price') }</th>
                            <th align="right" className="changable">{ tr('will_be_purchased') }</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    { mappedData }
                    </tbody>                                      
                </table>
                <ApButton size="large" onClick={ () => { this.backToBasic() } }>
                    { tr('return_to_basic_view') }
                </ApButton>
                <ApButton size="large" onClick={ () => { this.doOrder(toimittaja) } }>
                    { tr('make_order') }
                </ApButton>
                <h6 id='order_total_sum' style={{ float: 'right', fontWeight: '800', marginTop: '1.5em' }}>{ tr('total_amount') }: { totalSum != null ? `${totalSum.toFixed(2)}${this.state.currencySign ? this.state.currencySign : ''}` : '0.00' }</h6>
                </div>
            </div>
        }
    }

    renderDefaultView(storageBalance) {
        let newView;

        if(this.state.projectStorageExists) {
            if(storageBalance.length>0) { //Jos projektivarasto ei tyhjä
                const cRows = this.mapNeeds(storageBalance);
                newView = this.renderStorageView(cRows);
            } else { //Jos projektivarasto olemassa mutta tyhjä
                newView = <div className="treeContainer">
                <div className="splitView">
                    <div className="left">
                    { tr('project_storage_empty') }
                    </div>
                </div>
                </div>
            }
        } else if (this.state.regularStorageId != null) {
            newView = this.renderRegularStorageView(storageBalance);
        } else { newView = this.renderStorageNotExisting(); }

        return [newView, this.renderActions()];
    }
    
    renderStorageView(mappedData) {
        return <div style={{ marginTop: '3em' }}>
        <h4 style={{ fontWeight: 600 }}>{this.state.projectStorageExists ? tr('project_storage') : this.state.storageName}</h4>
        <ApReactTable
            rememberId="defaultTable"
            loading={ this.state.loading }
            data={ mappedData }
            filterable                        
            columns={ this.getPSColumns() }
            minRows={ 4 }
         />
        { this.renderOrderButtons() }
        { this.renderIncomingFields() }
        </div>
    }

    renderDefaultViewOld(cRows) { //TODO: Poista kun tätä selvitetty
        return <div className="treeContainer">

            <div className="left">

            <table className="componentTable">
                    <thead>
                        <tr>
                            { //<th>Id</th> 
                            }
                            <th style={{paddingLeft: '0.6em'}}>{ tr('name') }</th>
                            <th align="right" className="fixed">{ tr('initial_demand') }</th>
                            <th align="right" className="fixed">{ tr('made_or_installed') }</th>
                            <th align="right" className="fixed">{ tr('demand_now') }</th>
                            <th align="right" className="fixed">{ tr('balance') }</th>
                            <th align="right" className="fixed">{ tr('incoming') }</th>
                            <th align="right" className="fixed">{ tr('shortage') }</th>
                            <th align="right" className="fixed">{ tr('in_own_storages') }</th>
                            <th align="right" className="fixed">{ tr('default_supplier') }</th>
                            <th align="right" className="fixed">{ tr('unit') }</th>
                            <th>{ tr('external') }</th>
                            <th></th>
                        </tr>
                    </thead>
                <tbody>
                    { cRows }
                </tbody>
            </table>
            { this.renderOrderButtons() }
            { this.renderIncomingFields() }
            </div>  
        </div>
    }

    //Handlerit
    handleSupplierChange( value )
    {
        this.setState({ selectedSupplierId: value });
        if (value !== ' ')
            this.setState({ supplierSelected: true });
        else
            this.setState({ supplierSelected: false });
    }

    handleComponentDelete() {
        const data = {
            projectId: this.props.data.projectId,
            component_id: this.state.selected_id,
        }

        api({
            method: 'post',
            url: 'project/management/projectStorage/deleteComponent',
            data: data,
        }).then((response) => {
            if (response == 1) {
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <div>{ tr('deleted') }</ div>,
                });

                //Päivitä näkymä
                this.props.reload();
            } else {
                console.error(`Kyseistä komponenttia ei löytynyt varastosta.`);
            }
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('delete_error'));
        });
    }

    handleStorageDelete() {
        const data = {
            project_id: this.props.data.projectId
        }

        api({
            method: 'post',
            url: `project/management/deleteProjectStorage`,
            data: data
        }).then((response) => {
            if (response == 1 || response == 2) {
                const content = response == 1 ? tr('delete_project_storage_successful1') 
                    : tr('delete_project_storage_successful2');
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <div>{content}</div>,
                });
                this.setState({ regularStorageId: null, projectStorageExists: false });
            } else if (response == -1) {
                window.emitter.emit('popper', {
                    type: 'warning',
                    content: <div>{ tr('project_storage_cannot_be_deleted') }</div>,
                });
            }
            else {
                window.emitter.emit('popper', {
                    type: 'danger',
                    content: <div>{ tr('delete_error') }</div>,
                });
            }
            //console.log('Storage balance: ', response);
        });
    
    }

    handleComponentEdit(data) {
        api({
            method: 'post',
            url: 'storage/balance/component/update',
            data: data,
            errorPopper: tr('save_error'),
        }).then( ( response ) => {
            if (response == 0) {
                console.warn(`Komponenttia ei löytynyt.`);
                return;
            } 
            
            //Hae data uudestaan
            setTimeout(this.props.reload, 500);
        }).catch((error) => {
            console.error(`Ongelma komponttien tietojen muokkauksessa: ${error}`);
        });        


        this.setState({componentEditShow: false});
    }

    prepareStorageTransport() { this.setState ({ storageType : "T" });}
    prepareOrder() { this.setState ({ storageType : "O" }); }
    backToBasic() { this.setState ({ storageType : "B"} ); }
    
    editRow(index, iCount, type)
    {
        iCount = iCount.replace(',','.');
        if(isNaN(iCount))
        {
            return;
        }

        let storageBalance = this.state.storageBalance;
        if (type == 'po') {
            //console.log(`PO with ${iCount}`);
            storageBalance = this.filterSuppliers();
            storageBalance[ index ].count = iCount;

            //Kertoo montako komponenttiä jäi filterin ulkopuolelle.
            const filteredResults = this.state.storageBalance.length - storageBalance.length;
    
            //Työnnä muutokset takaisin storageBalanceen.
            let unfilteredStorage = this.state.storageBalance;
            unfilteredStorage[index + filteredResults].count = iCount;
            this.setState({ storageBalance: unfilteredStorage });
        } else {
            storageBalance[index].count = iCount;
            this.setState({ storageBalance: storageBalance });
        }
       
        let bOn = false;
        let sb = this.state.storageBalance;
        sb = this.filterSuppliers();

        sb.map(( c) => {
            if(c.selected && c.count>0)
                bOn = true;
        });
        this.setState({ bTransport : bOn});
    }

    editRowSelected(index)
    {
        let storageBalance = this.filterSuppliers();
        storageBalance[ index ].selected = !storageBalance[ index ].selected //bValue;  
        this.setState({ storageBalance: storageBalance });
        let bOn = false;
        let sb = this.state.storageBalance;
        sb.map(( c) => {
            if(c.selected && c.count>0)
                bOn = true;
        });
        this.setState({ bTransport : bOn});
    }

    //Funktiot

    doStorageTransport(cRows)
    {      
        let stors = this.state.storageBalance;
        let rec_id = '';
        let send_id = '';
        //console.log('stors: ', stors);

        let ss = stors.filter(function (stor) {
            if(stor.selected)
            {
                stor.target_final_type = 'proStorage';
                stor.target_final_id = stor.location_id;  
                stor.delivery_count = stor.count;    
                stor.type_id = 1;     
                stor.delivery_from_type = 'location';                  
                stor.delivery_to_type = 'project';
                stor.delivery_to_id = stor.location.id;
                rec_id = stor.location.id;

                stor.senderLocs.map(function (sL) {
                    let loc = sL.location;
                    if (loc.type.trim() === 'S') {
                        send_id = loc.id;
                        stor.delivery_from_id = loc.id;
                    }
                })

                return stor;
            }
        })

        let data = {

            sender_id: send_id,   // tämän varastopaikan perusteella haetaan lähettävä varasto controllerissa 
            receiver_id: rec_id,

            comment: '',
            components: ss,
        };

         //console.log('c:', data);

        api({
            method: 'post',
            url: 'storage/delivery/saveStorageTransport',
            data: data,
        }).then((response) => {
            this.setState({ type: 'show' });
            this.setState({loadingCounter: this.state.loadingCounter - 1});
            window.emitter.emit('popper', {
                type: 'success',
                content: <div>{ tr('save_successful') }</ div>,
            });
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({loadingCounter: this.state.loadingCounter - 1});
        });
        this.backToBasic();
    }

    mapStorageNeeds() {
        let mappedData = [];

        let sb = this.filterSuppliers();
        sb = sb.filter(c => c.onstock > 0); //Älä näytä komponentteja joilla ei omissa varastoissa saldoa

        sb.map(( c, cIndex ) => {
            let sS = "";
            c.senderLocs.map((s) => {
                let loc = s.location;
                if(loc.id !== c.location.id) {
                    if (loc.type.trim() != 'P') { //Näytä vain normaalit varastot
                        sS += `${tr('storage')}: ${loc.id}/${loc.name}, ${tr('free_balance')}: ${s.balance - (-s.balance_free)} `;
                    }
                }
            })

            const shortage = this.state.regularStorageId 
                ? c.balance > 0 ? -(c.balance - c.initial_need) : -c.balance
                : -c.balance_free;

            const ind = `count${cIndex}`;
            mappedData.push(<tr key={ c.id } className={ "c" }>
                <td width= "40" className="id">
                    {  c.component_id  }
                </td>

                <td width= "260" className="name">
                    {  c.name  }
                </td>

                <td  width= "100" align="right" className="shortage">
                    { shortage }
                </td>
                
                <td  width= "80" align="right" className="onstock">
                    <ApTooltip text={ sS ? sS : null } block position="topright">
                        { c.onstock.toFixed(2) }
                    </ApTooltip>
                </td>

                <td  width= "60" align="right" className="unit">
                    { c.unit }
                </td>

                <td width="80">
                <ApInputSmall
                    type="text"
                    id={ind}
                    name='count'
                    width="80"
                    value={ c.count }
                    onChange={ ( e ) => this.editRow( cIndex, e.target.value, 'st') }
                />
                </td>
                <ApSwitch
                    small
                    inline
                    name='OnOff'
                    id={ cIndex }
                    on = { c.selected }
                    onChange={ ( e ) => this.editRowSelected( cIndex, e.target.value) }
                />
            </tr>);
        });

        return mappedData;
    }

    mapNeeds(projectStorage) {
        let mappedData = [];

        projectStorage.map(( c, cIndex ) => {
            let sOletus = tr('not_given');
            let sT = "";
            c.supplier.map((s) => {
                sT = sT + s.name + ': ' + s.price + ' € ';
                if(s.primary)
                    sOletus = s.name;
            })

            let cData = {};

            if (this.state.regularStorageId) {
                cData.initial_need = this.roundNum(c.initial_need);
                cData.installed = this.roundNum(c.installed);
                cData.current_need = this.roundNum(c.initial_need - c.installed);
                cData.onstock = c.component_id != null ? this.roundNum(c.onstock) : 0;
            } else {
                cData.initial_need = this.roundNum(c.limit_notification);
                cData.installed = this.roundNum(c.limit_notification - c.limit_alarm);
                cData.current_need = this.roundNum(c.limit_alarm);
                cData.onstock = c.component_id != null ? this.roundNum(c.onstock) : 0;
            }
            

            cData.id = c.id;
            cData.name = c.name;
            cData.balance = this.roundNum(c.balance);
            cData.order_amount = this.roundNum(c.order_amount);
            cData.shortage = this.roundNum(-c.balance_free);
            cData.sOletus = sOletus;
            cData.unit = c.unit;
            cData.isExt = c.component_id == null;
            cData.in_billing = c.in_billing;
            cData.not_billable = c.not_billable;

            mappedData.push(cData);
        });

        return mappedData;

        /* //TODO: Vanha tapa, pitää jotain kopioida vielä ja sitten poistaa
        let mappedData = [];

        projectStorage.map(( c, cIndex ) => {
            let sOletus = "Ei annettu";
            let sT = "";
            c.supplier.map((s) => {
                sT = sT + s.name + ': ' + s.price + ' € ';
                if(s.primary)
                    sOletus = s.name;
            })

            let sS = "";
            c.senderLocs.map((s) => {
                if(s.location_id!==c.location.id)
                {
                    if(s.type.trim()==='P')
                        sS = sS + 'Projektivarasto: ' + s.location_id + '/' + s.name + ', vapaa saldo: ' + s.balance_free + ' ';
                    else
                        sS = sS + 'Varasto: ' + s.location_id + '/' + s.name + ', vapaa saldo: ' + s.balance_free + ' ';
                }
            })

            const diff = c.limit_alarm - (c.order_amount + c.limit_alarm); 
            const isRestIncoming = (0.009 > diff < 0.009); 

            mappedData.push(<tr key={ c.id } className={ c.balance_free >= 0 
                ? isRestIncoming != 0 ? "incomingCol" : "perus" 
                : "shortageCol" }>
                    
                    {
                    //    <td width= "40" className="id">
                    //    {  c.component_id == null ? 'U' : c.component_id //Palauttaa U:n jos varaston ulkopuolinen komponentti 
                    //    } 
                    //    </td>
                    }
                    

                    <td width= "260" className="name">
                        <p style={{marginLeft: '0.4em'}}>{ c.name }</p>
                    </td>

                    <td  width= "70" align="right" className="initial need">
                        { this.roundNum(c.limit_notification) }                  
                    </td>

                    <td  width= "70" align="right" className="installed">
                        { this.roundNum(c.limit_notification - c.limit_alarm) }                  
                    </td>

                    <td  width= "70" align="right" className="need now">
                        { this.roundNum(c.limit_alarm) }
                    </td>

                    <td  width= "70" align="right" className="saldo">
                        { this.roundNum(c.balance) }
                    </td>

                    <td  width= "70" align="right" className="coming">
                        { this.roundNum(c.order_amount) }
                    </td>

                    <td  width= "70" align="right" className="shortage">
                        { this.roundNum(-c.balance_free) }
                    </td>
                    
                    {//Omissa varastoissa kenttä
                    }
                    <td  width= "70" align="right" className="onstock">
                    <ApTooltip text={ sS ? sS : null } block position="topright">
                        { c.onstock.toFixed(2) }
                    </ApTooltip>                      
                    </td>

                    <td  width= "80" align="right" className="supplier">
                    <ApTooltip text={ sT ? sT : null } block position="topright">
                        { sOletus }
                    </ApTooltip>                     
                    </td>

                    <td  width= "40" align="right" className="unit">
                        { c.unit }
                    </td>

                    <td  width= "20" align="right" className="unit">
                        { c.component_id == null ? <SvgIcon icon="check-circle" type="solid" /> : '' }
                    </td>

                    <td width= "50" align="right" className="dropdown">
                    <ApDropdown
                        actionId={ 1 }
                        actions={[
                            {
                                label: "Näytä varastonimike",
                                icon: "edit",
                                action: ( item, closeFunc ) => {
                                    this.setState({
                                        componentEditShow: true,
                                        componentEditId: c.component_id,
                                    });
                                    closeFunc();
                                }
                            }//,{
                             //   label: "Näytä tulevat muutokset",
                             //   icon: "truck",
                             //   size: "tiny",
                             //   action: ( item, closeFunc ) => {
                             //       this.setState({
                             //           changesShow: true,
                             //            changesIndex: cIndex,
                             //       });
                            //     closeFunc();
                            //    }
                            //}
                        ]}
                        />
                    </td> 

                </tr>);
            });

            return mappedData;
            */
    }

    redirectToPage(type, id) {
        switch(type) {
            case 'po':
                window.emitter.emit('goTo', {pathname: `../../../../storage/purchase/order/${id}`});
                break;
        }
    }

    calculatePoSum(filtered) {
        let totalSum = 0;
        filtered.map(( c, cIndex ) => {
            c.supplier.map((s) => {
                if(s.id == this.state.selectedSupplierId) { totalSum += Number(c.count) * Number(s.price); }
            })
        });
        return totalSum;
    }

    doOrder(s)
    { 
        //console.log('s: ', s);
        let iPrice=0;
        let stors = this.state.storageBalance;
        
        //Filteröi pois ulkopuoliset komponentit
        stors = this.filterSuppliers();

        let stora = stors[0].location;
        let data = {};
        data.project_id = this.props.data.projectId;
        data.supplier_id = s.id;
        data.supplier_details = "";
        data.receiver_type = 'project';

        if (!this.state.regularStorageId) {
            data.receiver_id = stora.id;
            data.receiver_name = stora.delivery_name;
            data.receiver_address = stora.delivery_address;
            data.receiver_zipcode = stora.delivery_zipcode;
            data.receiver_city = stora.delivery_city;
            data.receiver_country = stora.delivery_country;
            data.receiver_contact_name = stora.delivery_contact;
        }
        
        data.dm_id = 1;
        data.dt_id = 1;
        data.pc_id = 1;

        data.date = '';
        data.reference_your = '';
        data.reference_our = tr('order_for_project');
        data.need_date = '';

        data.details = '';
        data.internal_commant = '';
        data.due_date = '';
        data.delivery_date = '';


        let ss = stors.filter(function (stor) {
            if(stor.count>0)
            {
                //console.log('stor: ', stor);
                let bOn = false;
                stor.supplier.map(function (st) {
                   if(st.name === s.name || st.name === tr('not_given'))
                   {
                        bOn = true;
                        iPrice = st.price;
                    }
                });
                
                if( bOn)
                {
                    stor.target_final_type = 'location';
                    stor.target_final_id = stora.id;
                    stor.purchase_order_price = iPrice;
                    return stor;
                }
            }
        })
        data.components = ss.map(c => {
            delete c.id;
            return c;
        });

        let path='';
       
        return api({
            method: 'post',
            url: 'storage/po/new',
            data: data,
        }).then( ( response ) => {
            if( response.id ) path = `/storage/purchase/order/id/${response.id}`;
            window.emitter.emit( 'goTo', { pathname: path } );
        }).catch((error) => {
            console.error(`doOrder ERROR: ${error}`);
            errorPopper(error, tr('save_error'));
        });

    }

}

export default ProjectStorage;
