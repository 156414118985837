import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import _ from 'lodash'

import api from 'services/Api/Api.js'
import SvgIcon from 'common/SvgIcon/SvgIcon.js'
import ApSelect from 'common/ApSelect/ApSelect.js'
import { ApAddon, ApInputStack, ApInput } from 'common/ApInput/ApInput'
import ApModal from 'common/ApModal/ApModal.js'
import { errorPopper, tr }  from 'services/Helpers/Helpers.js'
import ApButton from 'common/ApButton/ApButton.js'
import ApDropdown from 'common/ApDropdown/ApDropdown.js'
import { ApReactTable } from 'common/ApReactTable/ApReactTable.js'
import ApTooltip from 'common/ApTooltip/ApTooltip'

import './Checkout.css'

const Checkout = (props) => {
    const [companyName, setCompanyName] = useState(null)
    const [vat, setVat] = useState(24)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [storage, _setStorage] = useState(null)
    const [items, _setItems] = useState([])
    const [component, _setComponent] = useState('')
    const [currency, setCurrency] = useState('€')
    const [amount, setAmount] = useState(1)
    const [actions, setActions] = useState([])
    const [profitSettings, _setProfitSettings] = useState(null)
    const [inputValue, _setInputValue] = useState(null)

    const [rollingNumber, setRollingNumber] = useState(1)
    const [saleFinished, setSaleFinished] = useState(false)

    const [customer, setCustomer] = useState(null)
    const [openModal, setOpenModal] = useState(false)

    const [modifyItem, setModifyItem] = useState(null)
    const [modifyModalOpen, setModifyModalOpen] = useState(false)
    const [manualIndex, setManualIndex] = useState(1)

    const [importCollectionModal, setImportCollectionModal] = useState(false)
    const [collectionList, setCollectionList] = useState(null)
    const [collectionId, setCollectionId] = useState(null)

    const [checkbox, setCheckbox] = useState('')
    const [printReceipt, setPrintReceipt] = useState(true)
    const [browserZoomLevel, setBrowserZoomLevel] = useState(100)

    const inputRef = useRef(null)
    const inputValueRef = useRef(inputValue)
    const componentRef = useRef(component)
    const itemsRef = useRef(items)
    const profitSettingsRef = useRef(profitSettings)
    const storageRef = useRef(storage)

    useEffect(() => {
        setLoading(true)
        setBrowserZoomLevel(Math.round(window.devicePixelRatio * 100))

        api({
            method: 'get',
            url: 'checkout/settings',
        }).then(( response ) => {
            setCompanyName(response.company_name)
            setStorage(response.storage)
            setVat(response.default_vat ? response.default_vat : 24)
            setProfitSettings(response.profit_settings)

            if(response.currency === 'EUR') {
                setCurrency('€')
            }

            if (inputRef.current) {
                inputRef.current.focus();
            }

            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            errorPopper(error, tr('get_error'));
        });
    }, [])

    useEffect(() => {
        const checkoutListener = window.addEventListener('keyup', (event) => {
            if (event.key === 'Enter') {
                addOnEnter()
            }   
        })

        const zoomLevelListener = window.addEventListener('resize', () => {
            setBrowserZoomLevel(Math.round(window.devicePixelRatio * 100))
        })

        return () => {
            window.removeEventListener('keyup', checkoutListener)
            window.removeEventListener('resize', zoomLevelListener)
        }
    }, [])

    const addOnEnter = async () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
        
        let hasOne
        if (itemsRef.current.length > 0) {
            hasOne = itemsRef.current.find(item => {
                let hasIdentifier = false
                if (item.identifiers) {
                    item.identifiers.forEach(id => {
                        if (id.value.includes(inputValueRef.current)) {
                            hasIdentifier = true
                        }
                    })
                } 

                if (!hasIdentifier && 
                    item.manual && 
                    item.scancode &&
                    item.scancode === inputValueRef.current) {
                    hasIdentifier = true
                }

                if (hasIdentifier) {
                    return item
                }
            })
        } else {
            hasOne = false
        }

        const itemList = [...itemsRef.current]
        const isInNonFetched = itemList.find(item => item.scanned === true && item.name === inputRef.current.value)

        if (isInNonFetched) {
            //TODO: testaa toimivuus
            isInNonFetched.count = isInNonFetched.count + 1
            setItems(itemList)
            initComponentAdd()
            return
        }

        if (!hasOne && typeof inputValueRef.current === 'string' && inputValueRef.current !== null) {
            itemList.push({
                name: inputValueRef.current,
                scanned: true,
                price_sell: NaN,
                count: 1
            })
            await setItems(itemList)
            fetchComponentDetails(itemList.length -1, inputValueRef.current)
        } else if (!hasOne && typeof componentRef.current === 'object' && inputValueRef.current === null) {
            //manual enter
            add()
        } else {
            updateItemsList(hasOne)
        }

        initComponentAdd()     
    }

    const fetchComponentDetails = async (index, searchTerm) => {
        let newSearchTerm = searchTerm

        if ((searchTerm.length === 13 || searchTerm.length === 11) && searchTerm[0] == 0) {
            let firstIndex
            for(let i = 0; i < searchTerm.length; i++) {
                if (searchTerm[i] != 0) {
                    firstIndex = i
                    break
                }
            }

            newSearchTerm = searchTerm.slice(firstIndex, searchTerm.length)
        }
        
        const response = await api({
            method: 'post',
            url: 'storage/components',
            data: {
                search: newSearchTerm,
                formatter: 'checkout',
                filter_by_location_id: storageRef.current ? storageRef.current.id : false
            }
        })
        const updateList = [...itemsRef.current]
        if (response.length === 0) {
            updateList[index].notfound = true
            updateList[index].manual = true
            updateList[index].scanned = false
            updateList[index].scancode = searchTerm
            setItems(updateList)
            return
        }

        const newComponent = {...response[0], count: 1}
        const settings = profitSettingsRef.current

        updateList[index] = newComponent
        setItems(updateList)
    }

    const setComponent = data => {
        componentRef.current = data
        _setComponent(data)
    }

    const setItems = data => {
        itemsRef.current = data
        _setItems(data)
    }

    const setInputValue = data => {
        inputValueRef.current = data
        _setInputValue(data)
    }

    const setStorage = data => {
        storageRef.current = data
        _setStorage(data)
    }

    const setProfitSettings = data => {
        profitSettingsRef.current = data
        _setProfitSettings(data)
    }

    const calculateTotal = () => {
        const sum = items.reduce((a, b) => { return a + (Number(b.price_sell) * b.count) }, 0)
        return _.round(sum, 3)
    }

    const updateItemsList = (hasOne) => {
        const updatedComponent = {...hasOne}
            updatedComponent.count += amount

            const updatedItems = itemsRef.current.map((item) => {
                if (item.id === updatedComponent.id) {
                    return updatedComponent
                }
                return item
            })
            setItems(updatedItems)
            setActions(actions.concat({
                type: 'update',
                componentId: updatedComponent.id,
                amount: amount
            }))
    }

    const add = () => {
        const hasOne = itemsRef.current.find(item => item.id === componentRef.current.id)

        if (!hasOne) {
            const newComponent = {...componentRef.current, count: amount}
            const settings = profitSettingsRef.current

            const newItems = [...itemsRef.current]
            newItems.push(newComponent)
            setItems(newItems)

            setActions(actions.concat({
                type: 'new',
                componentId: newComponent.id,
                amount: amount
            }))
        } else {
            updateItemsList(hasOne)
        }
        initComponentAdd()
    }

    const initComponentAdd = () => {
        setComponent('')
        setAmount(1)
        setInputValue(null)

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const clearAll = () => {
        setLoading(false)
        setDisabled(false)
        setItems([])
        setComponent('')
        setAmount(1)
        setActions([])
        setSaleFinished(false)
        setCustomer(null)
        setOpenModal(false)
        setCollectionList(null)
        setImportCollectionModal(false)
        setCollectionId(null)
        setInputValue(null)
        setPrintReceipt(true)
        setCheckbox('')

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const removeCollection = async (id) => {
        try {
            await api({
                method: 'post',
                url: 'sales/collections/delete/id',
                data: {id}
            })
            setLoading(false)
        } catch (e) {
            setLoading(false)
            errorPopper(e, tr('delete_error'));
        }
    }

    const remove = () => {
        const oldActions = [...actions]
        const latestAction = oldActions[actions.length -1]

        if (!latestAction.type) return

        switch (latestAction.type) {
            case 'new':
                const oldItems = [...items]
                oldItems.pop()
                setItems(oldItems)

                const oldActions = [...actions]
                oldActions.pop()
                setActions(oldActions)
                break
            case 'update':
                const reverseComponent = items.find(item => item.id === latestAction.componentId)
                reverseComponent.count -= latestAction.amount

                const updatedItems = items.map((item) => {
                    if (item.id === reverseComponent.id) {
                        return reverseComponent
                    }
                    return item
                })
                setItems(updatedItems)

                const oldAction = [...actions]
                oldAction.pop()
                setActions(oldAction)
                break
            case 'remove':
                const itemListToAdd = [...items]
                itemListToAdd.splice(latestAction.index, 0, latestAction.component)

                setItems(itemListToAdd)

                const oldActionRemove = [...actions]
                oldActionRemove.pop()
                setActions(oldActionRemove)
                break
            case 'update-by-modal':
                const component = items.find(item => item.id === latestAction.componentId)
                component.price_sell = latestAction.price
                component.count = latestAction.count

                const newListToUpdate = items.map((item) => {
                    if (item.id === latestAction.componentId) {
                        return component
                    }

                    return item
                })

                setItems(newListToUpdate)

                const oldActionUpdate = [...actions]
                oldActionUpdate.pop()
                setActions(oldActionUpdate)
                break
            case 'fetch-collection':
                setItems([])
            default:
                break
        }
        
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const modifyEntry = (item, index) => {
        const deepCopyItem = {...item}
        setModifyItem({item: deepCopyItem, index})
        setModifyModalOpen(true)
    }

    const removeItem = () => {
        const index = modifyItem.index
        const newItemList = [...items]
        const removedItem = {...newItemList[index]}
        newItemList.splice(index, 1)

        setItems(newItemList)
        setActions(actions.concat({
            type: 'remove',
            component: removedItem,
            index: index
        }))

        setModifyItem(null)
        setModifyModalOpen(false)
    }

    const updateListWithManual = (newModifyItem) => {
        setItems(items.concat({
            id: modifyItem.item.id + manualIndex,
            name: modifyItem.item.name,
            price_sell: Number(newModifyItem.item.price_sell),
            count: Number(modifyItem.item.count)
        }))

        setActions(actions.concat({
            type: 'new-manual-item',
            componentId: modifyItem.item.id + manualIndex,
            price: Number(newModifyItem.item.price_sell),
            count: Number(modifyItem.item.count)
        }))

        const newIndex = manualIndex + 1
        setManualIndex(newIndex)
        setModifyItem(null)
        setModifyModalOpen(false)
    }

    const updateItem = async () => {
        const newModifyItem = {...modifyItem}

        if (vat > 0) {
            newModifyItem.item.price_sell = _.round((newModifyItem.item.price_sell / (1 + (vat / 100))), 3)
        }

        if (newModifyItem && newModifyItem.manual) {
            updateListWithManual(newModifyItem)
            return
        }

        console.log(modifyItem) //Täytyy olla. Jostain ihan ihmeellisestä syystä fyysinen viivakoodinlukija onnistuu painamaan manuaalisen lisäyksen modaalin jatka-nappia, jolloin tulee tänne vaikka ei kuuluisi. Ja jostain vielä kummallisemmasta syystä tämä estää tuon käyttäytymisen
        const index = newModifyItem.index
        const newItemList = [...items]
        const oldItem = {...newItemList[index]}
        newItemList[index].price_sell = Number(newModifyItem.item.price_sell)
        newItemList[index].count = Number(newModifyItem.item.count)
        newItemList[index].name = newModifyItem.item.name

        if (Object.keys(newModifyItem.item).includes('notfound')) {
            newItemList[index].notfound = newModifyItem.item.notfound
        }

        setItems(newItemList)
        setActions(actions.concat({
            type: 'update-by-modal',
            componentId: newModifyItem.item.id,
            price: oldItem.price_sell,
            count: oldItem.count
        }))

        setModifyItem(null)
        setModifyModalOpen(false)
    }

    const addManualComponent = () => {
        setModifyItem({
            manual: true,
            item: {
                manual: true,
                id: 'new',
                name: '',
                count: '',
                price_sell: ''
            }
        })
        setModifyModalOpen(true)
    }

    const checkModalValidity = () => {
        if (modifyItem && modifyItem.item.manual) {
            return !modifyItem.item.price_sell || !modifyItem.item.count || !modifyItem.item.name
        }

        return modifyItem && (!modifyItem.item.price_sell || !modifyItem.item.count)
    }

    const saveOrder = async () => {
        setLoading(true)
        const data = {
            type: 'delivery',
            related_type: `checkout-${checkbox}`,
            date: moment().format('YYYY-MM-DD'),
            components: items,
            price: (calculateTotal() * (1 + (vat / 100))).toFixed(2),
            receiver_name: customer ? customer : tr('cash_customer'),
            billing_name: customer ? customer : tr('cash_customer'),
            order_vat: vat,
            //require_project: false,
        }

        api({
            method: 'post',
            url: 'checkout/order/save',
            data: data
        }).then((response) => {
            if (checkbox=='bill') {
                downloadFile('bill', `${tr('cash_register_sales')}_${ rollingNumber }_${moment().format('DD_MM_YYYY')}`, 'pdf', response)
            }
            else {
                downloadFile('receipt', `${tr('cash_register_sales')}_${ rollingNumber }_${moment().format('DD_MM_YYYY')}`, 'pdf', response)
            }
        }).catch((error) => {
            setLoading(false)
            console.log('virhe:', error)
            errorPopper(error, tr('save_error'));
        })
    }

    const downloadFile = ( file, name, type, id ) => {
        if (printReceipt) {
            return api({
                method: 'get',
                url: `checkout/order/id/${ id }/file/${ file }/${ type }`,
                responseType: 'blob',
                errorPopper: tr('file_download_error'),
            }).then(( response ) => {
                const url = window.URL.createObjectURL( new Blob([ response ]) );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${ name }.${ type }` );
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setRollingNumber(rollingNumber + 1)
                setSaleFinished(true)
                setLoading(false)
                clearAll()
            }).catch( ( error ) => {
                setLoading(false)
            });
        } else {
            setRollingNumber(rollingNumber + 1)
            setSaleFinished(true)
            setLoading(false)
            clearAll()
        }        
    }

    const fetchCollection = async () => {
        setImportCollectionModal(true)
        setLoading(true)
        try {
            const response = await api({
                method: 'post',
                url: 'sales/collections/get',
                data: {
                    type: 'checkout'
                }
            })
            setCollectionList(response)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            errorPopper(e, tr('get_error'));
        }
    }

    const fetchCollectionDetails = async (id) => {
        try {
            const response = await api({
                method: 'post',
                url: 'sales/collections/get/id',
                data: {
                    id: id,
                    components: true
                }
            })
            
            const components = response.components.map((component) => {
                component.count = component.component_count
                component.id = component.component_id

                if (!component.id) {
                    component.name = `${component.component_name} (${tr('external')})`
                }
                
                return component
            })

            setActions(actions.concat({
                type: 'fetch-collection'
            }))

            setItems(components)
            setCollectionId(response.collection_id)
            setLoading(false)
            setImportCollectionModal(false)
        } catch (e) {
            setLoading(false)
            errorPopper(e, tr('get_error'));
        }
    }

    const renderCollectionTable = () => {
        const tableColumns = () => {
            return [    
                {
                    Header: 'Id',
                    id: 'id',
                    Cell: ({row}) => (
                        <div className='collection-list-row collection-list-row-text' 
                        onClick={() => fetchCollectionDetails(row._original.id)}
                        >{row._original.id}</div>
                    )               
                },
                {
                    Header: tr('description'),
                    id: 'description',
                    Cell: ({row}) => (
                        <div className='collection-list-row collection-list-row-text' 
                        onClick={() => fetchCollectionDetails(row._original.id)}
                        >{row._original.description}</div>
                    )               
                },
                /*
                {
                    Header: '',
                    id: 'delete',
                    width: 50,
                    className: 'overflowableOnHover',
                    Cell: ({row}) => (
                        <div className='collection-list-row' onClick={() => removeCollection(row._original.id)}
                        >
                            <ApTooltip position='left' text='Poista keräys'>
                                <SvgIcon icon="times-circle" type="solid" fill='red' style={{padding: '2px'}}/>
                            </ApTooltip>
                        </div>
                    )               
                },
                */
            ]
        }
    
        return(
            <div className="padding">
                <ApReactTable
                loading={ loading }
                data={collectionList ? collectionList : []}                     
                columns={ tableColumns() }
                minRows={ 4 }
                />
            </div>
        )
    }
 
    const renderBody = () => {
        if (!storage) {
            return (
                <div className="padding">
                    <div style={{display: 'flex', alignItems: 'flex-end'}}>
                        <SvgIcon icon="cubes" type="solid" style={{marginRight: '0.6em', marginBottom: '0.25em'}}/>
                        <h4>{ tr('storage_not_set') }</h4>
                    </div>
                    <div>{ tr('storage_not_set_info') }</div>
                </div>
            )
        }

        return (
            <div className="padding checkoutContainer">
                <div className="checkout-left-panel">
                    <div>
                        <div className={`pay-button ${items.length <= 0 || disabled ? ' disabled' : ''}`}
                             onClick={items.length > 0 ? () => {
                                 setOpenModal(true)
                                 setDisabled(true)
                                } : undefined}
                        >
                            <SvgIcon icon="shopping-cart" type="solid"/>
                            <p style={{marginLeft: '5px'}}>{ tr('go_to_pay') }</p>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '0.8em'}}>
                            <div className={`checkout-reverse-button ${items.length <= 0 || disabled ? ' disabled' : ''}`} 
                                 onClick={items.length > 0 ? () => remove() : undefined}
                            >
                                <SvgIcon icon="backspace" type="solid"/>
                                <p style={{marginLeft: '5px'}}>{ tr('back') }</p>
                            </div>

                            <div className={`checkout-add-button ${!component || disabled ? ' disabled' : ''}`} 
                                 onClick={component ? () => add() : undefined}
                            >
                                <SvgIcon icon="tags" type="solid"/>
                                <p style={{marginLeft: '5px'}}>{ tr('add') }</p>
                            </div>
                        </div>

                        <ApInputStack gap='0'>
                            <ApSelect
                                label={ tr('search_storage_component') }
                                loading={ loading }
                                value={component}
                                // optionRenderer={ this.dropDownOption }
                                optionRenderer="component_detail"
                                onChange={ (e) => {
                                    if (component !== '' && component !== e) {
                                        setAmount(1)
                                    }
                                    
                                    setComponent(e)
                                } }
                                objKeyId="id"
                                objKeyValue="name"
                                apiUrl="storage/components"
                                clearable
                                clearOnSelect
                                customChangeHandler={(e) => {
                                    setInputValue(e)
                                }}
                                customRef={inputRef}
                                apiData={{
                                    formatter: 'checkout',
                                    filter_by_location_id: storage ? storage.id : false
                                }}
                            />
                            <ApInput
                                width='70'
                                type="number"
                                name="amount"
                                id="amount"
                                align="right"
                                value={ amount }
                                onChange={ ( e ) => { 
                                    setAmount(e.target.value > 0 ? Number(e.target.value) : 1) 
                                } }
                                loading={ loading }
                            />
                            <ApAddon width="50" noLeftBorder> {component  && component.unit ? component.unit : tr('pcs')} </ApAddon>
                        </ApInputStack>
                    </div>

                </div>
                <div className='storageSummaryBox checkout-right-panel' style={{fontFamily: 'Roboto-Mono', width: '450px', maxWidth: '550px'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <ApTooltip text={ tr('add_manual_product') }>
                            <h1 className='checkout-header-container'
                                onClick={() => addManualComponent()}>
                                    <SvgIcon icon="tags" type="solid" className='checkout-header-logo'/>
                            </h1>
                        </ApTooltip>
                    </div>
                    <div className='vat-row'>
                        <div>{ tr('vat') }</div>
                        <button className={Number(vat) === 24 ? 'vat-selected' : ''}
                            onClick={() => setVat(24)}>24%</button>
                        <button className={Number(vat) === 0 ? 'vat-selected' : ''}
                            onClick={() => setVat(0)}>0%</button>
                    </div>
                    <hr />
                    <div>
                        <div key={`checkout-list-header`} className='receipt-row header-row'>
                            <div style={{paddingRight: '5px'}}>{ tr('product') }</div>
                            <div className='receipt-row-item'>{ tr('pcs') }</div>
                            <div className='receipt-row-item'>{ tr('unit_price') }</div>
                            <div className='receipt-row-item'>{ tr('in_total') }</div>
                        </div>
                        <hr />

                        {items.map((item, index) => 
                            { 
                                if (item.scanned) {
                                    return <div key={`checkout-list-${index}`}  onClick={() => modifyEntry(item, index)} className='receipt-row'>
                                        <div style={{paddingRight: '5px'}}>{item.name}</div>
                                        <div className='receipt-row-item'>{Number(item.count)}</div>
                                        <div className='receipt-row-item'><div className="apInlineLoader" /></div>
                                    </div>
                                }

                                if(item.notfound) {
                                    return <div key={`checkout-list-${index}`}  onClick={() => modifyEntry(item, index)} className='receipt-row checkout-not-found'>
                                        <div style={{paddingRight: '5px'}}>{ tr('product_not_found') }</div>
                                        <div className='receipt-row-item'>{Number(item.count)}</div>
                                    </div>
                                }
                            
                                return <div key={`checkout-list-${index}`}  onClick={() => modifyEntry(item, index)} className='receipt-row'>
                                    <div style={{paddingRight: '5px'}}>{item.name}</div>
                                    <div className='receipt-row-item'>{Number(item.count)}</div>
                                    <div className='receipt-row-item'>{_.round(Number(item.price_sell), 2).toFixed(2).replace('.', ',' )}</div>
                                    <div className='receipt-row-item'>{_.round((Number(item.price_sell) * Number(item.count)), 2).toFixed(2).replace('.', ',' )}</div>
                                </div>
                            }
                        )}
                    </div>
                    {items.length > 0 && <hr />}
                    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '3px'}}>
                        <div>{ tr('vat') } {vat}%</div>
                        <div>{_.round(((calculateTotal() * (1 + (vat / 100))) - calculateTotal()), 2).toFixed(2).replace('.', ',' )} {currency}</div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '3px', fontWeight: 'bold'}}>
                        <div>{ tr('in_total') } </div>
                        <div>{_.round((calculateTotal() * (1 + (vat / 100))), 2).toFixed(2).replace('.', ',' )} {currency}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id='pageCheckout'>
            <div className="apBox">
                <div className="apBoxHeader" style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: '1em'}}>
                        <h1>{ tr('checkout') }</h1>
                        {companyName ? <p>{companyName}</p> : <div className="apLoader" />}
                    </div>
                    
                    <ApDropdown
                        actionId='fetchCollection'
                        actions={[
                            {
                                label: tr('fetch_collection'),
                                icon: "dolly-flatbed",
                                disabled: loading,
                                action: ( item, closeFunc ) => {
                                    fetchCollection();
                                    closeFunc();
                                },
                            },
                        ]} 
                    />
                    <div className="apBoxCorner">
                        <ApButton
                            color="green"
                            onClick={() => {
                                props.history.push(`/storage/orders/checkout_archived`);
                            }}
                        >
                            {tr('cash_register_sales_archive')}
                        </ApButton>
                    </div>
                    
                </div>
                
                {companyName && renderBody()}
            </div>

            <Modal
				show={openModal}
				backdrop="static"
				bsSize="large"
			>
                <Modal.Header>
					<Modal.Title> 
						{ tr('payment_transaction') }
					</Modal.Title>
				</Modal.Header>

                {
                    loading ? 
                    <Modal.Body><div className="apLoader" /></Modal.Body> :
                    <Modal.Body>
                        {saleFinished ? 
                        <h4>{ tr('sale_completed') }</h4> : 
                        <div>
                            <ApInput
                                type='textarea'
                                label={ tr('customer_info') }
                                name="checkout_customer"
                                id="checkout_customer"
                                align="left"
                                value={ customer }
                                onChange={ ( e ) => setCustomer(e.target.value)}
                                loading={ loading }
                                disabled={loading}
                            />

                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '3px'}}>
                                <div>{ tr('vat') } {vat}%</div>
                                <div>{((calculateTotal() * (1 + (vat / 100))) - calculateTotal()).toFixed(2).replace('.', ',' )} {currency}</div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '3px', fontWeight: 'bold', fontSize: '1.2em'}}>
                                <div>{ tr('in_total') } </div>
                                <div>{(calculateTotal() * (1 + (vat / 100))).toFixed(2).replace('.', ',' )} {currency}</div>
                            </div>
                        </div>
                        }
                    </Modal.Body>
                }

                <Modal.Footer>
                    {saleFinished ? 
                        <ApButton 
                            color="green" 
                            onClick={() => {
                                if (collectionId) {
                                    removeCollection(collectionId)
                                }
                                clearAll()
                            }}
                        >
                            { tr('start_new_customer_transaction') }
                        </ApButton>  :  
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{fontSize: `${browserZoomLevel > 80 ? '1' : '1.2'}em`}}>
                                <div>
                                    <b style={{color: '#0099CC', marginRight: '1.55em'}}>{ tr('payment_method') }</b>
                                    <label style={{marginRight: '1em'}}>
                                        <span style={{marginRight: '5px'}}>{ tr('card') }</span>
                                        <input
                                            type='radio'
                                            name='payment'
                                            value='card'
                                            checked={checkbox === 'card'}
                                            onChange={() => {
                                                if (checkbox === 'card') {
                                                    setCheckbox('')
                                                } else {
                                                    setCheckbox('card')
                                                }
                                            }}/>
                                    </label>
                                    <label style={{marginRight: '1em'}}>
                                        <span style={{marginRight: '5px'}}>{ tr('cash') }</span>
                                        <input
                                            type='radio'
                                            name='payment'
                                            value='cash'
                                            checked={checkbox === 'cash'}
                                            onChange={() => {
                                                if (checkbox === 'cash') {
                                                    setCheckbox('')
                                                } else {
                                                    setCheckbox('cash')
                                                }
                                            }}/>
                                    </label>
                                    <label>
                                        <span style={{marginRight: '5px'}}>{ tr('invoice') }</span>
                                        <input
                                            type='radio'
                                            name='payment'
                                            value='bill'
                                            checked={checkbox === 'bill'}
                                            onChange={() => {
                                                if (checkbox === 'bill') {
                                                    setCheckbox('')
                                                } else {
                                                    setCheckbox('bill')
                                                }
                                            }}/>
                                    </label>
                                </div>
                                <div style={{float: 'left'}}>
                                    <b style={{color: '#0099CC', marginRight: '1em'}}>{ tr('receipt') }</b>
                                    <label style={{marginRight: '1em'}}>
                                        <span style={{marginRight: '5px'}}>{ tr('print_receipt') }</span>
                                        <input
                                            type='checkbox'
                                            checked={printReceipt}
                                            onChange={() => {
                                               setPrintReceipt(!printReceipt)
                                            }}/>
                                    </label>
                                </div>
                            </div>

                            <div>
                                <ApButton 
                                    color="blue"
                                    disabled={loading} 
                                    onClick={() => {
                                        setOpenModal(false)
                                        setDisabled(false)

                                        if (inputRef.current) {
                                            inputRef.current.focus();
                                        }
                                    }}
                                >
                                    { tr('cancel') }
                                </ApButton>
                                {' '}
                                <ApButton 
                                    color="green" 
                                    onClick={() => saveOrder()}
                                    disabled={loading || !checkbox}
                                >
                                    {printReceipt ? tr('accept_print_receipt') : tr('accept')}
                                </ApButton>
                            </div>
                        </div>
                    }
                </Modal.Footer>
            </Modal>

            {/*importing existing collection */}
            <ApModal
                show={importCollectionModal}
                handleClose={ () => {
                    setImportCollectionModal(false)
                } }
                className="narrow overflow"
                header={<h1 style={{marginLeft: '0.5em'}}>{ tr('collection') }</h1>}
                body={renderCollectionTable()}
            />

            {/*Adding & modifying */}
            <ApModal
                show={modifyModalOpen}
                handleClose={ () => {
                    setModifyItem(null)
                    setModifyModalOpen(false)
                } }
                className="narrow overflow"

                header={
                    <div className="padding">
                        {modifyItem && modifyItem.item.manual ?
                            <span>{ tr('add_manual_sales_product') }</span>
                            : <span>{ tr('edit_sales_product') } {modifyItem ? `(${modifyItem.item.name})` : ''}</span>
                        }
                        
                    </div>
                }
                body={ <div className="padding">
                        {modifyItem && modifyItem.item.manual && <ApInput
                            type="text"
                            id="name"
                            name="name"
                            label={ tr('name') }
                            required
                            value={  modifyItem ? modifyItem.item.name : '' }
                            onChange={ (e) => {
                                const newItem = {...modifyItem}
                                newItem.item.name = e.target.value
                                if (Object.keys(newItem.item).includes('notfound')) {
                                    newItem.item.notfound = false
                                }
                                setModifyItem(newItem)
                            } }
                        />}
                        <ApInput
                            type="number"
                            id="count"
                            name="count"
                            label={ tr('amount') }
                            required
                            value={  modifyItem ? modifyItem.item.count : '' }
                            onChange={ (e) => {
                                const newItem = {...modifyItem}
                                newItem.item.count = e.target.value
                                if (Object.keys(newItem.item).includes('notfound')) {
                                    newItem.item.notfound = false
                                }
                                setModifyItem(newItem)
                            } }
                        />
                        <ApInput
                        type="number"
                        id="price"
                        name="price"
                        label={ tr('price') }
                        required
                        value={  modifyItem ? modifyItem.item.price_sell : '' }
                        onChange={ (e) => {
                            const newItem = {...modifyItem}
                            newItem.item.price_sell = Number(e.target.value)

                            if (Object.keys(newItem.item).includes('notfound')) {
                                newItem.item.notfound = false
                            }
                            setModifyItem(newItem)
                        } }
                        />
                </div> }
                footer={ <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: '1em', marginRight: '1em'}}>
                    <ApButton onClick={ () => {
                        setModifyItem(null)
                        setModifyModalOpen(false)
                    } }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>

                    {modifyItem && modifyItem.item.manual ? null :
                        <ApButton color="red" onClick={ () => removeItem() }>
                            <SvgIcon icon="trash-alt" type="regular" />
                            { tr('delete') }
                        </ApButton>
                    }

                    <ApButton color="green" 
                        onClick={ () => updateItem() }
                        disabled={checkModalValidity()}
                    >
                        <SvgIcon icon="check" type="solid" />
                        { tr('continue') }
                    </ApButton>

            </div> }
        />
        </div>
    )
}

export default Checkout